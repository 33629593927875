import React, { useContext } from "react";

import Overlay from "../Overlay";
import { ModalContent } from "./Modal";
import { ModalAnimated } from "./Modal";
import { ModalContext } from "./Modal";

export const MobileModal = ({ className, nondisposable, ...modalProps }) => {
  const { closeModal } = useContext(ModalContext);

  return (
    <ModalAnimated>
      <Overlay
        close={closeModal}
        className={className}
        nondisposable={nondisposable}
        children={<ModalContent {...modalProps} />}
      />
    </ModalAnimated>
  );
};
