export const nameForChannel = name => {
  return (
    {
      airbnb: "Airbnb",
      booking: "Booking.com",
      expedia: "Expedia",
      homeaway: "VRBO",
      tripadvisor: "Tripadvisor"
    }[name] || name
  );
};
