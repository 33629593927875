import "./Photos.scss";

import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import Card from "../../../../components/common/Card";
import { PhotosUpload } from "../../../../components/common/PhotosUpload";
import { useHomeInfoContext } from "../HomeInfoContext";
import {
  DEFAULT_PROPERTY_FILE_FOLDER,
  PhotosContextProvider,
  usePhotosContext
} from "../PhotosContext";
import { PhotosGallery } from "./photos/PhotosGallery";

export const PhotosContent = () => {
  const { t } = useTranslation("common");
  const [error, setError] = useState(null);
  const { setUploading, uploading, uploadImage, getPhotos, photos, getError } =
    usePhotosContext();
  const { data } = useHomeInfoContext();

  const handleUpload = useCallback(
    acceptedFiles => {
      setUploading(true);

      Promise.allSettled(
        acceptedFiles.map(file => {
          return uploadImage(file, DEFAULT_PROPERTY_FILE_FOLDER);
        })
      ).then(res => {
        setUploading(false);
        getPhotos();

        if (res.every(item => item.status === "rejected")) {
          setError(t("problem_uploading_photos"));
        } else if (res.find(item => item.status === "rejected")) {
          const errorFiles = res
            .filter(item => item.status === "rejected")
            .map(item => item.reason.filename)
            .join(", ");

          setError(t("problem_uploading_some_photos", { errorFiles }));
        }
      });
    },
    [uploadImage, t, setUploading, getPhotos]
  );

  return (
    <Card className="HomeInfo__container Photos">
      {data.can_edit && (
        <PhotosUpload
          handleUpload={handleUpload}
          isUploading={uploading}
          error={error}
        />
      )}

      <h3 className="small-header"> {t("uploaded_files")} </h3>
      <PhotosGallery photos={photos} getError={getError} />
    </Card>
  );
};

export const Photos = () => {
  return (
    <PhotosContextProvider>
      <PhotosContent />
    </PhotosContextProvider>
  );
};
