import "./StripeCard.scss";

import { receiveCardDetails } from "data/actions/billing";
import get from "lodash/get";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Elements, StripeProvider } from "react-stripe-elements";
import { compose } from "redux";
import api from "services/api";

import CardForm from "./StripeCardForm";

class StripeCard extends Component {
  state = {
    submitting: false,
    error: false
  };

  handleSubmitCard = async stripe => {
    if (this.state.submitting) {
      return;
    }

    this.setState({ error: false, submitting: true });
    const { receiveCardDetails, toggleUpdate } = this.props;

    try {
      const { setup_intent_client_secret } = await api.post(
        "/v1/hosts/billing/setup_card"
      );

      const result = await stripe.handleCardSetup(setup_intent_client_secret);

      if (result.error) {
        throw result.error;
      }

      const { setupIntent } = result;
      const card = await api.post(
        `/v1/hosts/billing/setup_card/${setupIntent.id}/fulfill`
      );

      receiveCardDetails(card);
      toggleUpdate(false);
    } catch (e) {
      const error = get(e, "error.message", e.message);

      this.setState({
        error,
        submitting: false
      });
    } finally {
      this.setState({ submitting: false });
    }
  };

  render() {
    const { error, submitting } = this.state;

    return (
      <StripeProvider stripe={this.props.stripe}>
        <Elements>
          <CardForm
            onSubmitCard={this.handleSubmitCard}
            error={error}
            submitting={submitting}
            {...this.props}
          />
        </Elements>
      </StripeProvider>
    );
  }
}

export default compose(
  withTranslation("host.billing"),
  connect(
    state => ({
      card: state.billing.card
    }),
    { receiveCardDetails }
  )
)(StripeCard);
