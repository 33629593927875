export const hostNetEarningsForDisplay = revenue => {
  if (!revenue) {
    return null;
  }

  const {
    cleaning_fee_included_in_net: includeCleaningFee,
    net_host_booking_revenue_excl_cleaning: hostRevenueExclCleaning,
    net_host_booking_revenue: hostRevenue
  } = revenue;

  return includeCleaningFee ? hostRevenueExclCleaning : hostRevenue;
};

export const hostSummaryEarningsForDisplay = (revenue, channelName) => {
  if (!revenue) {
    return null;
  }

  const {
    cleaning_fee_included_in_net: includeCleaningFee,
    gross_host_booking_revenue: grossHostBookingRevenue,
    net_host_booking_revenue_excl_cleaning: hostRevenueExclCleaning,
    net_host_booking_revenue: hostRevenue
  } = revenue;

  if (channelName === "Airbnb") {
    return grossHostBookingRevenue;
  }

  return includeCleaningFee ? hostRevenueExclCleaning : hostRevenue;
};
