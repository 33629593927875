import "./OpportunityForm.scss";

import classNames from "classnames";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import Button from "../../../../components/buttons/Button";
import DefaultError from "../../../../components/common/DefaultError";
import Checkbox from "../../../../components/forms/Checkbox";
import i18n from "../../../../i18n";

const AVAILABILITY_CONFIRMATION_INITIAL_STATE = {
  time: false,
  space: false
};

const validationSchema = yup.object().shape({
  bedrooms: yup
    .number()
    .required(i18n.t("common:errors.field_required"))
    .min(0, i18n.t("common:invalid_min", { min: 0 }))
    .max(16, i18n.t("common:invalid_max", { max: 16 })),
  city: yup.string().required(i18n.t("common:errors.field_required")),
  locality_id: yup.string().required(i18n.t("common:errors.field_required")),
  street: yup.string().required(i18n.t("common:errors.field_required"))
});

const CityField = ({ localities }) => {
  const { t } = useTranslation("host.opportunity");
  const {
    setFieldValue,
    touched,
    values: { locality_id }
  } = useFormikContext();

  useEffect(() => {
    const localityName = localities.find(
      locality => +locality.id === +locality_id
    )?.name;

    if (!touched.city) {
      setFieldValue("city", localityName);
    }
  }, [localities, locality_id, setFieldValue, touched]);

  return (
    <div className="Opportunity-form__field">
      <label htmlFor="opportunityFormCity">{t("form.city")}</label>
      <Field
        component="input"
        id="opportunityFormCity"
        type="text"
        name="city"
        required
      />
      <ErrorMessage name="city" component={DefaultError} />
    </div>
  );
};

const OpportunityForm = ({
  handleSubmit,
  initialValues,
  localities,
  serverError
}) => {
  const { t } = useTranslation("host.opportunity");

  const [availabilityConfirmation, setAvailabilityConfirmation] = useState(
    AVAILABILITY_CONFIRMATION_INITIAL_STATE
  );

  const [confirmationError, setConfirmationError] = useState(false);

  const prepareValues = values => ({
    ...values,
    locality_id: +values.locality_id
  });

  const onSubmit = values => {
    if (!availabilityConfirmation.time || !availabilityConfirmation.space) {
      setConfirmationError(true);
      return;
    }
    setConfirmationError(false);
    handleSubmit(prepareValues(values));
  };

  const handleAvailabilityChange =
    property =>
    ({ target: { checked } }) => {
      setAvailabilityConfirmation({
        ...availabilityConfirmation,
        [property]: checked
      });
    };

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      <Form className="Opportunity-form">
        <p className="header Opportunity-form__header">
          {t("form.description1")}
        </p>
        <p className="small-header">{t("form.description2")}</p>

        <fieldset className="Opportunity-form__fieldset">
          <div className="Opportunity-form__fields">
            <div className="Opportunity-form__field">
              <label htmlFor="opportunityFormStreet">{t("form.street")}</label>
              <Field
                component="input"
                id="opportunityFormStreet"
                type="text"
                name="street"
                required
              />
              <ErrorMessage name="street" component={DefaultError} />
            </div>
          </div>

          <div className="Opportunity-form__fields">
            <div className="Opportunity-form__field">
              <label htmlFor="opportunityFormPostcode">
                {t("form.postcode")}
              </label>
              <Field
                component="input"
                id="opportunityFormPostcode"
                type="text"
                name="postcode"
              />
              <ErrorMessage name="postcode" component={DefaultError} />
            </div>

            <div className="Opportunity-form__field">
              <label htmlFor="opportunityFormLocality">
                {t("form.locality")}
              </label>
              <Field component="select" name="locality_id" required>
                {localities.map(locality => (
                  <option key={locality.id} value={locality.id}>
                    {locality.name}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="locality_id" component={DefaultError} />
            </div>
          </div>

          <div className="Opportunity-form__fields">
            <CityField localities={localities} />

            <div className="Opportunity-form__field">
              <label htmlFor="opportunityFormNumberOfBedrooms">
                {t("form.bedrooms")}
              </label>
              <Field
                component="input"
                type="number"
                id="opportunityFormNumberOfBedrooms"
                name="bedrooms"
                required
              />
              <ErrorMessage name="bedrooms" component={DefaultError} />
            </div>
          </div>
        </fieldset>

        <fieldset className="Opportunity-form__fieldset">
          <legend className="header">
            <span className="Opportunity-form__confirm">
              {t("form.confirm1")}
            </span>
            <span className="Opportunity-form__confirm">
              {t("form.confirm2")}
            </span>
          </legend>

          <div className="Opportunity-form__fields">
            <div className="Opportunity-form__field">
              <Checkbox
                name="availability_time"
                className={classNames("Checkbox", {
                  "Checkbox--error":
                    confirmationError && !availabilityConfirmation.time
                })}
                label={t("form.availability_time")}
                id="opportunityFormAvailabilityTime"
                onChange={handleAvailabilityChange("time")}
                checked={availabilityConfirmation.time}
                dataCy="checkbox-availability"
              />
            </div>
          </div>

          <div className="Opportunity-form__fields">
            <div className="Opportunity-form__field">
              <Checkbox
                name="availability_space"
                className={classNames("Checkbox", {
                  "Checkbox--error":
                    confirmationError && !availabilityConfirmation.space
                })}
                label={t("form.availability_space")}
                id="opportunityFormAvailabilitySpace"
                onChange={handleAvailabilityChange("space")}
                checked={availabilityConfirmation.space}
                dataCy="checkbox-space"
              />
            </div>
          </div>
        </fieldset>

        {confirmationError && (
          <DefaultError testId="confirmation-error">
            {t("form.confirm_error")}
          </DefaultError>
        )}

        {serverError && (
          <DefaultError testId="server-error">{serverError}</DefaultError>
        )}

        <div className="Opportunity-form__submit">
          <Button type="submit" data-testid="form-submit">
            {t("form.submit")}
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default OpportunityForm;
