import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Alert from "../../../../../components/common/Alert";
import Modal from "../../../../../components/common/modal";
import { BankAccountChangeDetails } from "./BankAccountChangeDetails";
import { PasswordConfirmation } from "./PasswordConfirmation";
import { STEP_CHANGE, STEP_DONE, STEP_PASSWORD } from "./steps";

export const BankAccountEdit = ({ handleClose }) => {
  const [step, setStep] = useState(STEP_CHANGE);
  const [formData, setFormData] = useState({});
  const { t } = useTranslation("host.billing");

  const modalTitle = useMemo(() => {
    switch (step) {
      case STEP_CHANGE:
        return t("bank_account.modal.title");
      case STEP_PASSWORD:
        return t("bank_account.password_confirm");
      case STEP_DONE:
        return t("bank_account_updated");
      default:
        return null;
    }
  }, [step, t]);

  if (step === STEP_DONE) {
    return (
      <Alert success fixed renderCloseButton onCloseClick={handleClose}>
        {t("bank_account_updated")}
      </Alert>
    );
  }

  return (
    <Modal
      title={modalTitle}
      closeModal={handleClose}
      testId="bank-account-modal"
    >
      {step === STEP_CHANGE && (
        <BankAccountChangeDetails
          setStep={setStep}
          setFormData={setFormData}
          handleCancel={handleClose}
          formData={formData}
        />
      )}

      {step === STEP_PASSWORD && (
        <PasswordConfirmation formData={formData} setStep={setStep} />
      )}
    </Modal>
  );
};
