import { parseDateTime } from "../../utils/dateParser";
import {
  PATCH_MAINTENANCE_ITEM,
  RECEIVE_MAINTENANCE_LIST
} from "../maintenance/types";

const maintenance = (
  state = {
    byProperty: {}
  },
  action
) => {
  switch (action.type) {
    case RECEIVE_MAINTENANCE_LIST: {
      const { propertyId, maintenance } = action;

      const newMaintenanceList = maintenance.map(parseMaintenance);

      return {
        ...state,
        byProperty: {
          [propertyId]: newMaintenanceList
        }
      };
    }

    case PATCH_MAINTENANCE_ITEM:
      return {
        ...state,
        byProperty: {
          ...state.byProperty,
          [action.propertyId]: state.byProperty[action.propertyId].map(
            existingMaintenanceItem => {
              return existingMaintenanceItem.id === action.maintenanceItem.id
                ? parseMaintenance(action.maintenanceItem)
                : existingMaintenanceItem;
            }
          )
        }
      };

    default:
      return state;
  }
};

export const parseMaintenance = maintenanceItem => {
  return {
    ...maintenanceItem,
    created_at: parseDateTime(maintenanceItem.created_at),
    resolution_date: parseDateTime(maintenanceItem.resolution_date),
    pictures: maintenanceItem.pictures
      ? maintenanceItem.pictures.map(image => ({
          ...image,
          created_at: parseDateTime(image.created_at)
        }))
      : []
  };
};

export default maintenance;
