import logger from "utils/logger";

const ENV = [
  "host.staging.airsorted.com",
  "dashboard.staging.houst.com"
].includes(window.location.host)
  ? "staging"
  : process.env.NODE_ENV;

const isProduction = ENV === "production";
const isStaging = ENV === "staging";
const isDevelopment = ENV === "development";

const API_HOST = isProduction
  ? "https://api.houst.com"
  : isStaging
  ? "https://api.staging.houst.com"
  : "http://localhost:3001";

const SEGMENT_KEY = isProduction
  ? "c9Fex0WdVZPZiKoALQ8xR2fjznsLCKSn"
  : isStaging
  ? "fWHFXVxU8C2dbmrQzEJLEhtFLQBoVToR"
  : null;

const SENTRY_DSN =
  isProduction || isStaging
    ? "https://0530fad957c84dbfb853b0e33e45e6c3@sentry.io/1248133"
    : null;

const SF_ACTION_URL = isProduction
  ? "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
  : "https://airsorted--uat.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8";

const SF_REDIRECT_URL = isProduction
  ? "https://dashboard.houst.com/maintenance?success=true"
  : "https://dashboard.staging.houst.com/maintenance?success=true";

const SF_ORGID = isProduction ? "00D1r000002BfRR" : "00D1w0000000eNL";

const SF_PROPID_ID = isProduction ? "00N1r00000KQDfT" : "00N1w000001PdJV";

const SF_CATEGORY_ID = isProduction ? "00N1r00000KnqGw" : "00N1w000001PdJz";

const SF_SUB_CATEGORY_ID = isProduction ? "00N1r00000KQDeB" : "00N1w000001PdK4";

const SF_MAINTENANCE_ID = isProduction
  ? "00N1r00000L23Lc"
  : "00N1w000001QyTtEAK";

const SF_CONTACT_FORM = {
  SF_ACTION_URL,
  SF_REDIRECT_URL,
  SF_ORGID,
  SF_PROPID_ID,
  SF_CATEGORY_ID,
  SF_SUB_CATEGORY_ID,
  SF_MAINTENANCE_ID
};

const FRONT_FORM_ACTION_URL = isProduction
  ? "https://webhook.frontapp.com/forms/airsorted/0SJyLF2W1Z7acVMKPb39hKmIjJxIAB7WaLKpw4Af_VzgbYfmq980xp_G7naP4xG-dyBH-seJrSPHHBl7xBzoWYH2VeKLSDupIp2QMhfBzQ"
  : isDevelopment || isStaging
  ? "https://webhook.frontapp.com/forms/airsorted/GjKtHzzESr5iLIElFqq1d0Xh6sr2ovM4f4LxvtRWLKH115mfYnBz3yMkyjwIuWDrWqryljZC6eJHvcXW3liCJCVpqCEG95o0IDwGQ_ZzpQ"
  : null;

const DELIGHTED_KEY = ENV === "production" ? "bFg2wWjI2UZjvhzo" : null;

const config = {
  ...process.env,
  API_HOST,
  SEGMENT_KEY,
  SENTRY_DSN,
  SF_CONTACT_FORM,
  FRONT_FORM_ACTION_URL,
  DELIGHTED_KEY
};

logger.info("Running with config", config);

export default config;
