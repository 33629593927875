import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { receiveBankAccountDetails } from "../data/actions/billing";
import { bankAccountSelector } from "../data/properties/selectors";
import api from "../services/api";

export const useBankAccount = () => {
  const [loadingBankAccount, setLoadingBankAccount] = useState(true);
  const dispatch = useDispatch();

  // With useSelector(), returning a new object every time will always force a re-render by default.
  // More info: https://react-redux.js.org/api/hooks#equality-comparisons-and-updates
  const bankAccount = useSelector(bankAccountSelector, shallowEqual);

  useEffect(() => {
    const fetchBilling = async () => {
      try {
        setLoadingBankAccount(true);

        const res = await api.get(`/v1/hosts/billing`);
        if (res) {
          dispatch(receiveBankAccountDetails(res.bank_account));
        }
      } finally {
        setLoadingBankAccount(false);
      }
    };

    fetchBilling();
  }, [bankAccount, dispatch]);

  return {
    loadingBankAccount,
    isMissingBankAccount: !loadingBankAccount && isEmpty(bankAccount)
  };
};
