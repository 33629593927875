import Button from "components/buttons/Button";
import Alert from "components/common/Alert";
import Input from "components/common/Input";
import Logo from "components/common/Logo";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";

import { LangSelect } from "../../../components/LangSelect";
import api from "../../../services/api";

const ForgottenPassword = () => {
  const location = useLocation();
  const { t } = useTranslation("host.auth");
  const isLoggedIn = useSelector(state => !!state.user.authToken);
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (location.state.email) {
      setEmail(location.state.email);
    }
    if (location.state.error) {
      setError(location.state.error);
    }
  }, [location]);

  const onEmailChange = value => {
    setEmail(value);
    setErrors({
      ...errors,
      email: null
    });
  };

  const submit = event => {
    event.preventDefault();

    api
      .post("/v1/hosts/user/request_password_reset", null, {
        email
      })
      .then(() => {
        setSuccess(true);
      })
      .catch(e => {
        const errors =
          e.error.code === 400
            ? e.error.message
            : { email: t("forgotten_password.problem_requesting") };

        setErrors(errors);
      });
  };

  const renderForgottenPasswordForm = () => {
    if (isLoggedIn) {
      return <Navigate to={{ pathname: "/" }} replace />;
    }

    return (
      <>
        <h4 className="Login__title header">{t("forgotten_password.title")}</h4>

        {error && (
          <div className="Login__error">
            <Alert danger>
              <p>
                <small>{error}</small>
              </p>
            </Alert>
          </div>
        )}

        <form onSubmit={submit}>
          <div className="Login__form">
            <div className="Login__group">
              <Input.Email
                label={t("login.form.email_label")}
                name="email"
                onChange={onEmailChange}
                value={email}
                error={errors["email"]}
                placeholder={t("login.form.email_placeholder")}
              />
            </div>

            <Button type="submit">
              {t("forgotten_password.request_instructions")}
            </Button>
          </div>

          <div className="LoginForm__secondary-action">
            <Link to="/login" state={{ email }}>
              {t("forgotten_password.cancel_request")}
            </Link>
          </div>
        </form>
      </>
    );
  };

  const renderSuccess = () => (
    <>
      <h4 className="Login__title header">
        {t("forgotten_password.success.title")}
      </h4>

      <p>{t("forgotten_password.success.text1")}</p>

      <p>{t("forgotten_password.success.text2")}</p>

      <div className="Login__link">
        <Button outline type="button">
          <Link to="/login" state={{ email }}>
            {t("common:login")}
          </Link>
        </Button>
      </div>
    </>
  );

  return (
    <div className="Signup Login">
      <div className="shapes-background" />
      <div className="Signup__layout-container">
        <div className="LoginForm">
          <div className="LoginForm__topContainer">
            <div className="dummy" />
            <Logo />
            <LangSelect />
          </div>

          {success ? renderSuccess() : renderForgottenPasswordForm()}
        </div>
      </div>
    </div>
  );
};

export default ForgottenPassword;
