import "./Accordion.scss";

import { ReactComponent as ChevronIcon } from "assets/icons/chevron.svg";
import classNames from "classnames";
import React, { useCallback, useState } from "react";

export const Accordion = ({ items }) => {
  const [openedItems, setOpenedItems] = useState(items.map(() => false));

  const handleClick = clickedIndex =>
    setOpenedItems(
      openedItems.map(
        (item, index) => index === clickedIndex && !openedItems[clickedIndex]
      )
    );

  return (
    <div className="Accordion">
      {items.map((item, index) => (
        <AccordionItem
          key={item.header}
          index={index}
          header={item.header}
          content={item.content}
          opened={openedItems[index]}
          onClick={handleClick}
        />
      ))}
    </div>
  );
};

export const AccordionItem = ({ index, header, content, opened, onClick }) => {
  const handleClick = useCallback(() => onClick(index), [index, onClick]);

  return (
    <>
      <div
        className={classNames("Accordion__header", { opened })}
        onClick={handleClick}
      >
        <ChevronIcon />
        <h3 className="Signup__small-header">{header}</h3>
      </div>

      <div className={classNames("Accordion__content", { opened })}>
        {content}
      </div>
    </>
  );
};
