import "./Logo.scss";

import { ReactComponent as HogarLogoSVG } from "assets/icons/hogar_listo_logo.svg";
import { ReactComponent as HoustLogoSVG } from "assets/icons/logo.svg";
import React from "react";
import bem from "utils/bem";

const Logo = ({ light, countryCode }) => {
  const Logo = countryCode === "ES" ? HogarLogoSVG : HoustLogoSVG;

  return (
    <div className={bem("logo", { light })}>
      <Logo />
    </div>
  );
};

export default Logo;
