import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  selectedPropertyLocalitySelector,
  selectedPropertyTaxLabelSelector
} from "../../data/properties/selectors";
import { formatAmountWithCurrency } from "../../utils/numbers";
import { hostNetEarningsForDisplay } from "../../utils/revenue";

const PayoutsSummary = () => {
  const { t } = useTranslation("host.bookings");

  return (
    <div className="PriceBreakdown__summary-text">
      <p>{t("booking.breakdown.summary.disclaimer")}</p>
      <p>
        <Link to="/billing">
          {t("booking.breakdown.summary.view_transactions_label")}
        </Link>
      </p>
    </div>
  );
};

export const RevenueWithoutCleaningFee = ({ revenue, channelName }) => {
  const taxLabel = useSelector(selectedPropertyTaxLabelSelector);
  const locality = useSelector(selectedPropertyLocalitySelector);
  const { t } = useTranslation("host.bookings");

  const {
    total_booking_revenue: totalBookingRevenue,
    channel_commission_host: channelCommision,
    houst_commission: houstCommision,
    houst_commission_vat: houstCommissionVat
  } = revenue;

  const netBookingRevenue = hostNetEarningsForDisplay(revenue);

  const Row = ({ children, className, label }) => (
    <tr className={className}>
      <td className="section-label">{label}</td>
      <td className="PriceBreakdown__breakdown__amount">{children}</td>
    </tr>
  );

  return (
    <>
      <table className="PriceBreakdown__breakdown">
        <tbody>
          <Row label={t("revenue.total_booking", { channelName })}>
            {formatAmountWithCurrency(totalBookingRevenue, locality)}
          </Row>
          <Row label={t("revenue.platform_charge", { channelName })}>
            &minus;{formatAmountWithCurrency(channelCommision, locality)}
          </Row>
          <Row label={t("revenue.houst_management_fee")}>
            &minus;{formatAmountWithCurrency(houstCommision, locality)}
          </Row>
          <Row label={taxLabel}>
            &minus;{formatAmountWithCurrency(houstCommissionVat, locality)}
          </Row>
          <Row
            className="PriceBreakdown__breakdown__total"
            label={t("revenue.net_revenue")}
          >
            {formatAmountWithCurrency(netBookingRevenue, locality)}
          </Row>

          <tr className="PriceBreakdown__breakdown__margin-row" />
        </tbody>
      </table>

      <PayoutsSummary />
    </>
  );
};
