import "./Maintenance.scss";

import Alert from "components/common/Alert";
import TabControl from "components/common/TabControl";
import { receiveMaintenance } from "data/actions/maintenance";
import {
  getSelectedProperty,
  getSelectedPropertyId
} from "data/properties/selectors";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import api from "services/api";

import {
  maintenanceCountSelector,
  maintenanceListSelector
} from "../../../data/maintenance/selectors";
import { log } from "../../../utils/logger";
import { parseQuerystring } from "../../../utils/querystring";
import MaintenanceList from "./components/MaintenanceList";
import { useMaintenanceTabs } from "./useMaintenanceTabs";

export const Maintenance = ({
  receiveMaintenance,
  maintenance,
  maintenanceCount,
  selectedPropertyId,
  history
}) => {
  const { t } = useTranslation("host.contact");
  const [loading, setLoading] = useState(true);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const { headers, currentTabId, updateCurrentTabId, currentTabData } =
    useMaintenanceTabs(maintenance);

  useEffect(() => {
    api
      .get(`/v1/hosts/properties/${selectedPropertyId}/maintenances`)
      .then(res => {
        receiveMaintenance({
          maintenance: res,
          propertyId: selectedPropertyId
        });

        setLoading(false);
      })
      .catch(err => log(err))
      .finally(() => setLoading(false));
  }, [selectedPropertyId, receiveMaintenance]);

  useEffect(() => {
    const { success } = parseQuerystring(window.location.search);

    if (success) {
      setShowSuccessAlert(true);
    }
  }, []);

  const hideSuccessAlert = () => {
    window.history.replaceState({}, "", "/");
    setShowSuccessAlert(false);
  };

  const renderTab = useCallback(
    () => (
      <MaintenanceList
        maintenanceData={currentTabData}
        loading={loading}
        currentTabId={currentTabId}
      />
    ),
    [currentTabId, currentTabData, loading]
  );

  // Avoid blinking screen with empty tabs
  if (maintenanceCount === undefined) {
    return null;
  }

  return (
    <div className="Maintenance" data-cy="maintenance-tab">
      {showSuccessAlert && (
        <Alert success fixed renderCloseButton onCloseClick={hideSuccessAlert}>
          <p>{t("form.success")}</p>
        </Alert>
      )}

      <TabControl
        tabs={headers}
        initialTabId={currentTabId}
        renderChildren={renderTab}
        onChange={tabId => updateCurrentTabId(tabId)}
      />
    </div>
  );
};

export default connect(
  state => ({
    selectedProperty: getSelectedProperty(state),
    selectedPropertyId: getSelectedPropertyId(state),
    maintenance: maintenanceListSelector(state, getSelectedPropertyId(state)),
    maintenanceCount: maintenanceCountSelector(
      state,
      getSelectedPropertyId(state)
    )
  }),
  { receiveMaintenance }
)(Maintenance);
