import React from "react";
import { useSelector } from "react-redux";
import { formatAmountWithCurrency } from "utils/numbers";

import { selectedPropertyLocalitySelector } from "../../data/properties/selectors";

export const Price = ({ amount, decimalPlaces }) => {
  const { currency, language, country_code } = useSelector(
    selectedPropertyLocalitySelector
  );

  return (
    <span>
      {formatAmountWithCurrency(amount, {
        currency,
        language,
        country_code,
        decimalPlaces
      })}
    </span>
  );
};
