import "./NavButtonsWrapper.scss";

import React from "react";

const NavButtonsWrapper = ({ prev, next }) => (
  <div className="NavButtonsWrapper">
    {next && (
      <div
        className="NavButtonsWrapper__button-container next"
        data-testid="next-button"
      >
        {next}
      </div>
    )}

    {prev && (
      <div
        className="NavButtonsWrapper__button-container prev"
        data-testid="prev-button"
      >
        {prev}
      </div>
    )}
  </div>
);

export default NavButtonsWrapper;
