import "./Alert.scss";

import { ReactComponent as CloseIcon } from "assets/icons/close-white.svg";
import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import classNames from "classnames";
import React, { Component } from "react";

class Alert extends Component {
  render() {
    const {
      warning,
      danger,
      info,
      success,
      small,
      renderCloseButton,
      onCloseClick,
      fixed
    } = this.props;

    const alertClassNames = classNames(
      "Alert",
      { "Alert--info": info },
      { "Alert--warning": warning },
      { "Alert--danger": danger },
      { "Alert--success": success },
      { "Alert--small": small },
      { "Alert--fixed": fixed }
    );

    return (
      <div className={alertClassNames} data-cy="alert">
        {renderCloseButton && (
          <button type="button" className="Alert__close" onClick={onCloseClick}>
            <CloseIcon />
          </button>
        )}
        {success && <TickIcon />}
        {this.props.children}
      </div>
    );
  }
}

export default Alert;
