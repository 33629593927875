import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { compose } from "redux";

import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";
import Button from "../../../../components/buttons/Button";
import Card from "../../../../components/common/Card";
import StripeCard from "../../../../components/common/StripeCard";
import Tip from "../../../../components/common/Tip";
import { cardSelector } from "../../../../data/selectors/billing";
import styles from "../../../../utils/style_constants";
import { useToggle } from "../../../../utils/useToggle";
import { withStripe } from "../WithStripe";
import BankCard from "./BankCard";

const ManageCard = ({ stripe }) => {
  const card = useSelector(cardSelector);
  const { t } = useTranslation("host.billing");

  const [isUpdating, toggleUpdate] = useToggle();

  const renderTooltip = () => (
    <>
      <h5 className="small-header">
        {isUpdating
          ? t("manage_card.update_title")
          : t("manage_card.active_title")}
      </h5>

      <p>{t("manage_card.tooltip.property_costs")}</p>
      <p>{t("manage_card.tooltip.maintenance_costs")}</p>
    </>
  );

  return (
    <Card
      title={
        <React.Fragment>
          {isUpdating
            ? t("manage_card.update_title")
            : t("manage_card.active_title")}
          <Tip position="bottom-end" html={renderTooltip}>
            <InfoIcon className="Billing__info-icon" />
          </Tip>
        </React.Fragment>
      }
      right={
        isUpdating && card ? (
          <Button
            theme="danger"
            small
            outline
            onClick={toggleUpdate}
            type="button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
              <g
                stroke={styles.colors.red}
                strokeLinecap="round"
                strokeWidth="4"
              >
                <path d="M2.032 2.047l29.841 29.669M31.873 2.047L2.033 31.716" />
              </g>
            </svg>{" "}
            {t("common:cancel")}
          </Button>
        ) : null
      }
    >
      <div className="ManageCard" id="card-details">
        {isUpdating || !card ? (
          <StripeCard
            submittingText={t("host.setup:payment_method.adding_card")}
            submitText={card ? t("update_card") : t("add_card")}
            submitTestID={"update-card-button"}
            toggleUpdate={toggleUpdate}
            stripe={stripe}
          />
        ) : (
          <div>
            <BankCard cardDetails={card} />

            <Button type="submit" onClick={toggleUpdate}>
              {card ? t("update_card") : t("add_card")}
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default compose(withStripe)(ManageCard);
