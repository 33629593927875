import "./NavBar.scss";

import Logo from "components/common/Logo";
import { selectedPropertyLocalitySelector } from "data/properties/selectors";
import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as AlertIcon } from "../../assets/icons/alert-circle-filled.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import Button from "../buttons/Button";
import { LangSelect } from "../LangSelect";
import { ProfileMenu } from "./ProfileMenu";
import PropertySelect from "./property_select/PropertySelect";

const NavBar = ({ handleToggleMenu, preferenceViewed }) => {
  const { country_code } = useSelector(selectedPropertyLocalitySelector);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const redirectToNewProperty = () => {
    navigate("/new-property");
  };

  return (
    <div className="NavBar__container">
      <div
        className={`NavBar ${
          process.env.NODE_ENV === "development" ? "NavBar--dev" : ""
        }`}
      >
        <div className="NavBar__content">
          <button
            type="button"
            className="NavBar__menu-button"
            onClick={handleToggleMenu}
          >
            <MenuIcon />
            {!preferenceViewed && <AlertIcon className="NavBar__alert-icon" />}
          </button>

          <div className="NavMenu__logo">
            <Link to="/">
              <Logo countryCode={country_code} />
            </Link>
          </div>

          <div className="NavBar__right">
            <div className="NavBar__wrapper">
              <PropertySelect
                handleNewPropertyRequest={redirectToNewProperty}
              />
            </div>

            <div className="NavBar__wrapper">
              <ProfileMenu />
            </div>

            <div className="NavBar__wrapper">
              <LangSelect />
            </div>

            {!isMobile && (
              <div className="NavBar__wrapper NavBar__wrapper--new-property">
                <Button outline onClick={redirectToNewProperty} type="button">
                  {t("nav.add_new_property")}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
