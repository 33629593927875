import { differenceInMonths, format, startOfYear } from "date-fns";

import i18n from "../../../../i18n";
import { parseDate } from "../../../../utils/dateParser";

export const prevMonthLastDayFromDate = (date = new Date()) => {
  return new Date(date.getFullYear(), date.getMonth(), 0);
};

export const monthsInYTD = (date = new Date()) => {
  const prevMonthLastDay = prevMonthLastDayFromDate(date);
  return (
    differenceInMonths(prevMonthLastDay, startOfYear(prevMonthLastDay)) + 1
  );
};

export const getPerformanceDateRange = ({
  monthsToShow,
  isYTD,
  date = new Date()
}) => {
  // The to_date will always be final day of previous month
  const prevMonthLastDay = prevMonthLastDayFromDate(date);

  // If date range is year to date, from_date will be 1st
  // day of current year. Otherwise, will be x months in past
  const from = isYTD
    ? new Date(prevMonthLastDay.getFullYear(), 0, 1)
    : date.setMonth(date.getMonth() - monthsToShow);

  const from_date = format(from, "yyyy-MM-dd");
  const to_date = format(prevMonthLastDay, "yyyy-MM-dd");

  return {
    from_date,
    to_date
  };
};

export const formatPerformanceGraphData = (incomeData, locality, key) => {
  // If there isn't any label for a given bar (either via labels prop,
  // or as a label property directly on the data object)
  // no tooltip will be rendered for that bar. This is to cut down on svg nodes.
  // Workaround: pass an empty string to label property.

  return incomeData.reduce((acc, curr) => {
    return [
      ...acc,
      {
        x: format(parseDate(curr.from_date), "MMM yy"),
        y: parseInt(curr.data[key]),
        label: "",
        label_text: curr.data?.net_earnings_formatted,
        type: curr.data?.type
      }
    ];
  }, []);
};

export const formatData = (incomeData, locality) => {
  return {
    net_earnings: formatPerformanceGraphData(
      incomeData,
      locality,
      "net_earnings"
    )
  };
};

export const formatPerformanceAmount = ({
  locality,
  amount,
  hasDecimals = true
}) => {
  const { country_code, currency, language } = locality;

  return amount.toLocaleString(`${language}-${country_code}`, {
    style: "currency",
    currency,
    minimumFractionDigits: hasDecimals ? 2 : 0,
    maximumFractionDigits: hasDecimals ? 2 : 0
  });
};

export const formatPerformanceMonthTick = ({ tick, format = "short" }) => {
  const [month, year] = tick.split(" ");

  const monthTranslated = i18n.t(
    `host.date:months.${format}.${month.toLowerCase()}`
  );
  return `${monthTranslated} '${year}`;
};
