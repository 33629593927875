import React from "react";
import { useTranslation } from "react-i18next";

export const PlatformLogo = ({ className, logoUrl }) => {
  const { t } = useTranslation();

  if (logoUrl) {
    return (
      <img className={className} src={logoUrl} alt={t("common:platform")} />
    );
  }

  return null;
};
