import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button from "../../../../components/buttons/Button";
import DefaultError from "../../../../components/common/DefaultError";
import Spinner from "../../../../components/Spinner";
import { previousCleansSelector } from "../../../../data/cleans/selectors";
import { getSelectedPropertyId } from "../../../../data/properties/selectors";
import { CLEANS_LIMIT, useGetPreviousCleans } from "../useGetCleans";
import PreviousClean from "./PreviousClean";

export const PreviousCleansList = ({ urlCleanId }) => {
  const { t } = useTranslation("host.cleans");

  const selectedPropertyId = useSelector(getSelectedPropertyId);
  const previousCleans = useSelector(state =>
    previousCleansSelector(state, selectedPropertyId)
  );
  const previousCleansCount = useMemo(
    () => previousCleans.length,
    [previousCleans]
  );
  const hasPreviousCleans = useMemo(
    () => previousCleansCount > 0,
    [previousCleansCount]
  );

  const { loading, error, getPreviousCleans, isFinal } =
    useGetPreviousCleans(previousCleansCount);

  const handleLoadMore = () => {
    getPreviousCleans(previousCleansCount, selectedPropertyId);
  };

  return (
    <div className="CleansList">
      {!hasPreviousCleans && <p>{t("cleans_list.previous_cleans_info")}</p>}

      {loading && previousCleansCount === 0 && <Spinner />}

      {hasPreviousCleans &&
        previousCleans.map((clean, index) => (
          <PreviousClean
            key={clean.id}
            clean={clean}
            index={index}
            urlCleanId={urlCleanId}
          />
        ))}

      {error && <DefaultError>{t("common:something_went_wrong")}</DefaultError>}

      {!isFinal && previousCleansCount !== 0 && (
        <Button
          outline
          type="button"
          small
          onClick={handleLoadMore}
          loading={loading}
        >
          {t("common:load_more")}
        </Button>
      )}

      {isFinal && previousCleansCount >= CLEANS_LIMIT && (
        <p>{t("cleans.no_more_previous")}</p>
      )}
    </div>
  );
};
