import "./Header.scss";

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import bem from "utils/bem";

class Header extends Component {
  render() {
    const { t, title, description, align, beta } = this.props;

    return (
      <div
        className={bem("Header", {
          [align]: align
        })}
        data-cy="header"
      >
        <h2 className="header">
          {title}{" "}
          {beta && <span className="Header__beta">{t("common:beta")}</span>}
        </h2>

        {description ? (
          <p className="Header__description">{description}</p>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(Header);
