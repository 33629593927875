import "./TabControl.scss";

import classNames from "classnames";
import React, { Component } from "react";

export default class TabControl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTabId: props.initialTabId
    };
  }

  setActiveTab = id => {
    this.props.onChange && this.props.onChange(id);

    this.setState({ activeTabId: id });
  };

  render() {
    const { tabs, renderChildren, className } = this.props;
    const { activeTabId } = this.state;

    return (
      <div className={classNames("TabControl", className)}>
        <div className="TabControl__tabs">
          {tabs.map(tab => (
            <h4
              key={tab.id}
              className={[
                `TabControl__tabs__tab control ${
                  tab.id === activeTabId
                    ? "TabControl__tabs__tab--active control"
                    : ""
                }`
              ]}
              onClick={() => this.setActiveTab(tab.id)}
              data-cy={tab.dataCy}
              data-testid={tab.dataCy}
            >
              {tab.text}
            </h4>
          ))}
        </div>

        <div>{renderChildren(tabs.find(tab => tab.id === activeTabId))}</div>
      </div>
    );
  }
}
