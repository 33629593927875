import "./Flash.scss";

import { ReactComponent as AlertIcon } from "assets/icons/alert-triangle.svg";
import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import classnames from "classnames";
import { setFlash } from "data/actions/flash";
import { SEVERITY_ERROR, SEVERITY_NOTICE } from "data/reducers/flash";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

export const useFlash = () => {
  const dispatch = useDispatch();

  const trigger = (message, severity = SEVERITY_NOTICE) => {
    dispatch(setFlash(message, severity));

    setTimeout(() => {
      dispatch({ type: "CLEAR_FLASH" });
    }, 5000);
  };

  return {
    notice: message => trigger(message, SEVERITY_NOTICE),
    error: message => trigger(message, SEVERITY_ERROR)
  };
};

export const Flash = () => {
  const flash = useSelector(state => state.flash);
  const nodeRef = useRef(null);

  const flashIcon = {
    [SEVERITY_NOTICE]: <TickIcon />,
    [SEVERITY_ERROR]: <AlertIcon />
  };

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={flash?.visible}
      appear={flash?.visible}
      timeout={500}
      unmountOnExit
      classNames="Flash"
    >
      <div
        ref={nodeRef}
        className={classnames("Flash", {
          "Flash--notice": flash.severity === SEVERITY_NOTICE,
          "Flash--error": flash.severity === SEVERITY_ERROR
        })}
      >
        <p className="Flash__message">
          {flashIcon[flash.severity]}
          {flash.message}
        </p>
      </div>
    </CSSTransition>
  );
};

export default Flash;
