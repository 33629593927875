import "./Contact.scss";

import Alert from "components/common/Alert";
import { HeadPortal } from "components/common/HeadPortal";
import { get } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { parseQuerystring } from "utils/querystring";

import Card from "../../../components/common/Card";
import { getSelectedProperty } from "../../../data/properties/selectors";
import { propertyNotInSpain } from "../../../data/property_states";
import { AmCard } from "./components/AmCard";
import { CallCard } from "./components/CallCard";
import ContactForm from "./components/ContactForm";
import { WhatsappCard } from "./components/WhatsappCard";

const CONTACT_FORM_PARAM = "contact-form";
const FRONT_RESPONSE_OK = "ok";
const FRONT_RESPONSE_SERVICE_UNAVAILABLE = "service_unavailable";

export const Contact = () => {
  const location = useLocation();
  const { t } = useTranslation("host.contact");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const selectedProperty = useSelector(getSelectedProperty);
  const formSubmitRef = useRef(null);

  const params = new URLSearchParams(location.search);
  const scrollToForm = params.get(CONTACT_FORM_PARAM) || false;

  const locationState = get(location, "state", {});

  const [formInitialValues, setFormInitialValues] = useState({
    body: locationState?.body || "",
    description: locationState?.description || "",
    maintenanceSalesforceId: locationState?.maintenanceSalesforceId || "",
    categoryId: locationState?.categoryId || "",
    subcategoryId: locationState?.subcategoryId || ""
  });

  const handleScrollToForm = useCallback(() => {
    formSubmitRef.current.scrollIntoView({ behavior: "smooth" });
  }, [formSubmitRef]);

  useEffect(() => {
    if (scrollToForm) {
      handleScrollToForm();
      setFormInitialValues({
        description: locationState?.description || "",
        categoryId: locationState?.categoryId || "",
        subcategoryId: locationState?.subcategoryId || ""
      });
    }
  }, [scrollToForm, handleScrollToForm, locationState]);

  useEffect(() => {
    const { code } = parseQuerystring(window.location.search);

    if (code === FRONT_RESPONSE_OK) {
      setShowSuccessAlert(true);
    }

    if (code === FRONT_RESPONSE_SERVICE_UNAVAILABLE) {
      setShowErrorAlert(true);
    }
  }, []);

  const hideAlert = name => {
    window.history.replaceState({}, "", "/");

    const hideAlertFns = {
      success: () => setShowSuccessAlert(false),
      error: () => setShowErrorAlert(false)
    };

    return hideAlertFns[name]();
  };

  return (
    <>
      <HeadPortal>
        <meta name="referrer" content="no-referrer-when-downgrade" />
      </HeadPortal>

      <div className="Contact" data-testid="contact-page">
        <h2 className="header">{t("title")}</h2>

        {propertyNotInSpain(selectedProperty) && <p>{t("contact_heading")}</p>}

        <div className="Contact__methods">
          {propertyNotInSpain(selectedProperty) && (
            <WhatsappCard
              countryCode={selectedProperty.locality.country_code}
            />
          )}
          <CallCard countryCode={selectedProperty.locality.country_code} />
          <AmCard
            setFormInitialValues={setFormInitialValues}
            handleScrollToForm={handleScrollToForm}
          />
        </div>

        {showSuccessAlert && (
          <Alert
            success
            fixed
            renderCloseButton
            onCloseClick={() => hideAlert("success")}
          >
            <p>{t("form.success")}</p>
          </Alert>
        )}

        {showErrorAlert && (
          <Alert
            danger
            fixed
            renderCloseButton
            onCloseClick={() => hideAlert("error")}
          >
            <p>{t("form.error_service_unavailable")}</p>
          </Alert>
        )}

        <Card>
          <h2 className="header">{t("get_in_touch")}</h2>

          <p className="text-limited-width">{t("contact_form_info")}</p>

          <ContactForm
            formSubmitRef={formSubmitRef}
            formInitialValues={formInitialValues}
          />
        </Card>
      </div>
    </>
  );
};

export default Contact;
