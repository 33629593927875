import Button from "components/buttons/Button";
import Modal from "components/common/modal";
import { logout } from "data/actions/user";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import api from "services/api";

class UpdateEmail extends Component {
  state = {
    password: null,
    newEmail: null,
    success: false,
    showModal: false
  };

  onChange = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  submit = e => {
    e.preventDefault();

    const { password, newEmail } = this.state;
    const payload = {
      email: newEmail,
      password: password
    };

    api
      .put("/v1/hosts/user/me/email", null, payload)
      .then(() => {
        this.setState({
          password: null,
          success: true,
          showModal: true
        });
      })
      .catch(() => {
        this.setState({
          success: false,
          showModal: true
        });
      });
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  render() {
    const { newEmail, password, success, showModal } = this.state;
    const { t, email, isActive, toggleEdit } = this.props;
    const canUpdateEmail = newEmail && password;

    return (
      <div className="UpdateEmail">
        {showModal && (
          <Modal
            title={
              success
                ? t("update_email.title.success")
                : t("update_email.title.error")
            }
            closeModal={() => this.toggleModal()}
            footer={
              <div>
                <Button
                  outline
                  onClick={() =>
                    success ? this.props.logout() : this.toggleModal()
                  }
                  data-cy="email-updated-ok"
                >
                  {t("common:ok")}
                </Button>
              </div>
            }
          >
            {success ? (
              <p>{t("update_email.success", { newEmail })}</p>
            ) : (
              <p>{t("update_email.error")}</p>
            )}
          </Modal>
        )}

        <div className="UpdateEmail">
          <div className="Account__item__container">
            <div className="Account__item__text">
              <span className="text-info">{t("common:email")}:</span> {email}
            </div>

            {!isActive && (
              <Button type="button" onClick={toggleEdit}>
                {t("update_email.change_email")}
              </Button>
            )}
          </div>

          {isActive && (
            <div>
              <form onSubmit={this.submit}>
                <div className="UpdateLoginCredentials">
                  <label htmlFor="updateEmail">
                    {t("update_email.form.new_email_label")}
                  </label>

                  <input
                    id="updateEmail"
                    onChange={e => this.onChange("newEmail", e.target.value)}
                    type="email"
                    value={newEmail || ""}
                    placeholder={t("update_email.form.new_email_placeholder")}
                    required
                  />

                  <label htmlFor="currentPassword">
                    {t("update_email.form.confirm_password_label")}
                  </label>

                  <input
                    id="currentPassword"
                    onChange={e => this.onChange("password", e.target.value)}
                    type="password"
                    value={password || ""}
                    placeholder={t(
                      "update_email.form.confirm_password_placeholder"
                    )}
                    required
                  />

                  <div className="Account__actions">
                    <Button outline onClick={toggleEdit} type="button">
                      {t("common:cancel")}
                    </Button>

                    <Button disabled={!canUpdateEmail} type="submit">
                      {t("common:confirm")}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation("host.account"),
  connect(null, dispatch => ({
    logout: () => dispatch(logout())
  }))
)(UpdateEmail);
