import { isFuture } from "date-fns";
import { get } from "lodash";
import { LONDON } from "utils/constants";

export const propertyHasMultiplatOption = property =>
  property.locality.multi_platform;

export const propertyNeedsOnboarding = property => {
  if (!property) {
    return false;
  }

  return !property.sorted_from && !property.offboarded_from;
};

export const propertySorted = property =>
  // Property finished onboarding and is ready to take bookings
  property?.sorted_from ? !isFuture(property.sorted_from) : false;

export const propertyOffboarded = property =>
  // Property is offboarded and no new bookings would be accepted
  property?.offboarded_from ? !isFuture(property.offboarded_from) : false;

export const hasLiveProperties = properties =>
  properties.some(
    property => propertySorted(property) && !propertyOffboarded(property)
  );

export const propertyNeedsInfo = property => {
  const requiredFields = ["full_address", "postcode", "locality"];
  return requiredFields.some(k => !property[k]);
};

export const propertyIsInLondon = property =>
  get(property, "locality.name") === LONDON;

export const propertyNeedsDeposit = property => property.needs_deposit;

export const propertyNeedsMandate = property => property.needs_mandate;

export const propertyNeedsAvailability = property =>
  !property.availability_type;

export const propertyChecklistCompleted = property =>
  property.checklist_completed;

export const propertyNeedsTerms = property => !property.terms_accepted;

export const propertyRequiresSetup = property => Boolean(property?.needs_setup);

export const propertyHasDirectDebit = property =>
  get(property, "locality.direct_debit", false);

export const propertyNotInSpain = property =>
  get(property, "locality.country_code") !== "ES";
