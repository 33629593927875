import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "./config";
import "./services/segment";

import * as Sentry from "@sentry/browser";
import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import config from "./config";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: config.ENV
  });
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<App />);

serviceWorkerRegistration.register();
