import { isFinite } from "lodash";

export const sanitize = value =>
  (value && value !== true && value.toString()) || "";

export const toSortCode = value =>
  sanitize(value)
    .replace(/[^\d]/g, "") // Remove non-digit characters
    .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1-$2-$3");

export const toBsb = value =>
  sanitize(value)
    .replace(/[^\d]/g, "") // Remove non-digit characters
    .replace(/^(\d{3})(\d{3})$/, "$1-$2");

export const formatAmount = (amount = 0, decimalPlaces = 2) =>
  Number(amount).toFixed(decimalPlaces);

export const intOrZero = value => {
  const number = parseInt(value, 10);
  return isFinite(number) ? number : 0;
};

export const formatAmountWithCurrency = (
  amount,
  { currency = "", decimalPlaces = 2, language, country_code } = {}
) => {
  return parseFloat(amount).toLocaleString(`${language}-${country_code}`, {
    style: "currency",
    currency,
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  });
};
