import "./StepOnboardingFee.scss";

import Button from "components/buttons/Button";
import get from "lodash/get";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useOnboardingContext } from "scenes/signup/SignupContext";

import { ReactComponent as CameraIcon } from "../../../../assets/icons/camera.svg";
import { ReactComponent as ListIcon } from "../../../../assets/icons/list.svg";
import { ReactComponent as LockIcon } from "../../../../assets/icons/lock.svg";
import { ReactComponent as MeetingIcon } from "../../../../assets/icons/meeting.svg";
import { ReactComponent as ShieldCheckedIcon } from "../../../../assets/icons/shield_checked.svg";
import DefaultError from "../../../../components/common/DefaultError.jsx";
import api from "../../../../services/api";
import { formatAmountWithCurrency } from "../../../../utils/numbers";
import { useStripe } from "../../../dashboard/billing/WithStripe.jsx";
import NavButtonsWrapper from "../NavButtonsWrapper";
import { CardForm } from "./CardForm.jsx";

const FeeExplained = () => {
  const { t } = useTranslation("host.signup");
  const {
    data: { property }
  } = useOnboardingContext();

  const { currency, language, country_code } = property.locality;

  const formattedSignupFee = formatAmountWithCurrency(
    property.locality.host_signup_fee_amount,
    {
      currency,
      language,
      country_code
    }
  );
  const remoteOnboardLocalities = ["GB", "IE", "AU", "NZ"];

  return (
    <>
      <h2 className="Signup__header" data-testid="onbaording-fee-header">
        {t("signup_fee.title")}
      </h2>
      <h4 className="Signup__subheader">
        {t("signup_fee.description", {
          signupFee: formattedSignupFee,
          taxAbbreviation: property.locality.tax_label
        })}
      </h4>

      <div className="StepDeposit__fee-explained">
        <CameraIcon /> <span>{t("signup_fee.explained.photography")}</span>
        <LockIcon /> <span>{t("signup_fee.explained.access_solution")}</span>
        <MeetingIcon />
        <span>
          {remoteOnboardLocalities.includes(property.locality.country_code)
            ? t("signup_fee.explained.onboarding_remote")
            : t("signup_fee.explained.onboarding")}
        </span>
        <ListIcon /> <span>{t("signup_fee.explained.listing")}</span>
      </div>
    </>
  );
};

export const StepOnboardingFee = () => {
  const {
    propertyId,
    refreshData,
    data,
    setLoading,
    loading,
    currentStep,
    handlePrev,
    handleNext
  } = useOnboardingContext();

  const { billing } = data;
  const { t } = useTranslation("host.signup");
  const stripe = useStripe(billing.stripe_key);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleSubmit = async () => {
    if (submitting) {
      return false;
    }

    setError(null);
    setLoading(true);

    setSubmitting(true);

    try {
      const { requires_action, payment_intent_client_secret } = await api.post(
        `/v1/hosts/properties/${propertyId}/pay_deposit`
      );

      if (requires_action) {
        const { error, paymentIntent } = await stripe.handleCardAction(
          payment_intent_client_secret
        );

        if (error) {
          throw error;
        }

        await api.post(
          `/v1/hosts/properties/${propertyId}/pay_deposit/${paymentIntent.id}/fulfill`
        );
      }

      await refreshData();
    } catch (e) {
      const error = get(e, "error.message", e.message);
      setError(error || t("common:error.try_again"));
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <div className="StepDeposit Signup__step Signup__step__content">
      {currentStep.done ? (
        <div className="StepDeposit__done-screen">
          <ShieldCheckedIcon />
          <h2
            className="Signup__header"
            data-testid="onboarding-fee-thank-you-header"
          >
            {t("common:thank_you")}
          </h2>
          <h4 className="Signup__subheader">{t("signup_fee.done.content")}</h4>
        </div>
      ) : (
        <>
          <FeeExplained />
          <CardForm
            card={billing.card}
            stripe={stripe}
            onUpdate={refreshData}
            setSubmitDisabled={setSubmitDisabled}
          />
        </>
      )}

      {error && <DefaultError>{error}</DefaultError>}
      <NavButtonsWrapper
        prev={
          <Button type="button" theme="plain" onClick={handlePrev}>
            {t("common:previous")}
          </Button>
        }
        next={
          currentStep.done ? (
            <Button type="button" onClick={handleNext}>
              {t("common:next")}
            </Button>
          ) : (
            <Button
              type="button"
              disabled={!billing.card || submitDisabled}
              loading={loading}
              onClick={handleSubmit}
            >
              {t("signup_fee.button_pay")}
            </Button>
          )
        }
      />
    </div>
  );
};
