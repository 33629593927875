import "./PasswordConfirmation.scss";

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import Button from "../../../../../components/buttons/Button";
import DefaultError from "../../../../../components/common/DefaultError";
import { receiveBankAccountDetails } from "../../../../../data/actions/billing";
import api from "../../../../../services/api";
import BankAccountDetails from "./BankAccountDetails";
import { STEP_CHANGE, STEP_DONE } from "./steps";

export const PasswordConfirmation = ({ setStep, setFormData, formData }) => {
  const { t } = useTranslation("host.billing");
  const [serverError, setServerError] = useState(null);
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    _password: yup.string().required(t("fill_in_correct_password"))
  });

  const handleSubmit = values => {
    setServerError(null);

    api
      .put("/v1/hosts/billing/bank_account", null, {
        ...values,
        ...formData
      })
      .then(async res => {
        dispatch(receiveBankAccountDetails(res));
        setStep(STEP_DONE);
      })
      .catch(err => {
        if (err.statusCode === 403) {
          setServerError(t("fill_in_correct_password"));
        } else {
          setServerError(t("common:error.try_again"));
        }
      });
  };

  return (
    <div className="PasswordConfirmation">
      <BankAccountDetails t={t} bankAccount={formData} />
      <Formik
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        initialValues={{
          _password: ""
        }}
      >
        {({ errors }) => (
          <Form>
            <label htmlFor="_password">{t("bank_account.your_password")}</label>
            <Field type="password" name="_password" id="_password" />
            <ErrorMessage name="_password" component={DefaultError} />

            {serverError && <DefaultError> {serverError} </DefaultError>}

            <div className="PasswordConfirmation__buttons">
              <Button
                outline
                onClick={() => setStep(STEP_CHANGE)}
                type="button"
              >
                {t("common:back")}
              </Button>

              <Button data-cy="submit-password" type="submit">
                {t("common:confirm")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
