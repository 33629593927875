import { get } from "lodash";

import qs from "./querystring";

export const getCalendlyCalendarUrl = (property, user, meetingType) => {
  const url =
    meetingType === "live"
      ? "https://calendly.com/hellohoust/inperson"
      : property.locality.onboarding_url;
  return qs(
    {
      first_name: user.first_name,
      last_name: user.last_name,
      email: get(user, "emails.0.address")
    },
    url
  );
};
