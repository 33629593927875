import "./Error.scss";

import * as Sentry from "@sentry/browser";
import React from "react";
import { useTranslation } from "react-i18next";

import Button from "../../components/buttons/Button.jsx";

const ReportDialogButton = ({ eventId, user }) => {
  const { t } = useTranslation();

  return (
    <Button
      small
      theme="plain"
      onClick={() => Sentry.showReportDialog({ eventId, user })}
    >
      {t("common:report_feedback")}
    </Button>
  );
};

export const Error = ({ title, text, eventId, user }) => {
  const { t } = useTranslation();

  return (
    <div className="Error__container">
      <div className="Error__card">
        <h4 className="Error__title header">
          {title || t("common:something_went_wrong")}
        </h4>
        <p>{text || t("common:contact_manager")}</p>

        <div className="Error__buttons">
          <Button
            type="button"
            small
            onClick={() => window.location.reload(true)}
          >
            {t("common:reload_page")}
          </Button>

          {eventId && <ReportDialogButton eventId={eventId} user={user} />}
        </div>
      </div>
    </div>
  );
};
