import "./MaintenanceItem.scss";

import { patchMaintenanceItem } from "data/actions/maintenance";
import { get } from "lodash";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReactComponent as NoImageIcon } from "../../../../../assets/icons/no_image.svg";
import ImagesCarousel from "../../../../../components/common/ImagesCarousel";
import LinkOut from "../../../../../components/common/LinkOut";
import { IMAGE_CAROUSEL_TYPE_MAINTENANCE } from "../../../../../data/cleans/types";
import {
  MAINTENANCE_REPORTER,
  REQUEST_INFORMATION_ACTION
} from "../../../../../data/maintenance/types";
import {
  getSelectedProperty,
  getSelectedPropertyCurrencySymbol
} from "../../../../../data/properties/selectors";
import api from "../../../../../services/api";
import { cleanImageUrl } from "../../../../../utils/cleanImageUrl";
import { prettyDate } from "../../../../../utils/dates";
import { Action } from "./Action";
import { ActionModal } from "./ActionModal";
import { ContactModal } from "./ContactModal";
import { CostInfo } from "./CostInfo";

export const MaintenanceItem = ({ item }) => {
  const { t } = useTranslation("host.maintenance");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currencySymbol = useSelector(getSelectedPropertyCurrencySymbol);
  const selectedProperty = useSelector(getSelectedProperty);
  const [choosenResolution, setChoosenResolution] = useState(null);
  const [showActionModal, setShowActionModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [showImagesCarousel, setShowImagesCarousel] = useState(false);

  const handleViewImage = useCallback(() => {
    setModalData(item);
    setShowImagesCarousel(true);
  }, [item]);

  const handleCloseImagesCarousel = useCallback(() => {
    setShowImagesCarousel(false);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowActionModal(false);
    setShowContactModal(false);
  }, []);

  const handleChoosenAction = useCallback(
    ({ type, item }) => {
      if (type === REQUEST_INFORMATION_ACTION) {
        navigate("/contact", { state: { body: item.description } });
      } else {
        setModalData({ maintenanceItem: item });
        setChoosenResolution(type);
        setShowActionModal(true);
      }
    },
    [navigate]
  );

  const handleResolutionTypeSubmit = useCallback(
    values => {
      handleChoosenAction({ type: values.host_response, item });
    },
    [handleChoosenAction, item]
  );

  const updateMaintenanceItem = useCallback(
    data => {
      api
        .patch(
          `/v1/hosts/properties/${selectedProperty.id}/maintenances/${item.id}`,
          null,
          { ...data }
        )
        .then(res => {
          dispatch(
            patchMaintenanceItem({
              maintenanceItem: res,
              propertyId: selectedProperty.id
            })
          );
        });
    },
    [dispatch, item.id, selectedProperty.id]
  );

  const imageUrl = get(item, "pictures[0].image_url", false);

  return (
    <>
      {showContactModal && (
        <ContactModal
          closeModal={handleCloseModal}
          description={item.description}
          maintenanceSalesforceId={item.salesforce_object_id}
        />
      )}
      {showActionModal && (
        <ActionModal
          choosenResolution={choosenResolution}
          modalData={modalData}
          selectedProperty={selectedProperty}
          closeModal={handleCloseModal}
          updateMaintenanceItem={updateMaintenanceItem}
        />
      )}

      {showImagesCarousel && (
        <ImagesCarousel
          currentImageIndex={0}
          images={get(modalData, "pictures", [])}
          toggleModal={handleCloseImagesCarousel}
          carouselType={IMAGE_CAROUSEL_TYPE_MAINTENANCE}
        />
      )}

      <div className="MaintenanceItemContainer">
        <div
          className="MaintenanceItem"
          data-testid="maintenance-item"
          data-cy="maintenance-item"
        >
          <div className="MaintenanceItem__image-with-details">
            <div className="MaintenanceItem__image">
              {imageUrl ? (
                <img
                  onClick={handleViewImage}
                  src={cleanImageUrl(imageUrl, { w: 145, h: 180 })}
                  alt="Issue"
                />
              ) : (
                <NoImageIcon />
              )}
            </div>

            <div className="MaintenanceItem__detail">
              {item.severity && (
                <div>
                  <span
                    className={`MaintenanceItem__detail__severity MaintenanceItem__detail__severity--${item.severity}`}
                  >
                    {t(`severity.${item.severity}`)}
                  </span>
                </div>
              )}

              <div className="MaintenanceItem__detail__short">
                <dl>
                  <dt>{t("label.type")}</dt>
                  <dd>{t(`type.${item.type}`)}</dd>
                  <dt>{t("label.location")}</dt>
                  <dd>{t(item.location)}</dd>
                  <CostInfo item={item} currencySymbol={currencySymbol} />
                  {Boolean(item.proposed_solution) && (
                    <>
                      <dt>{t("label.proposed_solution")}</dt>
                      <dd>{item.proposed_solution}</dd>
                    </>
                  )}

                  {Boolean(item.job_report_file) && (
                    <>
                      <dt>{t("label.job_report_file")}</dt>
                      <dd>
                        <LinkOut to={item.job_report_file.original_url}>
                          {t("download")}
                        </LinkOut>
                      </dd>
                    </>
                  )}
                </dl>
              </div>
            </div>
          </div>
          <div className="MaintenanceItem__actionsWrapper">
            <Action
              status={item.status}
              resolutionDate={item.resolution_date}
              handleResolutionTypeSubmit={handleResolutionTypeSubmit}
              updateMaintenanceItem={updateMaintenanceItem}
            />
            <p>{t("reference", { id: item.id })}</p>
          </div>
        </div>

        <div className="MaintenanceItem__detailsContainer">
          <p>{item.description}</p>
        </div>

        <div className="MaintenanceItem__date">
          <p>
            {t("updated_at", {
              date: prettyDate(item.created_at)
            })}
            &nbsp;
            {t(`reported_by.${MAINTENANCE_REPORTER[item.reporter_type]}`)}
          </p>
          <p className="MaintenanceItem__titleDescription">
            {t("title.description")}
          </p>
        </div>
      </div>
    </>
  );
};
