import { getPropertiesWithMultiplatOption } from "data/properties/selectors";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

import { showBankAccountForPayouts } from "../../utils/bankAccount";
import { pathForStep } from "./SignupRoutes";
import { StepBankAccount } from "./steps/step_bank_account/StepBankAccount";
import { StepBookMeeting } from "./steps/step_book_meeting/StepBookMeeting";
import { StepAddCard } from "./steps/step_cc/StepAddCard";
import { StepOnboardingFee } from "./steps/step_cc/StepOnboardingFee";
import { StepChecklist } from "./steps/step_checklist/StepChecklist";
import { StepDirectDebit } from "./steps/step_direct_debit/StepDirectDebit";
import { StepFinish } from "./steps/step_finish/StepFinish";
import { StepLoginDetails } from "./steps/step_login_details/StepLoginDetails";
import { StepPersonal } from "./steps/step_personal/StepPersonal";
import { StepProperty } from "./steps/step_property/StepProperty";
import { StepTerms } from "./steps/step_terms/StepTerms";

export const NULL_STEP = {
  path: null,
  hideNavigation: true
};

export const useSignupSteps = (data = {}) => {
  const navigate = useNavigate();
  const propertiesLocalityHasMultiplatOption =
    useSelector(getPropertiesWithMultiplatOption).length > 0;
  const currency = data.property?.locality?.currency;

  const allSteps = useMemo(() => {
    const needsDeposit = data.property?.needs_deposit;
    const skipDeposit = Boolean(data.property?.skip_deposit);
    const skipOnboardingMeeting = Boolean(
      data.property?.onboarding_meeting_skipped
    );
    const hideBankAccountStep = !showBankAccountForPayouts(
      currency,
      propertiesLocalityHasMultiplatOption,
      data.property
    );

    return [
      {
        path: "login_details",
        component: <StepLoginDetails />,
        done: Boolean(data.host?.has_authentication),
        hideNavigation: true
      },
      {
        path: "personal",
        component: <StepPersonal />,
        done: personalFieldsComplete(data),
        hidden: data.first_onboarding === false
      },
      {
        path: "property",
        component: <StepProperty />,
        done: propertyFieldsComplete(data)
      },
      {
        path: "terms_and_conditions",
        component: <StepTerms />,
        done: Boolean(data.property?.terms_accepted)
      },
      {
        path: "onboarding_fee",
        component: <StepOnboardingFee />,
        done: !needsDeposit,
        hidden: skipDeposit
      },
      {
        path: "card_details",
        component: <StepAddCard />,
        done: !needsDeposit,
        hidden: !skipDeposit
      },
      {
        path: "direct_debit",
        component: <StepDirectDebit />,
        done: !data.property?.needs_mandate,
        hidden: !data.property?.locality?.direct_debit
      },
      {
        path: "bank_account",
        component: <StepBankAccount />,
        done: data?.billing?.bank_account,
        hidden: hideBankAccountStep
      },
      {
        path: "book_a_meeting",
        component: <StepBookMeeting />,
        done: Boolean(data.property?.onboarding_meeting_booked),
        hidden: skipOnboardingMeeting
      },
      {
        path: "checklist",
        component: <StepChecklist />,
        done: Boolean(data.property?.checklist_completed),
        hidden: skipOnboardingMeeting
      },
      {
        path: "finish",
        component: <StepFinish />,
        hideNavigation: true
      }
    ];
  }, [data, currency, propertiesLocalityHasMultiplatOption]);

  const steps = useMemo(
    () => allSteps.filter(step => !step.hidden),
    [allSteps]
  );

  const { pathname } = useLocation();

  const computedProperties = useMemo(() => {
    const currentStep =
      steps.find(step =>
        matchPath(`/signup/:propertyId/${step.path}`, pathname)
      ) || NULL_STEP;
    const currentStepIndex = steps.indexOf(currentStep);
    const firstUncompletedStep = steps.find(s => !s.done);
    const firstUncompletedStepIndex = steps.findIndex(
      s => s === firstUncompletedStep
    );
    const accessibleSteps = steps.slice(0, firstUncompletedStepIndex + 1);
    const allDone = currentStep.component === StepFinish;

    const nextStep = steps[currentStepIndex + 1] || NULL_STEP;
    const prevStep = steps[currentStepIndex - 1];

    const handleNext = propertyId => {
      if (allDone) {
        return navigate("/");
      }

      if (!currentStep?.done) {
        return;
      }

      navigate(pathForStep(propertyId, nextStep.path));
    };

    const handlePrev = propertyId => {
      if (currentStep === steps[0]) {
        return;
      }

      navigate(pathForStep(propertyId, prevStep.path));
    };

    return {
      accessibleSteps,
      allDone,
      currentStep,
      currentStepIndex,
      firstUncompletedStep,
      firstUncompletedStepIndex,
      handleNext,
      handlePrev,
      nextStep,
      prevStep
    };
  }, [navigate, pathname, steps]);

  return {
    allSteps,
    steps,
    ...computedProperties
  };
};

const personalFieldsComplete = data =>
  ["last_name", "address", "country", "city"].every(key => data?.host?.[key]);

const propertyFieldsComplete = data =>
  ["address", "postcode", "locality"].every(key => data?.property?.[key]) &&
  !!data.property.locality?.id;
