import Button from "components/buttons/Button";
import Input from "components/common/Input";
import { Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ACCOUNT_TYPE_AU,
  ACCOUNT_TYPE_CA,
  ACCOUNT_TYPE_NZ,
  ACCOUNT_TYPE_ZA
} from "utils/constants";

import { ErrorText } from "../../../../../components/common/Input";
import FormGroup from "../../../../../components/forms/FormGroup";
import { validationSchema } from "../../../../../utils/bankAccountValidation";
import {
  ACCOUNT_TYPES_SPECIAL,
  ACCOUNT_TYPE_GB,
  ACCOUNT_TYPE_IBAN,
  IBAN_COUNTRIES
} from "../../../../../utils/constants";

const BankAccountForm = ({
  handleCancel,
  countryCode,
  serverError,
  handleSubmit,
  initialValues
}) => {
  const { t } = useTranslation("host.billing");
  const [isFormTypeIban, setIsFormTypeIban] = useState(
    IBAN_COUNTRIES.includes(countryCode)
  );

  const getAccountType = useCallback(() => {
    const lowerCasedCountryCode = countryCode.toLowerCase();

    if (isFormTypeIban) {
      return ACCOUNT_TYPE_IBAN;
    } else if (ACCOUNT_TYPES_SPECIAL.includes(lowerCasedCountryCode)) {
      return lowerCasedCountryCode;
    } else {
      return ACCOUNT_TYPE_GB;
    }
  }, [countryCode, isFormTypeIban]);

  const renderBankAccountFields = () => {
    switch (countryCode.toLowerCase()) {
      case ACCOUNT_TYPE_AU:
        return (
          <Input.Row data-testid="form-au">
            <FormGroup
              name="sort_code"
              label={t("bank_account.bsb")}
              placeholder="e.g. 123456"
            />

            <FormGroup
              name="account_number"
              label={t("bank_account.account_number")}
              placeholder="e.g. 1234567"
            />
          </Input.Row>
        );
      case ACCOUNT_TYPE_NZ:
        return (
          <FormGroup
            name="account_number"
            label={t("bank_account.account_number")}
            placeholder="e.g. BB-bbbb-AAAAAAA-SSS"
            data-testid="form-nz"
          />
        );
      case ACCOUNT_TYPE_CA:
        return (
          <FormGroup
            name="interac_email"
            label={t("bank_account.interac_email")}
            placeholder="e.g. email@email.com"
            data-testid="form-ca"
          />
        );
      case ACCOUNT_TYPE_ZA:
        return (
          <Input.Row data-testid="form-za">
            <FormGroup
              name="sort_code"
              label={t("bank_account.sort_code.branch")}
              placeholder="e.g. 12-34-56"
            />

            <FormGroup
              name="account_number"
              label={t("bank_account.account_number")}
              placeholder="e.g. 12345678"
            />
          </Input.Row>
        );
      default:
        return (
          <Input.Row data-testid="form-default">
            <FormGroup
              name="sort_code"
              label={t("bank_account.sort_code")}
              placeholder="e.g. 12-34-56"
            />

            <FormGroup
              name="account_number"
              label={t("bank_account.account_number")}
              placeholder="e.g. 12345678"
            />
          </Input.Row>
        );
    }
  };

  return (
    <Formik
      validationSchema={validationSchema({
        isAccountTypeIban: isFormTypeIban,
        countryCode,
        t
      })}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting, setErrors, resetForm }) =>
        handleSubmit({
          type: getAccountType(),
          values,
          setSubmitting,
          setErrors,
          resetForm
        })
      }
    >
      {({
        values,
        dirty,
        handleSubmit,
        isSubmitting,
        submitForm,
        errors,
        resetForm
      }) => (
        <Form className="BankAccount__form">
          <FormGroup
            name="owner_name"
            label={t("bank_account.account_owner")}
            placeholder={t("common:name_placeholder")}
            data-testid="account-owner"
          />

          {isFormTypeIban ? (
            <div data-testid="iban-form">
              <FormGroup
                name="iban"
                label={t("bank_account.iban_number")}
                placeholder="e.g. DE89 3704 0044 0532 0130 00"
                data-testid="iban-input"
              />

              <FormGroup
                name="bic"
                label={t("bank_account.bic")}
                placeholder="e.g. MIDLGB22"
                data-testid="bic-input"
              />
            </div>
          ) : (
            renderBankAccountFields()
          )}

          <button
            className="BankAccount__switchTypeBtn link"
            onClick={() => setIsFormTypeIban(!isFormTypeIban)}
            type="button"
            data-cy="toggle-iban"
          >
            {t(`bank_account.modal.change_account_type.${isFormTypeIban}`)}
          </button>

          {serverError && <ErrorText> {serverError} </ErrorText>}

          <div className="BankAccount__buttons">
            <Button
              outline
              onClick={() => {
                resetForm();
                handleCancel();
              }}
              type="button"
            >
              {t("common:cancel")}
            </Button>

            <Button data-cy="submit-bank-account" type="submit">
              {t("common:confirm")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BankAccountForm;
