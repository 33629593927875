import { countBy, groupBy } from "lodash";

import {
  MAINTENANCE_STATUS_ACCEPTED,
  MAINTENANCE_STATUS_HOST_PLANNED,
  MAINTENANCE_STATUS_IGNORED,
  MAINTENANCE_STATUS_PLANNED,
  MAINTENANCE_STATUS_RESOLVED
} from "./types";

export const baseMaintenanceState = {
  [MAINTENANCE_STATUS_ACCEPTED]: [],
  [MAINTENANCE_STATUS_PLANNED]: [],
  [MAINTENANCE_STATUS_HOST_PLANNED]: [],
  [MAINTENANCE_STATUS_RESOLVED]: [],
  [MAINTENANCE_STATUS_IGNORED]: []
};

export const maintenanceCountSelector = (state, propertyId) => {
  const maintenanceData = state.maintenance.byProperty[propertyId];

  return maintenanceData?.length;
};

export const unresolvedMaintenanceCountSelector = (state, propertyId) => {
  const maintenanceData = state.maintenance.byProperty[propertyId];

  return countBy(maintenanceData, "status")?.[MAINTENANCE_STATUS_ACCEPTED] || 0;
};

export const maintenanceListSelector = (state, propertyId) => {
  const maintenanceData = state.maintenance.byProperty[propertyId];

  if (maintenanceData) {
    return {
      ...baseMaintenanceState,
      ...groupBy(maintenanceData, "status")
    };
  }

  return baseMaintenanceState;
};
