import "./Bookings.scss";

import TabControl from "components/common/TabControl";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { receiveBankAccountDetails } from "../../../data/actions/billing";
import api from "../../../services/api";
import { PreviousBookings } from "./components/PreviousBookings";
import { UpcomingBookings } from "./components/UpcominBookings";

const CURRENT_BOOKING_TAB = "current";
const PREVIOUS_BOOKING_TAB = "previous";

const Bookings = () => {
  const { t } = useTranslation("host.bookings");
  const dispatch = useDispatch();

  useEffect(() => {
    api
      .get("/v1/hosts/billing")
      .then(res => dispatch(receiveBankAccountDetails(res.bank_account)));
  }, [dispatch]);

  const renderTabs = tab => {
    if (!tab) {
      return;
    }

    if (tab.id === CURRENT_BOOKING_TAB) {
      return <UpcomingBookings />;
    } else if (tab.id === PREVIOUS_BOOKING_TAB) {
      return <PreviousBookings />;
    }
  };

  return (
    <>
      <div>
        <TabControl
          tabs={[
            {
              id: CURRENT_BOOKING_TAB,
              text: t("booking.tab.current"),
              dataCy: "current-bookings-tab"
            },
            {
              id: PREVIOUS_BOOKING_TAB,
              text: t("booking.tab.previous"),
              dataCy: "previous-bookings-tab"
            }
          ]}
          initialTabId={CURRENT_BOOKING_TAB}
          renderChildren={renderTabs}
        />
      </div>
    </>
  );
};

export default Bookings;
