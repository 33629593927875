import "./Overview.scss";

import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";

import AirbnbAlert from "../../../components/banners/AirbnbAlert";
import { DigitalLockBanner } from "../../../components/banners/DigitalLockBanner";
import { DublinRegulationBanner } from "../../../components/banners/DublinRegulationBanner";
import { receiveUpcomingBookings } from "../../../data/actions/bookings";
import { airbnb90DayApprovalSelector } from "../../../data/preferences/selectors";
import {
  getPropertiesWith90DayLimit,
  getSelectedProperty,
  getSelectedPropertyId
} from "../../../data/properties/selectors";
import { getPerformance } from "../../../data/selectors/performance";
import api from "../../../services/api";
import { useGetEarnings } from "../performance/useGetEarnings";
import ActivityList from "./components/ActivityList";
import BookingsTile from "./components/BookingsTile";
import { HostingTeam } from "./components/HostingTeam";
import PerformanceChartTile from "./components/PerformanceChartTile.jsx";
import TopCharts from "./components/TopCharts";

// Max limit on the backend is 100 currently
// TODO: Check if this is necessary as we only display current one and count for upcoming
const BOOKINGS_LIMIT = 100;

export const Overview = props => {
  const {
    selectedProperty,
    limitedProperties,
    airbnb90DayApproval,
    performance,
    selectedPropertyId
  } = props;

  const [loadingBookings, setLoadingBookings] = useState(true);
  const { getEarnings } = useGetEarnings(selectedPropertyId);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchBookings = async () => {
      if (!selectedPropertyId) {
        return;
      }

      setLoadingBookings(true);

      try {
        const bookings = await api.get(
          `/v1/hosts/properties/${selectedPropertyId}/bookings/upcoming`,
          { limit: BOOKINGS_LIMIT }
        );

        if (bookings) {
          dispatch(receiveUpcomingBookings(bookings, selectedPropertyId));
        }
      } catch (error) {
        //TODO: add error handling
        console.log(error);
      } finally {
        setLoadingBookings(false);
      }
    };

    fetchBookings();
    getEarnings();
  }, [selectedPropertyId, dispatch, getEarnings]);

  const { hasAnyEarnings, ratings, bookingsCount, occupancy } = performance;

  return (
    <div data-cy="dashboard-overview">
      <AirbnbAlert
        selectedProperty={selectedProperty}
        limitedProperties={limitedProperties}
        airbnb90DayApproval={airbnb90DayApproval}
      />

      {/* Temporarily disabled while testing on current batch */}
      {/*<PropertyCheckBanner />*/}
      <DublinRegulationBanner />
      <DigitalLockBanner />

      <div className="Overview">
        <HostingTeam />
        <TopCharts
          selectedPropertyId={selectedPropertyId}
          ratings={ratings}
          bookingsCount={bookingsCount}
          occupancy={occupancy}
        />

        <ActivityList />

        <BookingsTile loading={loadingBookings} />

        <PerformanceChartTile hasAnyEarnings={hasAnyEarnings} />
      </div>
    </div>
  );
};

export default compose(
  connect(state => {
    const selectedPropertyId = getSelectedPropertyId(state);

    return {
      limitedProperties: getPropertiesWith90DayLimit(state),
      airbnb90DayApproval: airbnb90DayApprovalSelector(
        state,
        selectedPropertyId
      ),
      selectedProperty: getSelectedProperty(state),
      selectedPropertyId,
      performance: getPerformance(state)
    };
  })
)(Overview);
