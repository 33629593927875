import { toDate } from "date-fns";
import sortBy from "lodash/sortBy";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

import Button from "../../../../components/buttons/Button";
import DefaultError from "../../../../components/common/DefaultError";
import api from "../../../../services/api";
import Comment from "./Comment";

const COMMENTS_LIMIT = 10;

class CommentsSection extends Component {
  state = {
    comments: [],
    loading: false,
    showLoadMoreButton: true,
    error: null
  };

  fetchComments = async () => {
    const { propertyId } = this.props;
    const { comments } = this.state;
    this.setState({ loading: true });

    try {
      const fetchedComments = await api.get(
        `/v1/hosts/properties/${propertyId}/performance/comments`,
        { limit: COMMENTS_LIMIT, offset: comments.length }
      );

      if (fetchedComments.length > 0) {
        const newComments = fetchedComments.map(comment => ({
          ...comment,
          review_datetime: toDate(comment.review_datetime)
        }));

        const allComments = sortBy(
          [...comments, ...newComments],
          ["review_datetime"]
        );

        if (fetchedComments.length < 10) {
          this.setState({ showLoadMoreButton: false, comments: allComments });
        } else {
          this.setState({ comments: allComments });
        }
      }
    } catch (err) {
      this.setState({ error: "common:something_went_wrong" });
    } finally {
      this.setState({ loading: false });
    }
  };

  componentDidMount() {
    this.fetchComments();
  }

  componentDidUpdate(prevProps) {
    const prevPropertyId = prevProps.propertyId;
    const { propertyId } = this.props;

    if (prevPropertyId !== propertyId) {
      this.setState({
        comments: [],
        loading: false,
        showLoadMoreButton: true,
        error: null
      });

      this.fetchComments();
    }
  }

  render() {
    const { comments, error, showLoadMoreButton, loading } = this.state;
    const { t } = this.props;

    return (
      <div className="Performance__comments">
        <h3 className="small-header">{t("guest_comments")}</h3>
        <div>
          {comments.length > 0 &&
            comments.map(comment => (
              <Comment key={comment.id} comment={comment} />
            ))}
          {!error && comments.length === 0 && (
            <p data-testid="no-comments-info">{t("no_comments")}</p>
          )}
        </div>

        {comments.length > 0 && showLoadMoreButton && (
          <Button type="button" onClick={this.fetchComments} loading={loading}>
            {t("common:load_more")}
          </Button>
        )}

        {error && (
          <DefaultError testId="comments-loading-error">
            {t(error)}
          </DefaultError>
        )}
      </div>
    );
  }
}

export default compose(withTranslation("host.performance"))(CommentsSection);
