export const MAINTENANCE_RESTOCK_AFTER_GUEST = "after_guest";
export const MAINTENANCE_RESTOCK_CONTACT = "contact";
export const MAINTENANCE_RESTOCK_ASAP = "asap";

export const FRIDGE_CLEANING_LEAVE_ALL = "leave_all";
export const FRIDGE_CLEANING_REMOVE_PERISHABLES = "remove_perishables";
export const FRIDGE_CLEANING_REMOVE_ALL = "remove_all";

export const IMAGE_CAROUSEL_TYPE_MAINTENANCE = "maintenance";
export const IMAGE_CAROUSEL_TYPE_CLEAN_IMAGES = "clean_images";
