const cache = {};

export function getScript(url, { disableCache = false } = {}) {
  if (cache[url] && !disableCache) {
    return cache[url];
  }

  cache[url] = new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = url;
    script.onload = () => resolve();
    script.onerror = e => reject(e);

    document.head.appendChild(script);
  });

  return cache[url];
}

export function getStyleSheet(url) {
  if (cache[url]) {
    return cache[url];
  }

  cache[url] = new Promise((resolve, reject) => {
    const link = document.createElement("link");
    link.href = url;
    link.rel = "stylesheet";
    link.onload = () => resolve();
    link.onerror = e => reject(e);

    document.head.appendChild(link);
  });

  return cache[url];
}

export function loadStripe() {
  return getScript("https://js.stripe.com/v3/").then(() => window.Stripe);
}

export function getCalendly() {
  return Promise.all([
    getStyleSheet("https://calendly.com/assets/external/widget.css"),
    getScript("https://calendly.com/assets/external/widget.js")
  ]).then(() => window.Calendly);
}
