import "./ActivityList.scss";

import { ReactComponent as ActivityIcon } from "assets/icons/activity.svg";
import { ReactComponent as BookingActivity } from "assets/icons/booking-activity.svg";
import { ReactComponent as CleanActivity } from "assets/icons/clean-activity.svg";
import { ReactComponent as HostPayoutActivity } from "assets/icons/host-payout-activity.svg";
import { ReactComponent as InvoiceActivity } from "assets/icons/invoice-activity.svg";
import { ReactComponent as OpportunityActivity } from "assets/icons/opportunity-activity.svg";
import Spinner from "components/Spinner";
import get from "lodash/get";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import api from "services/api";
import { formatAmountWithCurrency } from "utils/numbers";
import querystring from "utils/querystring";

import bookingActivity from "../../../../assets/icons/booking-activity.svg";
import { selectedPropertyLocalitySelector } from "../../../../data/properties/selectors";
import { getSelectedProperty } from "../../../../data/properties/selectors";
import { nameForChannel } from "../../../../utils/channels";
import ActivityItem from "./ActivityItem";

const ACTIVITIES_LIMIT = 10;

export const ActivityList = ({ selectedProperty }) => {
  const { t } = useTranslation("host.overview");

  const activityRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [activities, setActivities] = useState([]);
  const [isFinal, setIsFinal] = useState(false);
  const { currency, language, country_code } = useSelector(
    selectedPropertyLocalitySelector
  );

  const sortActivities = activities =>
    activities.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const fetchActivities = useCallback(
    async (lastValue = undefined) => {
      const paginationQuerystring = querystring({
        limit: ACTIVITIES_LIMIT,
        last_value: lastValue
      });

      setLoading(true);

      try {
        const res = await api.get(
          `/v1/hosts/properties/${selectedProperty.id}/activities/${paginationQuerystring}`
        );

        if (res.length !== 0) {
          setActivities(oldActivities =>
            sortActivities([...oldActivities, ...res])
          );
        } else {
          setIsFinal(true);
        }
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [selectedProperty]
  );

  useEffect(() => {
    setIsFinal(false);
    setActivities([]);

    fetchActivities();
  }, [selectedProperty, fetchActivities]);

  const handleScroll = () => {
    if (isFinal) {
      return;
    }

    const activitiesContainer = activityRef.current;

    if (
      activitiesContainer.scrollTop + activitiesContainer.clientHeight >=
      activitiesContainer.scrollHeight
    ) {
      const lastValue = get(activities[activities.length - 1], "created_at");
      fetchActivities(lastValue);
    }
  };

  const activityContent = activity => {
    const activityTemplates = {
      booking_created: {
        img: renderGuestImage(get(activity, "data.guest_photo_url")),
        title: t("activity_list.new_booking.title"),
        body: t("activity_list.new_booking.body", {
          guestName: get(activity, "data.guest_name"),
          platform: nameForChannel(get(activity, "data.platform")),
          checkin: formatActivityDate(get(activity, "data.checkin"))
        })
      },
      booking_approved: {
        img: renderGuestImage(get(activity, "data.guest_photo_url")),
        title: t("activity_list.booking_approved.title"),
        body: t("activity_list.booking_approved.body", {
          guestName: get(activity, "data.guest_name")
        })
      },
      booking_cancelled: {
        img: renderGuestImage(get(activity, "data.guest_photo_url")),
        title: t("activity_list.booking_cancelled.title"),
        body: t("activity_list.booking_cancelled.body", {
          guestName: get(activity, "data.guest_name"),
          platform: nameForChannel(get(activity, "data.platform")),
          checkin: formatActivityDate(get(activity, "data.checkin"))
        })
      },
      clean_accepted: {
        img: renderCleanActivityImage(),
        title: activity.data?.clean_type
          ? t(`activity_list.clean_accepted.title.${activity.data.clean_type}`)
          : t("activity_list.clean_accepted.title"),
        body: t("activity_list.clean_accepted.body", {
          date: formatActivityDate(get(activity, "data.start"))
        })
      },
      clean_completed: {
        img: renderCleanActivityImage(),
        title: activity.data?.clean_type
          ? t(`activity_list.clean_completed.title.${activity.data.clean_type}`)
          : t("activity_list.clean_completed.title"),
        body: t("activity_list.clean_completed.body", {
          date: formatActivityDate(get(activity, "data.start"))
        })
      },
      clean_cancelled: {
        img: renderCleanActivityImage(),
        title: activity.data?.clean_type
          ? t(`activity_list.clean_cancelled.title.${activity.data.clean_type}`)
          : t("activity_list.clean_cancelled.title"),
        body: t("activity_list.clean_cancelled.body", {
          date: formatActivityDate(get(activity, "data.start"))
        })
      },
      invoice_created: {
        img: renderInvoiceActivityImage(),
        title: t("activity_list.invoice_created.title"),
        body: t("activity_list.invoice_created.body", {
          amount: formatInvoiceCurrency(get(activity, "data.amount"))
        })
      },
      invoice_paid: {
        img: renderInvoiceActivityImage(),
        title: t("activity_list.invoice_paid.title"),
        body: t("activity_list.invoice_paid.body", {
          amount: formatInvoiceCurrency(get(activity, "data.amount"))
        })
      },
      airbnb_review_created: {
        img: renderGuestImage(get(activity, "data.guest_img_url")),
        title: t("activity_list.airbnb_review_created.title"),
        body: t("activity_list.airbnb_review_created.body", {
          guestName: get(activity, "data.guest_first_name"),
          rating: get(activity, "data.rating")
        }),
        expandData: get(activity, "data.comments")
      },
      host_payout: {
        img: renderHostPayoutActivityImage(),
        title: t("activity_list.host_payout.title"),
        body: t("activity_list.host_payout.body", {
          amount: formatHostPayoutCurrency(
            activity?.data?.amount,
            activity?.data?.currency
          )
        })
      },
      opportunity_created: {
        img: renderOpportunityCreatedActivityImage(),
        title: t("activity_list.opportunity_created.title"),
        body: t("activity_list.opportunity_created.body")
      }
    };

    return activityTemplates[activity.activity_type];
  };

  const formatInvoiceCurrency = amount =>
    formatAmountWithCurrency(amount, {
      currency,
      language,
      country_code
    });

  const formatHostPayoutCurrency = (amount, currency) => {
    if (!amount || !currency) {
      return "";
    }
    return formatAmountWithCurrency(amount, {
      currency,
      language,
      country_code
    });
  };

  const formatRelativeDate = activity => moment(activity.created_at).fromNow();

  const formatActivityDate = start => moment(start).format("ddd, D MMM");

  const renderGuestImage = guestPhotoUrl => {
    return guestPhotoUrl ? (
      <img
        src={guestPhotoUrl}
        className="ActivityItem__img"
        alt="activity"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = bookingActivity;
        }}
      />
    ) : (
      <BookingActivity className="ActivityItem__img" />
    );
  };

  const renderCleanActivityImage = () => (
    <CleanActivity className="ActivityItem__img" />
  );

  const renderInvoiceActivityImage = () => (
    <InvoiceActivity className="ActivityItem__img" />
  );

  const renderHostPayoutActivityImage = () => (
    <HostPayoutActivity className="ActivityItem__img" />
  );

  const renderOpportunityCreatedActivityImage = () => (
    <OpportunityActivity className="ActivityItem__img" />
  );

  const hasActivities = () => activities.length !== 0;

  const renderActivitiesListFooter = () => {
    if (!isFinal) {
      return null;
    }

    return hasActivities() ? (
      <p data-cy="all-activities-loaded">
        {t("activity_list.all_activities_loaded")}
      </p>
    ) : (
      <div className="ActivityList__empty">
        <ActivityIcon />
        <p>{t("activity_list.no_activities")}</p>
      </div>
    );
  };

  return (
    <div className="OverviewItem ActivityList">
      <div className="OverviewItem__header">
        <h4 className="header">{t("activity_tile.title")}</h4>
      </div>
      {error ? (
        <div className="ActivityList__error">
          <p>{t("activity_list.error")}</p>
        </div>
      ) : (
        <div
          className="ActivityList__list"
          data-testid="activity-items"
          ref={activityRef}
          onScroll={handleScroll}
          data-cy="activity-list"
        >
          {activities.map(
            activity =>
              activityContent(activity) && (
                <ActivityItem
                  data-testid={`activity-item-${activity.id}`}
                  key={`${activity.activity_type}${activity.id}`}
                  t={t}
                  content={activityContent(activity)}
                  date={formatRelativeDate(activity)}
                />
              )
          )}

          {loading && (
            <div className="ActivityList__loading">
              {t("activity_list.loading_activities")}
              <Spinner />
            </div>
          )}

          {renderActivitiesListFooter()}
        </div>
      )}
    </div>
  );
};

export default connect(state => ({
  selectedProperty: getSelectedProperty(state)
}))(ActivityList);
