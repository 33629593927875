import React from "react";
import { useTranslation } from "react-i18next";
import {
  ACCOUNT_TYPE_AU,
  ACCOUNT_TYPE_CA,
  ACCOUNT_TYPE_GB,
  ACCOUNT_TYPE_IBAN,
  ACCOUNT_TYPE_NZ,
  ACCOUNT_TYPE_ZA
} from "utils/constants";
import { toBsb, toSortCode } from "utils/numbers";

const BankAccountDetails = ({ bankAccount }) => {
  const { t } = useTranslation("host.billing");
  const {
    type,
    iban_masked,
    account_number_masked,
    sort_code,
    owner_name,
    interac_email
  } = bankAccount;

  const typesWithAccountNumber = [
    ACCOUNT_TYPE_GB,
    ACCOUNT_TYPE_AU,
    ACCOUNT_TYPE_NZ,
    ACCOUNT_TYPE_ZA
  ];

  return (
    <dl>
      {type === ACCOUNT_TYPE_IBAN && (
        <>
          <dt>{t("bank_account.iban_number")}</dt>
          <dd data-cy="iban">{iban_masked}</dd>
        </>
      )}

      {type === ACCOUNT_TYPE_GB && (
        <>
          <dt>{t("bank_account.sort_code")}</dt>
          <dd data-cy="sort-code">{toSortCode(sort_code)}</dd>
        </>
      )}

      {type === ACCOUNT_TYPE_AU && (
        <>
          <dt>{t("bank_account.bsb")}</dt>
          <dd data-cy="sort-code">{toBsb(sort_code)}</dd>
        </>
      )}

      {type === ACCOUNT_TYPE_ZA && (
        <>
          <dt>{t("bank_account.sort_code.branch")}</dt>
          <dd data-cy="sort-code">{toSortCode(sort_code)}</dd>
        </>
      )}

      {typesWithAccountNumber.includes(type) && (
        <>
          <dt>{t("bank_account.account_number")}</dt>
          <dd data-cy="account-number">{account_number_masked}</dd>
        </>
      )}

      {type === ACCOUNT_TYPE_CA && (
        <>
          <dt>{t("common:email")}</dt>
          <dd data-cy="email">{interac_email}</dd>
        </>
      )}

      <dt>{t("bank_account.account_owner")}</dt>
      <dd data-cy="account-owner-name">{owner_name}</dd>
    </dl>
  );
};

export default BankAccountDetails;
