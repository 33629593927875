import "./BankAccount.scss";

import Button from "components/buttons/Button";
import { bankAccountSelector } from "data/properties/selectors";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import BankAccountDetails from "./BankAccountDetails";
import { BankAccountEdit } from "./BankAccountEdit";

export const BankAccount = () => {
  const { t } = useTranslation("host.billing");
  const bankAccount = useSelector(bankAccountSelector);
  const [showModal, setShowModal] = useState(false);

  const hasBankAccount = !isEmpty(bankAccount);

  return (
    <div className="BankAccount">
      {showModal && <BankAccountEdit handleClose={() => setShowModal(false)} />}

      {hasBankAccount ? (
        <BankAccountDetails bankAccount={bankAccount} />
      ) : (
        <p className="reset-margin" data-testid="no-account-added">
          {t("bank_account.bank_account_not_added")}
        </p>
      )}
      <Button
        type="button"
        onClick={() => setShowModal(true)}
        data-cy="add-bank-account"
        data-testid="add-bank-account"
      >
        {hasBankAccount
          ? t("bank_account.edit_bank_account_details")
          : t("bank_account.add_bank_account_details")}
      </Button>
    </div>
  );
};
