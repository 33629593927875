import "./AmenitiesPhotos.scss";

import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { PhotosUpload } from "../../../../../components/common/PhotosUpload";
import { amenitiesPhotosSelector } from "../../../../../data/selectors/homeInfo";
import { useHomeInfoContext } from "../../HomeInfoContext";
import {
  APPLIANCES_PROPERTY_FILE_FOLDER,
  PhotosContextProvider,
  usePhotosContext
} from "../../PhotosContext";
import { PhotosGallery } from "../photos/PhotosGallery";

export const PhotosContent = ({ type }) => {
  const { t } = useTranslation("host.home_info");
  const [error, setError] = useState(null);
  const {
    setUploading,
    uploading,
    uploadImage,
    selectedPropertyId,
    getAppliancesPhotos
  } = usePhotosContext();
  const { data } = useHomeInfoContext();
  const photos = useSelector(state =>
    amenitiesPhotosSelector(state, selectedPropertyId, type)
  );

  const handleUpload = useCallback(
    acceptedFiles => {
      setUploading(true);

      Promise.allSettled(
        acceptedFiles.map(file => {
          return uploadImage(file, APPLIANCES_PROPERTY_FILE_FOLDER, type);
        })
      ).then(res => {
        setUploading(false);
        getAppliancesPhotos(type);

        if (res.every(item => item.status === "rejected")) {
          setError(t("problem_uploading_photos"));
        } else if (res.find(item => item.status === "rejected")) {
          const errorFiles = res
            .filter(item => item.status === "rejected")
            .map(item => item.reason.filename)
            .join(", ");

          setError(t("problem_uploading_some_photos", { errorFiles }));
        }
      });
    },
    [uploadImage, t, setUploading, getAppliancesPhotos, type]
  );

  useEffect(() => {
    getAppliancesPhotos(type);
  }, [type, getAppliancesPhotos]);

  return (
    <div className="AmenitiesPhotos">
      <p>
        {t(`subsection.${type}`)} {t("common:photos")}
      </p>
      {data.can_edit && (
        <PhotosUpload
          handleUpload={handleUpload}
          isUploading={uploading}
          isError={error}
        />
      )}

      <PhotosGallery photos={photos} />
    </div>
  );
};

export const AmenitiesPhotos = ({ type }) => {
  return (
    <PhotosContextProvider>
      <PhotosContent type={type} />
    </PhotosContextProvider>
  );
};
