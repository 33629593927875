// See https://docs.imagekit.io/features/image-transformations
const buildImageKitTransformations = ({ w, h } = {}) =>
  [w && `w-${w}`, h && `h-${h}`, "fo-auto"].filter(Boolean).join(",");

export const cleanImageUrl = (imageUrl, options = {}) => {
  const transformations = buildImageKitTransformations(options);
  const { lastItem, urlEndpoint } = getUrlElements(imageUrl);

  return `${urlEndpoint}/tr:${transformations}/${lastItem}`;
};

export const getUrlElements = imageUrl => {
  const elementArray = imageUrl.split("/");
  const [lastItem] = elementArray.slice(-1);
  elementArray.pop();

  return { lastItem, urlEndpoint: elementArray.join("/") };
};
