import "./LoginForm.scss";

import { ErrorMessage, Field, Form, Formik } from "formik";
import trim from "lodash/trim";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as yup from "yup";

import Button from "./buttons/Button";
import DefaultError from "./common/DefaultError";
import Logo from "./common/Logo";
import { LangSelect } from "./LangSelect";

const LoginForm = ({
  buttonText,
  email,
  handleSubmit,
  initialEmailError = null,
  children,
  loading,
  isSignup
}) => {
  const { t } = useTranslation(["host.signup", "host.auth"]);

  const validationSchema = useMemo(() => {
    const password2Schema = yup
      .string()
      .oneOf([yup.ref("password")], t("common:errors.password_do_not_match"))
      .required(t("host.auth:login.form.password_required"));

    return yup.object().shape({
      login_email: yup
        .string()
        .email(t("host.auth:login.form.email_format"))
        .required(t("host.auth:login.form.email_required")),
      password: yup
        .string()
        .min(5, t("host.auth:login.form.password_min_length"))
        .required(t("host.auth:login.form.password_required")),
      password2: isSignup ? password2Schema : null
    });
  }, [isSignup, t]);

  const initialValues = useMemo(
    () => ({
      login_email: email || "",
      password: ""
    }),
    [email]
  );

  return (
    <div className="LoginForm">
      <div className="LoginForm__topContainer">
        <div className="dummy"></div>
        <Logo />
        <LangSelect />
      </div>

      {children}

      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, isValid, errors, values, setFieldValue }) => (
          <Form className="Signup__form" data-testid="login-form">
            <div className="LoginForm__form__email">
              <label>
                {t("login.email_label")}
                <Field
                  name="login_email"
                  type="text"
                  onChange={event => {
                    setFieldValue("login_email", trim(event.target.value));
                  }}
                  autoComplete="email"
                  data-testid="email-field"
                />

                {Boolean(initialEmailError) && (
                  <DefaultError> {initialEmailError}</DefaultError>
                )}

                <ErrorMessage name="login_email" component={DefaultError} />
              </label>
            </div>

            <div className="LoginForm__form__password">
              <label>
                {t("common:password")}
                <Field
                  name="password"
                  type="password"
                  autoComplete={isSignup ? "new-password" : "current-password"}
                  data-testid="password-field"
                />
                <ErrorMessage name="password" component={DefaultError} />
              </label>
            </div>

            {isSignup && (
              <div className="LoginForm__form__password_confirmation">
                <label>
                  {t("common:confirm_password")}
                  <Field
                    name="password2"
                    type="password"
                    autoComplete="new-password"
                    data-testid="password-confirmation-field"
                  />
                  <ErrorMessage name="password2" component={DefaultError} />
                </label>
              </div>
            )}

            {errors.loginError && (
              <DefaultError>{errors.loginError}</DefaultError>
            )}

            <Button
              loading={loading}
              disabled={!isValid || isSubmitting}
              type="submit"
              data-testid="submit-button"
            >
              {t(buttonText)}
            </Button>

            {!isSignup && (
              <div
                className="LoginForm__secondary-action"
                data-testid="forgotten-password-button"
              >
                <Link
                  to={{
                    pathname: "/forgotten_password"
                  }}
                  state={{ email: values.login_email }}
                >
                  {t("host.auth:login.forgotten_password_link")}
                </Link>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
