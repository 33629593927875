import { getSelectedProperty } from "data/properties/selectors";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";
import Card from "../../../../components/common/Card";
import Tip from "../../../../components/common/Tip";

export const EmergencyBuffer = () => {
  const { t } = useTranslation("host.billing");
  const { billing_account: billingAccount } = useSelector(getSelectedProperty);

  if (!billingAccount) {
    return null;
  }

  const {
    adjusted_balance_formatted: adjustedBalanceFormatted,
    adjusted_balance: adjustedBalance
  } = billingAccount;

  const renderTooltip = () => {
    return adjustedBalance < 0 ? (
      <p>{t("billing.balance_negative")}</p>
    ) : (
      <p>{t("billing.balance_positive")}</p>
    );
  };

  return (
    <Card
      title={
        <React.Fragment>
          {t("billing.account_balance")}
          <Tip position="bottom-end" html={renderTooltip()}>
            <InfoIcon className="Billing__info-icon" />
          </Tip>
        </React.Fragment>
      }
    >
      <p className="Billing__balance-info">{adjustedBalanceFormatted}</p>
    </Card>
  );
};
