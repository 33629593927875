import "./OnboardingElements.scss";

import { ReactComponent as HomeIcon } from "assets/icons/home.svg";
import { ReactComponent as CogIcon } from "assets/icons/preferences.svg";
import React from "react";
import { useTranslation } from "react-i18next";

export const OnboardingPreferencesCard = ({ children }) => {
  const { t } = useTranslation("host");

  return (
    <div className="Onboarding-Property preferences">
      <h4 className="header">
        <CogIcon />
        {t("nav.property.preferences")}
      </h4>
      {children}
    </div>
  );
};

export const OnboardingPropertyCard = ({ property, children }) => {
  const { t } = useTranslation("host");
  const title = property.full_address || t("nav.property.more_info_needed");

  return (
    <div className="Onboarding-Property">
      <h4 className="header">
        <HomeIcon />
        <span className="Onboarding-Property__text">{title}</span>
      </h4>
      {children}
    </div>
  );
};
