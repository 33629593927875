import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getSelectedPropertyId } from "../../../data/properties/selectors";
import api from "../../../services/api";
import { SECTIONS } from "./components/sections";

const CAN_EDIT = "can_edit";
const OTHER_BOOLEAN_STRINGS = [
  "unsuitable_for_infants",
  "allows_pets_as_host",
  "unsuitable_for_children"
];

const HomeInfoContext = React.createContext({});
export const useHomeInfoContext = () => useContext(HomeInfoContext);

const sanitizeDataForForm = data =>
  Object.keys(data).reduce((accumulator, key) => {
    const value = data[key];
    const isBoolean =
      key.endsWith("_available") || OTHER_BOOLEAN_STRINGS.includes(key);
    const emptyFieldValue = isBoolean ? Boolean(value) : "";

    return {
      ...accumulator,
      [key]: value || emptyFieldValue
    };
  }, {});

const sanitizeDataForUpdate = data =>
  Object.keys(data).reduce(
    (accumulator, key) =>
      key === CAN_EDIT
        ? accumulator
        : {
            ...accumulator,
            [key]: data[key]
          },
    {}
  );

export const HomeInfoContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState(undefined);
  const [data, setData] = useState(undefined);
  const selectedPropertyId = useSelector(getSelectedPropertyId);

  const request = async (fn, ...args) => {
    setError(null);
    setLoading(true);

    try {
      const newData = await fn(...args);
      setData(sanitizeDataForForm(newData));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const url = `/v1/hosts/home_info/${selectedPropertyId}`;

  const refreshData = useCallback(() => request(api.get, url), [url]);

  const handleSubmitAndNavigateNext = useCallback(
    sectionId => async data => {
      await request(api.patch, url, null, sanitizeDataForUpdate(data));
      navigate(`/home-info/${SECTIONS[sectionId].nextSection}`);
    },
    [navigate, url]
  );

  useEffect(() => {
    refreshData();
  }, [selectedPropertyId, refreshData]);

  return (
    <HomeInfoContext.Provider
      value={{
        data,
        loading,
        error,
        handleSubmitAndNavigateNext
      }}
    >
      {children}
    </HomeInfoContext.Provider>
  );
};
