import "./LangSelect.scss";

import { ReactComponent as Overviewcon } from "assets/icons/globe.svg";
import classNames from "classnames";
import { languages } from "countries-list";
import React, { useCallback } from "react";

import i18n from "../i18n";
import Dropdown from "./common/Dropdown";

const supportedLangCodes = ["en", "fr", "es", "pt"];

export const LangSelect = () => {
  const selectedLangCode = i18n.language;

  const handleSelect = useCallback(langCode => {
    i18n.changeLanguage(langCode);
  }, []);

  return (
    <Dropdown
      buttonCy="language-select"
      buttonContent={
        <>
          <Overviewcon />
          {selectedLangCode.toUpperCase()}
        </>
      }
    >
      {supportedLangCodes.map(langCode => (
        <LangSelectItem
          key={langCode}
          langCode={langCode}
          nativeName={languages[langCode].native}
          selected={selectedLangCode === langCode}
          onSelect={handleSelect}
        />
      ))}
    </Dropdown>
  );
};

const LangSelectItem = ({ langCode, nativeName, selected, onSelect }) => {
  const handleClick = useCallback(
    () => onSelect(langCode),
    [langCode, onSelect]
  );

  return (
    <div
      className={classNames("LangSelect__item", {
        "LangSelect__item--selected": selected
      })}
      onClick={handleClick}
    >
      <span>{nativeName}</span>
    </div>
  );
};
