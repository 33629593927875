import "./TermsFrame.scss";

import * as Fetchers from "data/actions/fetchers";
import { getSelectedPropertyId } from "data/properties/selectors";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import Button from "../buttons/Button";
import Fetcher from "../Fetcher";
import InlineIframe from "./InlineIframe";

class TermsFrame extends Component {
  print = () => {
    document.getElementById("terms").contentWindow.print();
  };

  render() {
    const { t, selectedPropertyId } = this.props;
    return (
      <div className="TermsFrame">
        <Fetcher
          fetcher={Fetchers.terms(selectedPropertyId)}
          render={({ payload }) => (
            <div className="TermsFrame__container">
              <InlineIframe id="terms" title="contract" htmlContent={payload} />
            </div>
          )}
          spin
          cover
        />

        <Button outline type="button" small onClick={this.print}>
          {t("terms.print")}
        </Button>
      </div>
    );
  }
}

export default compose(
  withTranslation("host.setup"),
  connect(state => ({
    selectedPropertyId: getSelectedPropertyId(state)
  }))
)(TermsFrame);
