import "./ExtraInfo.scss";

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useToggle } from "utils/useToggle";

import PayoutsIcon from "../../../../assets/icons/piggybank.svg";
import InsuranceIcon from "../../../../assets/icons/platforms/insurance.svg";
import RateIcon from "../../../../assets/icons/platforms/rate.svg";
import VerificationIcon from "../../../../assets/icons/platforms/verification.svg";
import { InsuranceTermsLink } from "../../../../components/common/InsuranceTermsLink.jsx";
import Modal from "../../../../components/common/modal";

const InfoButton = ({ title, src, alt, children }) => {
  const [isModalVisible, toggleModal] = useToggle();

  return (
    <>
      {isModalVisible && (
        <Modal title={title} closeModal={toggleModal} showDesktopCloseButton>
          {children}
        </Modal>
      )}

      <div
        data-cy="info-item"
        className="ExtraInfo__item"
        onClick={toggleModal}
      >
        <div className="ExtraInfo__item__icon">
          <img src={src} alt={alt} />
        </div>
        <h3>{title}</h3>
      </div>
    </>
  );
};

export const ExtraInfo = ({ property }) => {
  const { t } = useTranslation("host.platforms");
  const { locality } = property;

  return (
    <div className="ExtraInfo">
      <h3 className="small-header">
        {t("trust.header")}&nbsp;<small>{t("trust.header.click_more")}</small>
      </h3>

      <div className="ExtraInfo__container">
        <InfoButton
          title={t("trust.rate_title")}
          src={RateIcon}
          alt={t("trust.rate_img_alt")}
        >
          <Trans ns="host.platforms" i18nKey="trust.rate_info">
            <p>
              Listing across multiple platforms increases your property’s
              exposure to guests, making you more likely to receive bookings. We
              price your property strategically to account for each platform’s
              commission, and to achieve the best occupancy for your
              availability, by paying special attention to extra guest fees and
              using discounts to target mid term stays during lower seasons on
              the channels that accept longer term stays.
            </p>
            <p>
              Additional costs are passed on to guest to secure your revenue
              regardless of the platform that bookings come from: 3rd Party
              Channel Manager Fee: 2-3%, Trust and Safety Fee (contributes
              towards ID verification and Insurance): 7.5% and Payment
              Processing fee: 1.5%.
            </p>
            <p>
              Houst’s commission will be charged on the net of all platform
              fees, plus the cleaning fee, and will be the same percentage
              across all platforms.
            </p>
          </Trans>
        </InfoButton>

        <InfoButton
          title={t("trust.insurance_title")}
          src={InsuranceIcon}
          alt={t("trust.insurance_img_alt")}
        >
          <p>
            <Trans ns="host.platforms" i18nKey="trust.insurance_info">
              Airbnb is the only platform that provides all users with a Host
              Guarantee that covers damages and liabilities. Houst partners with
              Guardhog to provide the same level of cover for bookings (up to 6
              months) from all other platforms, at no additional costs to hosts.
              For more information see&nbsp;
              <InsuranceTermsLink locality={locality}>here</InsuranceTermsLink>.
            </Trans>
          </p>
        </InfoButton>

        <InfoButton
          title={t("trust.verification_title")}
          src={VerificationIcon}
          alt={t("trust.verification_img_alt")}
        >
          <p>{t("trust.verification_info")}</p>
        </InfoButton>

        <InfoButton
          title={t("trust.payouts_title")}
          src={PayoutsIcon}
          alt={t("trust.payouts_img_alt")}
        >
          <p>
            <Trans ns="host.platforms" i18nKey="trust.payouts_info">
              For non-Airbnb short-let bookings, you will receive a lump sum
              payout by the 10th of each month, following the month of checkin.
              Make sure your payout details are entered on the&nbsp;
              <Link to="/billing">Billing Page</Link>
            </Trans>
          </p>
        </InfoButton>
      </div>
    </div>
  );
};

export default ExtraInfo;
