import "./PostSetup.scss";

import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Spinner from "../../../components/Spinner.jsx";
import { fetch } from "../../../data/actions/fetcher";
import { updateProperty } from "../../../data/actions/fetchers";
import {
  receiveProperties,
  resumePropertySetup
} from "../../../data/actions/properties";
import {
  getPropertiesNeedingMeeting,
  getSelectedProperty,
  isSetupSkipped
} from "../../../data/properties/selectors";
import { getProfile } from "../../../data/reducers/user";
import api from "../../../services/api";
import { OnboardingChecks } from "./components/OnboardingChecks";
import { OnboardingPropertiesDetails } from "./components/OnboardingPropertiesDetails";
import { OnboardingStages } from "./components/OnboardingStages";

export const PostSetupContext = React.createContext({});

const Onboarding = () => {
  const { properties, selectedProperty } = useContext(PostSetupContext);

  return (
    <div className="PostSetup" data-testid="postsetup-onboarding">
      <OnboardingStages
        properties={properties}
        selectedProperty={selectedProperty}
      />

      <OnboardingChecks selectedProperty={selectedProperty} />
      <OnboardingPropertiesDetails />
    </div>
  );
};

const PostSetup = ({
  properties,
  setupSkipped,
  selectedProperty,
  resumePropertySetup,
  receiveProperties,
  resumeMeetingSetup
}) => {
  const { t } = useTranslation("host.onboarding");
  const [loading, setLoading] = useState(true);

  const fetchProperties = useCallback(async () => {
    try {
      setLoading(true);
      const properties = await api.get("/v1/hosts/properties");
      receiveProperties(properties);
    } finally {
      setLoading(false);
    }
  }, [receiveProperties]);

  useEffect(() => {
    fetchProperties();
  }, [fetchProperties]);

  if (loading) {
    return <Spinner cover>{t("common:loading")}</Spinner>;
  }

  return (
    <PostSetupContext.Provider
      value={{
        properties,
        resumeMeetingSetup,
        resumePropertySetup,
        selectedProperty,
        setupSkipped
      }}
    >
      <Onboarding />
    </PostSetupContext.Provider>
  );
};

export default connect(
  state => ({
    user: getProfile(state),
    selectedProperty: getSelectedProperty(state),
    properties: getPropertiesNeedingMeeting(state),
    setupSkipped: isSetupSkipped(state)
  }),
  dispatch => ({
    resumePropertySetup,
    receiveProperties,
    resumeMeetingSetup: propertyId =>
      dispatch(
        fetch(updateProperty(propertyId, { onboarding_meeting_skipped: false }))
      )
  })
)(PostSetup);
