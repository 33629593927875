import { useAllowedMinimumNights } from "data/preferences/useAllowedMinimumNights";
import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";
import Tip from "../../../../components/common/Tip.jsx";

export const MinimumNightsTooltip = ({ overrideAmount }) => {
  const { t } = useTranslation("host.calendar");
  const amount = useAllowedMinimumNights();

  return (
    <Tip
      position="bottom-start"
      html={
        <>
          <h5 className="small-header">{t("minimum_nights.tip.title")}</h5>
          <p>
            {t("minimum_nights.tip.description", {
              amount: overrideAmount || amount
            })}
          </p>
        </>
      }
    >
      <InfoIcon />
    </Tip>
  );
};
