import {
  EMAIL_UPDATED,
  LOGGED_OUT,
  PASSWORD_UPDATED,
  RECEIVE_LOCALITIES,
  RECEIVE_LOGIN,
  RECEIVE_USER,
  SET_ADMIN_USER
} from "../action_types";
import { clearUserFetcher } from "./fetcher";

export const logout = () => [
  clearUserFetcher,
  {
    type: LOGGED_OUT
  }
];

export const receiveLogin = ({ token, user }) => [
  clearUserFetcher,
  {
    type: RECEIVE_LOGIN,
    token,
    user
  }
];

export const receiveUser = user => ({
  type: RECEIVE_USER,
  user
});

export const setAdminUser = () => ({
  type: SET_ADMIN_USER
});

export const emailUpdated = user => ({
  type: EMAIL_UPDATED,
  user
});

export const passwordUpdated = () => ({
  type: PASSWORD_UPDATED
});

export const receiveLocalities = localities => ({
  type: RECEIVE_LOCALITIES,
  localities
});
