import { Field, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { useHomeInfoContext } from "../../HomeInfoContext";
import { HomeInfoButtons } from "../HomeInfoButtons";
import { HomeInfoForm } from "../HomeInfoForm.jsx";
import { ADDITIONAL_DETAILS } from "./constants";

export const AdditionalDetails = () => {
  const { t } = useTranslation("host.home_info");

  const { data, handleSubmitAndNavigateNext } = useHomeInfoContext();

  return (
    <div className="HomeInfo__content">
      <Formik
        initialValues={data}
        onSubmit={handleSubmitAndNavigateNext(ADDITIONAL_DETAILS)}
      >
        {({ handleChange, values }) => (
          <HomeInfoForm>
            <label className="HomeInfoForm__element">
              {t("label.emergency_contact")}
              <Field name="emergency_contact" />
            </label>

            <label className="HomeInfoForm__element">
              {t("label.emergency_number")}
              <Field name="emergency_number" />
            </label>

            <label className="HomeInfoForm__element">
              {t("label.building_manager")}
              <Field name="building_manager" />
            </label>
            <HomeInfoButtons sectionId={ADDITIONAL_DETAILS} />
          </HomeInfoForm>
        )}
      </Formik>
    </div>
  );
};
