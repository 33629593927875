import "./UpdateCommunication.scss";

import guardhogLogo from "assets/images/guardhog_insurance_partnered.png";
import Button from "components/buttons/Button";
import Alert from "components/common/Alert";
import Card from "components/common/Card";
import Input from "components/common/Input";
import Checkbox from "components/forms/Checkbox";
import { fetch } from "data/actions/fetcher";
import { updatePreferences } from "data/actions/fetchers";
import { get } from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import bem from "utils/bem";

import {
  ACCOUNT_PREFERENCES_KEYS,
  getAccountPreferences
} from "../../../../data/preferences/selectors";

const groupsMap = {
  little: "settings.preference_group_minimum_involvement",
  major: "settings.preference_group_major_issues_only",
  minor: "settings.preference_group_minor_issues_only",
  booking: "settings.preference_group_booking_issues_only"
};

export class UpdateCommunication extends Component {
  initialState = {
    settings: this.props.settings,
    success: false,
    error: null
  };

  state = Object.assign({}, this.initialState);

  get preferencesChanged() {
    const { settings } = this.props;

    return ACCOUNT_PREFERENCES_KEYS.some(
      key => settings[key] !== this.state.settings[key]
    );
  }

  resetPreferences = () => {
    this.setState({ ...this.initialState });
  };

  savePreferences = () => {
    const { updatePreferences, selectedProperty } = this.props;

    return updatePreferences(selectedProperty.id, this.state.settings)
      .then(() =>
        this.setState({
          success: true,
          error: null
        })
      )
      .catch(e => {
        this.setState({
          success: false,
          error: get(e, "error.message")
        });
      });
  };

  renderInput = (Comp, props) => {
    const { split = true, ...otherProps } = props;
    const { settings } = this.state;

    return (
      <div className={"Settings__field"}>
        <Comp
          value={settings[props.name]}
          onChange={value =>
            this.setState({
              settings: { ...settings, [props.name]: value }
            })
          }
          split={split}
          {...otherProps}
        />
      </div>
    );
  };

  renderCheckbox = ({ name, label }) => {
    const { settings } = this.state;

    return (
      <div className="Settings__field">
        <Checkbox
          id={name}
          label={label}
          onChange={({ target: { checked } }) => {
            this.setState({
              settings: { ...settings, [name]: checked }
            });
          }}
          checked={this.state.settings[name] || ""}
        />
      </div>
    );
  };

  renderInsuranceNote = () => {
    const { independent_insurance } = this.state.settings;
    const { t, selectedProperty } = this.props;
    const insuranceProvided = get(
      selectedProperty,
      "locality.insurance_provided"
    );

    if (!independent_insurance && insuranceProvided) {
      return (
        <div className={"Settings__insurance_alert"}>
          <Alert info>
            <img src={guardhogLogo} alt="Guardhog insurance partner" />
            <p>
              <small>{t("settings.insurance.disclosure_msg1")}</small>
            </p>
            <p>
              <small>{t("settings.insurance.disclosure_msg2")}</small>
            </p>
          </Alert>
        </div>
      );
    }
  };

  render() {
    const { t } = this.props;
    const { error } = this.state;

    return (
      <div className="AccountSettings">
        <Card title={t("settings.communications_title")}>
          <div className="Settings">
            <div className="Settings__group">
              <div className="Settings__field">
                <span className="Input__label">
                  {t("settings.communications.info")}
                </span>
              </div>
              {this.renderCheckbox({
                name: "send_booking_confirmation_smses",
                label: t(
                  "settings.communications.allow_booking_confirmation_smses"
                )
              })}
              {this.renderCheckbox({
                name: "send_booking_confirmation_emails",
                label: t(
                  "settings.communications.allow_booking_confirmation_emails"
                )
              })}
              {this.renderCheckbox({
                name: "send_host_clean_survey_emails",
                label: t(
                  "settings.communications.allow_housekeeping_photo_emails"
                )
              })}
              {this.renderCheckbox({
                name: "send_host_clean_survey_sms",
                label: t("settings.communications.allow_housekeeping_photo_sms")
              })}
            </div>

            <div className="Settings__group">
              {this.renderInput(Input.Select, {
                label: t("settings.info"),
                name: "host_preference_group",
                children: Object.keys(groupsMap).map(value => (
                  <option key={value} value={value}>
                    {t(groupsMap[value])}
                  </option>
                )),
                split: false,
                style: { marginTop: "1.25rem" }
              })}
            </div>
          </div>

          <div className="Settings__group__wide">
            <hr />

            <div className="Settings__group">
              {this.renderInput(Input.Toggle, {
                label: t("settings.insurance_info"),
                name: "independent_insurance",
                toggleLabelOn: t("common:yes"),
                toggleLabelOff: t("common:no")
              })}
            </div>
            <div className="Settings__group">{this.renderInsuranceNote()}</div>
          </div>
        </Card>
        <div
          className={bem("Preferences__footer", {
            showing: this.preferencesChanged
          })}
        >
          {error && (
            <div className="AccountSettings__error">Error: {error}</div>
          )}
          <Button outline type="button" onClick={this.resetPreferences}>
            {t("common:cancel")}
          </Button>
          <Button type="button" onClick={() => this.savePreferences()}>
            {t("common:save")}
          </Button>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation("host.preferences"),
  connect(
    state => ({
      settings: getAccountPreferences(state)
    }),
    dispatch => ({
      updatePreferences: (propertyId, prefs) =>
        dispatch(fetch(updatePreferences(propertyId, prefs)))
    })
  )
)(UpdateCommunication);
