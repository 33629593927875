function isPromise(val) {
  return val && typeof val.then === "function";
}

export default function promiseMiddleware({ dispatch }) {
  return next => action => {
    return isPromise(action)
      ? action.then(a => {
          if (a) {
            dispatch(a);
          }
        })
      : next(action);
  };
}
