import "./ChevronUpDown.scss";

import classNames from "classnames";
import React from "react";

import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron.svg";

export const ChevronUpDown = ({ expanded }) => (
  <div className={classNames("ChevronUpDown", { expanded })}>
    <ChevronIcon />
  </div>
);
