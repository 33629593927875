import React from "react";

import { prettyDate, shortPrettyDate } from "../../utils/dates";

//TODO: refactor date formating to use dateParser.js

export const BookingShortDate = ({ date }) => (
  <p className="booking-date">{shortPrettyDate(date)} </p>
);

export const BookingLongDate = ({ date }) => (
  <p className="booking-date" data-cy="booking-date">
    {" "}
    {prettyDate(date)}{" "}
  </p>
);

export const BookingDateTime = ({ date, time, separator }) => {
  const formatedTime = time.substring(0, time.length - 3);

  return (
    <p className="booking-date">
      {prettyDate(date)} {separator} {formatedTime}
    </p>
  );
};
