import "./Platform.scss";

import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

const PlatformLink = ({ url, children }) => {
  const { t } = useTranslation("host.platforms");

  return url ? (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      title={t("platform.open_link")}
    >
      {children}
    </a>
  ) : (
    children
  );
};

const Platform = platformData => {
  const {
    listing_url: listingUrl,
    status,
    channel_details: channelDetails
  } = platformData;

  return (
    <PlatformLink url={listingUrl}>
      <div
        className={classNames("Platform", `Platform--${status}`)}
        data-cy={`platform-${status}`}
      >
        <div className="Platform__icon">
          <img src={channelDetails.logo_url} alt={channelDetails.name} />
        </div>
      </div>
    </PlatformLink>
  );
};

export default Platform;
