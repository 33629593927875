import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { formatPerformanceMonthTick } from "./utils";

// Datum is passed down as props from the VictoryTooltip component
// This is used to render a custom HTML tooltip in an svg
// https://github.com/FormidableLabs/victory/issues/445#issuecomment-327953835
const PerformanceChartTooltip = ({ data, datum, x, y }) => {
  const { t } = useTranslation("host.performance");

  const tootlipText = useMemo(
    () => data.net_earnings.find(obj => obj.x === datum.xName).label_text,
    [data.net_earnings, datum.xName]
  );

  // Adjust the tooltip horizontal and vertical recommended position
  // passed by Victory via props, to prevent it overflowing
  const adjustedX = x * 0.65;
  const adjustedY = y * 0.5;

  return (
    <foreignObject x={adjustedX} y={adjustedY} width="120" height="100">
      <div className="PerformanceChart__tooltip">
        <h3>{formatPerformanceMonthTick({ tick: datum.x, format: "full" })}</h3>
        <div className="data">
          <p>
            {datum.type === "exact"
              ? t("chart_label_host_net")
              : t("chart_label_host_net_estimated")}
          </p>
          <p>{tootlipText}</p>
        </div>
      </div>
    </foreignObject>
  );
};

export default PerformanceChartTooltip;
