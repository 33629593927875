import { mapValues } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  bankAccountSelector,
  selectedPropertyCountryCodeSelector
} from "../../../../../data/properties/selectors";
import api from "../../../../../services/api";
import BankAccountForm from "./BankAccountForm";
import { STEP_PASSWORD } from "./steps";

export const BankAccountChangeDetails = ({
  setStep,
  setFormData,
  handleCancel,
  formData
}) => {
  const { t } = useTranslation("host.billing");
  const [serverError, setServerError] = useState(null);
  const bankAccount = useSelector(bankAccountSelector);
  const countryCode = useSelector(selectedPropertyCountryCodeSelector);

  const handleSubmit = async ({
    setSubmitting,
    setErrors,
    resetForm,
    type,
    values
  }) => {
    const formattedValues = await mapValues(values, (value, key) => {
      if (key !== "owner_name") {
        return value.replace(/[-|\s]/g, "");
      }

      return value;
    });

    setServerError(null);

    await api
      .put("/v1/hosts/billing/bank_account", null, {
        type,
        ...formattedValues
      })
      .catch(err => {
        if (err.statusCode === 400) {
          setServerError(t("bank_account.fill_form_with_correct_data"));
        }

        if (err.statusCode === 403) {
          setFormData({ type, ...formattedValues });
          setStep(STEP_PASSWORD);
        }

        setServerError(t("common:error.try_again"));
      });
  };

  if (bankAccount) {
    const initialValues = {
      owner_name: formData.owner_name || "",
      account_number: formData.account_number || "",
      sort_code: formData.sort_code || "",
      iban: formData.iban || "",
      bic: formData.bic || "",
      bsb: formData.bsb || "",
      interac_email: formData.interac_email || ""
    };

    return (
      <>
        <p data-testid="bank-account-change-header">
          {t("bank_account.modal.header")}
        </p>
        <BankAccountForm
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          initialValues={initialValues}
          serverError={serverError}
          countryCode={countryCode}
        />
      </>
    );
  }
};
