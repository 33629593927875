import "./Input.scss";
import "./Toggle.scss";

import classNames from "classnames";
import { identity, isNil } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import bem from "utils/bem";
import { intOrZero } from "utils/numbers";

let INPUT_COUNTER = 0;

const isBlank = value => value === "" || isNil(value);

export const ErrorText = props => {
  return (
    <div className="Input__error">
      <p className={"Input__error__message"}>
        <small>{props.children}</small>
      </p>
    </div>
  );
};

export default class Input extends Component {
  static className = "Input";

  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    afterLabel: PropTypes.string,
    suffix: PropTypes.bool,
    currencySymbol: PropTypes.string,
    inline: PropTypes.bool,
    split: PropTypes.bool,
    top: PropTypes.bool,
    name: PropTypes.string,
    className: PropTypes.string,
    formValue: PropTypes.object,
    toggleLabelOn: PropTypes.string,
    toggleLabelOff: PropTypes.string,
    autofocus: PropTypes.bool,
    testId: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isControlled: PropTypes.bool
  };

  static defaultProps = {
    inline: false,
    split: false,
    className: "",
    label: undefined,
    name: undefined,
    formValue: undefined,
    afterLabel: undefined,
    toggleLabelOn: undefined,
    toggleLabelOff: undefined,
    autofocus: undefined
  };

  static inputElement = "input";
  static inputType = "text";

  constructor(...args) {
    super(...args);

    INPUT_COUNTER += 1;
    this.id = INPUT_COUNTER;
  }

  get extraProps() {
    // eslint-disable-next-line
    const propTypes = this.constructor.propTypes || {};

    return Object.keys(this.props).reduce((s, p) => {
      if (propTypes[p]) return s;
      return { ...s, [p]: this.props[p] };
    }, {});
  }

  onFormChange = e => {
    const { onChange, withFormik } = this.props;

    if (!e || !e.target)
      throw new Error("Input change was called with an invalid argument");

    const { value, checked } = e.target;
    let val = this.constructor.inputType === "checkbox" ? checked : value;
    if (this.constructor.inputType === "number" && isFinite(val)) {
      val = parseInt(val, 10);
    }

    // TODO: refactor this whole component
    // withFormik flag added as workaround for home-info feature
    if (onChange) {
      if (withFormik) {
        onChange(e);
      } else {
        onChange(val);
      }
    }
  };

  get data() {
    const {
      id,
      label,
      className,
      afterLabel,
      inline,
      split,
      onBlur = this.onFormBlur,
      formValue = {},
      autofocus,
      isControlled,
      ...props
    } = this.props;

    const {
      name = formValue.name || "",
      checked = Boolean(formValue.value),
      value = formValue.value || null,
      error = formValue.error,
      disabled = formValue.disabled
    } = props;

    const key = id || `${name}_${this.id}`;

    return {
      key,
      name,
      checked,
      value,
      error,
      disabled,
      onChange: this.onFormChange,
      onBlur,
      autofocus,
      isControlled
    };
  }

  renderInput() {
    const {
      key,
      name,
      disabled,
      value,
      onChange,
      onBlur,
      checked,
      autofocus,
      isControlled = true
    } = this.data;
    const { inputElement, inputType } = this.constructor;
    const InputComponent = inputElement;
    const valueProp = isControlled
      ? {
          value: value || ""
        }
      : {};

    return (
      <span
        className={classNames(`Input__${inputElement}`, {
          "Input--disabled": disabled
        })}
      >
        <InputComponent
          type={inputType}
          {...this.extraProps}
          ref={r => (this.input = r)}
          id={key}
          name={name}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          checked={checked}
          autoFocus={autofocus}
          {...valueProp}
        />
      </span>
    );
  }

  render() {
    const { label, className, afterLabel, inline, split, top, testId } =
      this.props;
    const { key, value, checked, error } = this.data;

    return (
      <div
        className={`${bem("Input", {
          checked: checked || value === true,
          inline,
          split,
          top,
          error: !!error
        })} ${className}`}
      >
        {label && (
          <label htmlFor={key} className={"Input__label"}>
            {label}
          </label>
        )}

        <div data-cy={testId}>
          {this.renderInput()}
          {error && <ErrorText>{error}</ErrorText>}
        </div>
        {afterLabel && (
          <span className={"Input__label Input__label--after"}>
            {afterLabel}
          </span>
        )}
      </div>
    );
  }
}

Input.Area = class InputArea extends Input {
  static inputElement = "textarea";
};

Input.Select = class InputSelect extends Input {
  static inputElement = "select";
};

Input.Checkbox = class InputCheckbox extends Input {
  // WARNING: the inputElement is duplicated here and in other declarations to cover a bug in IE
  static inputElement = "input";
  static inputType = "checkbox";
};

Input.Email = class InputEmail extends Input {
  static inputElement = "input";
  static inputType = "email";
};

Input.Tel = class InputTel extends Input {
  static inputElement = "input";
  static inputType = "tel";
};

Input.Password = class InputPassword extends Input {
  static inputElement = "input";
  static inputType = "password";
};

Input.Number = class InputNumber extends Input {
  static inputElement = "input";
  static inputType = "number";
};

Input.Money = class InputNumber extends Input {
  static inputElement = "input";
  static inputType = "number";

  parsedValue = value => (isBlank(value) ? "" : `${intOrZero(value)}`);

  onFormChange = e => {
    const { value } = e.target;
    const { onChange = identity } = this.props;
    onChange(this.parsedValue(value));
  };

  renderInput() {
    const { key, name, disabled, value, onChange, onBlur, checked } = this.data;

    const { currencySymbol, suffix } = this.props;

    const parsedValue = this.parsedValue(value);

    const addon = currencySymbol && (
      <span className="input-group-addon">{currencySymbol}</span>
    );

    return (
      <div
        className={classNames("Input__input", "Input__input__with_addon", {
          Input__input__with_addon__suffix: suffix,
          Input__input__with_addon__prefix: !suffix
        })}
      >
        {!suffix && addon}
        <input
          {...this.extraProps}
          type={InputNumber.inputType}
          ref={r => (this.input = r)}
          id={key}
          name={name}
          value={parsedValue}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          checked={checked}
        />
        {suffix && addon}
      </div>
    );
  }
};

Input.Date = class InputDate extends Input {
  static inputElement = "input";
  static inputType = "date";
};

class InputRadioOption extends Input {
  static inputElement = "input";
  static inputType = "radio";
}

Input.Radio = class InputRadio extends Input {
  static inputElement = "input";
  static inputType = "radio";

  static propTypes = {
    // eslint-disable-next-line
    ...Input.propTypes,
    options: PropTypes.object.isRequired
  };

  render() {
    const { options, ...props } = this.props;
    const { formValue } = this.props;
    const { name = formValue.name, value = formValue.value } = this.props;

    return (
      <div className={"Input__radiogroup"}>
        {Object.keys(options).map(opt => (
          <InputRadioOption
            key={`${name}-${opt}`}
            {...props}
            id={`${name}-${opt}`}
            label={options[opt]}
            checked={opt === value}
            value={opt}
            after
          />
        ))}
      </div>
    );
  }
};

export class InputToggle extends Input {
  static inputElement = "input";
  static inputType = "checkbox";

  renderLabel = value => {
    const { toggleLabelOn, toggleLabelOff, t } = this.props;
    const suffix = value ? "on" : "off";
    const className = `Toggle__toggle__label__${suffix}`;
    const defaultLabelKey = `radio_toggle_label_${suffix}`;
    const labelOn = toggleLabelOn || t("common:yes");
    const labelOff = toggleLabelOff || t("common:no");
    const text = value ? labelOn : labelOff;

    return <span className={className}>{text || t(defaultLabelKey)}</span>;
  };

  renderInput() {
    const { key, value, onChange, disabled } = this.data;

    return (
      <div className="Toggle">
        <input
          className="Toggle__input"
          checked={value}
          onChange={onChange}
          id={key}
          name={key}
          type="checkbox"
          disabled={disabled}
        />

        <label className="Toggle__toggle" htmlFor={key}>
          {this.renderLabel(value)}
        </label>
      </div>
    );
  }
}
Input.Toggle = withTranslation("common")(InputToggle);

Input.Row = props => <div className={"InputRow"} {...props} />;
