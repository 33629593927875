import "./DocumentsLists.scss";

import React, { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import TabControl from "../../../../components/common/TabControl";
import Spinner from "../../../../components/Spinner";
import { selectedPropertyBillingAccount } from "../../../../data/properties/selectors";
import { isAdmin } from "../../../../data/reducers/user";

const Payouts = lazy(() => import("./Payouts"));
const Invoices = lazy(() => import("./Invoices"));
const Transactions = lazy(() => import("./Transactions"));

const INVOICES_TAB_ID = "invoices";
const TRANSACTIONS_TAB_ID = "transactions";
const PAYOUTS_TAB_ID = "payouts";

export const DocumentsLists = () => {
  const { t } = useTranslation("host.billing");
  const billingAccount = useSelector(selectedPropertyBillingAccount);
  const admin = useSelector(isAdmin);

  if (!billingAccount) {
    return (
      <Suspense fallback={<Spinner />}>
        <Invoices showHeader={true} />
      </Suspense>
    );
  }

  const renderTabContent = tab => (
    <div className={`DocumentsLists__active-tab-${tab.id}`}>
      {tab.id === INVOICES_TAB_ID && (
        <Suspense fallback={<Spinner />}>
          <Invoices showHeader={false} className="DocumentsLists__invoices" />
        </Suspense>
      )}
      {tab.id === TRANSACTIONS_TAB_ID && (
        <Suspense fallback={<Spinner />}>
          <Transactions
            billingAccount={billingAccount}
            className="DocumentsLists__transactions"
          />
        </Suspense>
      )}
      {tab.id === PAYOUTS_TAB_ID && (
        <Suspense fallback={<Spinner />}>
          <Payouts className="DocumentsLists__payouts" />
        </Suspense>
      )}
    </div>
  );

  if (!admin) {
    return (
      <TabControl
        tabs={[
          {
            id: TRANSACTIONS_TAB_ID,
            text: t("transactions")
          },
          {
            id: INVOICES_TAB_ID,
            text: t("invoice.invoices"),
            dataCy: "invoice-tab-select"
          }
        ]}
        initialTabId={TRANSACTIONS_TAB_ID}
        renderChildren={renderTabContent}
        className="DocumentsLists"
      />
    );
  }

  return (
    <TabControl
      tabs={[
        {
          id: TRANSACTIONS_TAB_ID,
          text: t("transactions")
        },
        {
          id: INVOICES_TAB_ID,
          text: t("invoice.invoices"),
          dataCy: "invoice-tab-select"
        },
        {
          id: PAYOUTS_TAB_ID,
          text: t("payouts")
        }
      ]}
      initialTabId={TRANSACTIONS_TAB_ID}
      renderChildren={renderTabContent}
      className="DocumentsLists"
    />
  );
};
