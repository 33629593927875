import "./PhotosGallery.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import thumbnail from "../../../../../assets/icons/video.svg";
import DefaultError from "../../../../../components/common/DefaultError";
import ImagesCarousel from "../../../../../components/common/ImagesCarousel";

const CAROUSEL_TYPE_HOST_PHOTOS = "host_photos";

export const PhotosGallery = ({ photos, getError }) => {
  const { t } = useTranslation("common");
  const [showImagesCarousel, setShowImagesCarousel] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const isArrayOfPhotos = Array.isArray(photos);

  const toggleImagesCarousel = () => {
    setShowImagesCarousel(!showImagesCarousel);
  };

  if (getError) {
    return (
      <div>
        <DefaultError>{getError}</DefaultError>
      </div>
    );
  }

  if (isArrayOfPhotos && photos.length === 0) {
    return (
      <div>
        <p className="reset-margin"> {t("no_images")} </p>
      </div>
    );
  }

  return (
    <div>
      {showImagesCarousel && (
        <ImagesCarousel
          currentImageIndex={currentImageIndex}
          images={photos}
          hostSurveyExists={false}
          toggleModal={toggleImagesCarousel}
          carouselType={CAROUSEL_TYPE_HOST_PHOTOS}
        />
      )}

      {isArrayOfPhotos && photos.length > 0 && (
        <div className="PhotosGallery">
          {photos.map((photo, index) => (
            <div
              className="Photo"
              onClick={() => {
                setCurrentImageIndex(index);
                setShowImagesCarousel(true);
              }}
              key={photo.thumbnail_url}
            >
              <img
                src={photo.thumbnail_url || thumbnail}
                alt="Clean"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
