import "./HomeInfoNav.scss";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const HomeInfoNav = ({ children, sections, sub }) => {
  const { t } = useTranslation("host.home_info");

  return (
    <ul className={`HomeInfoNav ${sub ? "HomeInfoNav--sub" : ""}`}>
      {sections.map(sectionId => (
        <li key={sectionId}>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "HomeInfoNav__item HomeInfoNav__item--active"
                : "HomeInfoNav__item"
            }
            to={sub ? `additional/${sectionId}` : sectionId}
          >
            {sub ? t(`amenities.${sectionId}`) : t(`subsection.${sectionId}`)}
          </NavLink>
        </li>
      ))}
      {children && <li>{children}</li>}
    </ul>
  );
};

export default HomeInfoNav;
