import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import Tip from "components/common/Tip";
import { getSelectedPropertyCurrencySymbol } from "data/properties/selectors";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { upcomingSampleBooking } from "utils/constants";

class SampleBookingBreakdown extends Component {
  renderTooltip = () => {
    const { t } = this.props;

    return (
      <>
        <h5 className="small-header">{t("breakdown.tooltip.title")}</h5>

        <p>
          <strong>{t("breakdown.tooltip.cleaning")}</strong>
          <br />
          {t("breakdown.tooltip.airbnb.cleaning_description")}
        </p>

        <p>
          <strong>{t("breakdown.tooltip.airbnb.total")}</strong>
          <br />
          {t("breakdown.tooltip.airbnb.total_description")}
        </p>
      </>
    );
  };

  render() {
    const { t, selectedPropertyCurrency } = this.props;

    const { data } = upcomingSampleBooking.airbnb_reservation_data;

    return (
      <table className="PriceBreakdown__breakdown">
        <tbody>
          <tr>
            <td data-testid="accommodation-title">
              {`${selectedPropertyCurrency}${t("sample_data.nights", {
                nights_breakdown: data.rate_localised_title
              })}`}
            </td>
            <td data-testid="accommodation-fee">{`${selectedPropertyCurrency}${data.rate_amount}`}</td>
          </tr>

          <tr>
            <td>{t("booking.breakdown.cleaning")}</td>
            <td data-testid="cleaning-fee">
              + {`${selectedPropertyCurrency}${data.cleaning_amount}`}
            </td>
          </tr>

          <tr className="PriceBreakdown__breakdown__fee">
            <td>{t("booking.breakdown.fee")}</td>
            <td data-testid="airbnb-fee">
              - {`${selectedPropertyCurrency}${data.service_amount}`}
            </td>
          </tr>

          <tr className="PriceBreakdown__breakdown__total">
            <td>
              {t("booking.breakdown.total")}

              <Tip position="bottom-end" html={this.renderTooltip()}>
                <InfoIcon />
              </Tip>
            </td>
            <td data-testid="total">{`${selectedPropertyCurrency}${data.host_payout_total}`}</td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default compose(
  withTranslation("host.bookings"),
  connect(state => ({
    selectedPropertyCurrency: getSelectedPropertyCurrencySymbol(state)
  }))
)(SampleBookingBreakdown);
