import React, { Component } from "react";

import UpdateEmail from "./UpdateEmail";
import UpdatePassword from "./UpdatePassword";

export default class UpdateAccount extends Component {
  state = {
    activeSection: null
  };

  setActiveSection = activeSection => {
    this.setState({
      activeSection: !this.isActive(activeSection) ? activeSection : null
    });
  };

  isActive = sectionName => this.state.activeSection === sectionName;

  render() {
    const { loginEmail } = this.props;

    return (
      <React.Fragment>
        <UpdateEmail
          email={loginEmail}
          toggleEdit={() => this.setActiveSection("updateEmail")}
          isActive={this.isActive("updateEmail")}
        />

        <UpdatePassword
          email={loginEmail}
          toggleEdit={() => this.setActiveSection("updatePassword")}
          isActive={this.isActive("updatePassword")}
        />
      </React.Fragment>
    );
  }
}
