import { APP_UPDATE_AVAILABLE } from "../action_types";

export const getUpdateAvailable = state => {
  return state.app.updateAvailable;
};

const app = (state = { updateAvailable: false }, action) => {
  switch (action.type) {
    case APP_UPDATE_AVAILABLE:
      return {
        ...state,
        updateAvailable: action.updateAvailable
      };

    default:
      return state;
  }
};

export default app;
