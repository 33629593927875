import Button from "components/buttons/Button";
import Modal from "components/common/modal";
import { logout } from "data/actions/user";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import api from "services/api";

class UpdatePassword extends Component {
  state = {
    password: null,
    newPassword: null,
    newPasswordRetype: null,
    success: false,
    error: null,
    showModal: false
  };

  onChange = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  requestPasswordReset = payload => {
    const { t } = this.props;

    api
      .put("/v1/hosts/user/me/password", null, payload)
      .then(() => {
        this.setState({
          password: null,
          newPassword: null,
          newPasswordRetype: null,
          success: true,
          showModal: true
        });
      })
      .catch(() => {
        this.setState({
          success: false,
          error: t("update_password.error_message"),
          showModal: true
        });
      });
  };

  checkPassword = (password, passwordRetype) => {
    const { t } = this.props;

    if (password !== passwordRetype) {
      return t("update_password.error_no_match");
    } else if (password.length < 5) {
      return t("update_password.error_too_short");
    }
  };

  submit = e => {
    e.preventDefault();

    const { password, newPassword, newPasswordRetype } = this.state;
    const payload = {
      email: this.props.email,
      password: newPassword,
      current_password: password
    };
    const error = this.checkPassword(newPassword, newPasswordRetype);

    if (error) {
      this.setState({
        error: error,
        showModal: true
      });
    } else {
      this.requestPasswordReset(payload);
    }
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  render() {
    const { t, isActive, toggleEdit } = this.props;
    const {
      password,
      newPassword,
      newPasswordRetype,
      success,
      error,
      showModal
    } = this.state;
    const canUpdatePassword = password && newPassword && newPasswordRetype;

    return (
      <div className="UpdatePassword">
        {showModal && (
          <Modal
            title={
              success
                ? t("update_password.title.success")
                : t("update_password.title.error")
            }
            closeModal={() => this.toggleModal()}
            footer={
              <div>
                <Button
                  outline
                  onClick={() =>
                    success ? this.props.logout() : this.toggleModal()
                  }
                >
                  {t("common:ok")}
                </Button>
              </div>
            }
          >
            {success ? <p>{t("update_password.success")}</p> : <p>{error}</p>}
          </Modal>
        )}

        <div className="UpdatePassword">
          <div className="Account__item__container">
            <div className="Account__item__text">
              <span className="text-info">{t("common:password")}:</span>{" "}
              &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
            </div>

            {!isActive && (
              <Button type="button" onClick={toggleEdit}>
                {t("update_password.change_password")}
              </Button>
            )}
          </div>

          {isActive && (
            <form onSubmit={this.submit} className="UpdateLoginCredentials">
              <label htmlFor="oldPassword">
                {t("update_password.form.old_password_label")}
              </label>
              <input
                id="oldPassword"
                onChange={e => this.onChange("password", e.target.value)}
                type="password"
                value={password || ""}
                placeholder={t("update_password.form.old_password_placeholder")}
                required
              />

              <label htmlFor="newPassword">
                {t("update_password.form.new_password_label")}
              </label>
              <input
                id="newPassword"
                onChange={e => this.onChange("newPassword", e.target.value)}
                type="password"
                value={newPassword || ""}
                placeholder={t("update_password.form.new_password_placeholder")}
                required
              />

              <label htmlFor="passwordRetype">
                {t("update_password.form.confirm_password_label")}
              </label>
              <input
                id="passwordRetype"
                onChange={e =>
                  this.onChange("newPasswordRetype", e.target.value)
                }
                type="password"
                value={newPasswordRetype || ""}
                placeholder={t(
                  "update_password.form.confirm_password_placeholder"
                )}
                required
              />

              <div className="Account__actions">
                <Button outline onClick={toggleEdit} type="button">
                  {t("common:cancel")}
                </Button>

                <Button disabled={!canUpdatePassword} type="submit">
                  {t("common:confirm")}
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation("host.account"),
  connect(null, dispatch => ({
    logout: () => dispatch(logout())
  }))
)(UpdatePassword);
