import React from "react";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadStripe } from "services/scripts";

export const useStripe = publicStripeKey => {
  const [stripe, setStripe] = useState(null);

  const setupStripe = useCallback(async () => {
    if (!publicStripeKey) {
      return;
    }

    await loadStripe();
    setStripe(window.Stripe(publicStripeKey));
  }, [publicStripeKey]);

  useEffect(() => {
    setupStripe();
  }, [setupStripe]);

  return stripe;
};

export const withStripe = WrappedComponent => {
  const WrapperComponent = props => {
    const stripe = useStripe(props.publicStripeKey);
    return <WrappedComponent stripe={stripe} {...props} />;
  };

  return connect(state => ({
    publicStripeKey: state.billing.stripe_key
  }))(WrapperComponent);
};
