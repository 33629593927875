import "./StepDirectDebit.scss";

import { ReactComponent as ThumbUpIcon } from "assets/icons/thumb_up.svg";
import Button from "components/buttons/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { DirectDebitButton } from "scenes/dashboard/billing/components/DirectDebitButton";
import { useOnboardingContext } from "scenes/signup/SignupContext";
import NavButtonsWrapper from "scenes/signup/steps/NavButtonsWrapper";
import { Accordion } from "scenes/signup/steps/step_direct_debit/Accordion";

import { ShapedImage } from "../../Shapes";

export const StepDirectDebit = () => {
  const { t } = useTranslation("host.signup");
  const { loading, handlePrev, handleNext, data } = useOnboardingContext();

  return (
    <React.Fragment>
      <div className="StepDirectDebit Signup__step">
        {data.billing.direct_debit ? (
          <div className="Signup__thank-you">
            <ThumbUpIcon />

            <h2 className="Signup__header">{t("direct_debit.done_header")}</h2>

            <h4 className="Signup__subheader">
              {t("direct_debit.done.content")}
            </h4>

            <DirectDebitButton directDebit plainLink />
          </div>
        ) : (
          <>
            <h2 className="Signup__header" data-testid="direct-debit-header">
              {t("direct_debit.header")}
            </h2>
            <h4 className="Signup__subheader">{t("direct_debit.subheader")}</h4>

            <div className="StepDirectDebit__button">
              <DirectDebitButton />
            </div>

            <Accordion
              items={[
                {
                  header: t("direct_debit.question_1"),
                  content: (
                    <>
                      <p>{t("direct_debit.answer_1_1")}</p>
                      <p>{t("direct_debit.answer_1_2")}</p>
                    </>
                  )
                },
                {
                  header: t("direct_debit.question_2"),
                  content: (
                    <>
                      <p>{t("direct_debit.answer_2_1")}</p>
                      <p>{t("direct_debit.answer_2_2")}</p>
                    </>
                  )
                },
                {
                  header: t("direct_debit.question_3"),
                  content: (
                    <>
                      <p>{t("direct_debit.answer_3_1")}</p>
                      <ul className="StepDirectDebit__list">
                        <li>{t("direct_debit.answer_3_2")}</li>
                        <li>{t("direct_debit.answer_3_3")}</li>
                        <li>{t("direct_debit.answer_3_4")}</li>
                      </ul>
                      <p>{t("direct_debit.answer_3_5")}</p>
                    </>
                  )
                }
              ]}
            />
          </>
        )}

        <NavButtonsWrapper
          next={
            <Button
              disabled={!data.billing.direct_debit}
              type="button"
              loading={loading}
              onClick={handleNext}
            >
              {t("common:next")}
            </Button>
          }
          prev={
            <Button type="button" theme="plain" onClick={handlePrev}>
              {t("common:previous")}
            </Button>
          }
        />
      </div>
      <ShapedImage imgId="direct" containerClassName="StepDirectDebit" />
    </React.Fragment>
  );
};
