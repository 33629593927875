import "./PerformanceChart.scss";

import Spinner from "components/Spinner";
import { differenceInMonths, differenceInYears, startOfYear } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import TimeRangeSelect from "../../overview/components/TimeRangeSelect.jsx";
import { useGetEarnings } from "../useGetEarnings";
import PerformanceChart from "./PerformanceChart";
import { PerformanceHeader } from "./PerformanceHeader";
import { RangePicker } from "./RangePicker";
import { getPerformanceDateRange } from "./utils";

const optionYTD = "ytd";
const monthsInYTD = differenceInMonths(Date.now(), startOfYear(Date.now()));
const optionCustom = "custom";

const PerformanceChartContainer = ({ property, hasAnyEarnings }) => {
  const { t } = useTranslation("host.performance");
  const [selectedRange, setSelectedRange] = useState({
    months: monthsInYTD,
    isYTD: true
  });
  const [showRangePicker, setShowRangePicker] = useState(false);

  const { getGraphData, data, loading, error } = useGetEarnings(
    property.id,
    hasAnyEarnings,
    property.locality
  );

  useEffect(() => getGraphData(), [property.id, getGraphData]);

  const handleDateRangeChange = useCallback(
    value => {
      if (value === optionCustom) {
        setShowRangePicker(true);
      } else {
        setShowRangePicker(false);
        const months = value === optionYTD ? monthsInYTD : value;
        const isYTD = value === optionYTD;

        const dateRange = getPerformanceDateRange({
          monthsToShow: months,
          isYTD
        });

        setSelectedRange({
          months,
          isYTD
        });

        getGraphData({ dateRange });
      }
    },
    [getGraphData]
  );

  const timeRangeOptions = useMemo(() => {
    let options = [
      { value: optionYTD, label: t("chart_tab_year_to_date") },
      { value: 6, label: t("chart_tab_6_months") },
      { value: 12, label: t("chart_tab_1_year") },
      { value: 24, label: "Two years" },
      { value: optionCustom, label: "Custom dates" }
    ];
    const hasMultipleYearsData =
      differenceInYears(Date.now(), property.sorted_from) > 2;

    hasMultipleYearsData &&
      options.push({
        value: "36",
        label: t("chart_tab_3_years")
      });

    return options;
  }, [property.sorted_from, t]);

  const { locality } = property;

  if (error) {
    return <div className="PerformanceChart__error">{t("chart_error")}</div>;
  }

  return (
    <div className="PerformanceChart">
      <div className="PerformanceChart__header">
        <PerformanceHeader hasAnyEarnings={hasAnyEarnings} />

        <TimeRangeSelect
          options={timeRangeOptions}
          onChange={handleDateRangeChange}
          disabled={!hasAnyEarnings}
          className="Input--small"
        />

        {showRangePicker && (
          <RangePicker
            toggleModal={() => {
              setShowRangePicker(!showRangePicker);
            }}
            getGraphData={getGraphData}
            setSelectedRange={setSelectedRange}
            sortedFrom={property.sorted_from}
          />
        )}
      </div>

      {data && !loading && (
        <PerformanceChart
          data={data}
          locality={locality}
          dateRange={selectedRange}
          sample={!hasAnyEarnings}
        />
      )}

      {loading && (
        <div className="PerformanceChart__loading">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default PerformanceChartContainer;
