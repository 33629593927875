import "./Modal.scss";

import classNames from "classnames";
import React, { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useWindowDimensions } from "utils/useWindowDimensions";

import { DesktopModal } from "./DesktopModal";
import { MobileModal } from "./MobileModal";

const KEY_CODE_ESC = 27;
const MODAL_PORTAL_ID = "modal";

export const ModalContext = React.createContext({
  closeModal: () => {}
});

export const Modal = ({ closeModal, ...props }) => {
  const handleKeyUp = useCallback(
    e => {
      if (e.keyCode !== KEY_CODE_ESC) {
        return;
      }

      e.preventDefault();
      closeModal && closeModal();
    },
    [closeModal]
  );

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp]);

  const { isMediumScreen } = useWindowDimensions();
  const ModalComponent = isMediumScreen ? MobileModal : DesktopModal;

  return (
    <ModalPortal>
      <ModalContext.Provider value={{ closeModal }}>
        <ModalComponent closeModal={closeModal} {...props} />
      </ModalContext.Provider>
    </ModalPortal>
  );
};

const ModalHeader = ({ title, titleClassName }) => {
  const { isLargeScreen } = useWindowDimensions();

  return React.isValidElement(title) ? (
    title
  ) : (
    <div className={classNames("Modal__header", titleClassName)}>
      <h3 className={classNames({ "big-header": isLargeScreen })}>{title}</h3>
    </div>
  );
};

export const ModalContent = ({ title, footer, children, titleClassName }) => (
  <React.Fragment>
    <ModalHeader title={title} titleClassName={titleClassName} />

    <div
      className={classNames("Modal__body", {
        "Modal__body__with-footer": Boolean(footer)
      })}
    >
      {children}
    </div>

    {footer && <div className="Modal__footer">{footer}</div>}
  </React.Fragment>
);

export const ModalAnimated = ({ children }) => (
  <TransitionGroup appear component="section">
    <CSSTransition classNames="Transition__modal" timeout={600}>
      {children}
    </CSSTransition>
  </TransitionGroup>
);

export const ModalPortal = ({ children }) => {
  return createPortal(children, document.getElementById(MODAL_PORTAL_ID));
};

export default Modal;
