import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Card from "../../../../components/common/Card";
import HomeInfoNav from "./HomeInfoNav";
import { Access } from "./property/Access";
import { AdditionalDetails } from "./property/AdditionalDetails";
import {
  ACCESS,
  ADDITIONAL_DETAILS,
  LISTING,
  LOCATION,
  PROPERTY_SECTIONS
} from "./property/constants";
import { Listing } from "./property/Listing";
import { Location } from "./property/Location";

export const Property = () => (
  <Card className="HomeInfo__container">
    <HomeInfoNav sections={PROPERTY_SECTIONS} />

    <Routes>
      <Route path={LOCATION} element={<Location />} />
      <Route path={ACCESS} element={<Access />} />
      <Route path={ADDITIONAL_DETAILS} element={<AdditionalDetails />} />
      <Route path={LISTING} element={<Listing />} />
      <Route index element={<Navigate to={LOCATION} />} />
    </Routes>
  </Card>
);
