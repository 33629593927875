import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import api from "../../../services/api";

export const usePlatformsList = property => {
  const { t } = useTranslation("host");
  const [shortTerm, setShortTerm] = useState([]);
  const [longTerm, setLongTerm] = useState([]);
  const [error, setError] = useState(false);

  const getPlatformsList = useCallback(async () => {
    await api
      .get(`/v1/hosts/properties/${property.id}/booking_channels`)
      .then(res => {
        if (res) {
          setShortTerm(
            res.filter(platform => platform.channel_details.lets === false)
          );
          setLongTerm(
            res.filter(platform => platform.channel_details.lets === true)
          );
        }
      })
      .catch(err => setError(t("common:error.try_again")));
  }, [property, t]);

  useEffect(() => {
    getPlatformsList();
  }, [getPlatformsList]);

  return { shortTerm, longTerm, error };
};
