import Card from "components/common/Card";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button from "../../../../components/buttons/Button";
import { LinkOut } from "../../../../components/common/LinkOut";
import { getSelectedProperty } from "../../../../data/properties/selectors";
import { ContactMethodIcon } from "./ContactMethodIcon";

const AU = "AU";
const NZ = "NZ";

export const WhatsappCard = ({ countryCode }) => {
  const { t } = useTranslation("host.contact");
  const {
    locality: { whatsapp_number: whatsappNumber, partner_id: partnerId }
  } = useSelector(getSelectedProperty);

  const getHours = countryCode => {
    switch (countryCode) {
      case AU:
      case NZ:
        return t("whatsapp.hours.anz");
      default:
        return t("whatsapp.hours");
    }
  };

  if (!whatsappNumber || partnerId) {
    return null;
  }

  return (
    <Card title={<ContactMethodIcon type="whatsapp" />}>
      <div className="Contact__methods__info">
        <h3 className="small-header"> {t("contact.whatsapp")}</h3>

        <p className="reset-margin">{t("whatsapp.card_text")}</p>

        <p className="reset-margin">{getHours(countryCode)}</p>
      </div>

      <Button type="button" small outline>
        <LinkOut to={`https://wa.me/${whatsappNumber}`}>
          {t("send_a_message")}
        </LinkOut>
      </Button>
    </Card>
  );
};
