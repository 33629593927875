import Tip from "components/common/Tip";
import { Field } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { useOnboardingContext } from "../SignupContext";

export const FieldWithTermsLock = ({ ...fieldProps }) => {
  const { t } = useTranslation("host.signup");
  const { data = {} } = useOnboardingContext();

  const testID = `field-with-lock-${fieldProps.name}`;

  if (data.property?.terms_accepted) {
    return (
      <Tip position="top-start" html={t("common.locked_input_tip")}>
        <Field data-testid={testID} disabled {...fieldProps} />
      </Tip>
    );
  }

  return <Field data-testid={testID} {...fieldProps} />;
};
