import Card from "components/common/Card";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getSelectedProperty } from "../../../../data/properties/selectors";
import { ContactMethodIcon } from "./ContactMethodIcon";

const AU = "AU";
const NZ = "NZ";

export const CallCard = ({ countryCode }) => {
  const { t } = useTranslation("host.contact");
  const { cs_number: csNumber } = useSelector(getSelectedProperty);

  const callInfo = useMemo(() => {
    switch (countryCode) {
      case AU:
      case NZ:
        return t("call.info.anz");
      default:
        return t("call.info");
    }
  }, [countryCode, t]);

  if (!csNumber) {
    return null;
  }

  return (
    <Card title={<ContactMethodIcon type="call" />}>
      <div className="Contact__methods__info">
        <h3 className="small-header"> {t("contact.call")}</h3>

        <p className="reset-margin">{callInfo}</p>
        <p className="text-info__smaller">{t("call.info.additional")}</p>
      </div>

      <a href={`tel:${csNumber}`}>{csNumber}</a>
    </Card>
  );
};
