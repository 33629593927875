import { createAction } from "redux-actions";

import {
  RECEIVE_BOOKING,
  RECEIVE_PREVIOUS_BOOKINGS,
  RECEIVE_UPCOMING_BOOKINGS
} from "../action_types";

export const RECEIVE_BOOKING_CANCELLATION_PENALTY =
  "RECEIVE_BOOKING_CANCELLATION_PENALTY";

export const UPCOMING_BOOKINGS_REQUEST = "UPCOMING_BOOKINGS_REQUEST";

export const upcomingBookingsRequest = createAction(UPCOMING_BOOKINGS_REQUEST);

export const receiveBooking = booking => ({
  type: RECEIVE_BOOKING,
  booking
});

export const receiveUpcomingBookings = (bookings = [], propertyId) => ({
  type: RECEIVE_UPCOMING_BOOKINGS,
  bookings,
  propertyId
});

export const receivePreviousBookings = (bookings = [], propertyId) => ({
  type: RECEIVE_PREVIOUS_BOOKINGS,
  bookings,
  propertyId
});

export const receiveBookingCancellationPenalty = penalty => ({
  type: RECEIVE_BOOKING_CANCELLATION_PENALTY,
  penalty
});
