import "./Overlay.scss";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import classNames from "classnames";
import React from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

const MODAL_PORTAL_ID = "modal";

export const Overlay = ({ close, nondisposable, children, className }) => {
  const { t } = useTranslation("host.common");

  return createPortal(
    <div className={classNames("Overlay", className)} data-cy="overlay-full">
      {!nondisposable && (
        <div className="Overlay__header">
          <button onClick={close} data-cy="close-full-overlay" type="button">
            {t("common:close")} <CloseIcon />
          </button>
        </div>
      )}

      <div className="Overlay__content">{children}</div>
    </div>,
    document.getElementById(MODAL_PORTAL_ID)
  );
};

export default Overlay;
