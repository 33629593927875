import "./Fetcher.scss";

import * as FetchActions from "data/actions/fetcher";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import Spinner from "./Spinner";

export const FetcherPropType = PropTypes.shape({
  // The url to fetch
  endpoint: PropTypes.string.isRequired,
  // Query params
  params: PropTypes.object,
  // Number of times to retry on a failure
  retryCount: PropTypes.number,
  // The function to run when the fetcher has done it's job
  onResponse: PropTypes.func
});

const multiRender = (ren, ...args) => {
  let comp = ren;

  if (typeof ren === "string") {
    comp = <span>{ren}</span>;
  } else if (typeof ren === "function") {
    comp = ren(...args);
  }

  return Array.isArray(comp) ? <span>{comp}</span> : comp;
};

const renderType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.element,
  PropTypes.string
]);

export class FetcherComponent extends Component {
  static className = "Fetcher";

  static propTypes = {
    fetcher: FetcherPropType.isRequired,

    // Fetcher props
    fetching: PropTypes.bool,
    fetched: PropTypes.number,
    failed: PropTypes.number,
    missing: PropTypes.number,

    // Child render types
    render: PropTypes.func,
    component: PropTypes.func,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),

    // Render types
    renderMissing: renderType,
    renderFailed: renderType,
    renderFetching: renderType,

    // Cover will show sensible defaults (a spinner and failure message)
    cover: PropTypes.bool,
    spin: PropTypes.bool,
    dispatch: PropTypes.func.isRequired
  };

  static defaultProps = {
    renderMissing: () => null,
    renderFailed: null,
    renderFetching: null,
    fetching: false,
    cover: false,
    spin: false,
    fetched: 0,
    failed: 0,
    missing: 0,
    render: null,
    children: null,
    component: null
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(oldProps) {
    if (this.props.fetcher.key !== oldProps.fetcher.key) {
      this.fetch();
    }
  }

  fetch = (shouldClear = false) => {
    if (this.props.fetcher.method !== "get") {
      return;
    }

    if (shouldClear) this.clear();

    this.props.dispatch(FetchActions.fetch(this.props.fetcher));
  };

  clear() {
    const { endpoint, params } = this.props.fetcher;
    this.props.dispatch(FetchActions.fetcherClear({ endpoint, params }));
  }

  renderFetching(forceRetry) {
    const { renderFetching, cover, spin } = this.props;
    if (renderFetching) return multiRender(renderFetching, forceRetry);
    return spin ? <Spinner spin cover={cover} /> : null;
  }

  renderMissing(forceRetry) {
    const { renderMissing } = this.props;
    if (renderMissing) return multiRender(renderMissing, forceRetry);
    return null;
  }

  renderFailed(forceRetry) {
    const { renderFailed } = this.props;
    if (renderFailed) return multiRender(renderFailed, forceRetry);
    return this.props.cover ? (
      <span className="Fetcher-failed">
        <p>Something went wrong...</p>
      </span>
    ) : null;
  }

  render() {
    const {
      fetched,
      fetching,
      missing,
      failed,
      children,
      component,
      render,
      renderMissing
    } = this.props;

    const forceRetry = () => this.fetch(true);

    // If fetched then use the `render` or `component` option
    if (fetched) {
      if (component) {
        return React.createElement(component, this.props);
      }

      return multiRender(children || render, this.props);
    }

    // Otherwise render fetching
    if (fetching) return this.renderFetching(forceRetry);

    // Or missing
    if (missing && renderMissing) return this.renderMissing(forceRetry);

    // Or finally failed
    if (failed) return this.renderFailed(forceRetry);

    return null;
  }
}

export const fetcherState = (state, { fetcher }) => {
  const f = typeof fetcher === "function" ? fetcher(state) : fetcher;

  return {
    fetcher: f,
    ...state.fetcher[fetcher.key]
  };
};

const Fetcher = connect(fetcherState)(FetcherComponent);

export default Fetcher;

export const withFetchers = list => Wrapped => {
  class MultiFetcher extends Component {
    // eslint-disable-line react/no-multi-comp
    static propTypes = {
      fetchers: PropTypes.array.isRequired,
      dispatch: PropTypes.func.isRequired
    };

    render() {
      const { fetchers, dispatch, ...props } = this.props;

      if (fetchers.every(f => f.fetched)) {
        return <Wrapped {...props} />;
      }

      return (
        <span>
          <Spinner spin cover />
          {fetchers.map(f => (
            <FetcherComponent
              {...f}
              dispatch={dispatch}
              key={`fetcher_${f.fetcher.key}`}
            />
          ))}
        </span>
      );
    }
  }

  return connect((state, props) => ({
    fetchers: (typeof list === "function" ? list(props) : list).map(f =>
      fetcherState(state, { fetcher: f })
    )
  }))(MultiFetcher);
};

/*
// Example usage

class Example extends Component {
  render() {
    const f = {};

    const FetcherList = p => <Fetcher fetcher={f.list} {...p} />;

    return (
      <FetcherList>
        {({ data, fetching, failed }) => (
          <div>
            <h1>{data ? 'My data' : 'Loading...'}</h1>
            <FetcherList
              renderFailed={retry => (
                <div>
                  <p>There was a problem fetching data!</p>
                  <button onClick={retry}>Retry</button>
                </div>
              )}
            />

            <FetcherList
              renderFetching={<Spinner spin cover />}
              render={data => (
                <ul>
                  {data.map(d => (
                    <li>{d}</li>
                  ))}
                </ul>
              )}
            />

            <FetcherList component={MyComponent} />
          </div>
        )}
      </FetcherList>
    );
  }
}
*/
