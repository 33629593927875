import { combineReducers } from "redux";
import { normalize } from "utils/fn";

import { parseDate } from "../../utils/dateParser";
import { localStorage, sessionStorage } from "../../utils/storage";
import {
  RECEIVE_PROPERTIES,
  RESUME_PROPERTY_SETUP,
  SELECT_PROPERTY,
  SKIP_PROPERTY_SETUP
} from "../action_types";

export default combineReducers({
  byId: (state = {}, action) => {
    if (action.type === RECEIVE_PROPERTIES) {
      const { properties } = action;

      const newPropertiesList = properties.map(property => ({
        ...property,
        offboarded_from: property.offboarded_from
          ? parseDate(property.offboarded_from)
          : null,
        sorted_from: property.sorted_from
          ? parseDate(property.sorted_from)
          : null
      }));
      return { ...state, ...normalize(newPropertiesList) };
    }

    return state;
  },

  skipSetup: (
    state = Boolean(sessionStorage.getItem("skip_setup")),
    action
  ) => {
    switch (action.type) {
      case SKIP_PROPERTY_SETUP:
        sessionStorage.setItem("skip_setup", true);
        return true;

      case RESUME_PROPERTY_SETUP:
        sessionStorage.removeItem("skip_setup");
        return false;

      default:
        return state;
    }
  },

  selectedId: (
    state = parseInt(localStorage.getItem("selected_property_id"), 10),
    action
  ) => {
    switch (action.type) {
      case SELECT_PROPERTY:
      case RESUME_PROPERTY_SETUP: {
        const { propertyId } = action;
        localStorage.setItem("selected_property_id", propertyId);

        return propertyId;
      }

      default:
        return state;
    }
  }
});
