import { map, uniq } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useFlash } from "../../../components/common/Flash";
import { getSelectedPropertyId } from "../../../data/properties/selectors";
import api from "../../../services/api";

export const NULL_CATEGORY_NAME = "other";
export const NULL_CATEGORY_KEY = "none";

export const useAmenities = () => {
  const [amenities, setAmenities] = useState(null);
  const [types, setTypes] = useState(null);
  const [editError, setEditError] = useState(null);
  const [getError, setGetError] = useState(null);
  const flash = useFlash();

  const selectedPropertyId = useSelector(getSelectedPropertyId);
  const { t } = useTranslation("host.home_info");

  const getAmenities = useCallback(() => {
    setGetError(false);

    api
      .get(`/v1/hosts/home_info/${selectedPropertyId}/amenities`)
      .then(res => {
        setAmenities(res);
      })
      .catch(() => {
        flash.error(t("common:error.try_again"));
        setGetError(t("common:problem_fetching_data"));
      });
  }, [flash, selectedPropertyId, t]);

  const getTypes = useCallback(() => {
    setGetError(false);

    api
      .get(`/v1/hosts/home_info/amenity_types`)
      .then(res => {
        setTypes(res);
      })
      .catch(err => {
        setGetError(t("common:problem_fetching_data"));
        flash.error(t("common:error.try_again"));
      });
  }, [flash, t]);

  const allCategories = useMemo(() => {
    if (!types) {
      return [];
    }

    return uniq(map(types, "category"));
  }, [types]);

  const postAmenity = useCallback(
    newAmenity => {
      setEditError(null);
      const amenityType = types.find(type => type.key === newAmenity.key);

      api
        .post(`/v1/hosts/home_info/${selectedPropertyId}/amenities`, null, {
          home_info_amenity_type_id: amenityType.id,
          ...newAmenity
        })
        .then(() => {
          flash.notice(t("common:changes_saved"));
          getAmenities();
        })
        .catch(err => {
          if (err.error.code === 400) {
            setEditError(t("validation.please_fill_in_all_fields"));
          } else {
            flash.error(t("common:error.try_again"));
            setEditError(t("common:error.try_again"));
          }
        });
    },
    [types, selectedPropertyId, flash, t, getAmenities]
  );

  const patchAmenity = useCallback(
    (newAmenityData, amenityId) => {
      setEditError(null);

      api
        .patch(
          `/v1/hosts/home_info/${selectedPropertyId}/amenities/${amenityId}`,
          null,

          newAmenityData
        )
        .then(() => {
          flash.notice(t("common:changes_saved"));
          getAmenities();
        })
        .catch(err => {
          if (err.error.code === 400) {
            console.log(err);
            setEditError(t("validation.please_fill_in_all_fields"));
          } else {
            setEditError(t("common:error.try_again"));
          }
        });
    },
    [selectedPropertyId, flash, t, getAmenities]
  );

  const deleteAmenity = useCallback(
    amenityId => {
      api
        .delete(
          `/v1/hosts/home_info/${selectedPropertyId}/amenities/${amenityId}`
        )
        .then(() => {
          flash.notice(t("amenities.flash.deleted"));
          getAmenities();
        })
        .catch(() => {
          setEditError(t("common:error.try_again"));
        });
    },
    [selectedPropertyId, flash, t, getAmenities]
  );

  const handleAmenityEdit = amenityValues => {
    if (!amenityValues.key || !amenityValues.external_notes) {
      setEditError(t("validation.please_fill_in_all_fields"));
      return;
    }

    if (amenityValues.amenity_id) {
      patchAmenity(amenityValues, amenityValues.amenity_id);
    } else {
      postAmenity(amenityValues);
    }
  };

  const typesForCategory = useCallback(
    param => {
      if (!types) {
        return [];
      }

      const key = param === NULL_CATEGORY_NAME ? null : param;
      return types.filter(type => type.category === key);
    },
    [types]
  );

  const amenitiesForCategory = useCallback(
    param => {
      if (!amenities) {
        return [];
      }

      const key = param === NULL_CATEGORY_NAME ? NULL_CATEGORY_KEY : param;
      return amenities[key] || [];
    },
    [amenities]
  );

  useEffect(() => {
    getAmenities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (types === null) {
      getTypes();
    }
  }, [types, getTypes]);

  return {
    allCategories,
    amenities,
    amenitiesForCategory,
    deleteAmenity,
    editError,
    getAmenities,
    getError,
    handleAmenityEdit,
    patchAmenity,
    postAmenity,
    types,
    typesForCategory
  };
};
