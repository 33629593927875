import "./TopCharts.scss";

import Fetcher from "components/Fetcher";
import * as Fetchers from "data/actions/fetchers";
import { format } from "date-fns";
import React, { Component } from "react";

import CircularProgress from "../../../../components/CircularProgress";
import {
  getEndOfPreviousMonth,
  getTimeRangeStartDate
} from "../../../../utils/dates";
import { CHART_RANGE_OPTIONS, getStatsSelectOptions } from "../utils";
import TimeRangeSelect from "./TimeRangeSelect";

const OCCUPANCY_MULTIPLIER = 100;
const DEFAULT_TIME_RANGE = 1;

class PropertyOccupancy extends Component {
  state = {
    fromDate: getTimeRangeStartDate(DEFAULT_TIME_RANGE)
  };

  handleDateRangeChange = value => {
    const dateRange = CHART_RANGE_OPTIONS[value];

    this.setState({
      fromDate: getTimeRangeStartDate(dateRange.months)
    });
  };

  render() {
    const { selectedPropertyId, occupancy, t } = this.props;

    const { fromDate } = this.state;
    const endOfLastMonth = getEndOfPreviousMonth();

    const occupancyFetcher = Fetchers.occupancy(selectedPropertyId, {
      from_date: format(fromDate, "yyyy-MM-dd"),
      to_date: format(endOfLastMonth, "yyyy-MM-dd")
    });

    return (
      <div className="OverviewItem TopCharts__box TopCharts__occupancy">
        {!occupancy && occupancy !== 0 && (
          <p className="TopCharts__error">{t("top_chart.error")}</p>
        )}

        <TimeRangeSelect
          onChange={this.handleDateRangeChange}
          options={getStatsSelectOptions(t)}
        />

        <Fetcher fetcher={occupancyFetcher} spin>
          <CircularProgress
            value={Math.round(occupancy * OCCUPANCY_MULTIPLIER)}
          />
        </Fetcher>
        <p>{t("occupancy_tile.title")}</p>
      </div>
    );
  }
}

export default PropertyOccupancy;
