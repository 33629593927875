import { Field, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { useHomeInfoContext } from "../../HomeInfoContext";
import { HomeInfoButtons } from "../HomeInfoButtons";
import { HomeInfoForm } from "../HomeInfoForm.jsx";
import { LOCATION } from "./constants";

const FLOORS = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

export const Location = () => {
  const { t } = useTranslation("host.home_info");

  const { data, handleSubmitAndNavigateNext } = useHomeInfoContext();

  return (
    <div className="HomeInfo__content">
      <Formik
        initialValues={data}
        onSubmit={handleSubmitAndNavigateNext(LOCATION)}
      >
        <HomeInfoForm>
          <label className="HomeInfoForm__element">
            {t("label.property_type")}
            <div className="select-wrapper">
              <Field type="text" name="unit_type" component="select">
                <option disabled={true} value="">
                  {t("common:please_select")}
                </option>
                <option value="home">{t("property_type.home")}</option>
                <option value="flat">{t("property_type.flat")}</option>
                <option value="house">{t("property_type.house")}</option>
                <option value="studio_apartment">
                  {t("property_type.studio_apartment")}
                </option>
                <option value="terraced_house">
                  {t("property_type.terraced_house")}
                </option>
                <option value="apartment">
                  {t("property_type.apartment")}
                </option>
                <option value="hotel">{t("property_type.hotel")}</option>
                <option value="other">{t("property_type.other")}</option>
              </Field>
            </div>
          </label>
          <label className="HomeInfoForm__element">
            {t("label.unit_floor")}
            <div className="select-wrapper">
              <Field type="text" name="unit_floor" component="select">
                <option disabled={true} value="">
                  {t("common:please_select")}
                </option>
                <option value="-1">{t("floor.lower_ground")}</option>
                <option value="0">{t("floor.ground")}</option>
                <option value="1">{t("floor.1st")}</option>
                <option value="2">{t("floor.2nd")}</option>
                <option value="3">{t("floor.3rd")}</option>
                {FLOORS.map(floor => (
                  <option value={floor} key={floor}>
                    {t(`floor.${floor}th`)}
                  </option>
                ))}
              </Field>
            </div>
          </label>
          <label className="HomeInfoForm__element">
            {t("label.property_location_instructions")}
            <Field
              component="textarea"
              rows={4}
              name="location_property_location_notes"
            />
          </label>
          <label className="HomeInfoForm__element">
            {t("label.nearest_station")}
            <Field
              component="textarea"
              rows={4}
              name="location_nearest_station"
            />
          </label>
          <label className="HomeInfoForm__element">
            {t("label.nearest_supermarket")}
            <Field
              component="textarea"
              rows={4}
              name="location_nearest_supermarket"
            />
          </label>
          <label className="HomeInfoForm__element">
            {t("label.favourite_spots")}
            <Field
              component="textarea"
              rows={4}
              name="location_favourite_spots"
            />
          </label>
          <label className="HomeInfoForm__element">
            {t("label.noise_level")}
            <div className="select-wrapper">
              <Field type="text" name="unit_noise_level" component="select">
                <option disabled={true} value="">
                  {t("common:please_select")}
                </option>
                <option value="quiet">{t("noise.quiet")}</option>
                <option value="medium">{t("noise.medium")}</option>
                <option value="loud">{t("noise.loud")}</option>
              </Field>
            </div>
          </label>
          <label className="HomeInfoForm__element">
            {t("label.noise_level_notes")}
            <Field
              component="textarea"
              rows={4}
              name="unit_noise_level_notes"
            />
          </label>
          <label className="HomeInfoForm__element">
            {t("label.other_instructions")}
            <Field
              component="textarea"
              rows={4}
              name="host_extra_instructions"
            />
          </label>

          <HomeInfoButtons sectionId={LOCATION} />
        </HomeInfoForm>
      </Formik>
    </div>
  );
};
