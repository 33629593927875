const CURRENCY_WITH_ENABLED_MODULR = ["GBP", "EUR"];

export const showBankAccountForPayouts = (
  currency,
  propertiesLocalityHasMultiplatOption,
  property
) => {
  if (CURRENCY_WITH_ENABLED_MODULR.includes(currency)) {
    return true;
  }

  if (!propertiesLocalityHasMultiplatOption) {
    return false;
  }

  // Show if property has multiplat enabled or it has been in past
  if (property.multiplat_active || property.multiplat_changed_at !== null) {
    return true;
  }

  return false;
};
