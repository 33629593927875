import "./ProfileMenu.scss";

import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";
import { getProfile } from "data/reducers/user";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { logout } from "../../data/actions/user";
import { LogoutButton } from "../buttons/LogoutButton.jsx";
import Dropdown from "../common/Dropdown";

export const ProfileMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    dispatch(logout());
    navigate("/login");
  }, [dispatch, navigate]);

  const { first_name, last_name } = profile;

  return (
    <Dropdown
      buttonCy="profile-menu"
      buttonContent={
        <>
          <ProfileIcon className="ProfileButton__icon" />
          <span className="ProfileButton__name">
            {first_name} {last_name}
          </span>
        </>
      }
    >
      <div className="ProfileMenu">
        <Link to="/account">
          <ProfileIcon />

          {t("nav.profile_menu.my-account")}
        </Link>

        <LogoutButton
          label={t("nav.profile_menu.logout")}
          onClick={handleLogout}
        />
      </div>
    </Dropdown>
  );
};
