import "./Dropdown.scss";

import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as ChevronIcon } from "../../assets/icons/down-triangle.svg";
import { useClickOutside } from "../../utils/useClickOutside";

const Dropdown = ({
  buttonContent,
  buttonCy,
  children,
  dropdownCy,
  hasContent = true
}) => {
  const dropdownRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useClickOutside(dropdownRef, () => setIsOpen(false));

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleEscape = event => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [children]);

  return (
    <div className="Dropdown" ref={dropdownRef}>
      <button
        type="button"
        className="Dropdown__button"
        onClick={handleClick}
        data-cy={buttonCy}
      >
        {buttonContent}
        {hasContent && <ChevronIcon />}
      </button>
      {hasContent && (
        <div
          className={classNames("Dropdown__content", {
            "Dropdown__content--open": isOpen
          })}
          data-cy={dropdownCy}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
