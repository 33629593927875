import { RECEIVE_CALENDAR, SET_SELECTED_BOOKING } from "../action_types";

export const receiveCalendar = (calendarDays, propertyId) => ({
  type: RECEIVE_CALENDAR,
  propertyId,
  calendarDays
});

export const setSelectedBooking = booking => ({
  type: SET_SELECTED_BOOKING,
  booking
});
