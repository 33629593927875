import "./Button.scss";

import PropTypes from "prop-types";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import bem from "utils/bem";

import Spinner from "../Spinner";

class Button extends Component {
  static propTypes = {
    theme: PropTypes.oneOf(["primary", "plain", "warning", "danger", "link"]),

    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    outline: PropTypes.bool,
    small: PropTypes.bool,
    to: PropTypes.string
  };

  static defaultProps = {
    theme: "primary",
    disabled: false,
    loading: false,
    outline: false,
    small: false,
    to: null
  };

  render() {
    const {
      theme,
      disabled,
      loading,
      small,
      outline,
      to,
      disabledLike,
      icon,
      type,
      ...props
    } = this.props;

    const Comp = to ? NavLink : "button";

    return (
      <Comp
        disabled={disabled || loading}
        className={bem("Button", {
          [theme]: theme,
          loading,
          small,
          outline,
          disabledLike,
          icon
        })}
        to={to}
        type={type || "button"}
        {...props}
      >
        <span>{this.props.children}</span>
        {loading && <Spinner />}
      </Comp>
    );
  }
}

export default Button;
