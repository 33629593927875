import { ADDITIONAL, BOILER, WIFI } from "./amenities/constants";
import { ITEMS_LOCATION, MAINTENANCE } from "./cleaning/constants";
import {
  ACCESS,
  ADDITIONAL_DETAILS,
  LISTING,
  LOCATION
} from "./property/constants";

export const SECTIONS = {
  [LOCATION]: {
    nextSection: `property/${ACCESS}`
  },
  [ACCESS]: {
    prevSection: `property/${LOCATION}`,
    nextSection: `amenities/${ADDITIONAL_DETAILS}`
  },
  [ADDITIONAL_DETAILS]: {
    prevSection: `property/${ACCESS}`,
    nextSection: `property/${LISTING}`
  },
  [LISTING]: {
    prevSection: `property/${ADDITIONAL_DETAILS}`,
    nextSection: `amenities/${WIFI}`
  },
  [WIFI]: {
    prevSection: `property/${LISTING}`,
    nextSection: `amenities/${BOILER}`
  },
  [BOILER]: {
    prevSection: `amenities/${WIFI}`,
    nextSection: `amenities/${ADDITIONAL}`
  },
  [ADDITIONAL]: {
    prevSection: `amenities/${BOILER}`,
    nextSection: `cleaning/${ITEMS_LOCATION}`
  },
  [ITEMS_LOCATION]: {
    prevSection: `amenities/${ADDITIONAL}`,
    nextSection: `cleaning/${MAINTENANCE}`
  },
  [MAINTENANCE]: {
    prevSection: `cleaning/${ITEMS_LOCATION}`,
    nextSection: `beds`
  }
};
