import Button from "components/buttons/Button";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { shortPrettyDate } from "utils/dates";
import { optionsArray } from "utils/preferences";

import {
  AIRSORTED_RESOLVE_ACTION,
  HOST_RESOLVE_ACTION,
  IGNORE_ACTION,
  MAINTENANCE_STATUS_ACCEPTED,
  MAINTENANCE_STATUS_HOST_PLANNED,
  MAINTENANCE_STATUS_IGNORED,
  MAINTENANCE_STATUS_PLANNED,
  MAINTENANCE_STATUS_RESOLVED,
  REQUEST_INFORMATION_ACTION
} from "../../../../../data/maintenance/types";

export const Action = ({
  status,
  handleResolutionTypeSubmit,
  updateMaintenanceItem,
  resolutionDate
}) => {
  const { t } = useTranslation("host.maintenance");

  if (status === MAINTENANCE_STATUS_ACCEPTED) {
    return (
      <div className="MaintenanceItem__actions">
        <Formik
          initialValues={{ host_response: "" }}
          onSubmit={handleResolutionTypeSubmit}
        >
          {({ handleReset, handleSubmit, values }) => (
            <Form>
              <div className="select-wrapper">
                <Field
                  component="select"
                  name="host_response"
                  value={values.host_response || ""}
                  data-cy="maintenance-host-response-select"
                >
                  <option key="" value="" disabled>
                    {t("option.please_select")}
                  </option>
                  {optionsArray(
                    {
                      [AIRSORTED_RESOLVE_ACTION]: t("option.airsorted_resolve"),
                      [HOST_RESOLVE_ACTION]: t("option.host_resolve"),
                      [IGNORE_ACTION]: t("option.ignore"),
                      [REQUEST_INFORMATION_ACTION]: t(
                        "option.request_information"
                      )
                    },
                    { includeEmptyOption: false }
                  )}
                </Field>
              </div>
              {values.host_response && (
                <React.Fragment>
                  <Button
                    type="submit"
                    data-cy="maintenance-host-response-submit"
                  >
                    {t("common:confirm")}
                  </Button>
                  <Button outline type="button" onClick={handleReset}>
                    {t("common:cancel")}
                  </Button>
                </React.Fragment>
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  if (
    [MAINTENANCE_STATUS_HOST_PLANNED, MAINTENANCE_STATUS_PLANNED].includes(
      status
    )
  ) {
    return (
      <div className="MaintenanceItem__actions">
        {status === MAINTENANCE_STATUS_PLANNED && resolutionDate && (
          <p className="MaintenanceItem__actions__date">
            <span className="MaintenanceItem__actions__label">
              {t("label.scheduled")}:
            </span>
            {shortPrettyDate(resolutionDate)}
          </p>
        )}
        {status === MAINTENANCE_STATUS_HOST_PLANNED && (
          <Button
            onClick={() =>
              updateMaintenanceItem({
                status: MAINTENANCE_STATUS_RESOLVED
              })
            }
            type="button"
          >
            {t("mark_as_resolved")}
          </Button>
        )}
      </div>
    );
  }

  if (status === MAINTENANCE_STATUS_RESOLVED && resolutionDate) {
    return (
      <div className="MaintenanceItem__actions">
        <p className="MaintenanceItem__actions__date">
          <span className="MaintenanceItem__actions__label">
            {t("label.resolved")}:
          </span>
          {shortPrettyDate(resolutionDate)}
        </p>
      </div>
    );
  }

  if (status === MAINTENANCE_STATUS_IGNORED) {
    return (
      <div className="MaintenanceItem__actions">
        <p className="MaintenanceItem__actions__date">
          <span className="MaintenanceItem__actions__label">
            {t("label.ignored")}
          </span>
        </p>
      </div>
    );
  }

  return null;
};
