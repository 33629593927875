import { logout } from "data/actions/user";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const Logout = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => !!state.user.authToken);
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(logout());
    }
  }, [dispatch, isLoggedIn]);

  return <Navigate to="/login" />;
};

export default Logout;
