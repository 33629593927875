import { Field, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { useHomeInfoContext } from "../../HomeInfoContext";
import { HomeInfoButtons } from "../HomeInfoButtons";
import { HomeInfoForm } from "../HomeInfoForm.jsx";
import { ITEMS_LOCATION } from "./constants";

export const ItemsLocation = () => {
  const { t } = useTranslation("host.home_info");

  const { data, handleSubmitAndNavigateNext } = useHomeInfoContext();

  return (
    <div className="HomeInfo__content">
      <Formik
        initialValues={data}
        onSubmit={handleSubmitAndNavigateNext(ITEMS_LOCATION)}
      >
        <HomeInfoForm>
          <label className="HomeInfoForm__element">
            {t("label.vacuum_make_and_model")}
            <Field type="text" name="maintenance_vacuum_make_and_model" />
          </label>
          <label className="HomeInfoForm__element">
            {t("label.vacuum_location")}
            <Field
              component="textarea"
              rows={4}
              name="cleaning_vacuum_location"
            />
          </label>
          <label className="HomeInfoForm__element">
            {t("label.cleaning_equipment_location")}
            <Field
              component="textarea"
              rows={4}
              name="cleaning_cleaning_equipment_location"
            />
          </label>
          <label className="HomeInfoForm__element">
            {t("label.mop_and_bucket_location")}
            <Field
              component="textarea"
              rows={4}
              name="cleaning_mop_bucket_location"
            />
          </label>
          <label className="HomeInfoForm__element">
            {t("label.ironing_board_location")}
            <Field
              component="textarea"
              rows={4}
              name="cleaning_ironing_board_location"
            />
          </label>
          <label className="HomeInfoForm__element">
            {t("label.spare_toiletries_location")}
            <Field
              component="textarea"
              rows={4}
              name="cleaning_spare_toiletries"
            />
          </label>
          <label className="HomeInfoForm__element">
            {t("label.spare_bedding_location")}
            <Field
              component="textarea"
              rows={4}
              name="cleaning_spare_bedding_location"
            />
          </label>
          <label className="HomeInfoForm__element">
            {t("label.linen_storage_location")}
            <Field
              component="textarea"
              rows={4}
              name="cleaning_linen_storage_location"
            />
          </label>

          <HomeInfoButtons sectionId={ITEMS_LOCATION} />
        </HomeInfoForm>
      </Formik>
    </div>
  );
};
