import Button from "components/buttons/Button";
import Modal from "components/common/modal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import api from "services/api";

import { DetailsForm } from "./DetailsForm";

export const UpdateEmailDetails = ({ data }) => {
  const { t } = useTranslation("host.account");
  const [emails, setEmails] = useState(data);
  const [success, setSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const toggleEdit = () => {
    setEditMode(!editMode);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleError = () => {
    setSuccess(false);
    setShowModal(true);
  };

  const handleSubmit = data => {
    const requests = data["emails"].map(item => {
      if (item.id) {
        return api.put(`/v1/hosts/emails/${item.id}`, null, item);
      } else {
        return api.post(`/v1/hosts/emails`, null, item);
      }
    });

    Promise.all(requests)
      .then(data => {
        setEmails(data);
        setSuccess(true);
        setShowModal(true);
        setEditMode(false);
      })
      .catch(err => {
        handleError();
      });
  };

  const deleteItem = async (id, index, arrayHelpers) => {
    if (id) {
      try {
        await api.delete(`/v1/hosts/emails/${id}`);
        arrayHelpers.remove(index);
        setEmails(data.filter(item => item.id !== id));
      } catch (e) {
        handleError();
      }
    } else {
      arrayHelpers.remove(index);
    }
  };

  const renderModal = () => {
    const title = success
      ? t("update_email.title.success")
      : t("update_email.title.error");

    const message = success
      ? t("update_email.communications_success")
      : t("update_email.error");

    return (
      <Modal
        title={title}
        closeModal={toggleModal}
        footer={
          <Button
            type="button"
            theme="primary"
            onClick={toggleModal}
            data-cy="email-updated-ok"
          >
            {t("common:ok")}
          </Button>
        }
      >
        <p>{message}</p>
      </Modal>
    );
  };

  return (
    <div>
      {showModal && renderModal()}

      <div>
        {editMode ? (
          <DetailsForm
            data={emails}
            deleteItem={deleteItem}
            toggleEdit={toggleEdit}
            handleSubmit={handleSubmit}
            type="emails"
            dataType="address"
          />
        ) : (
          <div>
            {emails.map(item => (
              <p key={item.id}>{item["address"]}</p>
            ))}

            <div className="Account__actions">
              <Button
                type="button"
                onClick={toggleEdit}
                data-cy="change-emails"
              >
                {t("common:change")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
