import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import LinkOut from "./LinkOut";

export const TermsPartTime = ({ updated, planName }) => {
  const { t } = useTranslation("host.terms");

  if (updated) {
    return (
      <div className="TermsPartTime">
        <Trans ns="host.terms" i18nKey="part_time.updated_terms">
          <p>
            We'd like to let you know about some changes to our Terms &
            Conditions. It's important to understand what they mean for you. You
            can see exactly what's changing&nbsp;
            <LinkOut to="https://pages.houst.com/pricing-faq">here</LinkOut>.
          </p>
          <p>
            To continue to your Host Dashboard, please read and accept the
            updated Terms & Conditions. You can view your full Terms &
            Conditions&nbsp;
            <Link to="/terms" target="_blank">
              here
            </Link>
            .
          </p>
        </Trans>
      </div>
    );
  }

  return (
    <div className="TermsPartTime">
      <Trans ns="host.terms" i18nKey="part_time.new_terms">
        This are our&nbsp;
        <Link to="/terms" target="_blank">
          Terms and Conditions
        </Link>
        . You're currently on {{ planName }} plan. By using our service you are
        agreeing to these terms.{" "}
        <LinkOut to="https://pages.houst.com/pricing-faq">
          Learn more about our management fees
        </LinkOut>
        .
      </Trans>
      <p className="bold">{t("part_time.footer_new")}</p>
    </div>
  );
};
