import "./Account.scss";

import Card from "components/common/Card";
import Header from "components/common/Header";
import Fetcher from "components/Fetcher";
import * as Fetchers from "data/actions/fetchers";
import { getSelectedProperty } from "data/properties/selectors";
import { getProfile } from "data/reducers/user";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import UpdateAccount from "./components/UpdateAccount";
import UpdateCommunication from "./components/UpdateCommunication";
import UpdateContactTimezone from "./components/UpdateContactTimezone";
import { UpdateEmailDetails } from "./components/UpdateEmailDetails";
import { UpdatePhoneDetails } from "./components/UpdatePhoneDetails";

const Account = () => {
  const { t } = useTranslation("host.account");
  const profile = useSelector(getProfile);
  const selectedProperty = useSelector(getSelectedProperty);

  const {
    login_email: loginEmail,
    phones,
    emails,
    contact_timezone: contactTimezone,
    language
  } = profile;

  return (
    <div>
      <div className="Account">
        <Header title={t("title")} />

        <div className="Account__row">
          <Card title="Host Dashboard Login" className="Account__item">
            <UpdateAccount loginEmail={loginEmail} />
          </Card>

          <Card title={t("phone_number_card_title")} className="Account__item">
            <label htmlFor="updatePhoneNumber">
              {t("phone_number_card_label")}
            </label>

            <UpdatePhoneDetails data={phones} />
          </Card>
        </div>

        <div className="Account__row">
          <Card title={t("email_address_card_title")} className="Account__item">
            <label htmlFor="updateEmail">{t("email_address_card_label")}</label>

            <UpdateEmailDetails data={emails} />
          </Card>

          <Card title={t("contact_preferences")} className="Account__item">
            <UpdateContactTimezone
              contactTimezone={contactTimezone}
              language={language}
            />
          </Card>
        </div>
      </div>

      <div className="communication">
        <Fetcher fetcher={Fetchers.preferences(selectedProperty?.id)} spin>
          <UpdateCommunication selectedProperty={selectedProperty} />
        </Fetcher>
      </div>
    </div>
  );
};

export default Account;
