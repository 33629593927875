import "./CleanInstructions.scss";

import Card from "components/common/Card";
import TruncatedTextBox from "components/common/TruncatedTextBox";
import { receivePreferences } from "data/actions/preferences";
import {
  getCleaningTasks,
  getHostCleanInstructions,
  getPropertyId,
  getSelectedProperty
} from "data/properties/selectors";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";

import CleanPreferenceForm from "./CleanPreferenceForm";

class CleanInstructions extends Component {
  render() {
    const { t, selectedPropertyId, hostCleanInstructions, cleaningTasks } =
      this.props;

    const hasCleaningRequests =
      hostCleanInstructions || cleaningTasks.length > 0;

    return (
      <Card
        title={t("host.cleans:clean.instructions.title")}
        className="CleanInstructions"
      >
        {hasCleaningRequests && (
          <React.Fragment>
            <p>{t("settings.restock.cleaning_instructions")}</p>

            {hostCleanInstructions && (
              <TruncatedTextBox
                text={hostCleanInstructions}
                truncationLength={300}
              />
            )}

            {cleaningTasks &&
              cleaningTasks.map(task => (
                <TruncatedTextBox key={task.id} text={task.description} />
              ))}

            <p className="Settings__contact-link">
              <Link to="/contact">{t("common:contact_manager_link")}</Link>{" "}
              {t("common:contact_manager_link_ending")}
            </p>
          </React.Fragment>
        )}

        {!hasCleaningRequests && (
          <React.Fragment>
            <p>
              <i>{t("host.cleans:clean.instructions.no_instructions")}</i>
            </p>
            <p className="Settings__contact-link">
              <Link to="/contact">{t("common:contact_manager_link")}</Link>{" "}
              {t("host.cleans:clean.instructions.no_instructions_link_ending")}
            </p>
          </React.Fragment>
        )}

        <CleanPreferenceForm propertyId={selectedPropertyId} t={t} />
      </Card>
    );
  }
}

export default compose(
  withTranslation("host.preferences"),
  connect(
    state => {
      const selectedProperty = getSelectedProperty(state);

      return {
        selectedPropertyId: getPropertyId(selectedProperty),
        hostCleanInstructions: getHostCleanInstructions(selectedProperty),
        cleaningTasks: getCleaningTasks(selectedProperty)
      };
    },
    { receivePreferences }
  )
)(CleanInstructions);
