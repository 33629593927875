import "./PropertySelectItem.scss";

import classNames from "classnames";
import {
  propertyNeedsOnboarding,
  propertyOffboarded
} from "data/property_states";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";

export const PropertySelectItem = ({ property, selected, onSelect }) => {
  const { t } = useTranslation();

  const offboarded = propertyOffboarded(property);
  const onboarding = propertyNeedsOnboarding(property);
  const active = !offboarded && !onboarding;
  const title = property.full_address || t("nav.property.more_info_needed");

  const handleClick = () => onSelect(property.id, onboarding);

  return (
    <div
      className={classNames("PropertySelectItem", {
        "PropertySelectItem--selected": selected
      })}
      onClick={handleClick}
    >
      <span title={title}>{title}</span>
      <p
        className={classNames("PropertySelectItem__status", {
          "PropertySelectItem__status--offboarded": offboarded,
          "PropertySelectItem__status--onboarding": onboarding,
          "PropertySelectItem__status--active": active
        })}
      >
        {active && t("nav.property.active")}
        {offboarded && t("nav.property.offboarded")}
        {onboarding && t("nav.property.onboarding")}
      </p>
    </div>
  );
};

PropertySelectItem.propTypes = {
  property: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default memo(PropertySelectItem);
