import {
  PATCH_MAINTENANCE_ITEM,
  RECEIVE_MAINTENANCE_LIST
} from "../maintenance/types";

export const receiveMaintenance = ({ maintenance, propertyId }) => ({
  type: RECEIVE_MAINTENANCE_LIST,
  maintenance,
  propertyId
});

export const patchMaintenanceItem = ({ maintenanceItem, propertyId }) => ({
  type: PATCH_MAINTENANCE_ITEM,
  maintenanceItem,
  propertyId
});
