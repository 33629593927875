import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import {
  receiveBankAccountDetails,
  receiveCardDetails,
  receiveDirectDebit,
  receiveStripeApiKey
} from "../../../data/actions/billing";
import api from "../../../services/api";

export const BOOKINGS_LIMIT = 10;

export const useGetBilling = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);

  const getBilling = useCallback(() => {
    api
      .get("/v1/hosts/billing")
      .then(res => {
        dispatch(receiveStripeApiKey(res.stripe_key));
        dispatch(receiveCardDetails(res.card));
        dispatch(receiveDirectDebit(res.direct_debit));
        dispatch(receiveBankAccountDetails(res.bank_account));
      })
      .catch(err => {
        setError(err);
      });
  }, [dispatch]);

  return [error, getBilling];
};
