import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import Tip from "../../../../components/common/Tip";

export const VerificationTooltip = ({ verified, channelCodeName }) => {
  const { t } = useTranslation("host.bookings");

  const tooltipContent = verified
    ? channelCodeName !== "airbnb"
      ? t("verified_tooltip")
      : t("verified_tooltip_by_airbnb")
    : t("verifying_tooltip");
  const tooltipTitle = verified ? t("verified") : t("verifying");

  return (
    <Tip
      html={
        <>
          <h5 className="small-header">{tooltipTitle}</h5>

          <p>{tooltipContent}</p>
        </>
      }
    >
      <p className={classNames("section-label", { approved: verified })}>
        {tooltipTitle}
      </p>
    </Tip>
  );
};
