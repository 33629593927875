import { get } from "lodash";

import i18n from "../../i18n";
import { localStorage } from "../../utils/storage";
import { RECEIVE_PROPERTIES } from "../action_types";
import { getProperties } from "../properties/selectors";

const languageSelectMiddleware = ({ getState }) => {
  return next => {
    // Do nothing when the language is already selected by a user
    const selectedLanguage = localStorage.getItem("language--persist");

    if (selectedLanguage) {
      return next;
    }

    // Language is not selected, determine it from the first property's locality
    return action => {
      const result = next(action);

      if (action.type === RECEIVE_PROPERTIES) {
        const properties = getProperties(getState());
        const language = get(properties, "0.locality.language", "en");

        i18n.changeLanguage(language);
      }

      return result;
    };
  };
};

export default languageSelectMiddleware;
