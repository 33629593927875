import "../../signup/Signup.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import api from "services/api";
import { track } from "services/segment";

import LoginForm from "../../../components/LoginForm";
import { receiveLogin } from "../../../data/actions/user";
import { isAdmin } from "../../../data/reducers/user";

const Login = () => {
  const { t } = useTranslation("host.auth");
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = useSelector(state => !!state.user.authToken);
  const admin = useSelector(isAdmin);

  const submit = (values, { setSubmitting, setErrors }) => {
    const { login_email, password } = values;

    api
      .post("/v1/hosts/user/login", null, {
        type: "email",
        email: login_email,
        password: String(password).trim()
      })
      .then(res => {
        setSubmitting(false);
        dispatch(receiveLogin(res));

        if (!admin) {
          track("USER_LOGIN");
        }
      })
      .catch(e => {
        setSubmitting(false);

        if (e.error.code === 400) {
          setErrors({ loginError: t("common:something_went_wrong") });
        } else {
          setErrors({ loginError: t("login.incorrect_details") });
        }
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="Signup Login">
      <div className="shapes-background"></div>
      <div className="Signup__layout-container">
        <LoginForm
          buttonText="host.auth:login.form.button_label"
          email={location?.state?.email || ""}
          handleSubmit={submit}
        >
          <h4 className="header">{t("login.title")}</h4>
        </LoginForm>
      </div>
    </div>
  );
};

export default Login;
