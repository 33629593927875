import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { receiveProperties } from "../data/actions/properties";
import { receiveUser } from "../data/actions/user";
import api from "../services/api";

export const useUserWithProperties = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const fetchUserWithProperties = useCallback(async () => {
    try {
      setLoading(true);

      const [user, properties] = await Promise.all([
        api.get(`/v1/hosts/user/me`),
        api.get(`/v1/hosts/properties`)
      ]);

      dispatch(receiveUser(user));
      dispatch(receiveProperties(properties));
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchUserWithProperties();
  }, [fetchUserWithProperties, setLoading]);

  return { loading, error };
};
