import "./Comment.scss";

import GuestAirbnbLink from "components/common/GuestAirbnbLink";
import GuestImage from "components/common/GuestImage";
import React, { Component } from "react";

class Comment extends Component {
  render() {
    const { comment } = this.props;

    return (
      <div className="Comment">
        <GuestAirbnbLink guestId={comment.airbnb_guest_id}>
          <div className="Comment__guest">
            <GuestImage imageUrl={comment.guest_img_url} />
          </div>
        </GuestAirbnbLink>

        <div className="Comment__message">
          <p>{comment.comments}</p>
          <p className="text-info">{comment.guest_first_name}</p>
        </div>
      </div>
    );
  }
}

export default Comment;
