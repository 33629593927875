import { ReactComponent as BinIcon } from "assets/icons/bin.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import Button from "components/buttons/Button";
import { Field, FieldArray, Form, Formik } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const PHONE_REGEXP = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const MAX_ENTRIES = 3;

const PRIMARY_LABEL = "primary";
const LABELS = [PRIMARY_LABEL, "secondary", "other"];

export const DetailsForm = ({
  toggleEdit,
  handleSubmit,
  data,
  type,
  deleteItem,
  dataType
}) => {
  const { t } = useTranslation("host.account");

  const validationSchema = useCallback(() => {
    return type === "phones"
      ? yup.object().shape({
          phones: yup.array().of(
            yup.object().shape({
              number: yup
                .string()
                .matches(
                  PHONE_REGEXP,
                  t("update_phone_number.validation.format")
                )
                .required(t("update_phone_number.validation.required"))
            })
          )
        })
      : yup.object().shape({
          emails: yup.array().of(
            yup.object().shape({
              address: yup
                .string()
                .email(t("update_email.validation.format"))
                .required(t("update_email.validation.required"))
            })
          )
        });
  }, [type, t]);

  const renderFieldError = useCallback(
    (errors, fieldIndex, touched) => {
      if (!errors[type] || !touched[type]) {
        return null;
      }

      const error = errors[type].find((e, errorIndex) => {
        // Return the error obj if this field has an error and has been touched
        return errorIndex === fieldIndex && touched[type][fieldIndex];
      });

      if (error) {
        return (
          <p className="Account__input__error">
            <small>{error[dataType]}</small>
          </p>
        );
      }

      return null;
    },
    [type, dataType]
  );

  return (
    <Formik
      initialValues={{ [type]: data }}
      validationSchema={validationSchema()}
      onSubmit={handleSubmit}
      render={({ values, isValid, errors, touched }) => {
        return (
          <Form>
            <div className="form__group">
              <FieldArray
                name={type}
                render={arrayHelpers => (
                  <React.Fragment>
                    {values[type].map((item, index) => (
                      <div
                        className="Phone"
                        key={index}
                        data-cy={`${type}-form`}
                      >
                        <div className="Phone__number">
                          <label>{t(`label.${item.label}`)}</label>

                          <Field
                            name={`${type}.${index}.${dataType}`}
                            data-cy={`${type}-${index}-${dataType}`}
                            placeholder={t(
                              `update_detail.form.new_${type}_placeholder`
                            )}
                            label={item.label}
                          />

                          {renderFieldError(errors, index, touched)}

                          {item?.label !== PRIMARY_LABEL && (
                            <BinIcon
                              className="Phone__action"
                              data-cy={`${type}-${index}-delete`}
                              onClick={() =>
                                deleteItem(item.id, index, arrayHelpers)
                              }
                            />
                          )}
                        </div>

                        <Field
                          name={`${type}.${index}.label`}
                          data-cy={`${type}-${index}-label`}
                          hidden
                          value={values[type][index].label}
                        />
                      </div>
                    ))}

                    {values[type] && values[type].length < MAX_ENTRIES && (
                      <div
                        className="Account__item__container add-new"
                        onClick={() =>
                          arrayHelpers.push({
                            address: "",
                            label: LABELS[values[type].length]
                          })
                        }
                      >
                        <span>{t(`add.${type}`)}</span>
                        <PlusIcon
                          className="Account__plus-icon"
                          data-cy={`add-${type}`}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )}
              />
            </div>

            <div className="Account__actions">
              <Button
                outline
                onClick={toggleEdit}
                type="button"
                data-cy="cancel"
              >
                {t("common:cancel")}
              </Button>

              <Button type="submit" data-cy="submit" disabled={!isValid}>
                {t("common:confirm")}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};
