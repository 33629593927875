import "../../ListForm.scss";

import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import { ReactComponent as BinIcon } from "../../../../../assets/icons/bin.svg";
import Button from "../../../../../components/buttons/Button";
import Alert from "../../../../../components/common/Alert";
import DefaultError from "../../../../../components/common/DefaultError";
import { TextareaWithInstructions } from "../../../../../components/common/TextareaWithInstructions";
import { showSaveButtonAmenities } from "../../formHelpers";
import { useAmenities } from "../../useAmenities";
import { HomeInfoForm } from "../HomeInfoForm";

const INITIAL_DATA = {
  external_notes: "",
  key: ""
};

export const Additional = () => {
  const { t, i18n } = useTranslation("host.home_info");

  const {
    allCategories,
    amenities: allAmenities,
    amenitiesForCategory,
    handleAmenityEdit,
    editError,
    getError,
    typesForCategory,
    deleteAmenity
  } = useAmenities();
  const { category: categoryParam } = useParams();

  if (allAmenities === null) {
    return null;
  }

  if (!categoryParam) {
    return <Navigate to={allCategories[0] || "other"} />;
  }

  const amenities = amenitiesForCategory(categoryParam);
  const types = typesForCategory(categoryParam);

  const handleCancelClick = (
    isNew,
    arrayHelpers,
    setFieldTouched,
    setFieldValue,
    index
  ) => {
    if (isNew) {
      arrayHelpers.remove(index);
    } else {
      setFieldValue(`amenities[${index}]`, amenities[index]);
      setFieldTouched(`amenities[${index}]`, false);
    }
  };

  return (
    <>
      {editError && (
        <Alert fixed danger small>
          {editError}
        </Alert>
      )}

      <div className="ListForm Amenities">
        {getError && <DefaultError>{getError}</DefaultError>}
        {types && (
          <Formik initialValues={{ amenities }} enableReinitialize={true}>
            {({
              values,
              touched,
              setFieldTouched,
              handleChange,
              setFieldValue,
              handleBlur
            }) => (
              <HomeInfoForm>
                <FieldArray
                  name="amenities"
                  render={arrayHelpers => (
                    <div className="HomeInfoForm__element ListFormSingle">
                      {values.amenities.map((amenity, index) => {
                        const translationKey = `host.home_info:${amenity.key}.instructions`;
                        const instructions = i18n.exists(translationKey)
                          ? t(translationKey)
                          : null;

                        return (
                          <div
                            className="ListFormDetails"
                            data-cy="amenity-form"
                            key={`${amenity.amenity_id}_${amenity.key}`}
                          >
                            <label className="HomeInfoForm__element">
                              {t("label.amenity_type")}
                              <div className="select-wrapper">
                                <Field
                                  type="text"
                                  component="select"
                                  name={`amenities.${index}.key`}
                                  disabled={amenity.amenity_id}
                                  data-cy={`amenities-${index}-key`}
                                >
                                  <option value="">
                                    {t("common:please_select")}
                                  </option>
                                  {types.map(type => (
                                    <option value={type.key} key={type.key}>
                                      {t(`label.${type.key}`)}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                              <ErrorMessage
                                name={`amenities[${index}].key`}
                                component={DefaultError}
                              />
                            </label>

                            <TextareaWithInstructions
                              label={t("label.amenity_notes")}
                              name={`amenities.${index}.external_notes`}
                              className="HomeInfoForm__element"
                              instructions={instructions}
                              value={amenity.external_notes}
                              handleChange={e => {
                                handleBlur(e);
                                handleChange(e);
                              }}
                            />
                            <ErrorMessage
                              name={`amenities[${index}].external_notes`}
                              component={DefaultError}
                            />

                            {showSaveButtonAmenities(
                              values.amenities[index],
                              touched.amenities?.[index]
                            ) && (
                              <>
                                <Button
                                  small
                                  type="button"
                                  data-cy={`confirm-button-${index}`}
                                  onClick={() => {
                                    handleAmenityEdit(values.amenities[index]);
                                  }}
                                >
                                  {t("common:save")}
                                </Button>

                                <Button
                                  outline
                                  small
                                  type="button"
                                  data-cy={`cancel-button-${index}`}
                                  onClick={() => {
                                    handleCancelClick(
                                      !values.amenities[index].amenity_id,
                                      arrayHelpers,
                                      setFieldTouched,
                                      setFieldValue,
                                      index
                                    );
                                  }}
                                >
                                  {t("common:cancel")}
                                </Button>
                              </>
                            )}

                            {values.amenities[index].amenity_id && (
                              <Button
                                outline
                                icon
                                type="button"
                                onClick={() => {
                                  deleteAmenity(
                                    values.amenities[index].amenity_id
                                  );
                                }}
                                data-cy={`delete-button-${index}`}
                              >
                                <BinIcon />
                              </Button>
                            )}
                          </div>
                        );
                      })}

                      <Button
                        outline
                        onClick={() => arrayHelpers.push(INITIAL_DATA)}
                        type="button"
                        data-cy="add-amenity-button"
                      >
                        {t("add_amenity")}
                      </Button>
                    </div>
                  )}
                />
              </HomeInfoForm>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};
