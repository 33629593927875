import "./TieredPricingInfo.scss";

import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PLAN_TYPE_FULL_TIME, PLAN_TYPE_PART_TIME } from "utils/constants";

import Card from "../../../../components/common/Card";
import {
  getSelectedPropertyCurrencySymbol,
  selectedPropertyBillingPolicySelector,
  selectedPropertyTaxLabelSelector
} from "../../../../data/properties/selectors";

const PRICING_TIER_PREMIUM = "premium";
const PRICING_TIER_STANDARD = "standard";

const PLAN_LENGTH_1_YEAR = 12;
const PLAN_LENGTH_2_YEARS = 24;
const PLAN_LENGTH_3_YEARS = 36;

export const TieredPricingInfo = () => {
  const { t } = useTranslation("host.billing");
  const billingPolicy = useSelector(selectedPropertyBillingPolicySelector);
  const taxLabel = useSelector(selectedPropertyTaxLabelSelector);
  const currencySymbol = useSelector(getSelectedPropertyCurrencySymbol);

  const {
    fee_percent: feePercent,
    plan_length: planLength,
    plan_price: planPrice,
    plan_tier: planTier,
    plan_type: planType,
    pause_fee_part_time: pauseFee
  } = billingPolicy;

  const planName = useMemo(() => {
    if (!planTier || !planLength) {
      return null;
    }

    const plans = {
      [PRICING_TIER_STANDARD]: t("plan_name.flexible"),
      [PRICING_TIER_PREMIUM]: t("plan_name.flexible_premium"),
      [PLAN_LENGTH_1_YEAR]: t("plan_name.full_time_12"),
      [PLAN_LENGTH_2_YEARS]: t("plan_name.full_time_24"),
      [PLAN_LENGTH_3_YEARS]: t("plan_name.full_time_36")
    };

    return planType === PLAN_TYPE_PART_TIME
      ? plans[planTier]
      : plans[planLength];
  }, [planType, planLength, planTier, t]);

  if (!planType) {
    return null;
  }

  return (
    <Card title={t("current_plan")} className="TieredPricingInfo">
      <p className="TieredPricingInfo__name">{planName}</p>

      <dl>
        {Boolean(planPrice) && (
          <PlanDetail
            label={t("label.platform_fee")}
            value={t("excl_tax", {
              taxLabel: taxLabel,
              value: `${currencySymbol}${planPrice}`
            })}
          />
        )}

        {Boolean(feePercent) && (
          <PlanDetail
            label={t("label.booking_fee")}
            value={t("excl_tax", {
              taxLabel: taxLabel,
              value: `${feePercent}%`
            })}
          />
        )}

        {Boolean(pauseFee) && (
          <PlanDetail
            label={t("label.pause_fee")}
            value={t("excl_tax", {
              taxLabel: taxLabel,
              value: `${currencySymbol}${pauseFee}`
            })}
          />
        )}

        {planType === PLAN_TYPE_FULL_TIME && planLength && (
          <PlanDetail
            label={t("label.contract_length")}
            value={t("months_count", { count: planLength })}
          />
        )}
      </dl>

      {planType === PLAN_TYPE_PART_TIME && (
        <p className="TieredPricingInfo__info-text">
          {t("info")}{" "}
          {Boolean(pauseFee) ? (
            <Trans ns="host.billing" i18nKey="info.active_action">
              Go
              <Link to="/calendar">here</Link>
              to pause your account.
            </Trans>
          ) : (
            <Trans ns="host.billing" i18nKey="info.paused_action">
              Click
              <Link to="/calendar">here</Link>
              to reactivate your account
            </Trans>
          )}
        </p>
      )}
    </Card>
  );
};

const PlanDetail = ({ label, value }) => (
  <>
    <dt>{label}</dt>
    <dd>{value}</dd>
  </>
);
