import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Card from "../../../../components/common/Card";
import {
  CLEANING_SECTIONS,
  ITEMS_LOCATION,
  MAINTENANCE
} from "./cleaning/constants";
import { ItemsLocation } from "./cleaning/ItemsLocation";
import { Maintenance } from "./cleaning/Maintenance";
import HomeInfoNav from "./HomeInfoNav";

export const Cleaning = () => (
  <Card className="HomeInfo__container">
    <HomeInfoNav sections={CLEANING_SECTIONS} />

    <Routes>
      <Route path={ITEMS_LOCATION} element={<ItemsLocation />} />
      <Route path={MAINTENANCE} element={<Maintenance />} />
      <Route index element={<Navigate to={ITEMS_LOCATION} />} />
    </Routes>
  </Card>
);
