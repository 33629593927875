import "./StepLoginDetails.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import { SignupNavigate } from "scenes/signup/SignupRoutes";

import LoginForm from "../../../../components/LoginForm";
import { useOnboardingContext } from "../../SignupContext";

export const StepLoginDetails = () => {
  const { t } = useTranslation(["host.signup", "host.auth"]);
  const { currentStep, data, error, handleSubmitAndNavigateNext, loading } =
    useOnboardingContext();

  if (currentStep.done) {
    return <SignupNavigate />;
  }

  const loginEmailError =
    error?.error?.message === "Email already exists." &&
    t("common:errors.already_taken", { fieldName: "Email" });

  return (
    <LoginForm
      buttonText="common:next"
      email={data.host.login_email}
      handleSubmit={handleSubmitAndNavigateNext}
      initialEmailError={loginEmailError}
      loading={loading}
      isSignup
    >
      <h2 className="Signup__header">{t("login.header")}</h2>
      <h4 className="Signup__subheader">{t("login.subheader")}</h4>
    </LoginForm>
  );
};
