import React from "react";
import { NavLink } from "react-router-dom";

const TabControlWithNavV6 = ({ tabs }) => {
  return (
    <div className="TabControl">
      <div className="TabControl__tabs">
        {tabs.map(tab => (
          <NavLink
            key={tab.id}
            data-cy={tab.dataCy}
            data-testid={tab.dataCy}
            to={tab.url}
            className={({ isActive }) =>
              isActive
                ? "TabControl__tabs__tab TabControl__tabs__tab--active"
                : "TabControl__tabs__tab"
            }
          >
            {tab.text}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default TabControlWithNavV6;
