import "./Opportunity.scss";

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../components/buttons/Button";
import Card from "../../../components/common/Card";
import DefaultError from "../../../components/common/DefaultError";
import Spinner from "../../../components/Spinner";
import { receiveLocalities } from "../../../data/actions/user";
import {
  getAvailableLocalitiesForProperty,
  selectedPropertyLocalitySelector
} from "../../../data/properties/selectors";
import api from "../../../services/api";
import OpportunityForm from "./components/OpportunityForm";

const Opportunity = () => {
  const { id: currentLocalityId } = useSelector(
    selectedPropertyLocalitySelector
  );
  const currentLocalities = useSelector(getAvailableLocalitiesForProperty);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation("host.opportunity");

  const initialValues = {
    city: "",
    locality_id: currentLocalityId,
    bedrooms: "",
    postcode: "",
    street: ""
  };

  const fetchLocalities = useCallback(async () => {
    try {
      setError(false);
      setLoading(true);
      const localities = await api.get("/v1/hosts/localities");
      dispatch(receiveLocalities(localities));
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const handleSubmit = useCallback(
    async values => {
      setServerError(null);
      try {
        await api.post("/v1/hosts/opportunities", null, values);
        setIsSubmitted(true);
      } catch (error) {
        if (error.statusCode === 400) {
          setServerError(t("fill_form_with_correct_data"));
        }
        setServerError(t("common:error.try_again"));
      }
    },
    [t]
  );

  useEffect(() => {
    fetchLocalities();
  }, [fetchLocalities]);

  if (loading) {
    return <Spinner cover />;
  }

  if (error) {
    return (
      <Card className="Opportunity__form-card">
        <DefaultError>{t("opportunity_error")}</DefaultError>
        <div className="Opportunity__actions">
          <Button small onClick={fetchLocalities}>
            {t("common:reload_page")}
          </Button>
        </div>
      </Card>
    );
  }

  if (isSubmitted) {
    return (
      <Card className="Opportunity__form-card">
        <p>{t("opportunity_submit_success")}</p>
      </Card>
    );
  }

  return (
    <div className="Opportunity">
      <Card className="Opportunity__form-card">
        <OpportunityForm
          initialValues={initialValues}
          localities={currentLocalities}
          handleSubmit={handleSubmit}
          serverError={serverError}
        />
      </Card>
    </div>
  );
};

export default Opportunity;
