import i18n from "i18next";

import { parseQuerystring } from "./utils/querystring";
import { localStorage } from "./utils/storage";

// Detect language from the location params, for example `https://host.airsorted.com/login?lang=fn`
const { lang: forcedLang } = parseQuerystring(window.location.search);

export const LANGUAGES = ["en", "fr", "es", "pt"];

const NAMESPACES = [
  "common",
  "host",
  "host.date",
  "host.account",
  "host.auth",
  "host.billing",
  "host.bookings",
  "host.contact",
  "host.calendar",
  "host.cleans",
  "host.install",
  "host.maintenance",
  "host.onboarding",
  "host.overview",
  "host.offboarding",
  "host.opportunity",
  "host.performance",
  "host.platforms",
  "host.preferences",
  "host.referrals",
  "host.setup",
  "host.signup",
  "host.terms",
  "host.home_info"
];

const requireLanguage = lng =>
  NAMESPACES.reduce(
    (acc, namespace) => ({
      ...acc,
      [namespace]: require(`./translations/${lng}/${namespace}.json`)
    }),
    {}
  );

const resources = LANGUAGES.reduce(
  (acc, lng) => ({
    ...acc,
    [lng]: requireLanguage(lng)
  }),
  {}
);

i18n.init({
  lng: forcedLang
    ? forcedLang
    : localStorage.getItem("language--persist") || "en",
  fallbackLng: "en",

  resources,

  // Uncomment to see translation suggestions in console
  // debug: true,
  // updateMissing: true,

  ns: NAMESPACES,
  defaultNS: "host",

  keySeparator: false,

  react: {
    useSuspense: false
  }
});

i18n.on("languageChanged", language =>
  localStorage.setItem("language--persist", language)
);

export default i18n;
