import { useEffect } from "react";

import { track } from "../services/segment";

const useBeforeInstallPromptEvent = () => {
  const handleAppInstalled = () => {
    window.deferredPrompt = null;
    window.PWAInstalled = true;
    track("App installed on homescreen.");
  };
  const handleBeforeInstallPrompt = event => {
    window.deferredPrompt = event;
  };

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    window.addEventListener("appinstalled", handleAppInstalled);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
      window.removeEventListener("appinstalled", handleAppInstalled);
    };
  }, []);
};

export default useBeforeInstallPromptEvent;
