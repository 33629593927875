import "./App.scss";

import { Flash } from "components/common/Flash";
import React from "react";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from "react-router-dom";
import { RawTerms } from "scenes/terms/RawTerms";

import AppUpdateBanner from "./components/nav/AppUpdateBanner";
import { PostSetupRoute } from "./components/nav/PostSetupRoute";
import RequireAuth from "./components/nav/RequireAuth";
import { Tracker } from "./components/Tracker";
import store from "./data/store";
import i18n from "./i18n";
import ForgottenPassword from "./scenes/auth/login/ForgottenPassword";
import Login from "./scenes/auth/login/Login";
import Logout from "./scenes/auth/login/Logout";
import PasswordReset from "./scenes/auth/login/PasswordReset";
import TokenLogin from "./scenes/auth/login/TokenLogin";
import ErrorBoundary from "./scenes/errors/ErrorBoundary";
import Install from "./scenes/install/Install";
import { Signup } from "./scenes/signup/Signup";
import useBeforeInstallPromptEvent from "./utils/useBeforeInstallPromptEvent";

const PageLayout = () => {
  return (
    <ErrorBoundary>
      <Tracker message="USER_ACCESSED" />
      <Flash />
      <AppUpdateBanner />

      <Outlet />
    </ErrorBoundary>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<PageLayout />}>
      <Route path="/install" element={<Install />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login/:token/:propertyId?" element={<TokenLogin />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/forgotten_password" element={<ForgottenPassword />} />
      <Route path="/reset_password/:token" element={<PasswordReset />} />
      <Route
        path="/signup/:propertyId/*"
        element={
          <RequireAuth>
            <Signup />
          </RequireAuth>
        }
      />
      <Route
        path="/terms"
        element={
          <RequireAuth>
            <RawTerms />
          </RequireAuth>
        }
      />
      <Route
        path="/*"
        element={
          <RequireAuth>
            <PostSetupRoute />
          </RequireAuth>
        }
      />
    </Route>
  )
);

const App = () => {
  useBeforeInstallPromptEvent();

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={router} />
      </I18nextProvider>
    </Provider>
  );
};

export default App;
