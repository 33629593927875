import { getProperties } from "data/properties/selectors";
import { hasLiveProperties } from "data/property_states";
import delighted from "services/delighted";
import analytics from "services/segment";

import {
  clearUserContext,
  configureScopeForUser
} from "../../utils/sentry_utils";
import {
  EMAIL_UPDATED,
  RECEIVE_PROPERTIES,
  RECEIVE_USER
} from "../action_types";
import { getProfileEmail, isAdmin } from "../reducers/user";

export default function trackerMiddleware(store) {
  if (!analytics()) return next => action => next(action);

  function handleAction(action) {
    switch (action.type) {
      case EMAIL_UPDATED:
        const {
          profile: { id, first_name: firstName, last_name: lastName, emails }
        } = store.getState().user;

        configureScopeForUser({ id, emails, firstName, lastName });
        break;

      case RECEIVE_USER:
        {
          const {
            profile: {
              id,
              first_name: firstName,
              last_name: lastName,
              created_at,
              emails
            }
          } = store.getState().user;
          const email = getProfileEmail(store.getState());
          const admin = isAdmin(store.getState());

          if (!admin) {
            analytics().identify(String(id), {
              firstName,
              lastName,
              createdAt: created_at,
              email
            });
          }

          configureScopeForUser({ id, emails, firstName, lastName });
        }
        break;

      case RECEIVE_PROPERTIES:
        {
          const properties = getProperties(store.getState());
          const email = getProfileEmail(store.getState());
          const admin = isAdmin(store.getState());
          const {
            profile: { id, first_name: firstName, last_name: lastName }
          } = store.getState().user;

          if (delighted() && !admin && hasLiveProperties(properties)) {
            delighted().survey({
              email,
              name: `${firstName} ${lastName}`,
              // Show the NPS survey once per month (overrides Delighted settings)
              recurringPeriod: 30 * 24 * 60 * 60,
              properties: {
                schedule: "web",
                lead_id: id
              }
            });
          }
        }
        break;

      case "LOGGED_OUT":
        analytics().reset();
        clearUserContext();
        break;
      default:
        break;
    }
  }

  return next => action => {
    const res = next(action);
    handleAction(action);
    return res;
  };
}
