import React, { PureComponent } from "react";

import { cleanImageUrl } from "../../../../utils/cleanImageUrl";

class CleanImage extends PureComponent {
  render() {
    const { image, onClick, imageIndex } = this.props;
    const currentImageIndex = imageIndex ? imageIndex : 0;

    return (
      <div
        className="CleanImages__clean-image"
        onClick={() => onClick(currentImageIndex)}
      >
        <img
          src={cleanImageUrl(image.image_url, { w: 100, h: 100 })}
          alt="Clean"
        />
      </div>
    );
  }
}

export default CleanImage;
