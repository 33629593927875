import PropTypes from "prop-types";
import React, { PureComponent } from "react";

class InlineIframe extends PureComponent {
  static propTypes = {
    htmlContent: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onScrolledDown: PropTypes.func
  };

  componentDidMount() {
    this.updateIframe();
  }

  componentDidUpdate() {
    this.updateIframe();
  }

  componentWillUnmount() {
    if (this.props.onScrolledDown) {
      this.removeScrollListener();
    }
  }

  removeScrollListener = () => {
    const doc = this.iframe.contentWindow.document;
    doc.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = e => {
    if (
      e.target.body.scrollHeight - e.target.body.scrollTop - 20 <=
      e.target.body.clientHeight
    ) {
      if (this.props.onScrolledDown) {
        this.removeScrollListener();
        this.props.onScrolledDown();
      }
    }
  };

  updateIframe() {
    if (!this.iframe) {
      return;
    }

    const doc = this.iframe.contentWindow.document;

    doc.open();
    doc.write(this.props.htmlContent);
    doc.close();

    if (this.props.onScrolledDown) {
      doc.addEventListener("scroll", this.handleScroll);
    }
  }

  render() {
    return (
      <iframe
        id={this.props.id}
        title={this.props.title}
        data-testid={this.props["data-testid"]}
        ref={r => (this.iframe = r)}
        src="about:blank"
      />
    );
  }
}

export default InlineIframe;
