import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import "./SingleDateInput.scss";

import { ErrorText } from "components/common/Input";
import moment from "moment";
import React, { useMemo } from "react";
import { useState } from "react";
import { SingleDatePicker } from "react-dates";

import { useWindowDimensions } from "../utils/useWindowDimensions";

const SingleDateInput = ({
  label,
  id,
  date,
  defaultDate,
  onDateChange,
  placeholder,
  showClearDate,
  error
}) => {
  const [focused, setFocused] = useState();
  const { isExtraSmallScreen, isSmallScreen, isMediumScreen } =
    useWindowDimensions();

  const datePickerDisplayProps = useMemo(() => {
    const SMALL_DAY_SIZE = 40;
    const LARGE_DAY_SIZE = 45;
    const ONE_MONTH = 1;
    const TWO_MONTHS = 2;

    const orientation = isSmallScreen ? "vertical" : "horizontal";
    const daySize = isExtraSmallScreen ? SMALL_DAY_SIZE : LARGE_DAY_SIZE;
    const numberOfMonths =
      isMediumScreen && orientation === "horizontal" ? ONE_MONTH : TWO_MONTHS;

    return {
      daySize,
      orientation,
      numberOfMonths,
      withPortal: !isSmallScreen,
      withFullScreenPortal: isSmallScreen,
      displayFormat: "DD/MM/YYYY"
    };
  }, [isExtraSmallScreen, isMediumScreen, isSmallScreen]);

  const momentDefaultDateObject =
    focused && defaultDate ? moment(defaultDate) : null;

  const momentDateObject = date ? moment(date) : null;

  return (
    <div className={error ? "SingleDateInput--error" : ""}>
      <label className="Input__label">
        {label}

        <SingleDatePicker
          id={id} // PropTypes.string.isRequired,
          date={momentDateObject || momentDefaultDateObject} // momentPropTypes.momentObj or null
          onDateChange={onDateChange} // PropTypes.func.isRequired
          focused={focused} // PropTypes.bool
          onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
          hideKeyboardShortcutsPanel
          noBorder
          readOnly
          placeholder={placeholder}
          showClearDate={showClearDate}
          {...datePickerDisplayProps}
        />
      </label>

      {error && <ErrorText>{error}</ErrorText>}
    </div>
  );
};

export default SingleDateInput;
