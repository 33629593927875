import Button from "components/buttons/Button";
import { selectedPropertyLocalitySelector } from "data/properties/selectors";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LONDON } from "utils/constants";

import { useLocalStorage } from "../../utils/useLocalStorage";
import Banner from "../common/Banner";

const BANNER_STORAGE_KEY = "digital_lock_banner";
const DIGITAL_LOCK_INFO_URL = "https://www.houst.com/smart-locks";

export const DigitalLockBanner = () => {
  const [dismissed, setDismissed] = useLocalStorage(BANNER_STORAGE_KEY);
  const { t } = useTranslation("host.overview");
  const locality = useSelector(selectedPropertyLocalitySelector);

  const handleRedirect = () => {
    window.open(DIGITAL_LOCK_INFO_URL, "_blank");
  };

  if (locality.name !== LONDON || locality.partner_id) {
    return null;
  }

  if (dismissed === "true") {
    return null;
  }

  return (
    <Banner handleDismissBanner={() => setDismissed(true)}>
      <div className="Banner__message">
        <div className="Banner__message__text">
          <b>{t("digital_lock_banner.header")}</b>
          <p>{t("digital_lock_banner.text")}</p>
        </div>
      </div>
      <div className="Banner__actions">
        <Button outline theme="plain" onClick={handleRedirect}>
          {t("ad_banner.secondary_action")}
        </Button>
      </div>
    </Banner>
  );
};
