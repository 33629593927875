import "./HostingTeam.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "../../../../components/buttons/Button";
import {
  getSelectedProperty,
  selectedPropertyPod,
  selectedPropertyTeamPhotos
} from "../../../../data/properties/selectors";
import { propertyNotInSpain } from "../../../../data/property_states";

export const HostingTeam = () => {
  const { t } = useTranslation("host.overview");
  const navigate = useNavigate();
  const teamPhotos = useSelector(selectedPropertyTeamPhotos);
  const { restricted_access: restrictedAccess } =
    useSelector(selectedPropertyPod);
  const selectedProperty = useSelector(getSelectedProperty);

  const redirectToContact = () => {
    navigate("/contact?contact-form=true");
  };

  if (restrictedAccess) {
    return null;
  }

  return (
    <div className="OverviewItem Team">
      <div className="OverviewItem__header">
        <h3 className="header">{t("your_hosting_team")}</h3>
      </div>
      <div className="OverviewItem__content">
        <p className="HostingTeam__text">{t("hosting_team_info")}</p>

        <div className="HostingTeam__bottom">
          {propertyNotInSpain(selectedProperty) && teamPhotos.length > 0 && (
            <div className="HostingTeam__photos">
              {teamPhotos.map(photo => (
                <div className="HostingTeam__photo">
                  <img src={photo} alt="Hosting team" />
                </div>
              ))}
            </div>
          )}

          <Button outline onClick={redirectToContact}>
            {t("contact")}
          </Button>
        </div>
      </div>
    </div>
  );
};
