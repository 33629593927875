import "./SignupProgress.scss";

import { ReactComponent as LeftArrowIcon } from "assets/icons/arrow-left-2.svg";
import classNames from "classnames";
import Logo from "components/common/Logo";
import { selectProperty, skipPropertySetup } from "data/actions/properties";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { OnboardingContext } from "./SignupContext";

const SignupProgress = ({ skipPropertySetup, selectProperty }) => {
  const {
    currentStepIndex,
    firstUncompletedStepIndex,
    steps,
    data: {
      property: { locality },
      oldest_active_property_id
    }
  } = useContext(OnboardingContext);
  const navigate = useNavigate();
  const { t } = useTranslation("host.signup");

  const displayBackToDashboard = oldest_active_property_id;

  const handleSkip = () => {
    skipPropertySetup();
    selectProperty(oldest_active_property_id);
    navigate("/", { replace: true });
  };

  return (
    <div className="SignupProgress">
      <div>
        <div className="logo-container">
          <Logo countryCode={locality.country_code} />
        </div>

        <ul className="SignupProgress__steps-list">
          {steps.map((step, index) => {
            if (step.hideNavigation) {
              return null;
            }

            return (
              <li
                key={step.path}
                className={classNames({
                  previous: index < currentStepIndex,
                  active: index === currentStepIndex,
                  available: index <= firstUncompletedStepIndex,
                  disabled: index > firstUncompletedStepIndex
                })}
              >
                <span>
                  <i className={`SignupProgress__step-button__${step.path}`} />
                </span>
                <Link key={step.path} to={step.path}>
                  {t(`progress_steps.${step.path}`)}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>

      {displayBackToDashboard && (
        <div className="SignupProgress__back-to-dashboard">
          <button
            type="button"
            className="link button-as-link"
            onClick={handleSkip}
          >
            <LeftArrowIcon />
            {t("progress_steps.back_to_dashboard")}
          </button>
        </div>
      )}
    </div>
  );
};

export default connect(null, { skipPropertySetup, selectProperty })(
  SignupProgress
);
