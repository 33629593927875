import { Field, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import Input from "../../../../../components/common/Input";
import { useHomeInfoContext } from "../../HomeInfoContext";
import { HomeInfoButtons } from "../HomeInfoButtons";
import { HomeInfoForm } from "../HomeInfoForm.jsx";
import { AmenitiesPhotos } from "./AmenitiesPhotos";
import { BOILER } from "./constants";

export const Boiler = () => {
  const { t } = useTranslation("host.home_info");

  const { data, handleSubmitAndNavigateNext } = useHomeInfoContext();

  return (
    <div className="HomeInfo__content">
      <Formik
        initialValues={data}
        onSubmit={handleSubmitAndNavigateNext(BOILER)}
      >
        {({ handleChange, values }) => (
          <HomeInfoForm>
            <label className="HomeInfoForm__element">
              {t("label.boiler_at_property")}
              <Input.Toggle
                split
                testId="heating-boiler-available"
                name="maintenance_heating_boiler_available"
                id="maintenance_heating_boiler_available"
                value={values.maintenance_heating_boiler_available}
                onChange={handleChange}
                withFormik={true}
              />
            </label>

            <label className="HomeInfoForm__element">
              {t("label.boiler_make_and_model")}
              <Field
                type="text"
                name="maintenance_heating_boiler_make_and_model"
              />
            </label>

            <label className="HomeInfoForm__element">
              {t("label.boiler_location")}
              <Field
                component="textarea"
                rows={4}
                name="maintenance_heating_boiler_location"
              />
            </label>

            <label className="HomeInfoForm__element">
              {t("label.boiler_notes")}
              <Field
                component="textarea"
                rows={4}
                name="maintenance_heating_boiler_notes"
              />
            </label>

            <AmenitiesPhotos type={BOILER} />

            <HomeInfoButtons sectionId={BOILER} />
          </HomeInfoForm>
        )}
      </Formik>
    </div>
  );
};
