import config from "config";

const delighted = window.delighted;

if (config.DELIGHTED_KEY && !delighted) {
  (function (e, t, r, n, a) {
    if (!e[a]) {
      for (var i = (e[a] = []), s = 0; s < r.length; s++) {
        var c = r[s];
        i[c] =
          i[c] ||
          // eslint-disable-next-line
          (function (e) {
            return function () {
              var t = Array.prototype.slice.call(arguments);
              i.push([e, t]);
            };
          })(c);
      }
      i.SNIPPET_VERSION = "1.0.1";
      var o = t.createElement("script");
      (o.type = "text/javascript"), // eslint-disable-line
        (o.async = !0),
        (o.src =
          "https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/" +
          n +
          "/" +
          a +
          ".js");
      var p = t.getElementsByTagName("script")[0];
      p.parentNode.insertBefore(o, p);
    }
  })(
    window,
    document,
    [
      "survey",
      "reset",
      "config",
      "init",
      "set",
      "get",
      "event",
      "identify",
      "track",
      "page",
      "screen",
      "group",
      "alias"
    ],
    config.DELIGHTED_KEY,
    "delighted"
  );
}

const fn = () => window.delighted;
export default fn;
