import React from "react";

const UK_DOC_FILENAME = "Guardhog hostcover to houst hosts 2019.pdf";
const NON_UK_DOC_FILENAME = "IBG Houst MP non UKI Host Guarantee.pdf";

export const InsuranceTermsLink = ({ locality, children }) => {
  const fileName =
    locality.country_code === "GB" ? UK_DOC_FILENAME : NON_UK_DOC_FILENAME;

  return (
    <a
      href={`${process.env.PUBLIC_URL}/docs/${fileName}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};
