import Checkbox from "components/forms/Checkbox";
import React, { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button from "../../../../components/buttons/Button";
import DefaultError from "../../../../components/common/DefaultError";
import { selectedPropertyLocalitySelector } from "../../../../data/properties/selectors";
import { ShapedImage } from "../../Shapes";
import { useOnboardingContext } from "../../SignupContext";
import NavButtonsWrapper from "../NavButtonsWrapper";
import { usePropertyChecklist } from "./usePropertyChecklist.js";

const ESSENTIALS_LIST_URL =
  "https://houst.com/static/Houst_Essentials_Checklist.pdf";

const ESSENTIALS_LIST_URL_ANZ =
  "https://houst.com/static/Houst_Essentials_Checklist_ANZ.pdf";

const AU = "AU";
const NZ = "NZ";
const ANZ_COUNTRIES = [AU, NZ];

export const StepChecklist = () => {
  const { t } = useTranslation("host.signup");
  const { propertyId, loading, handlePrev, handleSubmitAndNavigateNext } =
    useOnboardingContext();
  const { checklist, allSelected, onChange } = usePropertyChecklist(propertyId);
  const [showError, setShowError] = useState(false);
  const locality = useSelector(selectedPropertyLocalitySelector);

  const handleChange = event => onChange(event.target.name);

  const handleClickNextButton = useCallback(() => {
    allSelected
      ? handleSubmitAndNavigateNext({ checklist_completed: true })
      : setShowError(true);
  }, [allSelected, handleSubmitAndNavigateNext]);

  const listUrl = ANZ_COUNTRIES.includes(locality.country_code)
    ? ESSENTIALS_LIST_URL_ANZ
    : ESSENTIALS_LIST_URL;

  return (
    <React.Fragment>
      <div className="StepChecklist Signup__step">
        <h2 className="Signup__header" data-testid="checklist-header">
          {t("checklist.header")}
        </h2>
        <h4 className="Signup__subheader">{t("checklist.subheader")}</h4>

        <div>
          <Checkbox
            id="keys"
            onChange={handleChange}
            label={<h3>{t("checklist.keys.title")}</h3>}
            checked={checklist.keys}
            dataCy="keys-checkbox"
          />
          <p>{t("checklist.keys.content1")}</p>
          <p>{t("checklist.keys.content2")}</p>
        </div>

        <div>
          <Checkbox
            id="photo"
            onChange={handleChange}
            label={<h3>{t("checklist.photo.title")}</h3>}
            checked={checklist.photo}
            dataCy="photo-checkbox"
          />
          <p>{t("checklist.photo.content1")}</p>
          <p>{t("checklist.photo.content2")}</p>
        </div>

        <div>
          <Checkbox
            id="essentials"
            onChange={handleChange}
            label={<h3>{t("checklist.essentials.title")}</h3>}
            checked={checklist.essentials}
            dataCy="essentials-checkbox"
          />
          <p>
            <Trans ns="host.signup" i18nKey="info.active_action">
              Make sure you get familiar with the{" "}
              <a href={listUrl} target="_blank" rel="noopener noreferrer">
                essentials list.
              </a>
            </Trans>
          </p>
        </div>

        {!allSelected && showError && (
          <DefaultError>
            <p>{t("checklist.error")}</p>
          </DefaultError>
        )}
        <NavButtonsWrapper
          next={
            <Button
              loading={loading}
              type="button"
              onClick={handleClickNextButton}
              disabledLike={!allSelected}
            >
              {t("common:next")}
            </Button>
          }
          prev={
            <Button type="button" theme="plain" onClick={handlePrev}>
              {t("common:previous")}
            </Button>
          }
        />
      </div>
      <ShapedImage imgId="checklist" containerClassName="StepChecklist" />
    </React.Fragment>
  );
};
