import { getSelectedProperty } from "data/properties/selectors";
import {
  propertyHasDirectDebit,
  propertyOffboarded
} from "data/property_states";
import { getProfile } from "data/reducers/user";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Alert from "../../../../components/common/Alert";
import { useGetBilling } from "../useGetBilling";
import { BankAccountForPayouts } from "./BankAccountForPayouts";
import { Credit } from "./Credit";
import { Deposit } from "./Deposit";
import DirectDebit from "./DirectDebit";
import { EmergencyBuffer } from "./EmergencyBuffer";
import ManageCard from "./ManageCard";
import { TieredPricingInfo } from "./TieredPricingInfo";

export const BillingDetails = () => {
  const { t } = useTranslation("host.billing");
  const property = useSelector(getSelectedProperty);
  const user = useSelector(getProfile);
  const [error, getBilling] = useGetBilling();
  const isPropertyOffboarded = useMemo(
    () => propertyOffboarded(property),
    [property]
  );

  useEffect(() => {
    if (!isPropertyOffboarded) {
      getBilling();
    }
  }, [property, getBilling, isPropertyOffboarded]);

  if (isPropertyOffboarded) {
    return null;
  }

  if (error) {
    return (
      <Alert danger small>
        <p>{t("common:error.try_again")}</p>
      </Alert>
    );
  }

  return (
    <div className="Billing__container Billing__details">
      <EmergencyBuffer />

      <Credit />

      <TieredPricingInfo />

      <BankAccountForPayouts property={property} user={user} />

      <ManageCard />

      {propertyHasDirectDebit(property) && <DirectDebit />}

      <Deposit />
    </div>
  );
};
