// TODO - use classNames() instead of bem()
export default function bem(baseCssClass, modifiers = []) {
  if (!Array.isArray(modifiers)) {
    return Object.keys(modifiers)
      .reduce(
        (acc, key) => {
          return modifiers[key] ? [...acc, `${baseCssClass}--${key}`] : acc;
        },
        [baseCssClass]
      )
      .join(" ");
  }

  // TODO(lucassus): dead code ???
  return modifiers
    .reduce(
      (acc, key) => {
        return key ? [...acc, `${baseCssClass}--${key}`] : acc;
      },
      [baseCssClass]
    )
    .join(" ");
}
