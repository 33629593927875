import "./CleanModal.scss";

import { ReactComponent as AlertIcon } from "assets/icons/alert-triangle.svg";
import Button from "components/buttons/Button";
import Input from "components/common/Input";
import Modal from "components/common/modal";
import { getSelectedProperty } from "data/properties/selectors";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import api from "../../../../services/api";

const TYPE_CLEAN_DEMAND = "clean_demand";

export class CleanModal extends Component {
  state = {
    errors: null,
    cancellation_reason: null,
    submitted: false,
    cleanDeleted: false
  };

  handleUpdate = value => {
    this.setState({
      cancellation_reason: value,
      errors: null
    });
  };

  confirmCancel = async () => {
    const { cancellation_reason } = this.state;
    const { t, clean } = this.props;
    const id = clean.id;
    const propertyId = clean.property_id;

    if (!cancellation_reason) {
      return this.setState({
        errors: {
          cancellation_reason: t("clean.cancellation_reason_required")
        }
      });
    }

    this.setState({
      loading: true,
      submitted: true
    });

    await api
      .delete(`/v1/hosts/properties/${propertyId}/cleans`, null, {
        type: TYPE_CLEAN_DEMAND,
        id,
        cancellation_reason
      })
      .then(res => {
        this.setState({
          cleanDeleted: true
        });
      });
  };

  renderBeforeSubmissionFooter() {
    const { loading } = this.state;
    const { t, toggleCleanModal } = this.props;

    return (
      <React.Fragment>
        <Button
          outline
          type="button"
          theme="primary"
          onClick={() => toggleCleanModal()}
        >
          {t("clean.dont_cancel")}
        </Button>
        <Button
          data-cy="clean-confirm-cancel"
          theme="primary"
          onClick={this.confirmCancel}
          loading={loading}
        >
          <span>{t("clean.confirm_cancel")}</span>
        </Button>
      </React.Fragment>
    );
  }

  renderAfterSubmissionFooter() {
    const { t, toggleCleanModal } = this.props;

    return (
      <React.Fragment>
        <Button
          type="button"
          theme="primary"
          onClick={() => toggleCleanModal()}
        >
          <span>{t("common:close")}</span>
        </Button>
      </React.Fragment>
    );
  }

  renderAfterSubmission() {
    const { t, toggleCleanModal } = this.props;
    return (
      <Modal
        title={t("clean.cancel_request_sent")}
        closeModal={() => toggleCleanModal()}
        footer={this.renderAfterSubmissionFooter()}
      >
        <p>{t("clean.cancel_request_sent_confirmation_msg")}</p>
      </Modal>
    );
  }

  renderBeforeSubmission() {
    const { errors, cancellation_reason } = this.state;
    const { t, toggleCleanModal } = this.props;
    return (
      <Modal
        title={t("clean.cancel_confirmation_check")}
        closeModal={() => toggleCleanModal()}
        footer={this.renderBeforeSubmissionFooter()}
      >
        <p>{t("clean.cancel_explanation")}</p>
        <Input.Area
          label={`${t("clean.cancellation_reason_title")}:`}
          name="cancellation_reason"
          value={cancellation_reason}
          onChange={val => this.handleUpdate(val)}
          placeholder={t("clean.cancellation_reason_placeholder")}
          error={errors && errors.cancellation_reason}
        />
      </Modal>
    );
  }

  renderLateCancel() {
    const { t, toggleCleanModal } = this.props;

    return (
      <Modal
        title={t("clean.cancel_request")}
        closeModal={() => toggleCleanModal()}
      >
        <div className="CleanModal__alert">
          <div>
            <AlertIcon />
          </div>
          <div className="CleanModal__text">{t("clean.late.warning")}</div>
        </div>
        <p className="CleanModal__text">{t("clean.late.explanation")}</p>
        <p className="CleanModal__text">{t("common:contact_manager")}</p>
      </Modal>
    );
  }

  render() {
    const { submitted, cleanDeleted } = this.state;
    const { lateCancel } = this.props;
    const afterSubmission = cleanDeleted && submitted;

    if (lateCancel) {
      return this.renderLateCancel();
    }

    if (afterSubmission) {
      return this.renderAfterSubmission();
    }

    return this.renderBeforeSubmission();
  }
}

export default compose(
  withTranslation("host.cleans"),
  connect(state => ({
    selectedProperty: getSelectedProperty(state)
  }))
)(CleanModal);
