import * as Sentry from "@sentry/browser";
import api from "services/api";

import { logout } from "./user";

export const updateFetcher = (fetcher, type, args) => ({
  type,
  ...fetcher,
  ...args
});

export const fetcherClear = (fetcher, exact = true) =>
  updateFetcher(fetcher, "FETCH_CLEAR", { exact });
export const fetcherForceRefetch = (fetcher, exact = true) =>
  updateFetcher(fetcher, "FETCH_FORCE_REFETCH", { exact });
export const fetcherStart = fetcher => updateFetcher(fetcher, "FETCH_START");
export const fetcherSuccess = fetcher =>
  updateFetcher(fetcher, "FETCH_SUCCESS");
export const fetcherFailed = fetcher => updateFetcher(fetcher, "FETCH_FAILED");
export const fetcherMissing = fetcher =>
  updateFetcher(fetcher, "FETCH_MISSING");

// Whenever we change user we want to clear all fetcher memory of user specific routes
export const clearUserFetcher = fetcherClear({ key: "/v1/hosts" }, false);

const maybeFetch = ({ dispatch, getState }, fetcher) => {
  const {
    key,
    method = "get",
    endpoint,
    params = {},
    body = {},
    onResponse
  } = fetcher;
  const data = getState().fetcher[key] || {};

  if (data.fetching) {
    return Promise.resolve(null);
  }

  dispatch(fetcherStart({ key }));

  return api[method](endpoint, params, body)
    .then(res => {
      if (onResponse) dispatch(onResponse(res));

      dispatch(fetcherSuccess({ key, payload: res }));

      return res;
    })
    .catch(({ statusCode, error }) => {
      if (statusCode === 401) {
        return dispatch(logout());
      }

      if (statusCode === 404) {
        dispatch(fetcherMissing({ key }));
      } else {
        dispatch(fetcherFailed({ key, error, statusCode }));
      }

      // Don't report to sentry if statusCode is undefined as it means connectivity error
      if (statusCode && ![400, 403].includes(statusCode)) {
        const message =
          typeof error === "object" ? JSON.stringify(error) : error;

        Sentry.captureException(
          new Error(
            `Endpoint: ${endpoint}, statusCode: ${statusCode}, message: ${message}`
          )
        );
      }
    });
};

export const fetch = fetcher => {
  return (dispatch, getState) => {
    return maybeFetch({ dispatch, getState }, fetcher);
  };
};
