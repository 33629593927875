import {
  RECEIVE_BANK_ACCOUNT_DETAILS,
  RECEIVE_CARD_DETAILS,
  RECEIVE_DIRECT_DEBIT_INFO,
  RECEIVE_INVOICES,
  RECEIVE_PAYOUTS,
  RECEIVE_STRIPE_API_KEY,
  RECEIVE_TRANSACTIONS
} from "../action_types";

export const receiveCardDetails = card => ({
  type: RECEIVE_CARD_DETAILS,
  card
});

export const receiveDirectDebit = direct_debit => ({
  type: RECEIVE_DIRECT_DEBIT_INFO,
  direct_debit
});

export const receiveBankAccountDetails = bank_account => ({
  type: RECEIVE_BANK_ACCOUNT_DETAILS,
  bank_account
});

export const receiveStripeApiKey = stripe_key => ({
  type: RECEIVE_STRIPE_API_KEY,
  stripe_key
});

export const receiveInvoices = (invoices, propertyId) => ({
  type: RECEIVE_INVOICES,
  invoices,
  propertyId
});

export const receiveTransactions = (transactions, propertyId) => ({
  type: RECEIVE_TRANSACTIONS,
  transactions,
  propertyId
});

export const receivePayouts = (payouts, propertyId) => ({
  type: RECEIVE_PAYOUTS,
  payouts,
  propertyId
});
