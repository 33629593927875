import { CLEAR_FLASH, SET_FLASH } from "data/action_types";

export const SEVERITY_NOTICE = "notice";
export const SEVERITY_ERROR = "error";

const initialState = {
  message: null,
  severity: null,
  visible: false
};

const flash = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLASH:
      return {
        ...state,
        message: action.message,
        severity: action.severity,
        visible: true
      };

    case CLEAR_FLASH:
      return {
        ...state,
        visible: false
      };

    default:
      return state;
  }
};

export default flash;
