import { combineReducers } from "redux";
import api from "services/api";

import { localStorage, sessionStorage } from "../../utils/storage";
import { LOGGED_OUT } from "../action_types";
import cleans from "../cleans/reducer";
import app from "./app";
import billing from "./billing";
import bookings from "./bookings";
import calendar from "./calendar";
import fetcher from "./fetcher";
import flash from "./flash";
import homeInfo from "./homeInfo";
import maintenance from "./maintenance";
import performance from "./performance";
import preferences from "./preferences";
import properties from "./properties";
import user from "./user";

const rootReducer = combineReducers({
  app,
  billing,
  bookings,
  calendar,
  cleans,
  fetcher,
  flash,
  homeInfo,
  maintenance,
  performance,
  preferences,
  properties,
  user
});

const root = (state, action = {}) => {
  if (action.type === LOGGED_OUT) {
    localStorage.clear();
    sessionStorage.clear();
    api.setToken(null);

    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

export default root;
