import Button from "components/buttons/Button";
import { countries } from "countries-list";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import DefaultError from "../../../../components/common/DefaultError";
import { FieldWithTermsLock } from "../../common/FieldWithTermsLock";
import { sortedCountryCodes } from "../../common/utils";
import { ShapedImage } from "../../Shapes";
import { useOnboardingContext } from "../../SignupContext";
import NavButtonsWrapper from "../NavButtonsWrapper";

const validationSchema = yup.object().shape({
  first_name: yup.string(),
  last_name: yup.string().required(),
  street: yup.string().required(),
  postcode: yup.string(),
  city: yup.string().required(),
  country: yup.string().required(),
  type: yup.string().required()
});

const ACCOUNT_TYPE_PERSON = "person";
const ACCOUNT_TYPE_COMPANY = "company";

export const StepPersonal = () => {
  const { t } = useTranslation("host.signup");
  const {
    loading,
    handleSubmitAndNavigateNext,
    data: {
      host: { first_name, last_name, street, city, country, postcode, company }
    }
  } = useOnboardingContext();

  const handleFormSubmit = useCallback(
    async data => {
      data.company = data.type === ACCOUNT_TYPE_COMPANY;
      await handleSubmitAndNavigateNext({ host: data });
    },
    [handleSubmitAndNavigateNext]
  );

  const initialValues = {
    first_name: first_name || "",
    last_name: last_name || "",
    street: street || "",
    city: city || "",
    postcode: postcode || "",
    country: countries[country] ? country : "",
    type: company ? ACCOUNT_TYPE_COMPANY : ACCOUNT_TYPE_PERSON
  };

  return (
    <React.Fragment>
      <div className="StepPersonal Signup__step">
        <h2 className="Signup__header" data-testid="personal-details-header">
          {t("personal.header")}
        </h2>
        <h4 className="Signup__subheader">{t("personal.subheader")}</h4>

        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          enableReinitialize
        >
          {({ values }) => (
            <Form className="Signup__form">
              <div role="group" className="Signup__form__radio">
                <label>{t("personal.person_or_company")}</label>

                <label className="radio-label">
                  <Field type="radio" name="type" value={ACCOUNT_TYPE_PERSON} />
                  {t("personal.person")}
                </label>

                <label className="radio-label">
                  <Field
                    type="radio"
                    name="type"
                    value={ACCOUNT_TYPE_COMPANY}
                  />
                  {t("personal.company")}
                </label>
                <ErrorMessage name="type" component={DefaultError} />
              </div>

              {values["type"] === ACCOUNT_TYPE_PERSON ? (
                <div className="Signup__form__names">
                  <label>
                    {t("personal.first_name")}
                    <FieldWithTermsLock name="first_name" type="text" />
                    <ErrorMessage name="first_name" component={DefaultError} />
                  </label>
                  <label>
                    {t("personal.last_name")}
                    <FieldWithTermsLock name="last_name" type="text" />
                    <ErrorMessage name="last_name" component={DefaultError} />
                  </label>
                </div>
              ) : (
                <label>
                  {t("personal.company_name")}
                  <FieldWithTermsLock name="last_name" type="text" />
                  <ErrorMessage name="last_name" component={DefaultError} />
                </label>
              )}

              <label data-testid="personal-address-input">
                {t("personal.address")}
                <FieldWithTermsLock name="street" type="text" />
                <ErrorMessage name="street" component={DefaultError} />
              </label>

              <label>
                {t("personal.city")}
                <FieldWithTermsLock name="city" type="text" />
                <ErrorMessage name="city" component={DefaultError} />
              </label>

              <div className="Signup__form__postcode">
                <label data-testid="postcode-input">
                  {t("personal.post_code")}
                  <FieldWithTermsLock name="postcode" type="text" />
                  <ErrorMessage name="postcode" component={DefaultError} />
                </label>

                <label>
                  {t("personal.country")}
                  <div className="select-wrapper">
                    <FieldWithTermsLock name="country" component="select">
                      <option disabled value="" />
                      {sortedCountryCodes.map(countryCode => (
                        <option key={countryCode} value={countryCode}>
                          {countries[countryCode].name}
                        </option>
                      ))}
                    </FieldWithTermsLock>
                  </div>
                  <ErrorMessage name="country" component={DefaultError} />
                </label>
              </div>

              <NavButtonsWrapper
                next={
                  <Button type="submit" loading={loading}>
                    {t("common:next")}
                  </Button>
                }
              />
            </Form>
          )}
        </Formik>
      </div>
      <ShapedImage imgId="personal" containerClassName="StepPersonal" />
    </React.Fragment>
  );
};
