import axios from "axios";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { receiveAmenitiesPhotos } from "../../../data/actions/homeInfo";
import { getSelectedPropertyId } from "../../../data/properties/selectors";
import api from "../../../services/api";
import { parseDateTime } from "../../../utils/dateParser";

export const DEFAULT_PROPERTY_FILE_FOLDER = "Host";
export const APPLIANCES_PROPERTY_FILE_FOLDER = "Appliances";

const parsePhotoData = photoData => {
  return {
    ...photoData,
    created_at: parseDateTime(photoData.created_at),
    updated_at: parseDateTime(photoData.updated_at)
  };
};

const prepareFormData = (fields, file) => {
  const formData = new FormData();

  Object.entries(fields).forEach(([name, value]) => {
    formData.append(name, value);
  });

  formData.append("file", file);

  return formData;
};

const PhotosContext = createContext({});
export const usePhotosContext = () => useContext(PhotosContext);

export const PhotosContextProvider = ({ children }) => {
  const selectedPropertyId = useSelector(getSelectedPropertyId);
  const [uploading, setUploading] = useState(false);
  const [getError, setGetError] = useState(false);
  const [photos, setPhotos] = useState(null);
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const getAppliancesPhotos = useCallback(
    amenitiesType => {
      setGetError(false);

      api
        .get(
          `/v1/hosts/properties/${selectedPropertyId}/property-files/appliances`,
          { appliance_type: amenitiesType.replace("-", "_") }
        )
        .then(res => {
          const amenitiesPhotos = res.map(parsePhotoData);
          dispatch(
            receiveAmenitiesPhotos({
              photos: amenitiesPhotos,
              propertyId: selectedPropertyId,
              amenitiesType: amenitiesType
            })
          );
        })
        .catch(err => {
          setGetError(t("problem_fetching_data"));
        });
    },
    [selectedPropertyId, setGetError, t, dispatch]
  );

  const getPhotos = useCallback(() => {
    setGetError(false);

    api
      .get(`/v1/hosts/properties/${selectedPropertyId}/property-files/`)
      .then(res => {
        setPhotos(res.map(photo => parsePhotoData(photo)));
      })
      .catch(err => {
        setGetError(t("problem_fetching_data"));
      });
  }, [selectedPropertyId, setGetError, t]);

  useEffect(() => {
    getPhotos();
  }, [getPhotos]);

  const uploadImage = useCallback(
    (file, folderName, applianceType = null) => {
      return new Promise(async (resolve, reject) => {
        try {
          const { presigned_post: presignedPost, s3_object_key } =
            await api.post(
              `/v1/hosts/properties/${selectedPropertyId}/property-files/property-files-upload`,
              null,
              {
                content_type: file.type,
                filename: file.name,
                folder_name: folderName
              }
            );

          const formData = prepareFormData(presignedPost.fields, file);

          await axios.post(presignedPost.url, formData, {
            "Content-Type": "multipart/form-data"
          });

          const newphoto = api.post(
            `/v1/hosts/properties/${selectedPropertyId}/property-files/`,
            null,
            {
              content_type: file.type,
              file_name: file.name,
              s3_object_key,
              file_size: file.size,
              folder_name: folderName,
              appliance_type: applianceType
                ? applianceType.replace("-", "_")
                : null
            }
          );

          resolve(newphoto);
        } catch (err) {
          reject({
            errorMessage: err,
            filename: file.name
          });
        }
      });
    },
    [selectedPropertyId]
  );

  return (
    <PhotosContext.Provider
      value={{
        uploadImage,
        uploading,
        setUploading,
        photos,
        getError,
        getPhotos,
        getAppliancesPhotos,
        selectedPropertyId
      }}
    >
      {children}
    </PhotosContext.Provider>
  );
};
