import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import "./RangePicker.scss";

import { differenceInMonths, format } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-dates";
import { isMobile } from "react-device-detect";

import { ReactComponent as ArrowIcon } from "../../../../assets/icons/arrow.svg";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";

const BIG_DESKTOP_WIDTH = 1350;

export const RangePicker = ({
  toggleModal,
  getGraphData,
  setSelectedRange,
  sortedFrom
}) => {
  const [focused, setFocused] = useState(null);
  const [customDates, setCustomDates] = useState({
    start: moment(new Date()).subtract(6, "months"),
    end: moment(new Date())
  });
  const today = new Date();
  const { width } = useWindowDimensions();

  const maxDate = new Date(today.getFullYear(), 11, 31);

  const minDate = sortedFrom
    ? new Date(sortedFrom.getFullYear(), 0, 1)
    : new Date(today.getFullYear - 1, 0, 1);

  useEffect(() => {
    if (customDates.start !== null && customDates.end !== null) {
      const from_date = format(customDates.start._d, "yyyy-MM-dd");
      const to_date = format(customDates.end._d, "yyyy-MM-dd");
      const months = differenceInMonths(
        customDates.end.toDate(),
        customDates.start.toDate()
      );
      const dateRange = { from_date, to_date };

      setSelectedRange({
        months
      });

      getGraphData({ dateRange });
    }
  }, [getGraphData, customDates, setSelectedRange]);

  const handleCustomRangeSelect = async (startDate, endDate) => {
    setCustomDates({
      start: startDate,
      end: endDate
    });
  };

  return (
    <DateRangePicker
      startDate={customDates.start} // momentPropTypes.momentObj
      startDateId="startDate" // PropTypes.string.isRequired,
      endDate={customDates.end} // momentPropTypes.momentObj
      endDateId="endDate" // PropTypes.string.isRequired
      onDatesChange={({ startDate, endDate }) => {
        handleCustomRangeSelect(startDate, endDate);
      }} // PropTypes.func.isRequired,
      focusedInput={focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      onFocusChange={focusedInput => {
        setFocused(focusedInput);
      }} // PropTypes.func.isRequired,
      hideKeyboardShortcutsPanel={true}
      withPortal={isMobile ? true : false}
      isOutsideRange={date => date.isBefore(minDate) || date.isAfter(maxDate)}
      numberOfMonths={width < BIG_DESKTOP_WIDTH ? 1 : 2}
      initialVisibleMonth={() => {
        if (focused === "endDate" && customDates.end) {
          return customDates.end;
        }

        if (focused === "startDate" && customDates.start) {
          return customDates.start;
        }

        return moment(new Date());
      }}
      customArrowIcon={<ArrowIcon />}
      anchorDirection="right"
      displayFormat="DD/MM/YYYY"
      transitionDuration={0}
    />
  );
};
