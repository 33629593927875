import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  selectedPropertyLocalitySelector,
  selectedPropertyTaxLabelSelector
} from "../../data/properties/selectors";
import { formatAmountWithCurrency } from "../../utils/numbers";
import { hostNetEarningsForDisplay } from "../../utils/revenue";

const AIRBNB = "Airbnb";

const PayoutsSummary = ({ locality, revenue }) => {
  const {
    guest_cleaning_fee: guestCleaningFee,
    cleaning_fee_included_in_net: cleaningFeeIncluded
  } = revenue;

  const { t } = useTranslation("host.bookings");
  const money = amount => formatAmountWithCurrency(amount, locality);

  return (
    <div className="PriceBreakdown__summary-text">
      <p>
        {guestCleaningFee > 0 && !cleaningFeeIncluded && (
          <>
            {t("booking.breakdown.summary.cleaning_fee", {
              amount: money(guestCleaningFee)
            })}{" "}
          </>
        )}
      </p>
    </div>
  );
};

export const Revenue = ({ revenue, channelName }) => {
  const taxLabel = useSelector(selectedPropertyTaxLabelSelector);
  const locality = useSelector(selectedPropertyLocalitySelector);
  const { t } = useTranslation("host.bookings");

  const {
    total_booking_revenue: totalBookingRevenue,
    channel_commission_host: channelCommision,
    gross_host_booking_revenue: grossHostBookingRevenue,
    houst_commission: houstCommision,
    houst_commission_vat: houstCommissionVat,
    cleaning_fee_included_in_net: includeCleaningFee,
    guest_cleaning_fee: cleaningFee
  } = revenue;

  const netBookingRevenue = hostNetEarningsForDisplay(revenue);

  return (
    <>
      <table
        className="PriceBreakdown__breakdown"
        data-testid="multiplat-breakdown"
      >
        <tbody>
          <tr>
            <td className="section-label">
              {t("revenue.total_booking", { channelName })}
            </td>
            <td
              className="PriceBreakdown__breakdown__amount"
              data-testid="cleaning-fee"
            >
              {formatAmountWithCurrency(totalBookingRevenue, locality)}
            </td>
          </tr>

          <tr>
            <td className="section-label smaller">
              {t("revenue.includes_cleaning_fee")}
            </td>
            <td
              className="PriceBreakdown__breakdown__amount smaller"
              data-testid="including-cleaning-fee"
            >
              {formatAmountWithCurrency(cleaningFee, locality)}
            </td>
          </tr>

          <tr>
            <td className="section-label">
              {t("revenue.platform_charge", { channelName })}
            </td>
            <td
              className="PriceBreakdown__breakdown__amount"
              data-testid="airsorted-fee"
            >
              &minus;{formatAmountWithCurrency(channelCommision, locality)}
            </td>
          </tr>

          {channelName === AIRBNB && (
            <>
              <tr className="PriceBreakdown__breakdown__total">
                <td className="section-label">
                  {t("revenue.gross_host_booking_revenue", { channelName })}
                </td>
                <td
                  className="PriceBreakdown__breakdown__amount"
                  data-testid="airsorted-payout"
                >
                  {formatAmountWithCurrency(grossHostBookingRevenue, locality)}
                </td>
              </tr>
              <tr className="PriceBreakdown__breakdown__margin-row"></tr>
            </>
          )}

          <tr>
            <td className="section-label">
              {t("revenue.houst_management_fee")}
            </td>
            <td className="PriceBreakdown__breakdown__amount">
              &minus;{formatAmountWithCurrency(houstCommision, locality)}
            </td>
          </tr>

          <tr>
            <td className="section-label">{taxLabel}</td>
            <td className="PriceBreakdown__breakdown__amount">
              &minus;{formatAmountWithCurrency(houstCommissionVat, locality)}
            </td>
          </tr>

          {includeCleaningFee && (
            <>
              <tr>
                <td className="section-label">
                  {t("revenue.guest_cleaning_fee")}
                </td>
                <td
                  className="PriceBreakdown__breakdown__amount"
                  data-testid="cleaning_fee"
                >
                  &minus;{formatAmountWithCurrency(cleaningFee, locality)}
                </td>
              </tr>
            </>
          )}

          <tr className="PriceBreakdown__breakdown__total">
            <td className="section-label">{t("revenue.net_revenue")}</td>
            <td
              className="PriceBreakdown__breakdown__amount"
              data-testid="payout"
            >
              {formatAmountWithCurrency(netBookingRevenue, locality)}
            </td>
          </tr>

          <tr className="PriceBreakdown__breakdown__margin-row"></tr>
        </tbody>
      </table>

      <PayoutsSummary locality={locality} revenue={revenue} />
    </>
  );
};
