import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button from "../../../../components/buttons/Button";
import DefaultError from "../../../../components/common/DefaultError";
import Spinner from "../../../../components/Spinner";
import { upcomingCleansSelector } from "../../../../data/cleans/selectors";
import { getSelectedPropertyId } from "../../../../data/properties/selectors";
import { CLEANS_LIMIT, useGetUpcomingCleans } from "../useGetCleans";
import UpcomingClean from "./UpcomingClean";

export const UpcomingCleansList = ({ urlCleanId }) => {
  const { t } = useTranslation("host.cleans");

  const selectedPropertyId = useSelector(getSelectedPropertyId);
  const upcomingCleans = useSelector(state =>
    upcomingCleansSelector(state, selectedPropertyId)
  );
  const upcomingCleansCount = useMemo(
    () => upcomingCleans.length,
    [upcomingCleans]
  );
  const hasUpcomingCleans = useMemo(
    () => upcomingCleansCount > 0,
    [upcomingCleansCount]
  );

  const { loading, error, getUpcomingCleans, isFinal } =
    useGetUpcomingCleans(upcomingCleansCount);

  const handleLoadMore = () => {
    getUpcomingCleans(upcomingCleansCount);
  };

  return (
    <div className="CleansList">
      {!hasUpcomingCleans && <p>{t("cleans_list.upcoming_cleans_info")}</p>}

      {loading && upcomingCleansCount === 0 && <Spinner />}

      {hasUpcomingCleans &&
        upcomingCleans.map((clean, index) => (
          <UpcomingClean
            key={clean.id}
            clean={clean}
            index={index}
            urlCleanId={urlCleanId}
          />
        ))}

      {error && <DefaultError>{t("common:something_went_wrong")}</DefaultError>}

      {!isFinal && upcomingCleansCount !== 0 && (
        <Button
          outline
          type="button"
          small
          onClick={handleLoadMore}
          loading={loading}
        >
          {t("common:load_more")}
        </Button>
      )}

      {isFinal && upcomingCleansCount >= CLEANS_LIMIT && (
        <p>{t("cleans.no_more_upcoming")}</p>
      )}
    </div>
  );
};
