import "./AppUpdateBanner.scss";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { compose } from "redux";

import { getUpdateAvailable } from "../../data/reducers/app";

export class AppUpdateBanner extends Component {
  state = {
    dismissed: false
  };

  handleRefresh() {
    window.location.reload();
  }

  handleDismissNotification = () => {
    this.setState({
      dismissed: true
    });
  };

  render() {
    const { updateAvailable, t } = this.props;
    const { dismissed } = this.state;

    if (!updateAvailable || dismissed) {
      return false;
    }

    return (
      <TransitionGroup appear>
        <CSSTransition classNames="AppUpdateBanner__animation" timeout={300}>
          <div className="AppUpdateBanner">
            <p className="AppUpdateBanner__message">
              {t("nav.update_banner.message")}
            </p>

            <button
              className="AppUpdateBanner__refresh"
              type="button"
              onClick={this.handleRefresh}
            >
              {t("nav.update_banner.action")}
            </button>

            <button
              className="AppUpdateBanner__close"
              type="button"
              onClick={this.handleDismissNotification}
            >
              <CloseIcon />
            </button>
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default compose(
  withTranslation(),
  connect(state => ({
    updateAvailable: getUpdateAvailable(state)
  }))
)(AppUpdateBanner);
