import { get } from "lodash";

import { RECEIVE_BOOKINGS_COUNT } from "../action_types";

export const basePropertyState = () => ({
  hasAnyEarnings: false,
  ratings: null,
  bookingsCount: null
});

const cloneState = (state, propertyId) => ({
  ...(state.byProperty[propertyId] || basePropertyState())
});

const performance = (
  state = {
    byProperty: {}
  },
  action
) => {
  switch (action.type) {
    case "RECEIVE_HAS_ANY_EARNINGS": {
      const propertyId = action.propertyId;
      const perf = cloneState(state, propertyId);

      perf.hasAnyEarnings = action.hasAnyEarnings;

      return {
        ...state,
        byProperty: {
          ...state.byProperty,
          [propertyId]: perf
        }
      };
    }

    case "RECEIVE_RATINGS": {
      if (!Object.keys(action.ratings).length) {
        return state;
      }

      const propertyId = action.propertyId;
      const perf = cloneState(state, propertyId);

      perf.ratings = action.ratings;

      return {
        ...state,
        byProperty: {
          ...state.byProperty,
          [propertyId]: perf
        }
      };
    }

    case RECEIVE_BOOKINGS_COUNT: {
      const { bookingsCount, propertyId } = action;
      const { byProperty } = state;

      return {
        ...state,
        byProperty: {
          ...byProperty,
          [propertyId]: {
            ...byProperty[propertyId],
            bookingsCount: bookingsCount ? bookingsCount : 0
          }
        }
      };
    }

    case "RECEIVE_OCCUPANCY": {
      const { occupancy, propertyId } = action;
      const { byProperty } = state;

      return {
        ...state,
        byProperty: {
          ...byProperty,
          [propertyId]: {
            ...byProperty[propertyId],
            occupancy: occupancy ? occupancy : 0
          }
        }
      };
    }

    case "RECEIVE_EARNINGS": {
      const { earnings, propertyId } = action;
      const { byProperty } = state;

      const netEarnings = earnings
        .map(item => get(item, "data.net_earnings_sum", 0))
        .reduce((total, currentValue) => total + currentValue);

      return {
        ...state,
        byProperty: {
          ...byProperty,
          [propertyId]: {
            ...byProperty[propertyId],
            earnings: netEarnings
          }
        }
      };
    }

    default:
      return state;
  }
};

export default performance;
