import { Form } from "formik";
import React from "react";

import { useHomeInfoContext } from "../HomeInfoContext";

export const HomeInfoForm = props => {
  const {
    data: { can_edit }
  } = useHomeInfoContext();

  return (
    <Form className="HomeInfoForm">
      <fieldset disabled={!can_edit}>{props.children}</fieldset>
    </Form>
  );
};
