import { getSelectedProperty } from "data/properties/selectors";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Card from "../../../../components/common/Card";

export const Credit = () => {
  const { t } = useTranslation("host.billing");
  const { billing_account: billingAccount } = useSelector(getSelectedProperty);
  const availableCredit = billingAccount?.available_credit;

  if (availableCredit && availableCredit > 0) {
    const creditFormatted = billingAccount?.available_credit_formatted;

    return (
      <Card title={t("available_credit")}>
        <p className="Billing__balance-info">{creditFormatted}</p>
      </Card>
    );
  }

  return null;
};
