import "./CleanInstructions.scss";

import Button from "components/buttons/Button";
import { ErrorText } from "components/common/Input";
import { receivePreferences } from "data/actions/preferences";
import {
  FRIDGE_CLEANING_LEAVE_ALL,
  FRIDGE_CLEANING_REMOVE_ALL,
  FRIDGE_CLEANING_REMOVE_PERISHABLES
} from "data/cleans/types";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import api from "services/api";
import { optionsArray } from "utils/preferences";
import * as yup from "yup";

class CleanPreferenceForm extends Component {
  state = {
    fridge_cleaning: ""
  };

  constructor(props) {
    super(props);

    const { t } = props;

    this.validationSchema = yup.object().shape({
      fridge_cleaning: yup
        .string()
        .oneOf(
          [
            null,
            FRIDGE_CLEANING_LEAVE_ALL,
            FRIDGE_CLEANING_REMOVE_PERISHABLES,
            FRIDGE_CLEANING_REMOVE_ALL
          ],
          t("host.cleans:form.instructions.select_fridge_cleaning")
        )
        .nullable()
    });
  }

  componentDidMount() {
    const { propertyId } = this.props;

    api.get(`/v1/hosts/properties/${propertyId}/preferences`).then(settings => {
      this.setState({ fridge_cleaning: settings.fridge_cleaning });
    });
  }

  submit = async (values, { setSubmitting, setErrors, resetForm }) => {
    const { t } = this.props;
    const { propertyId } = this.props;

    try {
      setSubmitting(true);
      await api
        .patch(`/v1/hosts/properties/${propertyId}/preferences`, null, values)
        .then(res => {
          this.props.receivePreferences(res, propertyId);
          this.setState(
            {
              fridge_cleaning: res.fridge_cleaning
            },
            () => {
              resetForm({
                values: {
                  fridge_cleaning: this.state.fridge_cleaning
                }
              });
            }
          );
        })
        .catch(e => {
          if (e.error.code === 400) {
            setErrors(e.error.message);
          } else {
            setErrors({ formSubmit: t("login.incorrect_details") });
          }
        });
    } finally {
      setSubmitting(false);
    }
  };
  render() {
    const { t } = this.props;
    const { fridge_cleaning } = this.state;

    return (
      <div className="CleanInstructions__settings">
        <Formik
          validationSchema={this.validationSchema}
          onSubmit={this.submit}
          enableReinitialize={true}
          initialValues={{ fridge_cleaning }}
          render={({ values, isSubmitting, isValid, errors }) => (
            <Form className="CleanInstructions__form" noValidate>
              <div className="CleanInstructions__form__field">
                <div className="form__group">
                  <label htmlFor={"fridge_cleaning"}>
                    {t("settings.fridge_cleaning_title")}
                  </label>
                  <div className="select-wrapper">
                    <Field
                      component="select"
                      name="fridge_cleaning"
                      value={values.fridge_cleaning || ""}
                    >
                      <option key="" value="" disabled>
                        {t("common:please_select")}
                      </option>
                      {optionsArray(
                        {
                          [FRIDGE_CLEANING_LEAVE_ALL]: t(
                            "settings.fridge_cleaning.leave_all"
                          ),
                          [FRIDGE_CLEANING_REMOVE_PERISHABLES]: t(
                            "settings.fridge_cleaning.remove_perishables"
                          ),
                          [FRIDGE_CLEANING_REMOVE_ALL]: t(
                            "settings.fridge_cleaning.remove_all"
                          )
                        },
                        { includeEmptyOption: false }
                      )}
                    </Field>
                  </div>
                </div>
              </div>

              <ErrorMessage name="fridge_cleaning" component={ErrorText} />

              {errors.formSubmit && <ErrorText>{errors.formSubmit}</ErrorText>}
              <Button
                disabled={isSubmitting}
                type="submit"
                data-cy="clean-pref-submit"
              >
                {t("common:save")}
              </Button>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default connect(state => ({}), { receivePreferences })(
  CleanPreferenceForm
);
