import "./Shapes.scss";

import React from "react";

import bankAccountImageUrl from "../../assets/images/availability.png";
import meetingImageUrl from "../../assets/images/book-a-meeting.png";
import checklistImageUrl from "../../assets/images/checklist.png";
import directDebitImageUrl from "../../assets/images/direct-debit.png";
import londonImageUrl from "../../assets/images/london.png";
import personalImageUrl from "../../assets/images/personal-details.png";
import termsImageUrl from "../../assets/images/terms.png";

const images = {
  personal: personalImageUrl,
  london: londonImageUrl,
  terms: termsImageUrl,
  bankAccount: bankAccountImageUrl,
  meeting: meetingImageUrl,
  checklist: checklistImageUrl,
  direct: directDebitImageUrl
};

export const ShapedImage = ({ containerClassName, imgId }) => (
  <div className={`${containerClassName} Signup__image`}>
    <img src={images[imgId]} className="clipped-image" alt="Personal details" />
  </div>
);

const Shapes = () => (
  <svg className="svg-defs">
    <defs>
      <clipPath id="personal-shape" className="shape">
        <path
          d="M548.560254,860.611822 L550,-3.97903932e-13 C444.876801,117.148747 390.416612,175.723121 386.619435,175.723121 L32.9378883,175.723121 C32.9378883,175.723121 0,175.723121 0,208.649401 L0,637.417942 C0,637.417942 0,670.342409 32.9378883,670.362349 L271.3876,670.49649 C271.3876,670.49649 304.323675,670.514618 304.379889,703.440898 L304.379889,860.611822 L548.560254,860.611822 Z"
          id="path-1"
        ></path>
      </clipPath>

      <clipPath id="property-details-shape" className="shape">
        <path
          d="M550,812.978142 L548.497268,-2.27373675e-13 C444.592316,60.9531097 390.738478,91.4296645 386.935753,91.4296645 L34.1605839,91.4296645 C34.1605839,91.4296645 0,91.4296645 0,125.446259 L0,535.849734 C0,535.849734 0,569.873819 34.1605839,570.018029 L270.410999,571.029368 C270.410999,571.029368 304.571583,571.173578 304.391038,605.192045 L304.391038,812.978142 L550,812.978142 Z"
          id="path-1"
        ></path>
      </clipPath>

      <clipPath id="terms-shape" className="shape">
        <path
          d="M550,0.8359375 L221.398312,319.907716 C206.708834,334.777368 199.364094,352.2909 199.364094,372.448312 C199.364094,392.605724 199.364094,419.573514 199.364094,453.351682 C199.148108,473.9437 188.85096,484.239709 168.472651,484.239709 C148.094342,484.239709 102.233939,484.239709 30.891443,484.239709 C10.2971477,489.192768 -5.68434189e-14,499.488777 -5.68434189e-14,515.127737 C-5.68434189e-14,530.766697 -5.68434189e-14,611.673467 -5.68434189e-14,757.848048 L550,757.848048 L550,0.8359375 Z"
          id="Path"
          fill="#000000"
        ></path>
      </clipPath>

      <clipPath id="direct-debit-shape" className="shape">
        <path
          d="M549.260753,0 L290.078397,0 C273.451924,3.57508203 265.138688,14.6477881 265.138688,33.2181181 C265.138688,51.7884481 265.138688,129.710561 265.138688,266.984456 C265.138688,290.260996 254.64834,301.899266 233.667644,301.899266 C212.686949,301.899266 143.879125,301.899266 27.2441741,301.899266 C9.08139136,306.999143 0,317.617525 0,333.754413 C0,349.8913 0,489.491084 0,752.553763 L549.260753,752.553763 L549.260753,0 Z"
          id="Path-2"
          fill="#000000"
        ></path>
      </clipPath>

      <clipPath id="bank-account-shape" className="shape">
        <path
          d="M550,-1.36424205e-12 L275,-1.36424205e-12 C258.680669,-1.36424205e-12 244.472997,5.35504481 232.376985,16.0651344 C220.280973,26.7752241 143.725014,103.126445 2.7091075,245.118798 C-2.42593523,253.224556 1.61952544,257.277434 14.8454895,257.277434 C28.0714536,257.277434 78.6682933,257.277434 166.636009,257.277434 C182.3545,257.277434 190.213746,264.770954 190.213746,279.757993 C190.213746,294.745032 190.213746,411.550667 190.213746,630.174897 L550,630.174897 L550,-1.36424205e-12 Z"
          id="Path-3"
          fill="#000000"
        ></path>
      </clipPath>

      <clipPath id="book-a-meeting-shape" className="shape">
        <path
          d="M183.296714,0 C183.296714,0 157.82875,0 139.820022,18.0449867 L83.141042,74.8147844 C83.141042,74.8147844 65.132314,92.848535 65.132314,118.36545 L65.132314,426.124607 C65.132314,426.124607 65.132314,436.897105 54.376712,436.897105 L25.4679644,436.897105 C25.4679644,436.897105 0,436.897105 0,462.414019 L0,514.276502 C0,516.695173 183.333333,517.904509 550,517.904509 L550,2.91777188 L183.296714,0 Z"
          id="path-1"
        ></path>
      </clipPath>

      <clipPath id="checklist-shape" className="shape">
        <path
          d="M550.268617,4.54747351e-13 L252.017228,288.33435 C234.686113,305.542721 226.020556,322.502662 226.020556,339.214172 C226.020556,355.925682 226.020556,392.125755 226.020556,447.81439 C222.150797,466.342643 211.390203,475.60677 193.738775,475.60677 C176.087346,475.60677 121.850754,475.60677 31.0289974,475.60677 C10.3429991,475.60677 5.68434189e-14,484.360188 5.68434189e-14,501.867023 C5.68434189e-14,519.373859 5.68434189e-14,613.418184 5.68434189e-14,784 L550.268617,784 L550.268617,4.54747351e-13 Z"
          id="Path-4"
          fill="#000000"
        ></path>
      </clipPath>
    </defs>
  </svg>
);

export default Shapes;
