import "./TermsGuard.scss";

import classNames from "classnames";
import { format } from "date-fns";
import { kebabCase } from "lodash";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { PLAN_TYPE_PART_TIME } from "utils/constants";

import { receiveProperty } from "../../data/actions/properties";
import {
  getSelectedProperty,
  getSelectedPropertyId,
  selectedPropertyLatestBillingPolicySelector
} from "../../data/properties/selectors";
import { propertyNeedsTerms } from "../../data/property_states";
import api from "../../services/api";
import { useWindowDimensions } from "../../utils/useWindowDimensions";
import Button from "../buttons/Button";
import Modal from "../common/modal";
import { TermsPartTime } from "../common/TermsPartTime";
import { TermsTable } from "../common/TermsTable";

const PRICING_CALENDLY_URL =
  "https://calendly.com/host-calls-houst/pricing-and-subscription";
const COUNTRIES_WITH_CALENDLY = ["GB", "IE"];

export const TermsGuard = () => {
  const { t } = useTranslation("host");
  const property = useSelector(getSelectedProperty);
  const propertyId = useSelector(getSelectedPropertyId);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();

  const billingPolicy = useSelector(
    selectedPropertyLatestBillingPolicySelector
  );
  const { plan_type: planType } = billingPolicy;

  const dispatch = useDispatch();
  const { isLargeScreen } = useWindowDimensions();

  const isPartTimePlan = planType === PLAN_TYPE_PART_TIME;
  const showModal =
    propertyNeedsTerms(property) && !matchPath(`/contact`, pathname);

  const handleSubmit = useCallback(() => {
    setLoading(true);

    api
      .patch(`/v1/hosts/properties/${propertyId}`, null, {
        terms_accepted: format(Date.now(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
      })
      .then(res => {
        setLoading(false);
        dispatch(receiveProperty(res));
      })
      .catch(() => {
        setLoading(false);
      });
  }, [dispatch, propertyId]);

  return (
    <>
      {showModal && (
        <Modal
          nondisposable
          className="TermsGuardModal"
          title={
            <div className={classNames("Modal__header")}>
              <span className="tiny-header">
                {property.full_address} -&nbsp;
                {t("terms_modal.title_plan_name", {
                  planName: billingPolicy.plan_name
                })}
              </span>
              <h3 className={classNames({ "big-header": isLargeScreen })}>
                {t("terms_modal.title")}
              </h3>
            </div>
          }
          footer={
            <>
              {isPartTimePlan && <SwitchPlanButton />}
              <ContactUsButton />
              <Button
                type="button"
                data-testid="agree"
                loading={loading}
                onClick={handleSubmit}
              >
                {t("terms_modal.agree")}
              </Button>
            </>
          }
        >
          {isPartTimePlan ? <TermsPartTime updated /> : <TermsTable />}
        </Modal>
      )}
    </>
  );
};

export const SwitchPlanButton = () => {
  const { t } = useTranslation("host");
  const property = useSelector(getSelectedProperty);
  const city = property.locality?.name;

  const handleRedirectToPlans = useCallback(() => {
    const newWindow = window.open(
      `https://pages.houst.com/full-time-plan-${kebabCase(city)}`,
      "_blank",
      "noopener,noreferrer"
    );

    if (newWindow) {
      newWindow.opener = null;
    }
  }, [city]);

  return (
    <Button
      type="button"
      theme="plain"
      data-testid="contact-us"
      onClick={handleRedirectToPlans}
    >
      {t("terms_modal.switch_plan_button")}
    </Button>
  );
};

export const ContactUsButton = () => {
  const navigate = useNavigate();
  const property = useSelector(getSelectedProperty);
  const countryCode = property.locality?.country_code;
  const { t } = useTranslation("host");

  const handleRedirectToContact = useCallback(
    () => navigate("/contact"),
    [navigate]
  );

  const handleRedirectToCalendly = useCallback(() => {
    const newWindow = window.open(
      PRICING_CALENDLY_URL,
      "_blank",
      "noopener,noreferrer"
    );

    if (newWindow) {
      newWindow.opener = null;
    }
  }, []);

  return COUNTRIES_WITH_CALENDLY.includes(countryCode) ? (
    <Button
      type="button"
      theme="plain"
      data-testid="contact-us-calendly"
      onClick={handleRedirectToCalendly}
    >
      {t("terms_modal.contact_us")}
    </Button>
  ) : (
    <Button
      type="button"
      theme="plain"
      data-testid="contact-us"
      onClick={handleRedirectToContact}
    >
      {t("terms_modal.contact_us")}
    </Button>
  );
};
