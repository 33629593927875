import React, { Suspense, lazy } from "react";

import Spinner from "../../../../components/Spinner";

const Calendar = lazy(() => import("./Calendar"));

const CalendarView = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Calendar />
    </Suspense>
  );
};

export default CalendarView;
