import "./StepFinish.scss";

import { ReactComponent as HeartHomeIcon } from "assets/icons/heart_home.svg";
import Button from "components/buttons/Button";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { useOnboardingContext } from "../../SignupContext";

export const StepFinish = () => {
  const { t } = useTranslation("host.signup");
  const {
    loading,
    handleNext,
    data: {
      host: { first_name: name },
      property: { address }
    }
  } = useOnboardingContext();

  return (
    <div className="StepFinish">
      <HeartHomeIcon />

      <Trans ns="host.signup" i18nKey="finish.header">
        <h2 className="Signup__header" data-testid="congrats-header">
          Congratulations {{ name }},<br />
          you've just set up your property!
        </h2>
      </Trans>

      <Trans ns="host.signup" i18nKey="finish.subheader">
        <h4 className="Signup__subheader">
          <span className="bold">{{ address }}</span>
          will be ready to welcome guests soon.
        </h4>
      </Trans>

      <p>{t("finish.are_you_ready")}</p>

      <Button type="button" loading={loading} onClick={handleNext}>
        {t("finish.button")}
      </Button>
    </div>
  );
};
