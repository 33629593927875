import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectedPropertyLocalitySelector } from "../properties/selectors";
import { selectedPropertyLocalRegulationsApproval } from "./selectors";

const GENERAL_MINIMUM_NIGHTS = 2;
const DUBLIN_LIMITED_MINIMUM_NIGHTS = 14;
const DUBLIN = "Dublin";

export const useAllowedMinimumNights = () => {
  const locality = useSelector(selectedPropertyLocalitySelector);
  const localRegulationsApproval = useSelector(
    selectedPropertyLocalRegulationsApproval
  );

  const allowedMinimum = useMemo(() => {
    if (locality.name === DUBLIN && !localRegulationsApproval) {
      return DUBLIN_LIMITED_MINIMUM_NIGHTS;
    }

    return GENERAL_MINIMUM_NIGHTS;
  }, [localRegulationsApproval, locality.name]);

  return allowedMinimum;
};
