import {
  RECEIVE_PROPERTIES,
  RESUME_PROPERTY_SETUP,
  SELECT_PROPERTY,
  SKIP_PROPERTY_SETUP
} from "../action_types";

export const receiveProperties = properties => ({
  type: RECEIVE_PROPERTIES,
  properties
});

export const receiveProperty = property => ({
  type: RECEIVE_PROPERTIES,
  properties: [property]
});

export const selectProperty = propertyId => ({
  type: SELECT_PROPERTY,
  propertyId:
    typeof propertyId === "object" ? propertyId.id : parseInt(propertyId, 10)
});

export const skipPropertySetup = () => ({ type: SKIP_PROPERTY_SETUP });

export const resumePropertySetup = propertyId => ({
  type: RESUME_PROPERTY_SETUP,
  propertyId
});
