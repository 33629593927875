import "./ContactForm.scss";

import Button from "components/buttons/Button";
import { Field, Formik } from "formik";
import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import config from "../../../../config";
import { getSelectedProperty } from "../../../../data/properties/selectors";
import { getProfile } from "../../../../data/reducers/user";
import { findPrimaryEmail } from "../../../../utils/findPrimaryEmail";
import {
  CATEGORIES_WITHOUT_SECONDARY,
  CONTACT_REASONS_MAIN,
  CONTACT_REASONS_SECONDARY
} from "./contact_reasons";

const ContactForm = ({ formInitialValues, formSubmitRef }) => {
  const formRef = useRef(null);
  const { t } = useTranslation("host.contact");
  const profile = useSelector(getProfile);
  const { home_code } = useSelector(getSelectedProperty);

  const { FRONT_FORM_ACTION_URL } = config;

  const initialValues = useMemo(() => {
    const email = findPrimaryEmail(profile.emails);

    return {
      name: `${profile.first_name} ${profile.last_name}`,
      email: email?.address,
      body: formInitialValues.body,
      categoryId: formInitialValues.categoryId,
      subcategoryId: formInitialValues.subcategoryId
    };
  }, [
    profile.emails,
    profile.first_name,
    profile.last_name,
    formInitialValues.body,
    formInitialValues.categoryId,
    formInitialValues.subcategoryId
  ]);

  const handleSubmit = () => {
    // This is to trigger an oldschool synchronous POST request
    // We want to redirect to url in form's action attribute
    formRef.current.submit();
  };

  const generateSubject = values => {
    const categoriesPart = [values["categoryId"], values["subcategoryId"]]
      .filter(Boolean)
      .join(" / ");
    return `${home_code}: ${categoriesPart}`;
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {({ values }) => (
        <form
          ref={formRef}
          name="fa-form-1"
          action={FRONT_FORM_ACTION_URL}
          className="ContactForm"
          method="POST"
          encType="multipart/form-data"
          acceptCharset="utf-8"
        >
          <input
            type="hidden"
            id="home-code"
            name="home-code"
            value={home_code}
          />

          <input
            type="hidden"
            id="subject"
            name="subject"
            value={generateSubject(values)}
            data-testid="subject-field"
          />

          <div className="ContactForm__group">
            <label htmlFor="name">{t("form.label_name")}</label>

            <Field
              id="name"
              maxLength="80"
              name="name"
              size="20"
              component="input"
              placeholder="John Smith"
              required
              data-testid="name-field"
            />
          </div>

          <div className="ContactForm__group">
            <label htmlFor="email">{t("form.label_email")}</label>

            <Field
              id="email"
              maxLength="80"
              name="email"
              size="20"
              component="input"
              required
              data-testid="email-field"
            />
          </div>

          <div className="ContactForm__group">
            <label htmlFor="categoryId">{t("form.label_reason")}</label>
            <div className="select-wrapper">
              <Field
                component="select"
                id="categoryId"
                name="categoryId"
                title="Contact Category"
                required
                data-testid="main-category-field"
                data-cy="contact-category"
              >
                <option value="" hidden>
                  {t("form.reason.default")}
                </option>
                {CONTACT_REASONS_MAIN.map(({ label, value }) => (
                  <option key={label} value={value}>
                    {t(`form.reason.${label}`)}
                  </option>
                ))}
              </Field>
            </div>
          </div>

          {values["categoryId"] &&
            !CATEGORIES_WITHOUT_SECONDARY.includes(values["categoryId"]) && (
              <div className="ContactForm__group">
                <label htmlFor="subcategoryId">
                  {t("form.label_reason_specific")}
                </label>

                <div className="select-wrapper">
                  <Field
                    component="select"
                    id="subcategoryId"
                    data-testid="subcategory-field"
                    name="subcategoryId"
                    title="Contact Sub Category"
                    required
                  >
                    <option value="" hidden>
                      {t("form.reason.default")}
                    </option>

                    {CONTACT_REASONS_SECONDARY[values["categoryId"]].map(
                      ({ label, value }) => (
                        <option key={label} value={value}>
                          {t(`form.reason.secondary.${label}`)}
                        </option>
                      )
                    )}
                  </Field>
                </div>
              </div>
            )}

          <div className="ContactForm__group">
            <label htmlFor="message">{t("form.label_message")}</label>

            <Field
              component="textarea"
              name="body"
              id="body"
              placeholder={t("form.message_placeholder")}
              required
              data-testid="body-field"
            />
          </div>

          <div
            ref={formSubmitRef}
            className="ContactForm__buttons"
            data-cy="contact-form-buttons"
          >
            <Button type="submit">{t("form.submit")}</Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ContactForm;
