import React from "react";

const GuestAirbnbLink = ({ guestId, children }) => {
  const AIRBNB_PROFILE_URL = "https://www.airbnb.com/users/show";

  if (guestId) {
    return (
      <a
        href={`${AIRBNB_PROFILE_URL}/${guestId}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {children}
      </a>
    );
  }

  return children;
};

export default GuestAirbnbLink;
