import React, { Children, Component, cloneElement } from "react";
import { useLocation } from "react-router-dom";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.child.scrollTop = 0;
    }
  }

  render() {
    const child = Children.only(this.props.children);

    return cloneElement(child, {
      ref: el => (this.child = el)
    });
  }
}

function withLocation(Component) {
  return props => <Component {...props} location={useLocation()} />;
}

export default withLocation(ScrollToTop);
