import { countries } from "countries-list";
import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import Button from "../../../../components/buttons/Button";
import DefaultError from "../../../../components/common/DefaultError";
import { FieldWithTermsLock } from "../../common/FieldWithTermsLock";
import { sortedCountryCodes } from "../../common/utils";
import { ShapedImage } from "../../Shapes";
import { useOnboardingContext } from "../../SignupContext";
import NavButtonsWrapper from "../NavButtonsWrapper";

const validationSchema = yup.object().shape({
  street: yup.string().required(),
  postcode: yup.string(),
  city: yup.string().required(),
  country: yup.string().required()
});

export const StepProperty = () => {
  const { t } = useTranslation("host.signup");
  const {
    loading,
    handlePrev,
    handleSubmitAndNavigateNext,
    data: {
      property: { street, city, country, postcode },
      host: { first_name }
    }
  } = useOnboardingContext();

  const initialValues = {
    street: street || "",
    city: city || "",
    postcode: postcode || "",
    country: countries[country] ? country : ""
  };

  return (
    <React.Fragment>
      <div className="StepProperty Signup__step">
        <h2 className="Signup__header" data-testid="property-details-header">
          {t("property.header", { first_name })}
        </h2>
        <h4 className="Signup__subheader">{t("property.subheader")}</h4>

        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmitAndNavigateNext}
          enableReinitialize
        >
          <Form className="Signup__form">
            <label>
              {t("property.address")}
              <FieldWithTermsLock
                type="text"
                name="street"
                data-testid="property-address-input"
              />
              <ErrorMessage name="street" component={DefaultError} />
            </label>

            <label>
              {t("property.city")}
              <FieldWithTermsLock
                name="city"
                type="text"
                data-testid="city-input"
              />
              <ErrorMessage name="city" component={DefaultError} />
            </label>

            <div className="Signup__form__postcode">
              <label>
                {t("property.postcode")}
                <FieldWithTermsLock
                  name="postcode"
                  type="text"
                  data-testid="property-postcode-input"
                />
                <ErrorMessage name="address" component={DefaultError} />
              </label>

              <label>
                {t("property.country")}
                <div className="select-wrapper">
                  <FieldWithTermsLock
                    name="country"
                    component="select"
                    data-testid="country-select"
                  >
                    <option disabled value="" />
                    {sortedCountryCodes.map(countryCode => (
                      <option key={countryCode} value={countryCode}>
                        {countries[countryCode].name}
                      </option>
                    ))}
                  </FieldWithTermsLock>
                </div>
                <ErrorMessage name="country" component={DefaultError} />
              </label>
            </div>

            <NavButtonsWrapper
              next={
                <Button type="submit" loading={loading}>
                  {t("common:next")}
                </Button>
              }
              prev={
                <Button type="button" theme="plain" onClick={handlePrev}>
                  {t("common:previous")}
                </Button>
              }
            />
          </Form>
        </Formik>
      </div>

      <ShapedImage imgId="london" containerClassName="StepProperty" />
    </React.Fragment>
  );
};
