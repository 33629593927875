import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { NETWORK_ERROR_MESSAGE } from "../../services/api";
import { Error } from "./Error.jsx";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { error, children, t } = this.props;
    const { hasError } = this.state;

    if (
      hasError ||
      error?.error === NETWORK_ERROR_MESSAGE ||
      error?.statusCode === 404
    ) {
      return <Error title={t("common:problem_fetching_data")} />;
    }

    return children;
  }
}

export const FormErrorBoundary = withTranslation()(ErrorBoundary);
