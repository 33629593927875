import * as yup from "yup";

export const validationSchema = ({ isAccountTypeIban, countryCode, t }) => {
  if (isAccountTypeIban) {
    return yup.object().shape({
      owner_name: yup.string().required(
        t("bank_account.validation.required", {
          fieldType: "account owner name"
        })
      ),
      iban: yup
        .string()
        .matches(
          /^[A-Za-z]{2}\d{2}[\s*A-Za-z\d]{1,30}$/,
          t("bank_account.validation.iban")
        )
        .required(
          t("bank_account.validation.required", {
            fieldType: "IBAN"
          })
        ),
      bic: yup.string(/^(\d){8}$/)
    });
  } else {
    const fieldsByCountryCode = getValidationFieldsByCountryCode(
      countryCode,
      t
    );

    return yup.object().shape({
      owner_name: yup.string().required(
        t("bank_account.validation.required", {
          fieldType: "account owner name"
        })
      ),
      ...fieldsByCountryCode
    });
  }
};

const getValidationFieldsByCountryCode = (countryCode, t) => {
  switch (countryCode) {
    case "AU":
      return {
        account_number: yup
          .string()
          .matches(
            /^(\s*-?\d){6,9}$/,
            t("bank_account.validation.account_number.au")
          )
          .required(
            t("bank_account.validation.required", {
              fieldType: "account number"
            })
          ),
        sort_code: yup
          .string()
          .matches(/^(\s*-?\d){6}$/, t("bank_account.validation.bsb"))
          .required(
            t("bank_account.validation.required", {
              fieldType: "sort_code"
            })
          )
      };
    case "NZ":
      return {
        account_number: yup
          .string()
          .matches(
            /^(\s*-?\d){15,16}$/,
            t("bank_account.validation.account_number.nz")
          )
          .required(
            t("bank_account.validation.required", {
              fieldType: "account number"
            })
          )
      };
    case "CA":
      return {
        interac_email: yup
          .string()
          .email()
          .required(
            t("bank_account.validation.required", {
              fieldType: "email"
            })
          )
      };
    case "ZA":
      return {
        account_number: yup
          .string()
          .matches(
            /^(\s*-?\d){8,11}$/,
            t("bank_account.validation.account_number.za")
          )
          .required(
            t("bank_account.validation.required", {
              fieldType: "account number"
            })
          ),
        sort_code: yup
          .string()
          .matches(
            /^(\s*-?\d){6}$/,
            t("bank_account.validation.sort-code.branch")
          )
          .required(
            t("bank_account.validation.required", {
              fieldType: "branch code"
            })
          )
      };
    default:
      return {
        account_number: yup
          .string()
          .matches(
            /^(\s*-?\d){8}$/,
            t("bank_account.validation.account_number")
          )
          .required(
            t("bank_account.validation.required", {
              fieldType: "account number"
            })
          ),
        sort_code: yup
          .string()
          .matches(/^(\s*-?\d){6}$/, t("bank_account.validation.sort-code"))
          .required(
            t("bank_account.validation.required", {
              fieldType: "sort code"
            })
          )
      };
  }
};
