import Button from "components/buttons/Button";
import StripeCard from "components/common/StripeCard";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BankCard from "scenes/dashboard/billing/components/BankCard";

export const CardForm = ({ card, stripe, onUpdate, setSubmitDisabled }) => {
  const { t } = useTranslation("host.setup");

  const [cardFormVisible, setCardFormVisible] = useState(false);

  const handleUpdate = visible => {
    onUpdate && onUpdate();
    setCardFormVisible(visible);
    setSubmitDisabled(false);
  };

  const handleUpdateCard = () => {
    setSubmitDisabled(true);
    setCardFormVisible(true);
  };

  const handleCancel = () => {
    setSubmitDisabled(false);
    setCardFormVisible(false);
  };

  if (card && !cardFormVisible) {
    return (
      <div>
        <BankCard cardDetails={card} />

        <Button onClick={handleUpdateCard} type="button">
          {t("host.billing:manage_card.update_title")}
        </Button>
      </div>
    );
  }

  const cancelButton = card && (
    <span className="Setup__card__cancelContainer">
      <Button outline onClick={handleCancel} type="button">
        {t("common:cancel")}
      </Button>
    </span>
  );

  return (
    <StripeCard
      submittingText={t("payment_method.adding_card")}
      submitText={t("payment_method.add_card")}
      submitTestID="setup-card-main-button"
      toggleUpdate={handleUpdate}
      cancelButton={cancelButton}
      stripe={stripe}
    />
  );
};
