import { isWithinInterval } from "date-fns";
import { createSelector } from "reselect";

const isCurrentBooking = b => {
  const today = new Date();

  return isWithinInterval(today, {
    start: new Date(b.checkin),
    end: new Date(b.checkout)
  });
};

export const previousBookingsSelector = (state, propertyId) => {
  return state.bookings.previous[propertyId]
    ? state.bookings.previous[propertyId].sort((a, b) => b.checkin - a.checkin)
    : [];
};

export const getCurrentBooking = (state, propertyId) => {
  return state.bookings?.upcoming[propertyId]
    ? state.bookings?.upcoming[propertyId].find(b => isCurrentBooking(b))
    : null;
};

export const upcomingBookingsSelector = createSelector(
  (state, propertyId) => state.bookings.upcoming[propertyId],
  upcoming =>
    upcoming
      ? upcoming
          .filter(b => !isCurrentBooking(b))
          .sort((a, b) => a.checkin - b.checkin)
      : []
);

export const getBookingCancellationPenalty = (state, booking) => {
  return state.bookings.penalties[booking.id];
};

export const bookingChannelCodeSelector = booking =>
  booking.booking_channel?.code || null;
