import "./PerformanceChart.scss";

import classNames from "classnames";
import React from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryStack,
  VictoryTheme,
  VictoryTooltip
} from "victory";

import styles from "../../../../utils/style_constants";
import PerformanceChartTooltip from "./PerformanceChartTooltip";
import { formatPerformanceAmount, formatPerformanceMonthTick } from "./utils";

const primaryColor = styles.colors.blue;
const secondaryColor = styles.colors.lightBlue;
const CHART_HEIGHT = 295;
const CHART_WIDTH = 600;

const PerformanceChart = ({
  data,
  locality,
  dateRange,
  shouldAnimate,
  isOverviewChart,
  sample
}) => {
  const labelComponent = (
    <VictoryTooltip flyoutComponent={<PerformanceChartTooltip data={data} />} />
  );
  const isWideRange = dateRange.months > 12;

  const labelsStyles = {
    tickLabels: {
      fontFamily: styles.fonts.paragraph,
      fontSize: 12,
      color: styles.colors.black
    }
  };

  const barStyles = {
    strokeWidth: isWideRange ? 0 : 1,
    width: isWideRange ? 7 : 30
  };

  return (
    <div
      className={classNames("PerformanceChart", {
        PerformanceChart__overview: isOverviewChart,
        "PerformanceChart--sample": sample
      })}
    >
      <VictoryChart
        animate={
          shouldAnimate ? { duration: 500, easing: "sinOut" } : undefined
        }
        width={CHART_WIDTH}
        height={CHART_HEIGHT}
        domainPadding={{ x: 16 }}
        padding={{ left: 60, top: 20, right: 10, bottom: 60 }}
        theme={VictoryTheme.material}
      >
        <VictoryAxis
          dependentAxis
          tickFormat={y =>
            formatPerformanceAmount({
              locality,
              amount: y,
              hasDecimals: false
            })
          }
          style={{
            ...labelsStyles,
            axis: { stroke: "none" },
            ticks: { stroke: "none" },
            grid: {
              stroke: styles.colors.darkElephant,
              strokeWidth: 0.5,
              strokeLinecap: "round",
              strokeDasharray: "1, 5"
            }
          }}
        />

        <VictoryStack>
          <VictoryBar
            labelComponent={labelComponent}
            cornerRadius={4}
            style={{
              data: {
                fill: ({ datum }) =>
                  datum.type === "exact" ? primaryColor : secondaryColor,
                stroke: ({ datum }) =>
                  datum.type === "exact" ? primaryColor : secondaryColor,
                ...barStyles
              }
            }}
            data={data.net_earnings}
          />
        </VictoryStack>

        <VictoryAxis
          tickCount={isWideRange ? 18 : undefined}
          tickLabelComponent={
            isWideRange ? (
              <VictoryLabel angle={45} dy={-6} textAnchor="start" />
            ) : undefined
          }
          tickFormat={tick => formatPerformanceMonthTick({ tick })}
          style={{
            ...labelsStyles,
            axis: { stroke: styles.colors.darkElephant, strokeWidth: 1 },
            grid: {
              stroke: "none"
            }
          }}
        />
      </VictoryChart>
    </div>
  );
};

export default PerformanceChart;
