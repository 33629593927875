import "./DublinRegulationBanner.scss";

import Button from "components/buttons/Button";
import { ErrorMessage, Field, Form, Formik } from "formik";
import i18n from "i18n";
import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PLAN_TYPE_PART_TIME } from "utils/constants";
import * as yup from "yup";

import { receivePreferences } from "../../data/actions/preferences";
import {
  getPreferences,
  selectedPropertyLocalRegulationsApproval
} from "../../data/preferences/selectors";
import {
  getSelectedProperty,
  getSelectedPropertyId,
  selectedPropertyLocalitySelector
} from "../../data/properties/selectors";
import { MinimumNightsTooltip } from "../../scenes/dashboard/calendar/components/MinimumNightsTooltip.jsx";
import api from "../../services/api";
import Card from "../common/Card.jsx";
import DefaultError from "../common/DefaultError.jsx";
import LinkOut from "../common/LinkOut";

const REGULATION_URL =
  "https://www.houst.com/blog/short-term-regulation-dublin";
const DUBLIN = "Dublin";
const YES = "yes";
const NO = "no";

const validationSchema = yup.object().shape({
  local_regulations_approval: yup
    .string()
    .required(i18n.t("common:errors.field_required")),
  minimum_nights: yup.number().when("local_regulations_approval", {
    is: YES,
    then: yup.number().integer().nullable().min(2),
    otherwise: yup.number().integer().nullable().min(14)
  })
});

export const DublinRegulationBanner = () => {
  const { active_billing_policy } = useSelector(getSelectedProperty);
  const { settings } = useSelector(getPreferences);
  const isPartTime = active_billing_policy?.plan_type === PLAN_TYPE_PART_TIME;

  const { t } = useTranslation("host.overview");
  const locality = useSelector(selectedPropertyLocalitySelector);
  const selectedPropertyId = useSelector(getSelectedPropertyId);
  const propertyRegulationsApproval = useSelector(
    selectedPropertyLocalRegulationsApproval
  );

  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    ({ local_regulations_approval, minimum_nights }, { setSubmitting }) => {
      const boolMap = { [YES]: true, [NO]: false };

      const params = {
        local_regulations_approval: boolMap[local_regulations_approval],
        minimum_nights: minimum_nights === "" ? null : minimum_nights
      };

      api
        .patch(
          `/v1/hosts/properties/${selectedPropertyId}/preferences`,
          null,
          params
        )
        .then(updatedPreferences => {
          dispatch(receivePreferences(updatedPreferences, selectedPropertyId));
        })
        .finally(() => setSubmitting(false));
    },
    [dispatch, selectedPropertyId]
  );

  if (
    propertyRegulationsApproval !== null ||
    locality.name !== DUBLIN ||
    !isPartTime
  ) {
    return null;
  }

  return (
    <Card
      title={t("ireland_airbnb_regulation.title")}
      className="DublinRegulationBanner__card"
    >
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          local_regulations_approval: "",
          minimum_nights: settings.minimum_nights || ""
        }}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form data-cy="regulations-form">
            <p>
              <Trans
                ns="host.overview"
                i18nKey="ireland_airbnb_regulation.content"
              >
                Your listing is currently restricted to hosting stays of 14
                nights or above. You can find out more about the Dublin hosting
                restrictions <LinkOut to={REGULATION_URL}>here</LinkOut>
              </Trans>
            </p>

            {t("ireland_airbnb_regulation.permission_question")}
            <div
              role="group"
              className="DublinRegulationBanner__group DublinRegulationBanner__radio"
            >
              <label>
                <Field
                  type="radio"
                  name="local_regulations_approval"
                  value={YES}
                />
                {t("common:yes")}
              </label>

              <label>
                <Field
                  type="radio"
                  name="local_regulations_approval"
                  value={NO}
                />
                {t("common:no")}
              </label>

              <ErrorMessage
                name="local_regulations_approval"
                component={DefaultError}
              />
            </div>

            <div className="DublinRegulationBanner__group">
              <label htmlFor="minimum_nights">
                {t("host.preferences:settings.pricing.minimum_nights")}
                <MinimumNightsTooltip
                  overrideAmount={
                    values.local_regulations_approval === YES ? "3" : "14"
                  }
                />

                <br />
                <Field
                  type="number"
                  name="minimum_nights"
                  placeholder={t(
                    "host.preferences:settings.pricing.minimum_nights_placeholder",
                    {
                      amount:
                        values.local_regulations_approval === YES ? "3" : "14"
                    }
                  )}
                  data-cy="minimum-nights-input"
                />
              </label>

              <ErrorMessage name="minimum_nights" component={DefaultError} />
            </div>

            <div className="DublinRegulationBanner__actions">
              <Button
                data-cy="regulations-submit"
                type="submit"
                disabled={isSubmitting || !isValid}
              >
                {t("airbnb_alert.submit_and_close")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};
