import { noop } from "lodash";

const PREFIX = "hd:";
const PERSIST = "--persist";
const AUTH_TOKEN_KEY = `${PREFIX}auth_token`;

function wrapStorage(storage) {
  const buildKey = key => `${PREFIX}${key}`;

  return {
    getItem: key => storage.getItem(buildKey(key)),

    setItem: (key, val) => storage.setItem(buildKey(key), val),

    removeItem: key => storage.removeItem(buildKey(key)),

    clear() {
      // Auth token has to be removed as last one, otherwise loop breaks as user gets logged out instantly
      const keys = Object.keys(storage).filter(key => key !== AUTH_TOKEN_KEY);

      for (const key of keys) {
        // If the item in storage is from the dashboard (starts with hd:) and doesn't contain the
        // persist flag then remove it on
        if (key.startsWith(PREFIX) && !key.endsWith(PERSIST)) {
          storage.removeItem(key);
        }
      }

      storage.removeItem(AUTH_TOKEN_KEY);
    }
  };
}

function isSupported(storage) {
  try {
    storage.setItem("ls-compat", "true");
    storage.removeItem("ls-compat");

    return true;
  } catch (error) {
    return false;
  }
}

export const NOOP_STORAGE = {
  getItem: noop,
  setItem: noop,
  removeItem: noop,
  clear: noop
};

export const localStorage = isSupported(window.localStorage)
  ? wrapStorage(window.localStorage)
  : NOOP_STORAGE;

export const sessionStorage = isSupported(window.sessionStorage)
  ? wrapStorage(window.sessionStorage)
  : NOOP_STORAGE;
