import "./Signup.scss";

import classNames from "classnames";
import React, { useMemo } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useUserWithProperties } from "utils/useUserWithProperties";

import Spinner from "../../components/Spinner.jsx";
import { needsOnboarding } from "../../data/reducers/user";
import { FormErrorBoundary } from "../errors/FormErrorBoundary";
import Shapes from "./Shapes";
import { OnboardingContextProvider } from "./SignupContext";
import { useOnboardingContext } from "./SignupContext";
import SignupProgress from "./SignupProgress";
import { AccessibleStepsRoutes } from "./SignupRoutes";
import SignupTopBar from "./top_bar/SignupTopBar";

const Loading = () => {
  const { t } = useTranslation();

  return (
    <div className="Signup__step--loading">
      <Spinner spin cover>
        <p>{t("common:loading")}</p>
      </Spinner>
    </div>
  );
};

const SignupStep = () => {
  const { data } = useOnboardingContext();

  // Don't render any step before initial data load is finished
  const loaded = data.property?.id;
  return loaded ? <AccessibleStepsRoutes /> : <Loading />;
};

export const SignupTemplate = () => {
  // Fetch property and user as some selectors are depending on current property in redux state
  const { loading: loadingProperty } = useUserWithProperties();
  const {
    loading: loadingSignupData,
    currentStep,
    error
  } = useOnboardingContext();
  const stepClass = `Signup__step__${currentStep.path}`;
  const needsSetup = useSelector(needsOnboarding);
  const shapesClassName = classNames("shapes-background", {
    "shapes-background-mobile": !currentStep.hideNavigation
  });

  const showProgressBar = useMemo(
    () => !currentStep.hideNavigation && !isMobile,
    [currentStep]
  );

  if (loadingSignupData || loadingProperty) {
    return <Loading />;
  }

  if (!needsSetup) {
    return <Navigate to={"/"} />;
  }

  return (
    <FormErrorBoundary error={error}>
      <div className={classNames("Signup", stepClass)}>
        <div className={shapesClassName} />
        <div
          className={classNames("Signup__layout-container", {
            middleSteps: !currentStep.hideNavigation
          })}
        >
          {!currentStep.hideNavigation && <SignupTopBar />}
          {showProgressBar && <SignupProgress />}
          <SignupStep />
        </div>
        <Shapes />
      </div>
    </FormErrorBoundary>
  );
};

export const Signup = () => (
  <OnboardingContextProvider>
    <SignupTemplate />
  </OnboardingContextProvider>
);
