import Spinner from "components/Spinner";
import { selectProperty } from "data/actions/properties";
import { logout } from "data/actions/user";
import { receiveLogin, setAdminUser } from "data/actions/user";
import { get } from "lodash";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "services/api";

const TokenLogin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { propertyId, token } = useParams();

  useEffect(() => {
    api
      .post("/v1/hosts/user/login", null, {
        type: "token",
        token
      })
      .then(res => {
        // Links from Airbase will contain the admin query param on this route
        // Set the user to be an Admin for the duration of the session
        if (get(location, "search", "").includes("admin")) {
          dispatch(setAdminUser());
        }

        dispatch(receiveLogin(res));
        propertyId && dispatch(selectProperty(propertyId));
        navigate("/login", { replace: true });
      })
      .catch(() => {
        dispatch(logout());
        navigate("/login", { replace: true });
      });
  }, [dispatch, location, navigate, propertyId, token]);

  return <Spinner spin cover />;
};

export default TokenLogin;
