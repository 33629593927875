import "./BookingDetails.scss";

import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { hasEarnings } from "../../data/reducers/bookings";
import { bookingChannelCodeSelector } from "../../data/selectors/bookings";
import { GuestsDetails } from "../GuestsBreakdown";
import { BookingDateTime, BookingLongDate, BookingShortDate } from "./Dates";
import PriceBreakdown from "./PriceBreakdown";

export const BookingDetails = ({
  booking,
  className,
  sidebar = false,
  sample,
  expanded,
  guestDetails = true
}) => {
  const { t } = useTranslation("host.bookings");

  const {
    checkin,
    checkout,
    checkin_time,
    checkout_time,
    airbnb_reservation_data: airbnbReservationData
  } = booking;

  return (
    <div
      className={
        className ? className : classNames("BookingDetails", { expanded })
      }
      data-cy="booking-details"
    >
      <div className="BookingDetails__content">
        <p className="section-label">{t("check_in")}</p>

        {sidebar ? (
          checkin_time ? (
            <BookingDateTime
              date={checkin}
              time={checkin_time}
              separator={t("booking.from")}
            />
          ) : (
            <BookingLongDate date={checkin} />
          )
        ) : (
          <BookingShortDate date={checkin} />
        )}
      </div>

      <div className="BookingDetails__content">
        <p className="section-label">{t("check_out")}</p>
        {sidebar ? (
          checkout_time ? (
            <BookingDateTime
              date={checkout}
              time={checkout_time}
              separator={t("booking.by")}
            />
          ) : (
            <BookingLongDate date={checkout} />
          )
        ) : (
          <BookingShortDate date={checkout} />
        )}
      </div>

      {guestDetails && (
        <GuestsDetails
          channelCode={bookingChannelCodeSelector(booking)}
          airbnbReservationData={airbnbReservationData}
          className="BookingDetails__content"
        />
      )}

      {expanded && hasEarnings(booking) && (
        <div className="BookingDetails__content breakdown">
          <PriceBreakdown bookingId={booking.id} sample={sample} />
        </div>
      )}
    </div>
  );
};
