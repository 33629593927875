import { getSelectedProperty } from "data/properties/selectors";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";
import Card from "../../../../components/common/Card";
import Tip from "../../../../components/common/Tip";

export const Deposit = () => {
  const { t } = useTranslation("host.billing");
  const { billing_account: billingAccount } = useSelector(getSelectedProperty);

  if (!billingAccount) {
    return null;
  }

  const {
    deposit_amount: depositAmount,
    deposit_amount_formatted: depositFormatted
  } = billingAccount;

  if (!Boolean(depositAmount)) {
    return null;
  }

  return (
    <Card
      title={
        <React.Fragment>
          {t("billing.account_buffer")}
          <Tip
            position="bottom-end"
            html={<p>{t("billing.deposit_explained")}</p>}
          >
            <InfoIcon className="Billing__info-icon" />
          </Tip>
        </React.Fragment>
      }
      dataTestid="deposit"
    >
      <p className="Billing__balance-info">{depositFormatted}</p>
    </Card>
  );
};
