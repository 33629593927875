import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { upcomingSampleBooking } from "utils/constants";

import Button from "../../../../components/buttons/Button";
import DefaultError from "../../../../components/common/DefaultError";
import Spinner from "../../../../components/Spinner";
import { getSelectedPropertyId } from "../../../../data/properties/selectors";
import {
  getCurrentBooking,
  upcomingBookingsSelector
} from "../../../../data/selectors/bookings";
import { BOOKINGS_LIMIT, useGetUpcomingBookings } from "../useGetBookings";
import { Booking } from "./Booking";

export const UpcomingBookings = () => {
  const { t } = useTranslation("host.bookings");
  const selectedPropertyId = useSelector(getSelectedPropertyId);

  const currentBooking = useSelector(state =>
    getCurrentBooking(state, selectedPropertyId)
  );
  const upcomingBookings = useSelector(state =>
    upcomingBookingsSelector(state, selectedPropertyId)
  );
  const upcomingBookingsCount = useMemo(
    () => upcomingBookings.length,
    [upcomingBookings]
  );
  const hasUpcomingBookings = useMemo(
    () => upcomingBookingsCount > 0,
    [upcomingBookingsCount]
  );

  const [loading, error, getUpcomingBookings, isFinal] =
    useGetUpcomingBookings(0);

  const handleLoadMore = () => {
    getUpcomingBookings(upcomingBookingsCount);
  };

  return (
    <div className="Bookings">
      {currentBooking && (
        <Booking booking={currentBooking} current dataCy="current-booking" />
      )}
      {!hasUpcomingBookings && (
        <React.Fragment>
          <p className="Bookings__description">
            {t("upcoming_bookings.empty")}
          </p>
          <p className="text-info">{t("upcoming_bookings.example")}</p>
        </React.Fragment>
      )}

      {loading && upcomingBookingsCount === 0 && <Spinner />}

      <div className="Bookings__list">
        {hasUpcomingBookings &&
          upcomingBookings.map(b => (
            <Booking key={b.id} booking={b} dataCy="upcoming-booking" />
          ))}
        {!hasUpcomingBookings && !loading && (
          <Booking
            booking={upcomingSampleBooking}
            sample={true}
            dataCy="sample-upcoming-booking"
          />
        )}
      </div>

      {error && <DefaultError>{t("common:something_went_wrong")}</DefaultError>}

      {!isFinal && upcomingBookingsCount !== 0 && (
        <Button
          outline
          type="button"
          small
          onClick={handleLoadMore}
          loading={loading}
        >
          {t("common:load_more")}
        </Button>
      )}

      {isFinal && upcomingBookingsCount >= BOOKINGS_LIMIT && (
        <p>{t("bookings.no_more_upcoming")}</p>
      )}
    </div>
  );
};
