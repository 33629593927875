import { Field, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import Input from "../../../../../components/common/Input";
import { useHomeInfoContext } from "../../HomeInfoContext";
import { HomeInfoButtons } from "../HomeInfoButtons";
import { HomeInfoForm } from "../HomeInfoForm.jsx";
import { ACCESS } from "./constants";

const PARKING_TYPES = {
  free_street_parking: "free street parking",
  on_premises: "on premises",
  paid_off_premises: "paid parking off premises",
  paid_on_premises: "paid parking on premises"
};

export const Access = () => {
  const { t } = useTranslation("host.home_info");

  const { data, handleSubmitAndNavigateNext } = useHomeInfoContext();

  return (
    <div className="HomeInfo__content">
      <Formik
        initialValues={data}
        onSubmit={handleSubmitAndNavigateNext(ACCESS)}
      >
        {({ handleChange, values }) => (
          <HomeInfoForm>
            <label className="HomeInfoForm__element">
              {t("label.lock_up_instructions")}
              <Field
                component="textarea"
                rows={4}
                name="location_lock_up_instructions"
              />
            </label>
            <label className="HomeInfoForm__element">
              {t("label.parking_available")}
              <Input.Toggle
                split
                name="parking_available"
                id="parking_available"
                value={values.parking_available}
                onChange={handleChange}
                withFormik
              />
            </label>
            <label className="HomeInfoForm__element">
              {t("label.parking_type")}
              <div className="select-wrapper">
                <Field type="text" name="parking_type" component="select">
                  <option disabled={true} value="">
                    {t("common:please_select")}
                  </option>
                  {Object.entries(PARKING_TYPES).map(type => (
                    <option value={type[1]} key={type[0]}>
                      {t(`parking_type.${type[0]}`)}
                    </option>
                  ))}
                </Field>
              </div>
            </label>
            <label className="HomeInfoForm__element">
              {t("label.parking_notes")}
              <Field component="textarea" rows={4} name="parking_notes" />
            </label>
            <label className="HomeInfoForm__element">
              {t("label.ev_charger_available")}
              <Input.Toggle
                split
                name="ev_charger_available"
                id="ev_charger_available"
                value={values.ev_charger_available}
                onChange={handleChange}
                withFormik
              />
            </label>

            <label className="HomeInfoForm__element">
              {t("label.ev_charger_notes")}
              <Field component="textarea" rows={4} name="ev_charger_notes" />
            </label>

            <label className="HomeInfoForm__element">
              {t("label.lift_available")}
              <Input.Toggle
                split
                name="lift_available"
                id="lift_available"
                value={values.lift_available}
                onChange={handleChange}
                withFormik
              />
            </label>

            <label className="HomeInfoForm__element">
              {t("label.stairs_notes")}
              <Field component="textarea" rows={4} name="stairs_notes" />
            </label>
            <HomeInfoButtons sectionId={ACCESS} />
          </HomeInfoForm>
        )}
      </Formik>
    </div>
  );
};
