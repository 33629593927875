import { useCallback, useEffect, useMemo, useState } from "react";

import { localStorage } from "../../../../utils/storage";

const INITIAL_CHECKLIST = { keys: false, photo: false, essentials: false };

const loadPropertyChecklist = id => {
  const json = localStorage.getItem(`propertyChecklist_${id}`);

  try {
    return JSON.parse(json) || INITIAL_CHECKLIST;
  } catch {
    return INITIAL_CHECKLIST;
  }
};

export const usePropertyChecklist = id => {
  const [checklist, setChecklist] = useState(() => loadPropertyChecklist(id));

  useEffect(() => setChecklist(loadPropertyChecklist(id)), [id]);

  const allSelected = useMemo(
    () => Object.values(checklist).every(item => !!item),
    [checklist]
  );

  const onChange = useCallback(
    name => {
      const newChecklist = { ...checklist, [name]: !checklist[name] };
      setChecklist(newChecklist);
    },
    [checklist]
  );

  useEffect(
    () =>
      localStorage.setItem(
        `propertyChecklist_${id}`,
        JSON.stringify(checklist)
      ),
    [checklist, id]
  );

  return { checklist, allSelected, onChange };
};
