import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useUserWithProperties } from "utils/useUserWithProperties";

import { getSelectedPropertyId } from "../../data/properties/selectors";
import { isSetupSkipped } from "../../data/properties/selectors";
import { needsOnboarding } from "../../data/reducers/user";
import Dashboard from "../../scenes/dashboard/Dashboard.jsx";
import { Error } from "../../scenes/errors/Error";
import Spinner from "../Spinner.jsx";

export const PostSetupRoute = () => {
  const { t } = useTranslation();
  const { loading, error } = useUserWithProperties();
  const propertyId = useSelector(getSelectedPropertyId);
  const hasCompletedOnboarding = useSelector(
    state => !needsOnboarding(state) || isSetupSkipped(state)
  );

  if (error) {
    return (
      <Error
        title={t("common:problem_fetching_data")}
        text={t("common:please_check_connection")}
      />
    );
  }

  if (loading) {
    return <Spinner cover />;
  }

  if (!hasCompletedOnboarding) {
    return <Navigate to={`/signup/${propertyId}`} />;
  }

  return <Dashboard />;
};
