export const BED_SIZES = [
  {
    label: "small_single",
    value: 0
  },
  {
    label: "single",
    value: 1
  },
  {
    label: "small_double",
    value: 2
  },
  {
    label: "double",
    value: 3
  },
  {
    label: "king",
    value: 4
  },
  {
    label: "super_king",
    value: 5
  },
  {
    label: "queen",
    value: 6
  }
];
export const BED_TYPES = [
  {
    label: "primary",
    value: 0
  },
  {
    label: "airbed",
    value: 1
  },
  {
    label: "sofa",
    value: 2
  },
  {
    label: "trundle",
    value: 3
  }
];

export const showSaveButton = (values, isTouched) =>
  !values?.id || Boolean(isTouched);

export const showSaveButtonAmenities = (values, isTouched) =>
  !values?.amenity_id || Boolean(isTouched);
