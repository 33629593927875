import "react-tippy/dist/tippy.css";

import "./Tip.scss";

import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { Tooltip } from "react-tippy";

import Overlay from "./Overlay";

const mobileTooltipOverrides = {
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false
      },
      flip: {
        enabled: false
      },
      hide: {
        enabled: false
      }
    }
  }
};

const Tip = ({ html, children, ...props }) => {
  const [isOverlayVisible, toggleOverlay] = useState(false);

  const withContainer = <div className="tooltip-content">{html}</div>;

  const renderTip = () => {
    const tooltipProps = isMobile
      ? { ...props, ...mobileTooltipOverrides }
      : { ...props };

    if (isMobile) {
      return (
        <button
          className="Tip__button"
          type="button"
          onClick={() => toggleOverlay(!isOverlayVisible)}
        >
          {children}
        </button>
      );
    }

    return (
      <Tooltip
        className="tooltip"
        arrow={true}
        animation="fade"
        theme="tooltip"
        {...tooltipProps}
        html={withContainer}
      >
        {children}
      </Tooltip>
    );
  };

  return (
    <React.Fragment>
      {isOverlayVisible && (
        <Overlay close={() => toggleOverlay(!isOverlayVisible)}>
          {withContainer}
        </Overlay>
      )}
      {renderTip()}
    </React.Fragment>
  );
};

export default Tip;
