import "./Clean.scss";

import classnames from "classnames";
import { differenceInHours, format } from "date-fns";
import { get } from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar.svg";
import { ReactComponent as ClockIcon } from "../../../../assets/icons/clock.svg";
import Card from "../../../../components/common/Card";
import ImagesCarousel from "../../../../components/common/ImagesCarousel";
import { IMAGE_CAROUSEL_TYPE_CLEAN_IMAGES } from "../../../../data/cleans/types";
import { shortPrettyDate } from "../../../../utils/dates";
import CleanImages from "./CleanImages";

export class PreviousClean extends Component {
  state = {
    showImagesCarousel: false,
    currentImageIndex: 0
  };

  toggleImagesCarousel = currentImageIndex => {
    this.setState({
      showImagesCarousel: !this.state.showImagesCarousel,
      currentImageIndex
    });
  };

  componentDidMount() {
    const { urlCleanId, clean } = this.props;

    if (parseInt(urlCleanId) === clean.id) {
      this.toggleImagesCarousel();
    }
  }

  renderPastCleanText() {
    const { t, clean } = this.props;
    const type = clean.clean_type;
    const guestName = clean.booking && clean.booking.guest_name;

    const textOptions = {
      standard: guestName
        ? t("clean.past.autoscheduled", { guestName })
        : t("clean.past.standard"),
      first: t("clean.past.first_clean"),
      mid_stay: t("clean.past.mid_stay_clean", { guestName }),
      post_host: t("clean.past.post_host_clean")
    };

    return textOptions[type];
  }

  hasImagesInProgress = endTime => {
    const now = new Date(Date.now());
    const end = new Date(endTime);
    const timePassed = differenceInHours(now, end);

    if (timePassed < 12) return true;
    return false;
  };

  showImages = (showNoImagesInfo, cleanImages) => {
    if (cleanImages.length === 0 && !showNoImagesInfo) {
      return false;
    }

    return true;
  };

  render() {
    const { showImagesCarousel } = this.state;
    const { t, clean, index } = this.props;

    const cleanImages = get(clean, "presentation_images", []);
    const showNoImagesInfo = this.hasImagesInProgress(clean.end_time);
    const showImages = this.showImages(showNoImagesInfo, cleanImages);
    const cancelled = clean.status === "cancelled";

    const containerClassName = classnames("Clean", {
      "Clean--cancelled": cancelled,
      Clean__row: cleanImages.length === 0 && showNoImagesInfo
    });

    const infoClassName = classnames({
      "Clean__info-row": cleanImages.length === 0 && showNoImagesInfo
    });

    const hostSurveyExists = get(clean, "host_survey_exists", false);

    return (
      <Card className={containerClassName} data-cy="clean-info">
        {showImagesCarousel && (
          <ImagesCarousel
            currentImageIndex={this.state.currentImageIndex}
            images={cleanImages}
            hostSurveyExists={hostSurveyExists}
            cleanId={clean.id}
            toggleModal={this.toggleImagesCarousel}
            carouselType={IMAGE_CAROUSEL_TYPE_CLEAN_IMAGES}
          />
        )}

        <div className={infoClassName}>
          <div className="Clean__info-block">
            <div className="Clean__message">
              {this.renderPastCleanText()}

              {cancelled && t("clean.cancelled")}
            </div>

            <div className="Clean__dates">
              <CalendarIcon />
              {shortPrettyDate(clean.start)}
              {clean.start && clean.end && (
                <>
                  <ClockIcon />
                  {format(clean.start, "HH:mm")} - {format(clean.end, "HH:mm")}
                </>
              )}
            </div>
          </div>
        </div>

        {showImages && (
          <CleanImages
            index={index}
            cleanImages={cleanImages}
            showNoImagesInfo={showNoImagesInfo}
            showImagesCarousel={this.toggleImagesCarousel}
          />
        )}
      </Card>
    );
  }
}

export default withTranslation("host.cleans")(PreviousClean);
