import "./StepBookMeeting.scss";

import { ReactComponent as CalendarIcon } from "assets/icons/calendar_checked.svg";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";

import Button from "../../../../components/buttons/Button";
import api from "../../../../services/api";
import { getCalendly } from "../../../../services/scripts";
import { getCalendlyCalendarUrl } from "../../../../utils/calendly";
import { ShapedImage } from "../../Shapes";
import { OnboardingContext } from "../../SignupContext";
import NavButtonsWrapper from "../NavButtonsWrapper";

const LONDON = "London";

export const StepBookMeeting = () => {
  const { t } = useTranslation("host.signup");
  const {
    data,
    handleSubmit,
    loading: loadingContext,
    handlePrev,
    handleNext,
    refreshData
  } = useContext(OnboardingContext);
  const [loadingCalendly, setLoadingCalendly] = useState(false);
  const [loadingSkippingStep, setLoadingSkippingStep] = useState(false);

  const isLondon = useMemo(
    () => data.property.locality?.name === LONDON,
    [data.property.locality?.name]
  );

  const calendlyListener = useCallback(
    event => {
      if (event.data) {
        if (event.data.event === "calendly.event_scheduled") {
          handleSubmit({ onboarding_meeting_booked: true });
        }
        if (event.data.event === "calendly.event_type_viewed") {
          setLoadingCalendly(false);
        }
      }
    },
    [handleSubmit]
  );

  useEffect(() => {
    window.addEventListener("message", calendlyListener);
    return () => window.removeEventListener("message", calendlyListener);
  }, [calendlyListener]);

  const handleClickNext = useCallback(() => {
    if (data.property?.onboarding_meeting_booked) {
      return handleNext();
    }

    setLoadingCalendly(true);
    getCalendly().then(Calendly => {
      const meetingType = isLondon ? "live" : "online";
      Calendly.showPopupWidget(
        getCalendlyCalendarUrl(data.property, data.host, meetingType)
      );
    });
  }, [data.host, data.property, handleNext, isLondon]);

  const handleSkippingStep = useCallback(async () => {
    setLoadingSkippingStep(true);

    try {
      await api.patch(`/v1/hosts/properties/${data.property.id}`, null, {
        onboarding_meeting_skipped: true
      });
      refreshData();
    } finally {
      setLoadingSkippingStep(false);
    }
  }, [data.property.id, refreshData]);

  return (
    <React.Fragment>
      <div className="StepBookMeeting Signup__step">
        {data.property?.onboarding_meeting_booked ? (
          <div className="Signup__thank-you">
            <CalendarIcon />
            <h2 className="Signup__header">{t("common:thank_you")}</h2>
            <h4 className="Signup__subheader">
              {t("book_meeting.meeting_booked")}
            </h4>
          </div>
        ) : (
          <>
            <h2 className="Signup__header" data-testid="book-a-meeting-header">
              {t("book_meeting.header")}
            </h2>
            <h4 className="Signup__subheader">
              {isLondon
                ? t("book_meeting.subheader_in_person")
                : t("book_meeting.subheader")}
            </h4>

            <p>{t("book_meeting.essential_part")}</p>

            <p>{t("book_meeting.during_call")}</p>

            <ul className="StepBookMeeting__list">
              <li className="StepBookMeeting__list__element">
                {t("book_meeting.collect_keys")}
              </li>
              <li className="StepBookMeeting__list__element">
                {t("book_meeting.record_home_info")}
              </li>
              <li className="StepBookMeeting__list__element">
                {t("book_meeting.take_photos")}
              </li>
              <li className="StepBookMeeting__list__element">
                {t("book_meeting.set_up_access")}
              </li>
              <li className="StepBookMeeting__list__element">
                {t("book_meeting.dashboard")}
              </li>
            </ul>

            {!isLondon && <p>{t("book_meeting.be_sure_to_have_computer")}</p>}
          </>
        )}

        <div className="StepBookMeeting__triple-buttons">
          <NavButtonsWrapper
            next={
              <Button
                type="button"
                loading={loadingCalendly || loadingContext}
                onClick={handleClickNext}
              >
                {t(
                  data.property.onboarding_meeting_booked
                    ? "common:next"
                    : "book_meeting.button"
                )}
              </Button>
            }
            prev={
              <Button type="button" theme="plain" onClick={handlePrev}>
                {t("common:previous")}
              </Button>
            }
          />

          {!data.property.onboarding_meeting_booked && (
            <button
              type="button"
              className="link"
              onClick={handleSkippingStep}
              loading={loadingSkippingStep}
            >
              {t("book_meeting.button_skip")}
            </button>
          )}
        </div>
      </div>
      <ShapedImage imgId="meeting" containerClassName="StepBookMeeting" />
    </React.Fragment>
  );
};
