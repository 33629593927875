import "./Rating.scss";

import React, { Component } from "react";

class Ratings extends Component {
  render() {
    const { rating } = this.props;

    return (
      <div className="Rating__container">
        {[1, 2, 3, 4, 5].map(ratingIndex => {
          const targetWidth = 100;
          let width = targetWidth * (rating + 1 - ratingIndex);
          width = Math.min(Math.max(width, 0), targetWidth);

          return (
            <div className="Rating" key={ratingIndex}>
              <svg
                style={{ width: `${width}%` }}
                className="Rating__svg__front"
                data-rating="1"
              >
                <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" />
              </svg>
              <svg className="Rating__svg__back" data-rating="1">
                <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" />
              </svg>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Ratings;
