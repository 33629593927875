import "./Cleans.scss";

import TabControl from "components/common/TabControl";
import { selectProperty } from "data/actions/properties";
import { getSelectedProperty } from "data/properties/selectors";
import { propertyOffboarded } from "data/property_states";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import BookCleanForm from "./components/CleanForm";
import CleanInstructions from "./components/CleanInstructions";
import { PreviousCleansList } from "./components/PreviousCleansList";
import { UpcomingCleansList } from "./components/UpcomingCleansList";

const UPCOMING_TAB_ID = "upcoming";
const PREVIOUS_TAB_ID = "previous";
const LIGHTBOX_PARAM_NAME = "preview_clean";
const TAB_ID_PARAM_NAME = "view";

const Cleans = () => {
  const { t } = useTranslation("host.cleans");
  const dispatch = useDispatch();
  const property = useSelector(getSelectedProperty);
  const offboarded = propertyOffboarded(property);
  const [searchParams, setSearchParams] = useSearchParams();

  const lightboxCleanId = useMemo(
    () => searchParams.get(LIGHTBOX_PARAM_NAME),
    [searchParams]
  );

  // TODO: extract tabs logic to hook like in Maintenance.jsx
  const currentTabId = useMemo(() => {
    if (
      searchParams.get(LIGHTBOX_PARAM_NAME) &&
      searchParams.get(TAB_ID_PARAM_NAME) === UPCOMING_TAB_ID
    ) {
      return PREVIOUS_TAB_ID;
    }
    return searchParams.get(TAB_ID_PARAM_NAME) || UPCOMING_TAB_ID;
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.propertyId) {
      dispatch(selectProperty(searchParams.propertyId));
    }
  }, [dispatch, searchParams.propertyId]);

  const changeTab = useCallback(
    tabId => {
      const params = new URLSearchParams();
      params.set(TAB_ID_PARAM_NAME, tabId);
      setSearchParams(params);
    },
    [setSearchParams]
  );

  useEffect(() => {
    if (
      lightboxCleanId &&
      searchParams.get(TAB_ID_PARAM_NAME) === UPCOMING_TAB_ID
    ) {
      searchParams.set(TAB_ID_PARAM_NAME, PREVIOUS_TAB_ID);
      setSearchParams(searchParams);
    }
  }, [changeTab, currentTabId, lightboxCleanId, searchParams, setSearchParams]);

  const renderTabContent = tab => {
    return tab.id === UPCOMING_TAB_ID ? (
      <UpcomingCleansList urlCleanId={lightboxCleanId} />
    ) : (
      <PreviousCleansList urlCleanId={lightboxCleanId} />
    );
  };

  return (
    <div className="Cleans">
      <TabControl
        tabs={[
          {
            id: UPCOMING_TAB_ID,
            text: t("cleans_list.upcoming_cleans_title")
          },
          {
            id: PREVIOUS_TAB_ID,
            text: t("cleans_list.previous_cleans_title")
          }
        ]}
        initialTabId={currentTabId}
        renderChildren={renderTabContent}
        onChange={changeTab}
      />

      <div className="Cleans__forms">
        {!offboarded && (
          <>
            <BookCleanForm />
            <CleanInstructions />
          </>
        )}
      </div>
    </div>
  );
};

export default Cleans;
