import { Field, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { useHomeInfoContext } from "../../HomeInfoContext";
import { HomeInfoButtons } from "../HomeInfoButtons.jsx";
import { HomeInfoForm } from "../HomeInfoForm.jsx";
import { MAINTENANCE } from "./constants";

export const Maintenance = () => {
  const { t } = useTranslation("host.home_info");

  const { data, handleSubmitAndNavigateNext } = useHomeInfoContext();

  return (
    <div className="HomeInfo__content">
      <Formik
        initialValues={data}
        onSubmit={handleSubmitAndNavigateNext(MAINTENANCE)}
      >
        <HomeInfoForm>
          <label className="HomeInfoForm__element">
            {t("label.gas_meter_location")}
            <Field component="textarea" rows={4} name="gas_meter_location" />
          </label>

          <label className="HomeInfoForm__element">
            {t("label.electric_meter_location")}
            <Field
              component="textarea"
              rows={4}
              name="electric_meter_location"
            />
          </label>

          <label className="HomeInfoForm__element">
            {t("label.fuse_box_location")}
            <Field
              component="textarea"
              rows={4}
              name="maintenance_fusebox_location"
            />
          </label>

          <label className="HomeInfoForm__element">
            {t("label.waste_instructions")}
            <Field
              component="textarea"
              rows={4}
              name="cleaning_waste_instructions"
            />
          </label>

          <label className="HomeInfoForm__element">
            {t("label.waste_collection_days")}
            <Field
              component="textarea"
              rows={4}
              name="cleaning_waste_collection_days"
            />
          </label>

          <label className="HomeInfoForm__element">
            {t("label.waste_collection_frequency")}
            <Field
              component="textarea"
              rows={4}
              name="cleaning_waste_collection_frequency"
            />
          </label>

          <label className="HomeInfoForm__element">
            {t("label.recycling_instructions")}
            <Field
              component="textarea"
              rows={4}
              name="cleaning_recycling_instructions"
            />
          </label>

          <label className="HomeInfoForm__element">
            {t("label.host_cleaning_instructions")}
            <Field
              component="textarea"
              rows={4}
              name="cleaning_host_cleaning_instructions"
            />
          </label>

          <HomeInfoButtons sectionId={MAINTENANCE} />
        </HomeInfoForm>
      </Formik>
    </div>
  );
};
