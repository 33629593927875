import "./HomeInfoForm.scss";

import { Navigate, Route, Routes } from "react-router-dom";

import Card from "../../../../components/common/Card";
import { Additional } from "./amenities/Additional";
import { Boiler } from "./amenities/Boiler";
import { ADDITIONAL, BOILER, WIFI } from "./amenities/constants";
import { WiFi } from "./amenities/WiFi";
import { AmenitiesNavigation } from "./AmenititesNavigation";

export const Amenities = () => (
  <Card className="HomeInfo__container">
    <AmenitiesNavigation />

    <Routes>
      <Route path={WIFI} element={<WiFi />} />
      <Route path={BOILER} element={<Boiler />} />
      <Route path={ADDITIONAL} element={<Additional />} />
      <Route path={`${ADDITIONAL}/:category`} element={<Additional />} />
      <Route index element={<Navigate to={WIFI} />} />
    </Routes>
  </Card>
);
