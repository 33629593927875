import { useEffect, useState } from "react";

import { ScreenSizes } from "./constants";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  const isExtraSmallScreen = width < ScreenSizes.MOBILE_MODERN;
  const isSmallScreen = width < ScreenSizes.MOBILE_LANDSCAPE;
  const isMediumScreen = width < ScreenSizes.TABLET;
  const isLargeScreen = width > ScreenSizes.TABLET;

  return {
    width,
    height,
    isExtraSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};
