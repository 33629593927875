import "./PerformanceChart.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";
import Tip from "../../../../components/common/Tip.jsx";
import { getSelectedPropertyCurrency } from "../../../../data/properties/selectors";

const MODULR_CURRENCIES = ["GBP", "EUR"];

export const PerformanceHeader = ({ hasAnyEarnings }) => {
  const { t } = useTranslation("host.performance");
  const currency = useSelector(getSelectedPropertyCurrency);
  const isModulr = MODULR_CURRENCIES.includes(currency);

  return (
    <>
      <h3 className="header">
        {hasAnyEarnings ? t("performance") : t("performance_example")}{" "}
        {hasAnyEarnings && (
          <Tip
            className="PerformanceChart__header__tip"
            position="bottom-start"
            html={
              <p>{isModulr ? t("tooltip_modulr") : t("tooltip_no_modulr")}</p>
            }
          >
            <InfoIcon />
          </Tip>
        )}
      </h3>
    </>
  );
};
