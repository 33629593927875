import "./RawTerms.scss";

import TermsFrame from "components/common/TermsFrame";
import { getSelectedProperty } from "data/properties/selectors";
import { propertyNeedsTerms } from "data/property_states";
import React, { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Card from "../../components/common/Card.jsx";
import Logo from "../../components/common/Logo.jsx";
import Spinner from "../../components/Spinner.jsx";
import { useUserWithProperties } from "../../utils/useUserWithProperties";
import { Error } from "../errors/Error.jsx";

const AcceptedTerms = lazy(() =>
  import("../dashboard/preferences/components/AcceptedTerms")
);

export const RawTerms = () => {
  const { t } = useTranslation();
  const { loading, error } = useUserWithProperties();
  const selectedProperty = useSelector(getSelectedProperty);

  if (error) {
    return (
      <Error
        title={t("common:problem_fetching_data")}
        text={t("common:please_check_connection")}
      />
    );
  }

  if (loading) {
    return <Spinner cover />;
  }

  return (
    <div className="RawTerms">
      <Logo />
      {propertyNeedsTerms(selectedProperty) ? (
        <Card
          title={t("host.preferences:settings.property_terms_and_conditions")}
          className="Terms__Card"
        >
          <TermsFrame />
        </Card>
      ) : (
        <Suspense fallback={<Spinner />}>
          <AcceptedTerms />
        </Suspense>
      )}
    </div>
  );
};
