import "./Preferences.scss";
import "./components/Settings.scss";

import TabControl from "components/common/TabControl";
import Fetcher from "components/Fetcher";
import * as Fetchers from "data/actions/fetchers";
import {
  getSelectedProperty,
  selectedPropertyPlanType
} from "data/properties/selectors";
import React, { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PLAN_TYPE_FULL_TIME } from "utils/constants";
import { parseQuerystring } from "utils/querystring";

import Spinner from "../../../components/Spinner";
import { propertyNeedsTerms } from "../../../data/property_states";

const AcceptedTerms = lazy(() => import("./components/AcceptedTerms"));

const AvailabilitySettingsTab = lazy(() =>
  import("./components/AvailabilitySettingsTab")
);
const GuestSettingsTab = lazy(() => import("./components/GuestSettingsTab"));

const PricingSettingsTab = lazy(() =>
  import("./components/PricingSettingsTab")
);

const TieredPreferenceTab = lazy(() =>
  import("./components/TieredPreferenceTab")
);

const GUEST_TAB = "guest";
const TIERED_PREFERENCE_TAB = "tiered_preference";
const TERMS_TAB = "terms";
const PRICING_TAB = "pricing";
const AVAILABILITY_TAB = "availability";

const Preferences = () => {
  const { t } = useTranslation("host.preferences");
  const location = useLocation();
  const { tab: queryTabId } = parseQuerystring(location.search);
  const selectedProperty = useSelector(getSelectedProperty);
  const planType = useSelector(selectedPropertyPlanType);

  const getTabs = () => {
    const availabilityTab = {
      id: AVAILABILITY_TAB,
      text: t("settings.tabs.availability"),
      dataCy: "availability-tab"
    };
    const pricingTab = {
      id: PRICING_TAB,
      text: t("settings.tabs.pricing"),
      dataCy: "pricing-tab"
    };
    const termsTab = { id: TERMS_TAB, text: t("settings.terms") };

    return [
      { id: GUEST_TAB, text: t("settings.tabs.guest") },
      {
        id: TIERED_PREFERENCE_TAB,
        text: t("settings.tabs.preference"),
        dataCy: "tiered-preference-tab"
      },
      planType !== PLAN_TYPE_FULL_TIME && pricingTab,
      planType !== PLAN_TYPE_FULL_TIME && availabilityTab,
      !propertyNeedsTerms(selectedProperty) && termsTab
    ].filter(Boolean);
  };

  const setInitialTab = () =>
    getTabs().find(({ id }) => id === queryTabId) ? queryTabId : GUEST_TAB;

  const renderTabs = tab => {
    switch (tab?.id) {
      case GUEST_TAB:
        return (
          <Suspense fallback={<Spinner />}>
            <GuestSettingsTab />
          </Suspense>
        );
      case TIERED_PREFERENCE_TAB:
        return (
          <Suspense fallback={<Spinner />}>
            <TieredPreferenceTab />
          </Suspense>
        );
      case PRICING_TAB:
        return (
          <Suspense fallback={<Spinner />}>
            <PricingSettingsTab />
          </Suspense>
        );
      case AVAILABILITY_TAB:
        return (
          <Suspense fallback={<Spinner />}>
            <AvailabilitySettingsTab />
          </Suspense>
        );
      case TERMS_TAB:
        return (
          <Suspense fallback={<Spinner />}>
            <AcceptedTerms />
          </Suspense>
        );
      default:
        return;
    }
  };

  return (
    <div className="Preferences">
      <div className="Preferences__container">
        <Fetcher fetcher={Fetchers.preferences(selectedProperty.id)}>
          <TabControl
            tabs={getTabs()}
            initialTabId={setInitialTab()}
            renderChildren={renderTabs}
          />
        </Fetcher>
      </div>
    </div>
  );
};

export default Preferences;
