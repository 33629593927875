import React from "react";

export const LinkOut = ({ to, children, target, rel }) => {
  if (typeof to === "string" && to.length) {
    const shortUrl = to.match(/^.*\/\/(.*?)\/?$/)[1]; // remove URL prefix and dangling /

    return (
      <a
        href={to}
        target={target || "_blank"}
        rel={rel || "noopener noreferrer"}
      >
        {children || shortUrl}
      </a>
    );
  }

  return "";
};

export default LinkOut;
