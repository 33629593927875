import { format } from "date-fns";

import { formatAmountWithCurrency } from "../../../utils/numbers";

const getFullSampleDate = (month, day) => {
  const currentYear = new Date().getFullYear();
  const date = new Date(currentYear, month, day);

  return format(date, "yyyy-MM-dd");
};

export const samplePerfomanceData = ({ currency, language, country_code }) => {
  const formatAmount = amount =>
    formatAmountWithCurrency(amount, { currency, language, country_code });

  return [
    {
      data: {
        net_earnings: 131.33,
        net_earnings_formatted: formatAmount(131.33)
      },
      from_date: getFullSampleDate(0, 1),
      to_date: getFullSampleDate(0, 31)
    },
    {
      data: {
        net_earnings: 134.36,
        net_earnings_formatted: formatAmount(134.36)
      },
      from_date: getFullSampleDate(1, 1),
      to_date: getFullSampleDate(1, 28)
    },
    {
      data: {
        net_earnings: 1311.33,
        net_earnings_formatted: formatAmount(1311.33)
      },
      from_date: getFullSampleDate(2, 1),
      to_date: getFullSampleDate(2, 31)
    },
    {
      data: {
        net_earnings: 1541.6,
        net_earnings_formatted: formatAmount(1541.6)
      },
      from_date: getFullSampleDate(3, 1),
      to_date: getFullSampleDate(3, 30)
    },
    {
      data: {
        net_earnings: 2591.0,
        net_earnings_formatted: formatAmount(2591.0)
      },
      from_date: getFullSampleDate(4, 1),
      to_date: getFullSampleDate(4, 31)
    },
    {
      data: {
        net_earnings: 3213.56,
        net_earnings_formatted: formatAmount(3213.56)
      },
      from_date: getFullSampleDate(5, 1),
      to_date: getFullSampleDate(5, 30)
    }
  ];
};
