import { addDays, subDays } from "date-fns";

export const DEFAULT_REPLACEMENT_SPEND = 120;
export const DEFAULT_DISCRETIONARY_SPEND = 150;

export const RESTRICTED_CARD_TYPES = ["amex", "discover"];
export const IBAN_COUNTRIES = ["IE", "FR", "ES", "PT"];

export const ACCOUNT_TYPE_GB = "gb";
export const ACCOUNT_TYPE_IBAN = "iban";
export const ACCOUNT_TYPE_AU = "au";
export const ACCOUNT_TYPE_NZ = "nz";
export const ACCOUNT_TYPE_CA = "ca";
export const ACCOUNT_TYPE_ZA = "za";
export const ACCOUNT_TYPES_SPECIAL = [
  ACCOUNT_TYPE_AU,
  ACCOUNT_TYPE_NZ,
  ACCOUNT_TYPE_CA,
  ACCOUNT_TYPE_ZA
];

export const FEEDBACK_FORM_BASE_URL =
  "https://airassist.typeform.com/to/D3PBV0";

export const ScreenSizes = {
  MOBILE_MODERN: 375,
  MOBILE_LANDSCAPE: 480,
  TABLET: 768
};

export const Labels = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  OTHER: "other"
};

export const upcomingSampleBooking = {
  approved: false,
  airbnb_reservation_data: {
    data: {
      host_payout_total: "481.03",
      rate_localised_title: "205 x 2",
      rate_amount: "410.00",
      cleaning_amount: "89.00",
      service_amount: "17.97"
    }
  },
  channel: "airbnb",
  checkin: addDays(new Date(), 1),
  checkout: addDays(new Date(), 3),
  estimated_earnings: 481.03,
  guest_name: "Ms Sample Booking",
  guest_photo_url:
    "https://s3.amazonaws.com/uifaces/faces/twitter/adellecharles/128.jpg",
  host_earnings: 481.03,
  id: 1,
  is_multiplat: false,
  booking_channel: {
    name: "Airbnb"
  }
};

export const previousSampleBooking = {
  approved: true,
  channel: "airbnb",
  checkin: subDays(new Date(), 5),
  checkout: subDays(new Date(), 1),
  estimated_earnings: 481.03,
  guest_name: "Mr Sample Booking",
  guest_photo_url:
    "https://s3.amazonaws.com/uifaces/faces/twitter/calebogden/128.jpg",
  host_earnings: 481.03,
  id: 1,
  is_multiplat: false,
  booking_channel: {
    name: "Airbnb"
  }
};

export const links = {
  gettingStarted: {
    london: "http://static.houst.com/getting_started.pdf",
    dubai: "http://static.houst.com/getting_started_ae.pdf",
    paris: "http://static.houst.com/getting_started_fr.pdf",
    madrid: "http://static.houst.com/getting_started_es.pdf",
    lisbon: "http://static.houst.com/getting_started_pt.pdf"
  }
};

export const LONDON = "London";
export const DUBLIN = "Dublin";

export const AS_LETS_LOCALITIES = [
  LONDON,
  "Brighton",
  "Bristol",
  "Bath",
  "Edinburgh"
];

export const SUPPORT_URL = "https://www.houst.com/knowledge-base";
export const PROPERTY_CHECK_FAQ = "https://www.houst.com/property-checks";

export const PLAN_TYPE_PART_TIME = "part_time";
export const PLAN_TYPE_FULL_TIME = "full_time";
