import "./CleanRatingSurvey.scss";

import { ReactComponent as SadIcon } from "assets/icons/emoji-frowning2.svg";
import { ReactComponent as NeutralIcon } from "assets/icons/emoji-neutral-face.svg";
import { ReactComponent as SmileIcon } from "assets/icons/emoji-slight-smile.svg";
import classNames from "classnames";
import Button from "components/buttons/Button";
import Modal from "components/common/modal";
import { find } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import api from "services/api";

import { useGetPreviousCleans } from "../useGetCleans";

const VOTE_VALUE_SAD = 1;
const VOTE_VALUE_MEH = 2;
const VOTE_VALUE_YAY = 3;

const voteOptions = [
  {
    id: 1,
    value: VOTE_VALUE_YAY,
    icon: SmileIcon,
    className: "happy",
    labelKey: "vote_happy"
  },
  {
    id: 2,
    value: VOTE_VALUE_MEH,
    icon: NeutralIcon,
    className: "meh",
    labelKey: "vote_meh"
  },
  {
    id: 3,
    value: VOTE_VALUE_SAD,
    icon: SadIcon,
    className: "sad",
    labelKey: "vote_sad"
  }
];

const CleanRatingSurvey = ({ cleanId, toggleModal }) => {
  const { t } = useTranslation("host.cleans");

  const [rating, setRating] = useState(undefined);
  const [notes, setNotes] = useState(undefined);
  const [saving, setSaving] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(undefined);

  const { getPreviousCleans } = useGetPreviousCleans();

  const handleOnSubmit = async e => {
    e.preventDefault();

    const data = {
      clean_id: cleanId,
      rating,
      issue_notes: notes
    };

    try {
      setSaving(true);

      await api.post("/v1/hosts/clean_survey", null, data);

      getPreviousCleans();
      setSubmitted(true);
    } catch (e) {
      setError(t("common:error.try_again"));
    } finally {
      setSaving(false);
    }
  };

  const surveyOptionClassNames = value => {
    const option = find(voteOptions, { value });

    return classNames(option.className, { active: value === rating });
  };

  const hasNoRequiredComment = () => {
    return rating < VOTE_VALUE_YAY && !notes;
  };

  const renderOptionsButtons = () => {
    return voteOptions
      .slice(0)
      .reverse()
      .map(({ id, value, icon: IconComponent, labelKey }) => (
        <button
          key={id}
          testid={`face_button_${id}`}
          type="button"
          onClick={() => setRating(value)}
          className={surveyOptionClassNames(value)}
        >
          <span
            role="img"
            aria-label={t(`clean.images_carousel.labels.${labelKey}`)}
          >
            <IconComponent />
          </span>
        </button>
      ));
  };

  const renderSurvey = () => (
    <div className="CleanRatingSurvey__container">
      <div className="surveyOptions">{renderOptionsButtons()}</div>

      <div data-testid="clean_survey_notes_container" className="surveyNotes">
        <span>
          {rating < VOTE_VALUE_YAY
            ? t("clean.images_carousel.feedback_notes_line_1")
            : t("clean.images_carousel.feedback_notes_line_1_yay")}
          <br />
          {rating < VOTE_VALUE_YAY
            ? t("clean.images_carousel.feedback_notes_line_2")
            : t("clean.images_carousel.feedback_notes_line_2_yay")}
        </span>
        <textarea
          rows={4}
          placeholder={rating < VOTE_VALUE_YAY ? t("common:is_required") : null}
          value={notes}
          onChange={e => setNotes(e.target.value)}
        />
      </div>
      <p className={"error"}>
        <small>{error}</small>
      </p>
    </div>
  );

  const renderSummary = () => {
    const option = find(voteOptions, { value: rating });

    const { label, icon: IconComponent } = option;

    return (
      <div className="CleanRatingSurvey__summary">
        <span
          role="img"
          aria-label={label}
          data-cy="clean-rating-complete-icon"
        >
          <IconComponent />
        </span>
      </div>
    );
  };

  const renderFooter = () => {
    if (submitted) {
      return (
        <div className="CleanRatingSurvey__footer">
          <p>
            {rating === VOTE_VALUE_YAY
              ? t("clean.images_carousel.feedback_positive")
              : t("clean.images_carousel.feedback")}
          </p>
          <Button type="button" onClick={toggleModal}>
            {t("common:close")}
          </Button>
        </div>
      );
    }

    return (
      <Button
        testid="submit_survey_button"
        disabled={!rating || saving || hasNoRequiredComment()}
        onClick={handleOnSubmit}
      >
        {t("common:save_and_close")}
      </Button>
    );
  };

  return (
    <Modal
      className="CleanRatingSurveyModal"
      title={
        submitted ? (
          <h3 className="CleanRatingSurveyModal__header big-header">
            {t("clean.images_carousel.rating_sent")}
          </h3>
        ) : (
          <h3 className="CleanRatingSurveyModal__header big-header">
            {t("clean.images_carousel.survey_question")}
          </h3>
        )
      }
      closeModal={toggleModal}
      footer={renderFooter()}
      showDesktopCloseButton={true}
    >
      {submitted ? renderSummary() : renderSurvey()}
    </Modal>
  );
};

export default CleanRatingSurvey;
