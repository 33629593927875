import { useAmenities } from "../useAmenities";
import { NULL_CATEGORY_NAME } from "../useAmenities.jsx";
import { AMENITIES_SECTIONS } from "./amenities/constants";
import HomeInfoNav from "./HomeInfoNav";

export const AmenitiesNavigation = () => {
  const { allCategories } = useAmenities();

  return (
    <HomeInfoNav sections={AMENITIES_SECTIONS}>
      <HomeInfoNav
        sub
        sections={allCategories.map(category => category || NULL_CATEGORY_NAME)}
      />
    </HomeInfoNav>
  );
};
