import "./Checkbox.scss";

import React, { Component } from "react";

class Checkbox extends Component {
  render() {
    const { id, onChange, label, checked, className, dataCy } = this.props;

    return (
      <div className={className}>
        <input
          className="Checkbox__input"
          type="checkbox"
          name={id}
          id={id}
          onChange={onChange}
          checked={checked}
        />

        {label ? (
          <label
            className="Checkbox__label"
            htmlFor={id}
            data-testid={dataCy}
            data-cy={dataCy}
          >
            {label}
          </label>
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}

export default Checkbox;
