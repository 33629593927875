import { SEVERITY_NOTICE } from "data/reducers/flash";

import { CLEAR_FLASH, SET_FLASH } from "../action_types";

export const setFlash = (message, severity = SEVERITY_NOTICE) => ({
  type: SET_FLASH,
  message,
  severity
});

export const clearFlash = () => ({
  type: CLEAR_FLASH
});
