const styles = {
  fonts: {
    header: "Stuart",
    paragraph: "Moderat-Regular"
  },
  colors: {
    white: "#ffffff",
    black: "#000000",
    yellow: "#f5f514",

    lightElephant: "#e6e6e6",
    elephant: "#b3b3b3",
    darkElephant: "#373737",

    orange: "#f9c310",
    red: "#b30e0e",
    green: "#239a5e",
    blue: "#078fd9",
    lightBlue: "#cde6f4"
  }
};

export default styles;
