import Button from "components/buttons/Button";
import Modal from "components/common/modal";
import { languages } from "countries-list";
import { fetch } from "data/actions/fetcher";
import * as Fetchers from "data/actions/fetchers";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  formatTimezoneLabel,
  timezonesSortedByLabel as timezones
} from "utils/timezones";

import Input from "../../../../components/common/Input";
import { LANGUAGES } from "../../../../i18n";

export class UpdateContactTimezone extends Component {
  defaultState = {
    newTimezone: this.props.contactTimezone || null,
    success: false,
    error: null,
    newLanguage: this.props.language || null
  };

  state = this.defaultState;

  resetState = () => {
    this.setState(this.defaultState);
  };

  handleSubmit = async e => {
    e.preventDefault();

    const { newTimezone, newLanguage } = this.state;
    const { updateUser } = this.props;

    try {
      await updateUser({
        contact_timezone: newTimezone,
        language: newLanguage
      });

      this.setState({
        success: true,
        showModal: true
      });
    } catch (e) {
      this.setState({
        success: false,
        showModal: true
      });
    }
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  renderModal = () => {
    const { success } = this.state;
    const { t } = this.props;

    const title = success
      ? t("update_contact_timezone.title.success")
      : t("update_contact_timezone.title.error");

    const message = success
      ? t("update_success")
      : t("update_contact_timezone.error");

    return (
      <Modal
        title={title}
        closeModal={this.toggleModal}
        footer={
          <Button outline type="button" onClick={this.toggleModal}>
            {t("common:ok")}
          </Button>
        }
      >
        <p>{message}</p>
      </Modal>
    );
  };

  valuesChanged = () => {
    const { contactTimezone, language } = this.props;
    const { newTimezone, newLanguage } = this.state;

    return newTimezone !== contactTimezone || newLanguage !== language;
  };

  render() {
    const { showModal, newTimezone, newLanguage } = this.state;
    const { t } = this.props;

    return (
      <>
        {showModal && this.renderModal()}

        <div className="form__group">
          <Input.Select
            id="updateContactTimezone"
            onChange={value => this.setState({ newTimezone: value })}
            value={newTimezone}
            label={t("update_contact_timezone.form.new_contact_timezone_label")}
            name="updateContactTimezone"
            className="timezone-input"
          >
            <option value={""} disabled>
              &nbsp;
            </option>

            {timezones.map(timezone => (
              <option key={timezone.name} value={timezone.name}>
                {formatTimezoneLabel(timezone)}
              </option>
            ))}
          </Input.Select>
        </div>

        <div className="form__group">
          <Input.Select
            id="updateLanguage"
            onChange={value => this.setState({ newLanguage: value })}
            value={newLanguage}
            label={t("contact_language")}
            name="updateLanguage"
          >
            <option value={""} disabled>
              {t("common:please_select")}
            </option>

            {LANGUAGES.map(langCode => (
              <option value={langCode} key={langCode}>
                {languages[langCode].native}
              </option>
            ))}
          </Input.Select>
        </div>

        <div className="Account__actions">
          {this.valuesChanged() && (
            <React.Fragment>
              <Button outline type="button" onClick={this.resetState}>
                {t("common:cancel")}
              </Button>

              <Button
                type="submit"
                disabled={!newTimezone}
                onClick={this.handleSubmit}
              >
                {t("common:confirm")}
              </Button>
            </React.Fragment>
          )}
        </div>
      </>
    );
  }
}

export default compose(
  withTranslation("host.account"),
  connect(null, dispatch => ({
    updateUser: user => dispatch(fetch(Fetchers.updateUser(user)))
  }))
)(UpdateContactTimezone);
