import { getSelectedPropertyId } from "data/properties/selectors";
import { compareDesc } from "date-fns";
import { createSelector } from "reselect";

export const upcomingCleansSelector = createSelector(
  [state => state.cleans.list, getSelectedPropertyId],
  (list, selectedPropertyId) =>
    list
      .filter(({ property_id }) => property_id === selectedPropertyId)
      .sort((a, b) => compareDesc(a.clean_date, b.clean_date))
);

export const previousCleansSelector = createSelector(
  [state => state.cleans.previousList, getSelectedPropertyId],
  (previousList, selectedPropertyId) =>
    previousList
      .filter(({ property_id }) => property_id === selectedPropertyId)
      .sort((a, b) => compareDesc(a.start, b.start))
);
