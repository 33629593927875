import { getPropertyNeedsSetup } from "data/properties/selectors";
import { get } from "lodash";
import api from "services/api";

import { localStorage } from "../../utils/storage";
import {
  EMAIL_UPDATED,
  PASSWORD_UPDATED,
  RECEIVE_LOCALITIES,
  RECEIVE_LOGIN,
  RECEIVE_USER,
  SET_ADMIN_USER
} from "../action_types";

export const getProfile = state => state.user.profile;

export const isAdmin = state => state.user.isAdmin;

export const getProfileEmail = state =>
  get(getProfile(state), "emails.0.address");

export const getLoginEmail = state => get(getProfile(state), "login_email");

export const getPersonalReferralCode = state =>
  get(getProfile(state), "personal_referral_code");

export const needsOnboarding = state => getPropertyNeedsSetup(state);

export const authTokenSelector = state => state.user.authToken;

const getInitialState = () => ({
  authToken: localStorage.getItem("auth_token"),
  profile: {},
  localities: [],
  isAdmin: false
});

const { authToken } = getInitialState();
api.setToken(authToken);

const user = (state = getInitialState(), action) => {
  switch (action.type) {
    case RECEIVE_LOGIN:
      localStorage.setItem("auth_token", action.token);
      api.setToken(action.token);

      return {
        ...state,
        authToken: action.token,
        profile: action.user
      };

    case RECEIVE_USER:
    case EMAIL_UPDATED: {
      const profile = action.user;

      return { ...state, profile };
    }

    case SET_ADMIN_USER:
      return {
        ...state,
        isAdmin: true
      };

    case PASSWORD_UPDATED:
      return {
        ...state,
        profile: {
          ...state.profile,
          has_authentication: true
        }
      };

    case RECEIVE_LOCALITIES:
      return {
        ...state,
        localities: action.localities
      };

    default:
      return state;
  }
};

export default user;
