import { Component } from "react";

// component coming from: https://codesandbox.io/s/vymm4oln6y

class ChangingProgressProvider extends Component {
  static defaultProps = {
    interval: 1000
  };

  state = {
    valuesIndex: 0,
    shownValues: 1
  };

  componentDidMount() {
    const animate = setInterval(() => {
      const shouldReload = this.shouldAnimate();
      const { valuesIndex, shownValues } = this.state;
      const { values } = this.props;

      if (!shouldReload) {
        clearInterval(animate);
      } else {
        this.setState({
          valuesIndex: (valuesIndex + 1) % values.length,
          shownValues: shownValues + 1
        });
      }
    }, this.props.interval);
  }

  shouldAnimate = () => {
    const { values } = this.props;
    const { shownValues } = this.state;

    if (shownValues === values.length) return false;
    return true;
  };

  render() {
    return this.props.children(this.props.values[this.state.valuesIndex]);
  }
}

export default ChangingProgressProvider;
