import "./PerformanceChartTile.scss";

import Spinner from "components/Spinner";
import { getSelectedProperty } from "data/properties/selectors";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import PerformanceChart from "../../performance/components/PerformanceChart";
import {
  getPerformanceDateRange,
  monthsInYTD
} from "../../performance/components/utils";
import { useGetEarnings } from "../../performance/useGetEarnings";
import TimeRangeSelect from "./TimeRangeSelect";

const DEFAULT_CHART_RANGE = 6;

const PerformanceChartTile = ({ hasAnyEarnings }) => {
  const { t } = useTranslation("host.performance");
  const [selectedRange, setSelectedRange] = useState(
    getPerformanceDateRange({
      monthsToShow: DEFAULT_CHART_RANGE,
      isYTD: false
    })
  );
  const property = useSelector(getSelectedProperty);

  const { getGraphData, data, loading, error } = useGetEarnings(
    property.id,
    hasAnyEarnings,
    property.locality
  );

  useEffect(() => {
    getGraphData({ dateRange: selectedRange });
  }, [property.id, getGraphData, selectedRange]);

  const handleDateRangeChange = value => {
    const months = value === "ytd" ? monthsInYTD() : value;
    const isYTD = value === "ytd";

    setSelectedRange(
      getPerformanceDateRange({
        monthsToShow: months,
        isYTD
      })
    );
  };

  const getSelectOptions = () => {
    return [
      { value: "6", label: t("chart_tab_6_months") },
      { value: "ytd", label: t("chart_tab_year_to_date") },
      { value: "12", label: t("chart_tab_1_year") }
    ];
  };

  if (error) {
    return <div className="PerformanceChart__error">{t("chart_error")}</div>;
  }

  return (
    <div className="PerformanceChartTile OverviewItem">
      <div className="OverviewItem__header">
        <h4 className="header">{t("performance.overview.title")}</h4>

        <div className="right">
          <TimeRangeSelect
            options={getSelectOptions()}
            onChange={handleDateRangeChange}
          />
        </div>
      </div>

      <div className="OverviewItem__content">
        {!hasAnyEarnings && (
          <div className="text-info">{t("performance.sample_data")}</div>
        )}

        {data && !loading ? (
          <PerformanceChart
            data={data}
            locality={property.locality}
            dateRange={selectedRange}
            isOverviewChart={true}
            sample={!hasAnyEarnings}
          />
        ) : (
          <div className="PerformanceChart__loading">
            <Spinner />
          </div>
        )}
      </div>

      <Link to="/performance" className="OverviewItem__see-more">
        {t("common:see_more")}
      </Link>
    </div>
  );
};

export default PerformanceChartTile;
