export const APP_UPDATE_AVAILABLE = "APP_UPDATE_AVAILABLE";

export const RECEIVE_CARD_DETAILS = "RECEIVE_CARD_DETAILS";
export const RECEIVE_DIRECT_DEBIT_INFO = "RECEIVE_DIRECT_DEBIT_INFO";
export const RECEIVE_BANK_ACCOUNT_DETAILS = "RECEIVE_BANK_ACCOUNT_DETAILS";
export const RECEIVE_STRIPE_API_KEY = "RECEIVE_STRIPE_API_KEY";
export const RECEIVE_INVOICES = "RECEIVE_INVOICES";

export const RECEIVE_PROPERTIES = "RECEIVE_PROPERTIES";
export const SELECT_PROPERTY = "SELECT_PROPERTY";

export const RECEIVE_BOOKING = "RECEIVE_BOOKING";
export const RECEIVE_BOOKINGS = "RECEIVE_BOOKINGS";
export const RECEIVE_UPCOMING_BOOKINGS = "RECEIVE_UPCOMING_BOOKINGS";
export const RECEIVE_PREVIOUS_BOOKINGS = "RECEIVE_PREVIOUS_BOOKINGS";

export const LOGGED_OUT = "LOGGED_OUT";
export const RECEIVE_LOGIN = "RECEIVE_LOGIN";
export const RECEIVE_USER = "RECEIVE_USER";
export const PASSWORD_UPDATED = "PASSWORD_UPDATED";
export const EMAIL_UPDATED = "EMAIL_UPDATED";
export const RECEIVE_LOCALITIES = "RECEIVE_LOCALITIES";
export const SET_ADMIN_USER = "SET_ADMIN_USER";

export const SKIP_PROPERTY_SETUP = "SKIP_PROPERTY_SETUP";
export const RESUME_PROPERTY_SETUP = "RESUME_PROPERTY_SETUP";

export const RECEIVE_BOOKINGS_COUNT = "RECEIVE_BOOKINGS_COUNT";
export const RECEIVE_MAINTENANCE = "RECEIVE_MAINTENANCE";
export const RECEIVE_MAINTENANCE_LIST = "RECEIVE_MAINTENANCE_LIST";
export const PATCH_MAINTENANCE_ITEM = "PATCH_MAINTENANCE_ITEM";

export const SET_SELECTED_BOOKING = "SET_SELECTED_BOOKING";
export const RECEIVE_CALENDAR = "RECEIVE_CALENDAR";

export const RECEIVE_TRANSACTIONS = "RECEIVE_TRANSACTIONS";

export const RECEIVE_AMENITIES_PHOTOS = "RECEIVE_AMENITIES_PHOTOS";

export const RECEIVE_PAYOUTS = "RECEIVE_PAYOUTS";
export const RECEIVE_PREFERENCES = "RECEIVE_PREFERENCES";

export const SET_FLASH = "SET_FLASH";
export const CLEAR_FLASH = "CLEAR_FLASH";
