const timezones = [
  {
    label: "Niue",
    name: "Pacific/Niue",
    offset: -11
  },
  {
    label: "Pago Pago",
    name: "Pacific/Pago_Pago",
    offset: -11
  },
  {
    label: "Hawaii Time",
    name: "Pacific/Honolulu",
    offset: -10
  },
  {
    label: "Rarotonga",
    name: "Pacific/Rarotonga",
    offset: -10
  },
  {
    label: "Tahiti",
    name: "Pacific/Tahiti",
    offset: -10
  },
  {
    label: "Marquesas",
    name: "Pacific/Marquesas",
    offset: -9.5
  },
  {
    label: "Gambier",
    name: "Pacific/Gambier",
    offset: -9
  },
  {
    label: "Alaska Time",
    name: "America/Anchorage",
    offset: -8
  },
  {
    label: "Pitcairn",
    name: "Pacific/Pitcairn",
    offset: -8
  },
  {
    label: "Dawson",
    name: "America/Dawson",
    offset: -7
  },
  {
    label: "Mountain Time - Arizona",
    name: "America/Phoenix",
    offset: -7
  },
  {
    label: "Mountain Time - Dawson Creek",
    name: "America/Dawson_Creek",
    offset: -7
  },
  {
    label: "Mountain Time - Hermosillo",
    name: "America/Hermosillo",
    offset: -7
  },
  {
    label: "Pacific Time",
    name: "America/Los_Angeles",
    offset: -7
  },
  {
    label: "Pacific Time - Tijuana",
    name: "America/Tijuana",
    offset: -7
  },
  {
    label: "Pacific Time - Vancouver",
    name: "America/Vancouver",
    offset: -7
  },
  {
    label: "Pacific Time - Whitehorse",
    name: "America/Whitehorse",
    offset: -7
  },
  {
    label: "Belize",
    name: "America/Belize",
    offset: -6
  },
  {
    label: "Boise",
    name: "America/Boise",
    offset: -6
  },
  {
    label: "Central Time - Regina",
    name: "America/Regina",
    offset: -6
  },
  {
    label: "Central Time - Tegucigalpa",
    name: "America/Tegucigalpa",
    offset: -6
  },
  {
    label: "Costa Rica",
    name: "America/Costa_Rica",
    offset: -6
  },
  {
    label: "Easter Island",
    name: "Pacific/Easter",
    offset: -6
  },
  {
    label: "El Salvador",
    name: "America/El_Salvador",
    offset: -6
  },
  {
    label: "Galapagos",
    name: "Pacific/Galapagos",
    offset: -6
  },
  {
    label: "Guatemala",
    name: "America/Guatemala",
    offset: -6
  },
  {
    label: "Managua",
    name: "America/Managua",
    offset: -6
  },
  {
    label: "Mountain Time",
    name: "America/Denver",
    offset: -6
  },
  {
    label: "Mountain Time - Chihuahua, Mazatlan",
    name: "America/Mazatlan",
    offset: -6
  },
  {
    label: "Mountain Time - Edmonton",
    name: "America/Edmonton",
    offset: -6
  },
  {
    label: "Mountain Time - Yellowknife",
    name: "America/Yellowknife",
    offset: -6
  },
  {
    label: "America Cancun",
    name: "America/Cancun",
    offset: -5
  },
  {
    label: "Bogota",
    name: "America/Bogota",
    offset: -5
  },
  {
    label: "Central Time",
    name: "America/Chicago",
    offset: -5
  },
  {
    label: "Central Time - Mexico City",
    name: "America/Mexico_City",
    offset: -5
  },
  {
    label: "Central Time - Winnipeg",
    name: "America/Winnipeg",
    offset: -5
  },
  {
    label: "Guayaquil",
    name: "America/Guayaquil",
    offset: -5
  },
  {
    label: "Jamaica",
    name: "America/Jamaica",
    offset: -5
  },
  {
    label: "Lima",
    name: "America/Lima",
    offset: -5
  },
  {
    label: "Panama",
    name: "America/Panama",
    offset: -5
  },
  {
    label: "Rio Branco",
    name: "America/Rio_Branco",
    offset: -5
  },
  {
    label: "Asuncion",
    name: "America/Asuncion",
    offset: -4
  },
  {
    label: "Barbados",
    name: "America/Barbados",
    offset: -4
  },
  {
    label: "Boa Vista",
    name: "America/Boa_Vista",
    offset: -4
  },
  {
    label: "Campo Grande",
    name: "America/Campo_Grande",
    offset: -4
  },
  {
    label: "Caracas",
    name: "America/Caracas",
    offset: -4
  },
  {
    label: "Cuiaba",
    name: "America/Cuiaba",
    offset: -4
  },
  {
    label: "Curacao",
    name: "America/Curacao",
    offset: -4
  },
  {
    label: "Detroit",
    name: "America/Detroit",
    offset: -4
  },
  {
    label: "Eastern Time",
    name: "America/New_York",
    offset: -4
  },
  {
    label: "Eastern Time - Iqaluit",
    name: "America/Iqaluit",
    offset: -4
  },
  {
    label: "Eastern Time - Toronto",
    name: "America/Toronto",
    offset: -4
  },
  {
    label: "Grand Turk",
    name: "America/Grand_Turk",
    offset: -4
  },
  {
    label: "Guyana",
    name: "America/Guyana",
    offset: -4
  },
  {
    label: "Havana",
    name: "America/Havana",
    offset: -4
  },
  {
    label: "La Paz",
    name: "America/La_Paz",
    offset: -4
  },
  {
    label: "Manaus",
    name: "America/Manaus",
    offset: -4
  },
  {
    label: "Martinique",
    name: "America/Martinique",
    offset: -4
  },
  {
    label: "Nassau",
    name: "America/Nassau",
    offset: -4
  },
  {
    label: "Port of Spain",
    name: "America/Port_of_Spain",
    offset: -4
  },
  {
    label: "Port-au-Prince",
    name: "America/Port-au-Prince",
    offset: -4
  },
  {
    label: "Porto Velho",
    name: "America/Porto_Velho",
    offset: -4
  },
  {
    label: "Puerto Rico",
    name: "America/Puerto_Rico",
    offset: -4
  },
  {
    label: "Santiago",
    name: "America/Santiago",
    offset: -4
  },
  {
    label: "Santo Domingo",
    name: "America/Santo_Domingo",
    offset: -4
  },
  {
    label: "Araguaina",
    name: "America/Araguaina",
    offset: -3
  },
  {
    label: "Atlantic Time - Halifax",
    name: "America/Halifax",
    offset: -3
  },
  {
    label: "Belem",
    name: "America/Belem",
    offset: -3
  },
  {
    label: "Bermuda",
    name: "Atlantic/Bermuda",
    offset: -3
  },
  {
    label: "Buenos Aires",
    name: "America/Argentina/Buenos_Aires",
    offset: -3
  },
  {
    label: "Cayenne",
    name: "America/Cayenne",
    offset: -3
  },
  {
    label: "Cordoba",
    name: "America/Argentina/Cordoba",
    offset: -3
  },
  {
    label: "Fortaleza",
    name: "America/Fortaleza",
    offset: -3
  },
  {
    label: "Maceio",
    name: "America/Maceio",
    offset: -3
  },
  {
    label: "Montevideo",
    name: "America/Montevideo",
    offset: -3
  },
  {
    label: "Palmer",
    name: "Antarctica/Palmer",
    offset: -3
  },
  {
    label: "Paramaribo",
    name: "America/Paramaribo",
    offset: -3
  },
  {
    label: "Punta Arenas",
    name: "America/Punta_Arenas",
    offset: -3
  },
  {
    label: "Recife",
    name: "America/Recife",
    offset: -3
  },
  {
    label: "Rothera",
    name: "Antarctica/Rothera",
    offset: -3
  },
  {
    label: "Salvador",
    name: "America/Bahia",
    offset: -3
  },
  {
    label: "Sao Paulo",
    name: "America/Sao_Paulo",
    offset: -3
  },
  {
    label: "Stanley",
    name: "Atlantic/Stanley",
    offset: -3
  },
  {
    label: "Thule",
    name: "America/Thule",
    offset: -3
  },
  {
    label: "Newfoundland Time - St. Johns",
    name: "America/St_Johns",
    offset: -2.5
  },
  {
    label: "Godthab",
    name: "America/Godthab",
    offset: -2
  },
  {
    label: "Miquelon",
    name: "America/Miquelon",
    offset: -2
  },
  {
    label: "Noronha",
    name: "America/Noronha",
    offset: -2
  },
  {
    label: "South Georgia",
    name: "Atlantic/South_Georgia",
    offset: -2
  },
  {
    label: "Cape Verde",
    name: "Atlantic/Cape_Verde",
    offset: -1
  },
  {
    label: "Abidjan",
    name: "Africa/Abidjan",
    offset: 0
  },
  {
    label: "Accra",
    name: "Africa/Accra",
    offset: 0
  },
  {
    label: "Azores",
    name: "Atlantic/Azores",
    offset: 0
  },
  {
    label: "Bissau",
    name: "Africa/Bissau",
    offset: 0
  },
  {
    label: "Casablanca",
    name: "Africa/Casablanca",
    offset: 0
  },
  {
    label: "Danmarkshavn",
    name: "America/Danmarkshavn",
    offset: 0
  },
  {
    label: "El Aaiun",
    name: "Africa/El_Aaiun",
    offset: 0
  },
  {
    label: "GMT (no daylight saving)",
    name: "Etc/GMT",
    offset: 0
  },
  {
    label: "Monrovia",
    name: "Africa/Monrovia",
    offset: 0
  },
  {
    label: "Reykjavik",
    name: "Atlantic/Reykjavik",
    offset: 0
  },
  {
    label: "Scoresbysund",
    name: "America/Scoresbysund",
    offset: 0
  },
  {
    label: "UTC",
    name: "UTC",
    offset: null
  },
  {
    label: "Algiers",
    name: "Africa/Algiers",
    offset: 1
  },
  {
    label: "Canary Islands",
    name: "Atlantic/Canary",
    offset: 1
  },
  {
    label: "Dublin",
    name: "Europe/Dublin",
    offset: 1
  },
  {
    label: "Faeroe",
    name: "Atlantic/Faroe",
    offset: 1
  },
  {
    label: "Lagos",
    name: "Africa/Lagos",
    offset: 1
  },
  {
    label: "Lisbon",
    name: "Europe/Lisbon",
    offset: 1
  },
  {
    label: "London",
    name: "Europe/London",
    offset: 1
  },
  {
    label: "Ndjamena",
    name: "Africa/Ndjamena",
    offset: 1
  },
  {
    label: "Sao Tome",
    name: "Africa/Sao_Tome",
    offset: 1
  },
  {
    label: "Tunis",
    name: "Africa/Tunis",
    offset: 1
  },
  {
    label: "Amsterdam",
    name: "Europe/Amsterdam",
    offset: 2
  },
  {
    label: "Andorra",
    name: "Europe/Andorra",
    offset: 2
  },
  {
    label: "Berlin",
    name: "Europe/Berlin",
    offset: 2
  },
  {
    label: "Brussels",
    name: "Europe/Brussels",
    offset: 2
  },
  {
    label: "Budapest",
    name: "Europe/Budapest",
    offset: 2
  },
  {
    label: "Cairo",
    name: "Africa/Cairo",
    offset: 2
  },
  {
    label: "Central European Time - Belgrade",
    name: "Europe/Belgrade",
    offset: 2
  },
  {
    label: "Central European Time - Prague",
    name: "Europe/Prague",
    offset: 2
  },
  {
    label: "Ceuta",
    name: "Africa/Ceuta",
    offset: 2
  },
  {
    label: "Copenhagen",
    name: "Europe/Copenhagen",
    offset: 2
  },
  {
    label: "Gibraltar",
    name: "Europe/Gibraltar",
    offset: 2
  },
  {
    label: "Johannesburg",
    name: "Africa/Johannesburg",
    offset: 2
  },
  {
    label: "Khartoum",
    name: "Africa/Khartoum",
    offset: 2
  },
  {
    label: "Luxembourg",
    name: "Europe/Luxembourg",
    offset: 2
  },
  {
    label: "Madrid",
    name: "Europe/Madrid",
    offset: 2
  },
  {
    label: "Malta",
    name: "Europe/Malta",
    offset: 2
  },
  {
    label: "Maputo",
    name: "Africa/Maputo",
    offset: 2
  },
  {
    label: "Monaco",
    name: "Europe/Monaco",
    offset: 2
  },
  {
    label: "Moscow-01 - Kaliningrad",
    name: "Europe/Kaliningrad",
    offset: 2
  },
  {
    label: "Oslo",
    name: "Europe/Oslo",
    offset: 2
  },
  {
    label: "Paris",
    name: "Europe/Paris",
    offset: 2
  },
  {
    label: "Rome",
    name: "Europe/Rome",
    offset: 2
  },
  {
    label: "Stockholm",
    name: "Europe/Stockholm",
    offset: 2
  },
  {
    label: "Tirane",
    name: "Europe/Tirane",
    offset: 2
  },
  {
    label: "Tripoli",
    name: "Africa/Tripoli",
    offset: 2
  },
  {
    label: "Vienna",
    name: "Europe/Vienna",
    offset: 2
  },
  {
    label: "Warsaw",
    name: "Europe/Warsaw",
    offset: 2
  },
  {
    label: "Windhoek",
    name: "Africa/Windhoek",
    offset: 2
  },
  {
    label: "Zurich",
    name: "Europe/Zurich",
    offset: 2
  },
  {
    label: "Amman",
    name: "Asia/Amman",
    offset: 3
  },
  {
    label: "Athens",
    name: "Europe/Athens",
    offset: 3
  },
  {
    label: "Baghdad",
    name: "Asia/Baghdad",
    offset: 3
  },
  {
    label: "Beirut",
    name: "Asia/Beirut",
    offset: 3
  },
  {
    label: "Bucharest",
    name: "Europe/Bucharest",
    offset: 3
  },
  {
    label: "Chisinau",
    name: "Europe/Chisinau",
    offset: 3
  },
  {
    label: "Damascus",
    name: "Asia/Damascus",
    offset: 3
  },
  {
    label: "Gaza",
    name: "Asia/Gaza",
    offset: 3
  },
  {
    label: "Helsinki",
    name: "Europe/Helsinki",
    offset: 3
  },
  {
    label: "Istanbul",
    name: "Europe/Istanbul",
    offset: 3
  },
  {
    label: "Jerusalem",
    name: "Asia/Jerusalem",
    offset: 3
  },
  {
    label: "Kiev",
    name: "Europe/Kiev",
    offset: 3
  },
  {
    label: "Minsk",
    name: "Europe/Minsk",
    offset: 3
  },
  {
    label: "Moscow+00 - Moscow",
    name: "Europe/Moscow",
    offset: 3
  },
  {
    label: "Nairobi",
    name: "Africa/Nairobi",
    offset: 3
  },
  {
    label: "Nicosia",
    name: "Asia/Nicosia",
    offset: 3
  },
  {
    label: "Qatar",
    name: "Asia/Qatar",
    offset: 3
  },
  {
    label: "Riga",
    name: "Europe/Riga",
    offset: 3
  },
  {
    label: "Riyadh",
    name: "Asia/Riyadh",
    offset: 3
  },
  {
    label: "Sofia",
    name: "Europe/Sofia",
    offset: 3
  },
  {
    label: "Syowa",
    name: "Antarctica/Syowa",
    offset: 3
  },
  {
    label: "Tallinn",
    name: "Europe/Tallinn",
    offset: 3
  },
  {
    label: "Vilnius",
    name: "Europe/Vilnius",
    offset: 3
  },
  {
    label: "Baku",
    name: "Asia/Baku",
    offset: 4
  },
  {
    label: "Dubai",
    name: "Asia/Dubai",
    offset: 4
  },
  {
    label: "Mahe",
    name: "Indian/Mahe",
    offset: 4
  },
  {
    label: "Mauritius",
    name: "Indian/Mauritius",
    offset: 4
  },
  {
    label: "Moscow+01 - Samara",
    name: "Europe/Samara",
    offset: 4
  },
  {
    label: "Reunion",
    name: "Indian/Reunion",
    offset: 4
  },
  {
    label: "Tbilisi",
    name: "Asia/Tbilisi",
    offset: 4
  },
  {
    label: "Yerevan",
    name: "Asia/Yerevan",
    offset: 4
  },
  {
    label: "Kabul",
    name: "Asia/Kabul",
    offset: 4.5
  },
  {
    label: "Tehran",
    name: "Asia/Tehran",
    offset: 4.5
  },
  {
    label: "Aqtau",
    name: "Asia/Aqtau",
    offset: 5
  },
  {
    label: "Aqtobe",
    name: "Asia/Aqtobe",
    offset: 5
  },
  {
    label: "Ashgabat",
    name: "Asia/Ashgabat",
    offset: 5
  },
  {
    label: "Dushanbe",
    name: "Asia/Dushanbe",
    offset: 5
  },
  {
    label: "Karachi",
    name: "Asia/Karachi",
    offset: 5
  },
  {
    label: "Kerguelen",
    name: "Indian/Kerguelen",
    offset: 5
  },
  {
    label: "Maldives",
    name: "Indian/Maldives",
    offset: 5
  },
  {
    label: "Mawson",
    name: "Antarctica/Mawson",
    offset: 5
  },
  {
    label: "Moscow+02 - Yekaterinburg",
    name: "Asia/Yekaterinburg",
    offset: 5
  },
  {
    label: "Tashkent",
    name: "Asia/Tashkent",
    offset: 5
  },
  {
    label: "Colombo",
    name: "Asia/Colombo",
    offset: 5.5
  },
  {
    label: "India Standard Time",
    name: "Asia/Calcutta",
    offset: 5.5
  },
  {
    label: "Katmandu",
    name: "Asia/Katmandu",
    offset: 5.75
  },
  {
    label: "Almaty",
    name: "Asia/Almaty",
    offset: 6
  },
  {
    label: "Bishkek",
    name: "Asia/Bishkek",
    offset: 6
  },
  {
    label: "Chagos",
    name: "Indian/Chagos",
    offset: 6
  },
  {
    label: "Dhaka",
    name: "Asia/Dhaka",
    offset: 6
  },
  {
    label: "Moscow+03 - Omsk",
    name: "Asia/Omsk",
    offset: 6
  },
  {
    label: "Thimphu",
    name: "Asia/Thimphu",
    offset: 6
  },
  {
    label: "Vostok",
    name: "Antarctica/Vostok",
    offset: 6
  },
  {
    label: "Cocos",
    name: "Indian/Cocos",
    offset: 6.5
  },
  {
    label: "Rangoon",
    name: "Asia/Yangon",
    offset: 6.5
  },
  {
    label: "Bangkok",
    name: "Asia/Bangkok",
    offset: 7
  },
  {
    label: "Christmas",
    name: "Indian/Christmas",
    offset: 7
  },
  {
    label: "Davis",
    name: "Antarctica/Davis",
    offset: 7
  },
  {
    label: "Hanoi",
    name: "Asia/Saigon",
    offset: 7
  },
  {
    label: "Hovd",
    name: "Asia/Hovd",
    offset: 7
  },
  {
    label: "Jakarta",
    name: "Asia/Jakarta",
    offset: 7
  },
  {
    label: "Moscow+04 - Krasnoyarsk",
    name: "Asia/Krasnoyarsk",
    offset: 7
  },
  {
    label: "Brunei",
    name: "Asia/Brunei",
    offset: 8
  },
  {
    label: "Casey",
    name: "Antarctica/Casey",
    offset: 8
  },
  {
    label: "China Time - Beijing",
    name: "Asia/Shanghai",
    offset: 8
  },
  {
    label: "Choibalsan",
    name: "Asia/Choibalsan",
    offset: 8
  },
  {
    label: "Hong Kong",
    name: "Asia/Hong_Kong",
    offset: 8
  },
  {
    label: "Kuala Lumpur",
    name: "Asia/Kuala_Lumpur",
    offset: 8
  },
  {
    label: "Macau",
    name: "Asia/Macau",
    offset: 8
  },
  {
    label: "Makassar",
    name: "Asia/Makassar",
    offset: 8
  },
  {
    label: "Manila",
    name: "Asia/Manila",
    offset: 8
  },
  {
    label: "Moscow+05 - Irkutsk",
    name: "Asia/Irkutsk",
    offset: 8
  },
  {
    label: "Singapore",
    name: "Asia/Singapore",
    offset: 8
  },
  {
    label: "Taipei",
    name: "Asia/Taipei",
    offset: 8
  },
  {
    label: "Ulaanbaatar",
    name: "Asia/Ulaanbaatar",
    offset: 8
  },
  {
    label: "Western Time - Perth",
    name: "Australia/Perth",
    offset: 8
  },
  {
    label: "Dili",
    name: "Asia/Dili",
    offset: 9
  },
  {
    label: "Jayapura",
    name: "Asia/Jayapura",
    offset: 9
  },
  {
    label: "Moscow+06 - Yakutsk",
    name: "Asia/Yakutsk",
    offset: 9
  },
  {
    label: "Palau",
    name: "Pacific/Palau",
    offset: 9
  },
  {
    label: "Pyongyang",
    name: "Asia/Pyongyang",
    offset: 9
  },
  {
    label: "Seoul",
    name: "Asia/Seoul",
    offset: 9
  },
  {
    label: "Tokyo",
    name: "Asia/Tokyo",
    offset: 9
  },
  {
    label: "Central Time - Adelaide",
    name: "Australia/Adelaide",
    offset: 9.5
  },
  {
    label: "Central Time - Darwin",
    name: "Australia/Darwin",
    offset: 9.5
  },
  {
    label: "Dumont D'Urville",
    name: "Antarctica/DumontDUrville",
    offset: 10
  },
  {
    label: "Eastern Time - Brisbane",
    name: "Australia/Brisbane",
    offset: 10
  },
  {
    label: "Eastern Time - Hobart",
    name: "Australia/Hobart",
    offset: 10
  },
  {
    label: "Eastern Time - Melbourne",
    name: "Australia/Melbourne",
    offset: 10
  },
  {
    label: "Eastern Time - Melbourne, Sydney",
    name: "Australia/Sydney",
    offset: 10
  },
  {
    label: "Guam",
    name: "Pacific/Guam",
    offset: 10
  },
  {
    label: "Moscow+07 - Vladivostok",
    name: "Asia/Vladivostok",
    offset: 10
  },
  {
    label: "Port Moresby",
    name: "Pacific/Port_Moresby",
    offset: 10
  },
  {
    label: "Truk",
    name: "Pacific/Chuuk",
    offset: 10
  },
  {
    label: "Efate",
    name: "Pacific/Efate",
    offset: 11
  },
  {
    label: "Guadalcanal",
    name: "Pacific/Guadalcanal",
    offset: 11
  },
  {
    label: "Kosrae",
    name: "Pacific/Kosrae",
    offset: 11
  },
  {
    label: "Moscow+08 - Magadan",
    name: "Asia/Magadan",
    offset: 11
  },
  {
    label: "Norfolk",
    name: "Pacific/Norfolk",
    offset: 11
  },
  {
    label: "Noumea",
    name: "Pacific/Noumea",
    offset: 11
  },
  {
    label: "Ponape",
    name: "Pacific/Pohnpei",
    offset: 11
  },
  {
    label: "Auckland",
    name: "Pacific/Auckland",
    offset: 12
  },
  {
    label: "Fiji",
    name: "Pacific/Fiji",
    offset: 12
  },
  {
    label: "Funafuti",
    name: "Pacific/Funafuti",
    offset: 12
  },
  {
    label: "Kwajalein",
    name: "Pacific/Kwajalein",
    offset: 12
  },
  {
    label: "Majuro",
    name: "Pacific/Majuro",
    offset: 12
  },
  {
    label: "Moscow+09 - Petropavlovsk-Kamchatskiy",
    name: "Asia/Kamchatka",
    offset: 12
  },
  {
    label: "Nauru",
    name: "Pacific/Nauru",
    offset: 12
  },
  {
    label: "Tarawa",
    name: "Pacific/Tarawa",
    offset: 12
  },
  {
    label: "Wake",
    name: "Pacific/Wake",
    offset: 12
  },
  {
    label: "Wallis",
    name: "Pacific/Wallis",
    offset: 12
  },
  {
    label: "Apia",
    name: "Pacific/Apia",
    offset: 13
  },
  {
    label: "Enderbury",
    name: "Pacific/Enderbury",
    offset: 13
  },
  {
    label: "Fakaofo",
    name: "Pacific/Fakaofo",
    offset: 13
  },
  {
    label: "Tongatapu",
    name: "Pacific/Tongatapu",
    offset: 13
  },
  {
    label: "Kiritimati",
    name: "Pacific/Kiritimati",
    offset: 14
  }
];

export const formatTimezoneLabel = zone => {
  const offsetSign = zone.offset < 0 ? "-" : "+";
  const hours = `${Math.floor(Math.abs(zone.offset))}`.padStart(2, "0");
  const minutes = `${Math.abs((zone.offset % 1) * 60)}`.padStart(2, "0");

  return `${zone.label} (${offsetSign}${hours}:${minutes})`;
};

export const timezonesSortedByLabel = timezones.sort((a, b) =>
  a.label.localeCompare(b.label)
);

export default timezones;
