export default function querystring(query, prefix = "") {
  const params = query || {};

  const qs = Object.keys(params)
    .filter(k => typeof params[k] !== "undefined")
    .map(k => `${k}=${encodeURIComponent(params[k])}`)
    .join("&");

  return qs ? `${prefix}?${qs}` : prefix;
}

export const parseQuerystring = (qs = "") => {
  return qs
    .substr(1)
    .split("&")
    .filter(part => !!part)
    .map(parts => parts.split("="))
    .reduce((params, [name, value]) => ({ ...params, [name]: value }), {});
};
