import "./Clean.scss";

import { ReactComponent as BinIcon } from "assets/icons/bin.svg";
import classnames from "classnames";
import { differenceInDays, format, isPast } from "date-fns";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar.svg";
import { ReactComponent as ClockIcon } from "../../../../assets/icons/clock.svg";
import Card from "../../../../components/common/Card";
import { shortPrettyDate } from "../../../../utils/dates";
import CleanModal from "./CleanModal";

const CANCELLATION_DAYS_AWAY = 3;

export class UpcomingClean extends Component {
  state = {
    showCleanModal: false
  };

  toggleCleanModal = () => {
    this.setState({
      showCleanModal: !this.state.showCleanModal
    });
  };

  renderCleanText() {
    const { t, clean } = this.props;
    const type = clean.clean_type;
    const guestName = clean.booking && clean.booking.guest_name;

    const textOptions = {
      standard: guestName
        ? t("clean.autoscheduled", { guestName })
        : t("clean.standard"),
      first: t("clean.first_clean"),
      mid_stay: t("clean.mid_stay_clean"),
      post_host: t("clean.post_host_clean")
    };

    return textOptions[type];
  }

  render() {
    const { showCleanModal } = this.state;
    const { t, clean } = this.props;
    const startDate = clean.current_confirmed_clean?.start || clean.clean_date;
    const tooLate =
      differenceInDays(startDate, new Date()) < CANCELLATION_DAYS_AWAY;
    const isCancelled = Boolean(clean.cancelled_at);
    const showCancel = !isCancelled && !isPast(startDate);
    const cleanTime = clean.current_confirmed_clean?.start;

    const containerClassName = classnames("Clean", {
      "Clean--cancelled": isCancelled
    });

    return (
      <Card className={containerClassName} data-cy="clean-info">
        {showCleanModal && (
          <CleanModal
            clean={clean}
            toggleCleanModal={this.toggleCleanModal}
            lateCancel={tooLate}
          />
        )}

        <div className="Clean__info-block">
          <div className="Clean__message">
            <div>
              <strong className="Clean__info">{this.renderCleanText()}</strong>
            </div>
            <div>
              {showCancel && (
                <BinIcon
                  data-cy="clean-bin"
                  className="Clean__bin"
                  onClick={this.toggleCleanModal}
                />
              )}
              {isCancelled && t("clean.cancelled")}
            </div>
          </div>
        </div>

        <div className="Clean__info-block">
          <div className="Clean__dates">
            <CalendarIcon />
            {shortPrettyDate(startDate)}
            {cleanTime && (
              <>
                <ClockIcon />
                {format(cleanTime, "HH:mm")}
              </>
            )}
          </div>
        </div>
      </Card>
    );
  }
}

export default withTranslation("host.cleans")(UpcomingClean);
