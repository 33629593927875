import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "services/api";

import {
  previousCleansResponse,
  upcomingCleansResponse
} from "../../../data/cleans/actions";
import { getSelectedPropertyId } from "../../../data/properties/selectors";

export const CLEANS_LIMIT = 10;

export const useGetUpcomingCleans = upcomingCleansLength => {
  const dispatch = useDispatch();
  const selectedPropertyId = useSelector(getSelectedPropertyId);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isFinal, setIsFinal] = useState(true);

  const getUpcomingCleans = useCallback(
    async offset => {
      setLoading(true);

      api
        .get(
          `/v1/hosts/properties/${selectedPropertyId}/upcoming_clean_demands`,
          {
            offset,
            limit: CLEANS_LIMIT
          }
        )
        .then(res => {
          setLoading(false);

          dispatch(upcomingCleansResponse(res, selectedPropertyId));

          if (res.items.length < CLEANS_LIMIT || res.items.length === 0) {
            setIsFinal(true);
          }
        })
        .catch(err => {
          setError(err);
        });
    },
    [selectedPropertyId, dispatch]
  );

  useEffect(() => {
    if (upcomingCleansLength === 0) {
      setIsFinal(false);
      getUpcomingCleans();
    }
  }, [selectedPropertyId, getUpcomingCleans, upcomingCleansLength]);

  return { getUpcomingCleans, isFinal, loading, error };
};

export const useGetPreviousCleans = previousCleansLength => {
  const dispatch = useDispatch();
  const selectedPropertyId = useSelector(getSelectedPropertyId);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isFinal, setIsFinal] = useState(true);

  const getPreviousCleans = useCallback(
    async offset => {
      setLoading(true);

      api
        .get(`/v1/hosts/properties/${selectedPropertyId}/previous_cleans`, {
          offset,
          limit: CLEANS_LIMIT
        })
        .then(res => {
          setLoading(false);

          dispatch(previousCleansResponse(res, selectedPropertyId));

          if (res.items.length < CLEANS_LIMIT || res.items.length === 0) {
            setIsFinal(true);
          }
        })
        .catch(err => {
          setError(err);
        });
    },
    [selectedPropertyId, dispatch]
  );

  useEffect(() => {
    if (previousCleansLength === 0) {
      setIsFinal(false);
      getPreviousCleans();
    }
  }, [selectedPropertyId, getPreviousCleans, previousCleansLength]);

  return { getPreviousCleans, isFinal, loading, error };
};
