import "./Banner.scss";

import { ReactComponent as CloseIcon } from "assets/icons/close-white.svg";
import React from "react";

const Banner = ({ title, children, handleDismissBanner }) => (
  <div className="Banner Banner--info">
    <h4 className="Banner__title header">{title}</h4>

    <button
      type="button"
      className="Banner__close"
      onClick={handleDismissBanner}
    >
      <CloseIcon />
    </button>

    <div className="Banner__content">{children}</div>
  </div>
);

export default Banner;
