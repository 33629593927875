const reducers = {
  FETCH_START: (state, { key }) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        fetching: true,
        failed: 0,
        missing: 0,
        error: null
      }
    };
  },

  FETCH_SUCCESS: (state, { key, payload = null }) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        fetching: false,
        fetched: Date.now(),
        failed: 0,
        missing: 0,
        error: null,
        payload
      }
    };
  },

  FETCH_FAILED: (state, { key, error }) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        fetching: false,
        failed: Date.now(),
        error
      }
    };
  },

  FETCH_MISSING: (state, { key }) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        fetching: false,
        missing: Date.now()
      }
    };
  },

  FETCH_CLEAR: (state, { key, exact = true }) => {
    const keys = [key];

    if (!exact) {
      Object.keys(state).forEach(k => {
        if (k.indexOf(key) !== -1) {
          keys.push(k);
        }
      });
    }

    keys.forEach(k => delete state[k]);

    return {
      ...state
    };
  },

  FETCH_FORCE_REFETCH: (state, { key, exact = true }) => {
    const keys = [key];

    if (!exact) {
      Object.keys(state).forEach(k => {
        if (k.indexOf(key) !== -1) {
          keys.push(k);
        }
      });
    }

    keys.forEach(k => {
      if (!state[k]) {
        return;
      }

      // Reset the fetched value so it triggers a refetch either to 1 if already fetched or null
      state[k] = {
        ...state[k],
        fetched: state[k].fetched ? 1 : null
      };
    });

    return {
      ...state
    };
  }
};

export default function fetchers(state = {}, action) {
  return reducers[action.type] ? reducers[action.type](state, action) : state;
}
