import { RECEIVE_PREFERENCES } from "../action_types";

export const basePropertyState = () => ({
  settings: {}
});

export const initialState = {
  byProperty: {}
};

const preferences = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PREFERENCES: {
      const propertyId = action.propertyId;
      const prefs = state.byProperty[propertyId] || basePropertyState();

      return {
        ...state,
        byProperty: {
          ...state.byProperty,
          [propertyId]: {
            ...prefs,
            settings: {
              ...prefs.settings,
              ...action.settings
            }
          }
        }
      };
    }

    default:
      return state;
  }
};

export default preferences;
