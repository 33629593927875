import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { createLogger } from "redux-logger";
import reduxThunk from "redux-thunk";

import logger from "../utils/logger";
import arrayMiddleware from "./middleware/arrayMiddleware";
import languageSelectMiddleware from "./middleware/languageSelectMiddleware";
import promiseMiddleware from "./middleware/promiseMiddleware";
import trackerMiddleware from "./middleware/trackerMiddleware";
import rootReducer from "./reducers/root";

const middlewares = [
  reduxThunk,
  promiseMiddleware,
  arrayMiddleware,
  trackerMiddleware,
  languageSelectMiddleware,
  createLogger({
    logger,
    collapsed: true
  })
].filter(Boolean);

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
