import "./PlatformsToggle.scss";

import Button from "components/buttons/Button";
import Modal from "components/common/modal";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";
import Input from "../../../../components/common/Input";
import Tip from "../../../../components/common/Tip.jsx";
import { receiveProperty } from "../../../../data/actions/properties";
import api from "../../../../services/api";

const PlatformsToggle = ({ property }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation("host.platforms");
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    ({ multiplatActive: multiplat_active }) => {
      api
        .patch(`/v1/hosts/properties/${property.id}`, null, {
          multiplat_active
        })
        .then(res => {
          dispatch(receiveProperty(res));
          setShowModal(false);
        });
    },
    [dispatch, property.id]
  );

  const handleMultiplatformToggle = useCallback(() => {
    property.multiplat_active
      ? setShowModal(true)
      : handleSubmit({ multiplatActive: true });
  }, [handleSubmit, property.multiplat_active]);

  return (
    <div className="PlatformsToggle">
      {showModal && (
        <Modal
          title={t("platforms_toggle.modal_title")}
          closeModal={() => setShowModal(false)}
          footer={
            <div>
              <Button outline type="button" onClick={() => setShowModal(false)}>
                {t("common:cancel")}
              </Button>

              <Button
                type="submit"
                onClick={() => handleSubmit({ multiplatActive: false })}
              >
                {t("platforms_toggle.modal_confirm_disable")}
              </Button>
            </div>
          }
        >
          <p>{t("platforms_toggle.modal_info_1")}</p>
          <p>{t("platforms_toggle.modal_info_2")}</p>
        </Modal>
      )}

      <div className="PlatformsToggle__container">
        <Input.Toggle
          testId="multiplatform-toggle"
          split
          name="multiplat_active"
          label={
            <>
              {t("platforms_toggle.toggle.label")}
              <Tip position="right" html={t("platforms_description")}>
                <InfoIcon className="TipIcon" />
              </Tip>
            </>
          }
          toggleLabelOn={t("common:radio_toggle_label_on")}
          toggleLabelOff={t("common:radio_toggle_label_off")}
          value={property.multiplat_active}
          onChange={handleMultiplatformToggle}
        />
      </div>
    </div>
  );
};

export default PlatformsToggle;
