import "./PriceBreakdown.scss";

import { parseBooking } from "data/reducers/bookings";
import { isAdmin } from "data/reducers/user";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import api from "services/api";

import { Revenue } from "./Revenue";
import { RevenueWithoutCleaningFee } from "./RevenueWithoutCleaningFee";
import SampleBookingBreakdown from "./SampleBookingBreakdown";

const PriceBreakdown = ({ bookingId, sample }) => {
  const { t } = useTranslation("host.bookings");

  const [booking, setBooking] = useState(null);
  const [error, setError] = useState(false);
  const admin = useSelector(isAdmin);

  useEffect(() => {
    const getBooking = bookingId => {
      api
        .get(`/v1/hosts/bookings/${bookingId}`, {
          is_admin: admin
        })
        .then(fetchedBooking => {
          setBooking(parseBooking(fetchedBooking));
        })
        .catch(() => {
          setError(true);
        });
    };

    getBooking(bookingId);
  }, [admin, bookingId]);

  if (error) {
    return (
      <div className="PriceBreakdown">
        <p className="PriceBreakdown__error-text">
          {t("common:something_went_wrong")}
        </p>
      </div>
    );
  }

  if (sample) {
    return (
      <div className="PriceBreakdown">
        <SampleBookingBreakdown />
      </div>
    );
  }

  if (booking?.revenue_breakdown && !booking.is_tenancy) {
    const revenue = booking.revenue_breakdown;
    if (revenue.clean_excluded) {
      // Modulr
      return (
        <RevenueWithoutCleaningFee
          revenue={revenue}
          channelName={booking?.booking_channel.name}
        />
      );
    }
    return (
      <Revenue revenue={revenue} channelName={booking?.booking_channel.name} />
    );
  }

  return null;
};

export default PriceBreakdown;
