export const AIRSORTED_RESOLVE_ACTION = "airsorted_resolve";
export const HOST_RESOLVE_ACTION = "host_resolve";
export const IGNORE_ACTION = "ignore";
export const REQUEST_INFORMATION_ACTION = "request_information";

export const PATCH_MAINTENANCE_REQUEST = "patch-maintenance-request";
export const PATCH_MAINTENANCE_RESPONSE = "patch-maintenance-repsonse";

export const RECEIVE_MAINTENANCE_LIST = "RECEIVE_MAINTENANCE_LIST";
export const PATCH_MAINTENANCE_ITEM = "PATCH_MAINTENANCE_ITEM";

export const MAINTENANCE_REPORTER = {
  host_via_cs: "host",
  guest_via_gex: "guest",
  cleaner: "cleaner",
  operator: "operator"
};

export const MAINTENANCE_STATUS_ACCEPTED = "accepted";
export const MAINTENANCE_STATUS_PLANNED = "planned";
export const MAINTENANCE_STATUS_HOST_PLANNED = "host_planned";
export const MAINTENANCE_STATUS_RESOLVED = "resolved";
export const MAINTENANCE_STATUS_IGNORED = "ignored";

export const MAINTENANCE_FEE_TYPE_TIME_BASED = ["hr", "day"];
export const MAINTENANCE_FEE_TYPE_MAP = { hr: "hr", day: "day" };
