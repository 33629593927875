import "./ImagesCarousel.scss";

import Button from "components/buttons/Button";
import Modal from "components/common/modal";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";

import thumbnail from "../../assets/icons/video.svg";
import { IMAGE_CAROUSEL_TYPE_CLEAN_IMAGES } from "../../data/cleans/types";
import CleanRatingSurvey from "../../scenes/dashboard/cleans/components/CleanRatingSurvey";
import { cleanImageUrl } from "../../utils/cleanImageUrl";
import { shortPrettyDate } from "../../utils/dates";

const VIDEO_CONTENT_TYPES = ["video/mp4", "video/quicktime"];

export const ImagesCarousel = ({
  toggleModal,
  hostSurveyExists,
  carouselType,
  cleanId,
  images
}) => {
  const { t } = useTranslation("host.cleans");
  const [showCleanSurvey, setShowCleanSurvey] = useState(false);
  const [index, setIndex] = useState(0);

  const formattedImages = useMemo(() => {
    if (carouselType === "host_photos") {
      return images.map(image => {
        if (VIDEO_CONTENT_TYPES.includes(image.content_type)) {
          return {
            original: image.image_url,
            thumbnail: thumbnail,
            originalClass: "Original",
            thumbnailClass: "Thumbnail",
            created_at: image.created_at,
            name: image.file_name,
            renderItem: () => (
              <video controls muted className="video">
                <source src={image.image_url} />
              </video>
            )
          };
        }
        return {
          original: image.image_url,
          thumbnail: image.thumbnail_url,
          originalClass: "Original",
          thumbnailClass: "Thumbnail",
          created_at: image.created_at,
          name: image.file_name
        };
      });
    }

    return images.map(image => ({
      original: cleanImageUrl(image.image_url),
      thumbnail: cleanImageUrl(image.image_url, { w: 100, h: 100 }),
      originalClass: "Original",
      thumbnailClass: "Thumbnail",
      originalTitle: t(`clean.images.types.${image.type}`),
      created_at: image.created_at
    }));
  }, [images, t, carouselType]);

  const handleOnClickRate = () => {
    setShowCleanSurvey(true);
  };

  if (showCleanSurvey) {
    return <CleanRatingSurvey toggleModal={toggleModal} cleanId={cleanId} />;
  }

  return (
    <Modal
      closeModal={() => toggleModal()}
      className="ImagesCarouselModal"
      showDesktopCloseButton={true}
    >
      <>
        <ImageMetaData image={formattedImages[index]} />
        <ImageGallery
          items={formattedImages}
          onSlide={setIndex}
          showPlayButton={false}
          lazyLoad={true}
          showFullscreenButton={false}
        />
      </>

      <div className="ImagesCarousel__bottom">
        <h5 className="ImagesCarousel__counter">
          {t("clean.images_carousel.title", {
            current: index + 1,
            total: images.length
          })}
        </h5>

        {carouselType === IMAGE_CAROUSEL_TYPE_CLEAN_IMAGES &&
          !hostSurveyExists && (
            <Button
              disabled={hostSurveyExists}
              testid="rate-clean-button"
              onClick={handleOnClickRate}
              theme="plain"
            >
              {t("clean.images_carousel.rate_clean")}
            </Button>
          )}
      </div>
    </Modal>
  );
};

const ImageMetaData = ({ image }) => {
  const { originalTitle, created_at, name } = image;

  return (
    <div className="ImagesCarousel__header" data-cy="clean-image-header">
      {Boolean(originalTitle) && <p>{originalTitle}</p>}
      {Boolean(created_at) && <p>{shortPrettyDate(created_at)}</p>}
      {Boolean(name) && <p>{name}</p>}
    </div>
  );
};

export default ImagesCarousel;
