export const CHART_RANGE_OPTIONS = {
  6: { months: 6, key: "6" },
  12: { months: 12, key: "12" },
  1: { months: 1, key: "1" }
};

export const getStatsSelectOptions = t => [
  { value: "1", label: t("stats.last_month") },
  { value: "6", label: t("stats.six_months") },
  { value: "12", label: t("stats.one_year") }
];
