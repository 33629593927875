import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import Card from "components/common/Card";
import Tip from "components/common/Tip";
import {
  getPropertiesWithMultiplatOption,
  getSelectedPropertyCurrency
} from "data/properties/selectors";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { showBankAccountForPayouts } from "../../../../utils/bankAccount";
import { BankAccount } from "./bank_account";

export const BankAccountForPayouts = ({ property }) => {
  const { t } = useTranslation("host.billing");
  const propertiesLocalityHasMultiplatOption =
    useSelector(getPropertiesWithMultiplatOption).length > 0;
  const currency = useSelector(getSelectedPropertyCurrency);

  const showContent = useMemo(
    () =>
      showBankAccountForPayouts(
        currency,
        propertiesLocalityHasMultiplatOption,
        property
      ),
    [currency, propertiesLocalityHasMultiplatOption, property]
  );

  const renderTooltip = () => {
    return (
      <>
        <h5 className="small-header">{t("bank_account.card_title")}</h5>

        <p>{t("bank_account.tooltip.description")}</p>
      </>
    );
  };

  if (!showContent) {
    return null;
  }

  return (
    <Card
      title={
        <React.Fragment>
          {t("bank_account.card_title")}

          <Tip position="bottom-end" html={renderTooltip()}>
            <InfoIcon className="Billing__info-icon" />
          </Tip>
        </React.Fragment>
      }
      dataTestid="bank-account-for-payouts"
    >
      <BankAccount />
    </Card>
  );
};

export default BankAccountForPayouts;
