import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const RequireAuth = ({ children }) => {
  const isLoggedIn = useSelector(state => !!state.user.authToken);
  return isLoggedIn ? children : <Navigate to="/login" />;
};

export default RequireAuth;
