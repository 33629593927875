import "./OverviewBooking.scss";

import GuestImage from "components/common/GuestImage";
import { Price } from "components/common/Price";
import { format } from "date-fns";
import React from "react";

import { PlatformLogo } from "../../../../components/common/PlatformLogo.jsx";

const OverviewBooking = ({ booking }) => (
  <div className="OverviewBooking">
    <div className="OverviewBooking__Image">
      <GuestImage imageUrl={booking.guest_photo_url} />
    </div>

    <div className="OverviewBooking__Details">
      <p className="OverviewBooking__Details--name">{booking.guest_name}</p>
      <span>
        <small>{format(booking.checkin, "eee d MMM")}</small>
      </span>
      {" - "}
      <span>
        <small> {format(booking.checkout, "eee d MMM")}</small>
      </span>
    </div>

    <div className="OverviewBooking__Breakdown">
      <PlatformLogo logoUrl={booking.booking_channel?.logo_url} />

      {booking.host_earnings && (
        <p>
          <Price amount={booking.host_earnings} />
        </p>
      )}
    </div>
  </div>
);

export default OverviewBooking;
