import "./HomeInfo.scss";

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, Navigate, Route, Routes } from "react-router-dom";

import Alert from "../../../components/common/Alert.jsx";
import TabControlWithNav from "../../../components/common/TabControlWithNavV6";
import Spinner from "../../../components/Spinner.jsx";
import { FormErrorBoundary } from "../../errors/FormErrorBoundary.jsx";
import { Amenities } from "./components/Amenities";
import { Beds } from "./components/beds/Beds";
import { Cleaning } from "./components/Cleaning";
import { Photos } from "./components/Photos";
import { Property } from "./components/Property";
import { HomeInfoContextProvider } from "./HomeInfoContext";
import { useHomeInfoContext } from "./HomeInfoContext";

const AMENITIES = "amenities";
const CLEANING = "cleaning";
const PROPERTY = "property";
const BEDS = "beds";
const FILES = "Files";

const Loading = () => {
  const { t } = useTranslation();

  return (
    <div className="HomeInfo__loading">
      <Spinner spin>{t("common:loading")}</Spinner>
    </div>
  );
};

export const HomeInfoLayout = () => {
  const url = "home-info";
  const { t } = useTranslation("host.home_info");

  const { data, error } = useHomeInfoContext();

  // Don't render any step before initial data load is finished
  if (!data) {
    return <Loading />;
  }

  return (
    <FormErrorBoundary error={error}>
      <div className="HomeInfo">
        {!data.can_edit && (
          <Alert warning small>
            <p>
              <Trans ns="host.home_info" i18nKey="cant_edit_alert">
                If you need to make any changes to the property information we
                have on file, please contact our{" "}
                <Link to="/contact">support team</Link>
              </Trans>
            </p>
          </Alert>
        )}

        <TabControlWithNav
          tabs={[
            {
              id: PROPERTY,
              url: `/${url}/${PROPERTY}`,
              text: t(PROPERTY)
            },
            {
              id: AMENITIES,
              url: `/${url}/${AMENITIES}`,
              text: t(AMENITIES)
            },
            {
              id: CLEANING,
              url: `/${url}/${CLEANING}`,
              text: t(CLEANING)
            },
            {
              id: BEDS,
              url: `/${url}/${BEDS}`,
              text: t(BEDS)
            },
            {
              id: FILES,
              url: `/${url}/${FILES}`,
              text: t(FILES)
            }
          ]}
        />

        <Routes>
          <Route path={`${AMENITIES}/*`} element={<Amenities />} />
          <Route path={`${CLEANING}/*`} element={<Cleaning />} />
          <Route path={`${PROPERTY}/*`} element={<Property />} />
          <Route path={`${BEDS}`} element={<Beds />} />
          <Route path={`${FILES}`} element={<Photos />} />
          <Route index element={<Navigate to={`${AMENITIES}`} />} />
        </Routes>
      </div>
    </FormErrorBoundary>
  );
};

export const HomeInfo = () => (
  <HomeInfoContextProvider>
    <HomeInfoLayout />
  </HomeInfoContextProvider>
);
