import React from "react";

import Input from "../../../../components/common/Input";

const TimeRangeSelect = ({
  onChange,
  options,
  dataCy,
  className = "Input--minimal",
  disabled
}) => (
  <Input.Select
    onChange={value => onChange(value)}
    className={className}
    data-cy={dataCy}
    disabled={disabled}
    isControlled={false}
  >
    {options.map(option => (
      <option value={option.value} key={option.value}>
        {option.label}
      </option>
    ))}
  </Input.Select>
);

export default TimeRangeSelect;
