import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStripe } from "scenes/dashboard/billing/WithStripe.jsx";
import api from "services/api";

import { ReactComponent as ThumbUpIcon } from "../../../../assets/icons/thumb_up.svg";
import Button from "../../../../components/buttons/Button.jsx";
import DefaultError from "../../../../components/common/DefaultError.jsx";
import { useOnboardingContext } from "../../SignupContext.jsx";
import NavButtonsWrapper from "../NavButtonsWrapper.jsx";
import { CardForm } from "./CardForm.jsx";

export const StepAddCard = () => {
  const { refreshData, data, loading, currentStep, handlePrev, handleNext } =
    useOnboardingContext();
  const { property, billing } = data;
  const stripe = useStripe(billing.stripe_key);
  const [error, setError] = useState(null);
  const [submittingSignedAt, setSubmittingSignedAt] = useState(false);

  const { t } = useTranslation("host.signup");

  const handleSubmitSignedAt = useCallback(async () => {
    if (submittingSignedAt || currentStep.done) {
      return false;
    }

    setError(null);
    setSubmittingSignedAt(true);

    try {
      await api.patch(`/v1/hosts/properties/${property.id}`, null, {
        signed_at: new Date(Date.now())
      });

      await refreshData();
    } catch (e) {
      const error = e.error?.message || e.message;
      setError(error || t("common:error.try_again"));
    } finally {
      setSubmittingSignedAt(false);
    }
  }, [currentStep.done, property.id, refreshData, submittingSignedAt, t]);

  useEffect(() => {
    billing.card && handleSubmitSignedAt();
  }, [handleSubmitSignedAt, billing.card]);

  return (
    <div className="StepDeposit Signup__step Signup__step__content">
      {currentStep.done ? (
        <div className="StepDeposit__done-screen">
          <ThumbUpIcon />
          <h2 className="Signup__header">{t("common:thank_you")}</h2>
          <h4 className="Signup__subheader">{t("add_card.completed")}</h4>
        </div>
      ) : (
        <>
          <h2 className="Signup__header" data-cy="card-header">
            {t("add_card.header")}
          </h2>
          <h4 className="Signup__subheader">{t("add_card.description")}</h4>

          {!submittingSignedAt && (
            <CardForm
              card={billing.card}
              stripe={stripe}
              onUpdate={refreshData}
            />
          )}
        </>
      )}

      {error && <DefaultError>{error}</DefaultError>}
      <NavButtonsWrapper
        prev={
          <Button type="button" theme="plain" onClick={handlePrev}>
            {t("common:previous")}
          </Button>
        }
        next={
          <Button
            type="button"
            disabled={!currentStep.done || loading}
            onClick={handleNext}
          >
            {t("common:next")}
          </Button>
        }
      />
    </div>
  );
};
