import "./Booking.scss";

import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as ClockIcon } from "../../../../assets/icons/clock.svg";
import { BookingDetails } from "../../../../components/booking_details";
import { BookingSummary } from "./BookingSummary";

export const Booking = ({ booking, previous, sample, current, dataCy }) => {
  const { t } = useTranslation("host.bookings");
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    if (booking.host_earnings) {
      setExpanded(!expanded);
    }
  };

  return (
    <div
      className={classNames("Booking", { current, sample })}
      onClick={handleClick}
      data-cy={dataCy}
    >
      {current && (
        <div className="Booking__current-header">
          <ClockIcon />
          {t("current_booking.title")}
        </div>
      )}

      <BookingSummary booking={booking} expanded={expanded} />

      {!sample && <BookingDetails booking={booking} expanded={expanded} />}
    </div>
  );
};
