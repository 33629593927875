import "./Modal.scss";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import classNames from "classnames";
import React, { useContext } from "react";

import { ModalAnimated } from "./Modal";
import { ModalContent } from "./Modal";
import { ModalContext } from "./Modal";

export const DesktopModal = ({
  style,
  className,
  showDesktopCloseButton,
  extraWidthClassName,
  ...modalProps
}) => {
  const { closeModal } = useContext(ModalContext);

  return (
    <ModalAnimated>
      <div className={classNames("Modal-container", className)}>
        <button type="button" className="Modal-overlay" onClick={closeModal} />
        <div
          className={classNames("Modal", extraWidthClassName)}
          data-cy="modal"
          style={style}
        >
          {showDesktopCloseButton && (
            <button onClick={closeModal} className="Modal__close" type="button">
              <CloseIcon />
            </button>
          )}
          <div className="Modal__content">
            <ModalContent {...modalProps} />
          </div>
        </div>
      </div>
    </ModalAnimated>
  );
};
