import "./DirectDebit.scss";

import Button from "components/buttons/Button";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import api from "services/api";

export const DirectDebitButton = ({ directDebit, plainLink, ...props }) => {
  const { t } = useTranslation("host.billing");
  const [loading, setLoading] = useState(false);

  const handleRedirect = useCallback(async () => {
    setLoading(true);

    try {
      const { redirect_url } = await api.put("/v1/hosts/billing/direct_debit");
      window.location = redirect_url;
    } catch {
      setLoading(false);
    }
  }, []);

  return plainLink ? (
    <button type="button" onClick={handleRedirect} className="link">
      {directDebit
        ? t("direct_debit.update_details")
        : t("direct_debit.set_up")}
    </button>
  ) : (
    <Button
      data-cy={props["data-cy"]}
      loading={loading}
      disabled={loading}
      onClick={handleRedirect}
    >
      {directDebit
        ? t("direct_debit.update_details")
        : t("direct_debit.set_up")}
    </Button>
  );
};

export default connect(state => ({
  directDebit: state.billing.direct_debit
}))(DirectDebitButton);
