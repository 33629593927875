import { parseDateTime } from "../../utils/dateParser";
import {
  RECEIVE_BANK_ACCOUNT_DETAILS,
  RECEIVE_CARD_DETAILS,
  RECEIVE_DIRECT_DEBIT_INFO,
  RECEIVE_INVOICES,
  RECEIVE_PAYOUTS,
  RECEIVE_STRIPE_API_KEY,
  RECEIVE_TRANSACTIONS
} from "../action_types";

const billing = (
  state = {
    invoices: {
      byProperty: {}
    },
    transactions: {
      byProperty: {}
    },
    payouts: {
      byProperty: {}
    }
  },
  action
) => {
  switch (action.type) {
    case RECEIVE_CARD_DETAILS:
      return {
        ...state,
        card: action.card
      };

    case RECEIVE_DIRECT_DEBIT_INFO:
      return {
        ...state,
        direct_debit: action.direct_debit
      };

    case RECEIVE_BANK_ACCOUNT_DETAILS:
      return {
        ...state,
        bank_account: action.bank_account
      };

    case RECEIVE_STRIPE_API_KEY:
      return {
        ...state,
        stripe_key: action.stripe_key
      };

    case RECEIVE_INVOICES: {
      const { invoices, propertyId } = action;

      const newInvoices = invoices.map(invoice => ({
        ...invoice,
        paid_at: parseDateTime(invoice.paid_at),
        created_at: parseDateTime(invoice.created_at)
      }));

      const prevInvoices = state.invoices.byProperty[propertyId] || [];

      return {
        ...state,
        invoices: {
          byProperty: {
            ...state.invoices.byProperty,
            [propertyId]: [...prevInvoices, ...newInvoices]
          }
        }
      };
    }

    case RECEIVE_TRANSACTIONS: {
      const { transactions, propertyId } = action;

      const newTransactions = transactions.map(transaction => ({
        ...transaction,
        created_at: parseDateTime(transaction.created_at),
        completed_at: parseDateTime(transaction.completed_at)
      }));

      const prevTransactions = state.transactions.byProperty[propertyId] || [];

      return {
        ...state,
        transactions: {
          byProperty: {
            ...state.transactions.byProperty,
            [propertyId]: [...prevTransactions, ...newTransactions]
          }
        }
      };
    }

    case RECEIVE_PAYOUTS: {
      const { payouts, propertyId } = action;

      const newPayouts = payouts.map(payout => ({
        ...payout,
        created_at: parseDateTime(payout.created_at),
        completed_at: parseDateTime(payout.completed_at)
      }));

      const prevPayouts = state.payouts.byProperty[propertyId] || [];

      return {
        ...state,
        payouts: {
          byProperty: {
            ...state.payouts.byProperty,
            [propertyId]: [...prevPayouts, ...newPayouts]
          }
        }
      };
    }

    default:
      return state;
  }
};

export default billing;
