import React from "react";

import { ReactComponent as HogarLogo } from "../../assets/icons/hogar_listo_logo.svg";
import { ReactComponent as HoustIcon } from "../../assets/icons/logo-icon.svg";

export const LogoIcon = ({ countryCode }) => {
  const Icon = countryCode === "ES" ? HogarLogo : HoustIcon;

  return <Icon />;
};
