import Button from "components/buttons/Button";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PostSetupContext } from "../PostSetup";
import { OnboardingPropertyCard } from "./OnboardingElements";

export const PropertySetupCard = ({ property }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("host.onboarding");
  const { resumePropertySetup, setupSkipped } = useContext(PostSetupContext);

  const handleCompleteSetupClick = useCallback(() => {
    resumePropertySetup(property.id);
    navigate(`/signup/${property.id}`, { replace: true });
  }, [navigate, property.id, resumePropertySetup]);

  const handleResumeSetup = setupSkipped && property.needs_setup;

  return (
    <OnboardingPropertyCard property={property}>
      <div className="PostSetup__property-card">
        {handleResumeSetup && (
          <Button type="button" onClick={handleCompleteSetupClick}>
            {t("host.setup:complete_setup_link")}
          </Button>
        )}
      </div>
    </OnboardingPropertyCard>
  );
};
