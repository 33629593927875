import "./Dashboard.scss";

import Alert from "components/common/Alert";
import NavBar from "components/nav/NavBar";
import NavMenu from "components/nav/NavMenu";
import ScrollToTop from "components/nav/ScrollToTop";
import { TermsGuard } from "components/nav/TermsGuard";
import { receivePreferences } from "data/actions/preferences";
import {
  getSelectedProperty,
  getSelectedPropertyNeedsOnboarding
} from "data/properties/selectors";
import { propertyOffboarded } from "data/property_states";
import { isAdmin } from "data/reducers/user";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import api from "services/api";
import { localStorage } from "utils/storage";

import Spinner from "../../components/Spinner.jsx";
import { receiveMaintenance } from "../../data/actions/maintenance";
import { unresolvedMaintenanceCountSelector } from "../../data/maintenance/selectors";
import { hasMissingPreferences } from "../../data/preferences/selectors";
import { getSelectedPropertyId } from "../../data/properties/selectors";
import Account from "./account/Account";
import Billing from "./billing/Billing";
import Bookings from "./bookings/Bookings";
import CalendarView from "./calendar/components/CalendarView";
import Cleans from "./cleans/Cleans";
import Contact from "./contact/Contact";
import { HomeInfo } from "./home-info/HomeInfo";
import Maintenance from "./maintenance/Maintenance";
import PostSetup from "./onboarding/PostSetup";
import Opportunity from "./opportunity/Opportunity";
import Overview from "./overview/Overview";
import Performance from "./performance/Performance";
import Platforms from "./platforms/Platforms";
import Preferences from "./preferences/Preferences";
import Referrals from "./referrals/Referrals";

const Dashboard = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const preferenceViewed = useState(
    localStorage.getItem("preference_viewed") || false
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const missingPreferences = useSelector(hasMissingPreferences);
  const isPropertyUnsorted = useSelector(getSelectedPropertyNeedsOnboarding);
  const selectedProperty = useSelector(getSelectedProperty);
  const selectedPropertyId = useSelector(getSelectedPropertyId);
  const unresolvedMaintenanceCount = useSelector(state =>
    unresolvedMaintenanceCountSelector(state, selectedPropertyId)
  );
  const admin = useSelector(isAdmin);
  const { pathname } = useLocation();

  const isOffboarded = propertyOffboarded(selectedProperty);

  useEffect(() => {
    if (selectedPropertyId) {
      api
        .get(`/v1/hosts/properties/${selectedPropertyId}/preferences`)
        .then(res => {
          dispatch(receivePreferences(res, selectedPropertyId));
        });
    }
  }, [selectedPropertyId, dispatch]);

  useEffect(() => {
    if (selectedPropertyId) {
      api
        .get(`/v1/hosts/properties/${selectedPropertyId}/maintenances`)
        .then(res => {
          dispatch(
            receiveMaintenance({
              maintenance: res,
              propertyId: selectedPropertyId
            })
          );
        });
    }
  }, [selectedPropertyId, dispatch]);

  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);

  const handleToggleMenu = forceClose => {
    setMenuOpen(forceClose === true ? false : !menuOpen);
  };

  if (!selectedProperty) {
    return <Spinner cover />;
  }

  return (
    <div className="Dashboard">
      {!isOffboarded && !admin && <TermsGuard />}

      <NavMenu
        menuOpen={menuOpen}
        handleToggleMenu={handleToggleMenu}
        hasMissingPreferences={missingPreferences}
        preferenceViewed={preferenceViewed}
        unresolvedMaintenanceCount={unresolvedMaintenanceCount}
      />

      <NavBar
        property={selectedProperty}
        handleToggleMenu={handleToggleMenu}
        hasMissingPreferences={missingPreferences}
        preferenceViewed={preferenceViewed}
      />

      <div className="Dashboard__container">
        {isOffboarded && (
          <div className="wide-alert">
            <Alert danger navBar>
              {t("account_closed")}
            </Alert>
          </div>
        )}
        <ScrollToTop>
          <div
            className="Dashboard__container__content"
            data-testid="dashboard-container"
          >
            <Routes>
              <Route
                index
                element={isPropertyUnsorted ? <PostSetup /> : <Overview />}
              />
              <Route path="home-info/*" element={<HomeInfo />} />
              <Route exact path="bookings" element={<Bookings />} />
              <Route exact path="platforms" element={<Platforms />} />
              <Route exact path="preferences" element={<Preferences />} />
              <Route exact path="calendar" element={<CalendarView />} />
              <Route exact path="performance" element={<Performance />} />
              <Route exact path="billing" element={<Billing />} />
              <Route exact path="cleans/:propertyId?" element={<Cleans />} />
              <Route exact path="maintenance" element={<Maintenance />} />

              <Route exact path="/account" element={<Account />} />
              <Route exact path="/refer-a-friend" element={<Referrals />} />
              <Route path="/contact" element={<Contact />} />
              <Route exact path="/new-property" element={<Opportunity />} />
            </Routes>
          </div>
        </ScrollToTop>
      </div>
    </div>
  );
};

export default Dashboard;
