import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { mergeCollections } from "utils/fn";

import { parseDate, parseDateTime } from "../../utils/dateParser";
import { previousCleansResponse, upcomingCleansResponse } from "./actions";

export default combineReducers({
  list: handleActions(
    {
      [upcomingCleansResponse]: {
        next: (state, action) => {
          const { items } = action.payload;
          const prevList = state || [];

          if (!items) return prevList;

          const newCleansList = items.map(clean => ({
            ...clean,
            clean_date: parseDate(clean.clean_date),
            created_at: parseDateTime(clean.created_at),
            current_confirmed_clean: clean.current_confirmed_clean?.start
              ? {
                  start: parseDateTime(clean.current_confirmed_clean.start)
                }
              : null
          }));

          return mergeCollections(newCleansList, prevList);
        }
      }
    },
    []
  ),
  previousList: handleActions(
    {
      [previousCleansResponse]: {
        next: (state, action) => {
          const { items } = action.payload;
          const prevList = state || [];

          if (items.length === 0) return prevList;

          const newCleansList = items.map(clean => ({
            ...clean,
            clean_date: parseDate(clean.clean_date),
            created_at: parseDateTime(clean.created_at),
            start: parseDateTime(clean.start),
            end: parseDateTime(clean.end),
            presentation_images: clean.presentation_images
              ? clean.presentation_images.map(image => ({
                  ...image,
                  created_at: parseDateTime(image.created_at)
                }))
              : []
          }));

          return mergeCollections(newCleansList, prevList);
        }
      }
    },
    []
  )
});
