import { createSelector } from "reselect";

import { getSelectedPropertyId } from "../properties/selectors";
import { basePropertyState } from "../reducers/performance";

export const getPerformance = createSelector(
  [getSelectedPropertyId, state => state.performance.byProperty],
  (id, performanceByProperty) =>
    performanceByProperty[id] || basePropertyState()
);

export const ratingsSelector = state =>
  state.performance.byProperty[getSelectedPropertyId(state)]?.ratings || null;
