import "./StepTerms.scss";

import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import api from "services/api";

import Button from "../../../../components/buttons/Button";
import DefaultError from "../../../../components/common/DefaultError.jsx";
import { TermsTable } from "../../../../components/common/TermsTable.jsx";
import { selectedPropertyLatestBillingPolicySelector } from "../../../../data/properties/selectors";
import { ShapedImage } from "../../Shapes";
import { useOnboardingContext } from "../../SignupContext";
import NavButtonsWrapper from "../NavButtonsWrapper";

const SALES_MAIL = "sales@houst.com";

export const StepTerms = () => {
  const { t } = useTranslation("host.signup");
  const {
    loading,
    handlePrev,
    handleNext,
    handleSubmitAndNavigateNext,
    data: { property }
  } = useOnboardingContext();

  const billingPolicy = useSelector(
    selectedPropertyLatestBillingPolicySelector
  );

  const { plan_type: planType, plan_name: planName } = billingPolicy;
  const [terms, setTerms] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    api
      .get(`/v1/hosts/properties/${property.id}/terms.html`)
      .then(setTerms)
      .catch(() => {
        setError(t("common:terms_error"));
      });
  }, [property.id, t]);

  const handleSubmit = useCallback(() => {
    if (!buttonClicked) {
      setButtonClicked(true);
    }

    if (property.terms_accepted) {
      handleNext();
    } else {
      handleSubmitAndNavigateNext({
        terms_accepted: new Date()
      });
    }
  }, [
    buttonClicked,
    handleNext,
    handleSubmitAndNavigateNext,
    property.terms_accepted
  ]);

  return (
    <React.Fragment>
      <div className="StepTerms Signup__step Signup__step__content">
        <h2 className="Signup__header" data-testid="terms-header">
          {t("terms.header")}
        </h2>

        {property.terms_accepted && (
          <h4 className="Signup__subheader">{t("terms.subheader.accepted")}</h4>
        )}

        <div
          className={classNames("StepTerms__container", {
            loading: !terms && !error
          })}
        >
          {error && (
            <DefaultError>
              {error} <a href={`mailto:${SALES_MAIL}`}>{SALES_MAIL}</a>
            </DefaultError>
          )}

          <TermsContainer
            termsAccepted={property.terms_accepted}
            terms={terms}
            planType={planType}
            planName={planName}
          />
        </div>

        <NavButtonsWrapper
          next={
            <Button loading={loading} onClick={handleSubmit} type="button">
              {t(property.terms_accepted ? "common:next" : "terms.agree")}
            </Button>
          }
          prev={
            <Button type="button" theme="plain" onClick={handlePrev}>
              {t("common:previous")}
            </Button>
          }
        />
      </div>
      <ShapedImage imgId="terms" containerClassName="StepTerms" />
    </React.Fragment>
  );
};

const TermsContainer = ({ termsAccepted, terms, planType, planName }) => {
  if (termsAccepted || !terms) {
    return null;
  }

  return <TermsTable />;
};
