import "./CleanForm.scss";

import Button from "components/buttons/Button";
import Card from "components/common/Card";
import SingleDateInput from "components/SingleDateInput";
import { getSelectedPropertyId } from "data/properties/selectors";
import { format } from "date-fns";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import * as yup from "yup";

import DefaultError from "../../../../components/common/DefaultError";
import Checkbox from "../../../../components/forms/Checkbox";
import api from "../../../../services/api";
import { useGetUpcomingCleans } from "../useGetCleans";

const START_TIME = "start_time";
const OVERLAPPING_BOOKING = "overlapping_booking";
const OVERLAPPING_CLEAN = "overlapping_clean";
const CLEAN_TIME_ERRORS = [START_TIME, OVERLAPPING_CLEAN, OVERLAPPING_BOOKING];

export const CleanForm = ({ selectedPropertyId }) => {
  const { t } = useTranslation("host.cleans");
  const { getUpcomingCleans } = useGetUpcomingCleans();

  const [submitted, setSubmitted] = useState(false);

  const validationSchema = yup.object().shape({
    start: yup.mixed().required(t("form.pick_date")),
    time: yup.string().required(t("form.pick_time"))
  });

  const handleRequestClean = (values, { setErrors }) => {
    const { start, time, notes, skip_laundry } = values;

    api
      .post(`/v1/hosts/properties/${selectedPropertyId}/cleans`, null, {
        clean_date: format(start._d, "yyyy-MM-dd"),
        clean_time: time,
        notes: notes ? notes : undefined,
        skip_laundry: skip_laundry
      })
      .then(res => {
        getUpcomingCleans();

        setSubmitted(true);
      })
      .catch(error => {
        if (error.error.message) {
          const message = error.error.message;

          CLEAN_TIME_ERRORS.forEach(key => {
            if (message[key]) {
              setErrors({ start: t(`form.error.${key}`) });
            }
          });
        } else {
          setErrors({ server: t("common:something_went_wrong") });
        }
      });
  };

  if (submitted) {
    return (
      <div className="BookClean__success">
        <h3 className="big-header">{t("form.clean_requested_title")}</h3>
        <p>{t("form.clean_requested_response")}</p>

        <Button type="button" onClick={() => setSubmitted(false)}>
          {t("form.request_another_clean")}
        </Button>
      </div>
    );
  }

  return (
    <Card title={t("form.book_clean")} className="BookClean">
      <Formik
        initialValues={{
          start: null,
          time: "",
          notes: "",
          skip_laundry: false
        }}
        onSubmit={handleRequestClean}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({ isSubmitting, isValid, errors, values, setFieldValue }) => (
          <Form className="BookClean__form">
            <SingleDateInput
              label={t("form.date_of_clean")}
              id="rangeStartDate"
              date={values["start"]}
              onDateChange={date => setFieldValue("start", date)}
              error={errors["start"] || errors["start_time"]}
            />

            <label htmlFor="time">{t("form.time_of_clean")}</label>
            <div className="select-wrapper">
              <Field
                component="select"
                name="time"
                data-testid="clean-time-select"
              >
                <option value="">{t("common:please_select")}</option>
                <option value="09:30">
                  {t("common:time_am_lower", { time: "9.30" })}
                </option>
                <option value="11:00">
                  {t("common:time_am_lower", { time: "11:00" })}
                </option>
              </Field>
            </div>

            <ErrorMessage name="time" component={DefaultError} />

            <label htmlFor="time">
              {t("form.special_clean_request_title")}
            </label>
            <Field
              component="textarea"
              name="notes"
              placeholder={t("form.clean_request_placeholder")}
            />
            <div>
              <Checkbox
                id="skipLaundry"
                onChange={event => {
                  setFieldValue("skip_laundry", event.target.checked);
                }}
                label={<p>{t("check.clean.skip_laundry.label")}</p>}
                checked={values["skip_laundry"]}
                dataCy="skip-laundry-checkbox"
              />
              <p>{t("check.clean.skip_laundry.content")}</p>
            </div>

            {errors.server && <DefaultError> {errors.server} </DefaultError>}

            <Button data-testid="submit-clean-demand" type="submit">
              {t("form.request_new_clean")}
            </Button>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default compose(
  connect(state => ({
    selectedPropertyId: getSelectedPropertyId(state)
  }))
)(CleanForm);
