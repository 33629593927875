import "./PlatformsList.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";
import DefaultError from "../../../../components/common/DefaultError";
import Tip from "../../../../components/common/Tip.jsx";
import { usePlatformsList } from "../usePlatformsList";
import Platform from "./Platform";

const PROPERTY_LISTING_STATUS = ["closed", "active", "inactive", "pending"];
const LONG_TERM = "long";
const SHORT_TERM = "long";

const PlatformsList = ({ property }) => {
  const { t } = useTranslation("host.platforms");
  const { longTerm, shortTerm, error } = usePlatformsList(property);

  if (error) {
    return <DefaultError>{error}</DefaultError>;
  }

  return (
    <div className="PlatformsList">
      {shortTerm.length > 0 && (
        <PlatformsByStatus
          // Don't render header if long term platforms are not present at all
          title={longTerm.length > 0 && t("platforms.short_term")}
          testId="short-term-platforms"
          platforms={shortTerm}
          type={SHORT_TERM}
        />
      )}

      {longTerm.length > 0 && (
        <PlatformsByStatus
          title={t("platforms.long_term")}
          testId="long-term-platforms"
          platforms={longTerm}
          type={LONG_TERM}
        />
      )}
    </div>
  );
};

const PlatformsByStatus = ({ platforms, title, testId, type }) => {
  return (
    <div>
      <h3>{title}</h3>

      {PROPERTY_LISTING_STATUS.map(status => (
        <PlatformTiles
          key={status}
          status={status}
          platforms={platforms.filter(platform => platform.status === status)}
          type={type}
          testId={`${testId}-${status}`}
        />
      ))}
    </div>
  );
};

const PlatformTiles = ({ status, platforms = [], type, testId }) => {
  const { t } = useTranslation("host.platforms");

  if (platforms.length === 0) {
    return null;
  }

  const isLongTerm = type === LONG_TERM;
  const tipText =
    status === PROPERTY_LISTING_STATUS.inactive && isLongTerm
      ? t("platform.status_tip.inactive_long_term")
      : t(`platform.status_tip.${status}`);

  return (
    <div>
      <div className="PlatformsSection">
        <h5>
          {t(`platform.status_${status}`)}
          <Tip position="right" html={tipText}>
            <InfoIcon />
          </Tip>
        </h5>

        <div className="PlatformsSection__platforms" data-cy={testId}>
          {platforms.map(platform => (
            <Platform key={platform.channel_details.name} {...platform} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlatformsList;
