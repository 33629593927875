import React from "react";

import { ReactComponent as CallIcon } from "../../../../assets/icons/call.svg";
import { ReactComponent as PersonIcon } from "../../../../assets/icons/profile.svg";
import { ReactComponent as WhatsappIcon } from "../../../../assets/icons/whatsapp.svg";

const ICONS = {
  call: <CallIcon />,
  whatsapp: <WhatsappIcon />,
  am: <PersonIcon />
};

export const ContactMethodIcon = ({ type }) => {
  return <div className="Contact__icon">{ICONS[type]}</div>;
};
