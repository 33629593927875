import "./Ratings.scss";

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../../components/Spinner";
import { receiveRatings } from "../../../../data/actions/performance";
import { getSelectedPropertyId } from "../../../../data/properties/selectors";
import { ratingsSelector } from "../../../../data/selectors/performance";
import api from "../../../../services/api";
import Rating from "./Rating";

const Ratings = () => {
  const { t } = useTranslation("host.performance");
  const selectedPropertyId = useSelector(getSelectedPropertyId);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const ratings = useSelector(ratingsSelector);

  const getRatings = useCallback(() => {
    setLoading(true);
    api
      .get(`/v1/hosts/properties/${selectedPropertyId}/performance/ratings`)
      .then(res => {
        dispatch(receiveRatings(selectedPropertyId, res));
        setLoading(false);
      });
  }, [selectedPropertyId, dispatch]);

  useEffect(() => {
    getRatings();
  }, [getRatings]);

  if (loading) {
    return (
      <div className="Ratings">
        <Spinner />
      </div>
    );
  }

  if (!ratings) {
    return (
      <div className="Ratings">
        <h3 className="small-header">{t("star_reviews")}</h3>
        <p>{t("ratings_empty")}</p>
      </div>
    );
  }

  const {
    checkin,
    communication,
    accuracy,
    location,
    cleanliness,
    value,
    overall
  } = ratings;

  return (
    <div className="Ratings">
      <h3 className="small-header">{t("star_reviews")}</h3>
      <table>
        <tbody>
          <tr>
            <td>
              <strong>{t("ratings_overall")}</strong>
            </td>
            <td>
              <Rating rating={overall} />
            </td>
          </tr>
          <tr>
            <td>{t("ratings_accuracy")}</td>
            <td>
              <Rating rating={accuracy} />
            </td>
          </tr>
          <tr>
            <td>{t("ratings_check_in")}</td>
            <td>
              <Rating rating={checkin} />
            </td>
          </tr>
          <tr>
            <td>{t("ratings_cleanliness")}</td>
            <td>
              <Rating rating={cleanliness} />
            </td>
          </tr>
          <tr>
            <td>{t("ratings_communication")}</td>
            <td>
              <Rating rating={communication} />
            </td>
          </tr>
          <tr>
            <td>{t("ratings_location")}</td>
            <td>
              <Rating rating={location} />
            </td>
          </tr>
          <tr>
            <td>{t("ratings_value")}</td>
            <td>
              <Rating rating={value} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Ratings;
