import "./HomeInfoButtons.scss";

import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Button from "../../../../components/buttons/Button";
import { useHomeInfoContext } from "../HomeInfoContext";
import { SECTIONS } from "./sections";

export const HomeInfoButtons = ({ sectionId }) => {
  const { t } = useTranslation("host.home_info");
  const { dirty } = useFormikContext();
  const {
    data: { can_edit }
  } = useHomeInfoContext();

  const { nextSection, prevSection } = SECTIONS[sectionId];

  if (!can_edit) {
    return null;
  }

  return (
    <div className="HomeInfoButtons">
      {nextSection &&
        (dirty ? (
          <Button
            data-testid="next-button"
            className="Button Button--primary next-button"
            type="submit"
          >
            {t("button.save_and_continue")}
          </Button>
        ) : (
          <Link
            data-testid="previous-button"
            className="Button Button--primary next-button"
            to={`/home-info/${nextSection}`}
          >
            {t("common:continue")}
          </Link>
        ))}

      {prevSection && (
        <Link
          className="Button Button--primary Button--outline"
          to={`/home-info/${prevSection}`}
        >
          {t("common:previous")}
        </Link>
      )}
    </div>
  );
};
