import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import styles from "../utils/style_constants";
import ChangingProgressProvider from "./ChangingProgessProvider";

const CircularProgress = ({ value }) => {
  return (
    <ChangingProgressProvider values={[0, value]}>
      {value => (
        <CircularProgressbar
          value={value}
          text={`${value}%`}
          circleRatio={0.75}
          styles={buildStyles({
            rotation: 0.625,
            strokeLinecap: "round",
            trailColor: styles.colors.lightBlue
          })}
        />
      )}
    </ChangingProgressProvider>
  );
};

export default CircularProgress;
