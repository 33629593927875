import { ReactComponent as StripeIcon } from "assets/icons/stripe.svg";
import React from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { RESTRICTED_CARD_TYPES } from "utils/constants";

import styles from "../../utils/style_constants";
import Button from "../buttons/Button";
import Spinner from "../Spinner";

const CARD_ELEMENT_STYLE = {
  base: {
    fontSize: "18px",
    fontFamily: `${styles.fonts.paragraphRegular}, sans-serif`,
    "::placeholder": {
      color: styles.colors.elephant
    }
  }
};

class CardForm extends React.Component {
  state = {
    error: false,
    isComplete: false
  };

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error) {
      this.setState({ error: this.props.error });
    }
  }

  handleOnChange = ({ brand, complete, error }) => {
    if (RESTRICTED_CARD_TYPES.includes(brand)) {
      return this.setState({
        error: this.props.t("common:restricted_card_error")
      });
    }

    this.setState({
      error: error && error.message,
      isComplete: complete
    });
  };

  render() {
    const {
      t,
      label,
      stripe,
      submitting,
      submitText,
      submittingText,
      submitTestID,
      onSubmitCard,
      cancelButton
    } = this.props;
    const { error, isComplete } = this.state;

    if (!stripe) {
      return <Spinner />;
    }

    return (
      <div>
        <div className="StripeCard__container">
          {label && <label className="StripeCard__label">{label}</label>}

          <CardElement
            hidePostalCode
            style={CARD_ELEMENT_STYLE}
            onChange={this.handleOnChange}
            onReady={el => el.focus()}
          />

          <p className="text-info">
            <small>{t("host.setup:deposit.security_info")}</small>
          </p>

          <StripeIcon />
        </div>

        {error && <div className="StripeCard__error ">{error}</div>}

        <div>
          <Button
            onClick={() => onSubmitCard(stripe)}
            loading={submitting}
            disabled={!isComplete || submitting}
            data-testid={submitTestID}
          >
            {submitting ? submittingText : submitText}
          </Button>

          {cancelButton}
        </div>
      </div>
    );
  }
}

export default injectStripe(CardForm);
