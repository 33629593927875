import { selectProperty } from "data/actions/properties";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { PostSetupContext } from "../PostSetup";
import { OnboardingPreferencesCard } from "./OnboardingElements";
import { PropertySetupCard } from "./OnboardingPropertySetupCard";

export const OnboardingPropertiesDetails = () => {
  const { t } = useTranslation("host.onboarding");
  const { properties } = useContext(PostSetupContext);
  const dispatch = useDispatch();

  const handlePropertySelect = id => {
    dispatch(selectProperty(id));
  };

  return (
    <div className="PostSetup__section">
      {properties.map(property => (
        <React.Fragment key={property.id}>
          <div className="PostSetup__notice">
            <PropertySetupCard property={property} />

            <OnboardingPreferencesCard>
              <div className="PostSetup__preferences">
                <Link
                  onClick={() => handlePropertySelect(property.id)}
                  to="/preferences"
                >
                  {t("guest_approvals")}
                </Link>
                <Link
                  onClick={() => handlePropertySelect(property.id)}
                  to="/preferences?tab=tiered_preference"
                >
                  {t("host.preferences:settings.tabs.property")}
                </Link>
              </div>
            </OnboardingPreferencesCard>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
