import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { useOnboardingContext } from "./SignupContext";

export const SIGNUP_PATH = "/signup/:propertyId";
export const pathForStep = (propertyId, step = "") =>
  `/signup/${propertyId}/${step}`;

// Redirect to firstUncompletedStep
export const SignupNavigate = () => {
  const { firstUncompletedStep, propertyId } = useOnboardingContext();
  const path = pathForStep(propertyId, firstUncompletedStep.path);

  return <Navigate to={path} />;
};

// Generates routes for the steps that user is allowed to view at the moment
export const AccessibleStepsRoutes = () => {
  const { accessibleSteps } = useOnboardingContext();

  return (
    <Routes>
      {accessibleSteps.map(({ path, component }) => (
        <Route key={path} path={path} element={component} />
      ))}
      <Route index element={<SignupNavigate />} />
      <Route path={"/*"} element={<SignupNavigate />} />
    </Routes>
  );
};
