import "./Install.scss";

import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left-2.svg";
import { useMemo, useState } from "react";
import {
  isAndroid,
  isChrome,
  isChromium,
  isMobile,
  isMobileSafari
} from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import androidImage1Url from "../../assets/images/pwa_guide/android_1.png";
import androidImage2Url from "../../assets/images/pwa_guide/android_2.png";
import androidImage3Url from "../../assets/images/pwa_guide/android_3.png";
import chromeImage1Url from "../../assets/images/pwa_guide/chrome_1.png";
import chromeImage2Url from "../../assets/images/pwa_guide/chrome_2.png";
import safariImage1Url from "../../assets/images/pwa_guide/safari_1.png";
import safariImage2Url from "../../assets/images/pwa_guide/safari_2.png";
import safariImage3Url from "../../assets/images/pwa_guide/safari_3.png";
import Button from "../../components/buttons/Button";

const Install = () => {
  const { t } = useTranslation("host.install");
  const [installed, setIsInstalled] = useState(false);
  const navigate = useNavigate();

  const PWADisplayMode = useMemo(() => {
    const isStandalone = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;
    if (document.referrer.startsWith("android-app://")) {
      return "twa";
    } else if (navigator.standalone || isStandalone) {
      return "standalone";
    }
    return "browser";
  }, []);

  const handleDeferredPrompt = async () => {
    const { deferredPrompt } = window;

    deferredPrompt.prompt();

    const { outcome } = await deferredPrompt.userChoice;
    if (outcome === "accepted") {
      window.deferredPrompt = null;
      setIsInstalled(true);
    }
  };

  const renderContent = () => {
    // Desktop Chromium guide
    if (!isMobile && (isChromium || isChrome)) {
      return (
        <ol className="Install__guide">
          <li>
            <span>{t("install.guide.chrome_1")}</span>
            <img src={chromeImage1Url} alt={t("install.guide.chrome_1")} />
          </li>
          <li>
            <span>{t("install.guide.chrome_2")}</span>
            <img src={chromeImage2Url} alt={t("install.guide.chrome_2")} />
          </li>
        </ol>
      );
    }

    // Just installed or already in PWA mode
    if (installed || window.PWAInstalled || PWADisplayMode !== "browser") {
      return <p className="Install__text">{t("install.thank_you")}</p>;
    }

    // Mobile Safari
    if (isMobileSafari) {
      return (
        <ol className="Install__guide">
          <li>
            <span>{t("install.guide.mobile_safari_1")}</span>
            <img
              src={safariImage1Url}
              alt={t("install.guide.mobile_safari_1")}
            />
          </li>
          <li>
            <span>{t("install.guide.mobile_safari_2")}</span>
            <img
              src={safariImage2Url}
              alt={t("install.guide.mobile_safari_2")}
            />
          </li>
          <li>
            <span>{t("install.guide.mobile_safari_3")}</span>
            <img
              src={safariImage3Url}
              alt={t("install.guide.mobile_safari_3")}
            />
          </li>
        </ol>
      );
    }

    // Android
    if (isAndroid) {
      return (
        <>
          {/* Has deferredPrompt (Androids that have recently closed the prompt) */}
          {window.deferredPrompt && (
            <>
              <p className="Install__text">{t("install.guide.button")}</p>
              <Button onClick={handleDeferredPrompt}>
                {t("install.button")}
              </Button>
              <p className="Install__text">{t("install.guide.or")}</p>
            </>
          )}
          <ol className="Install__guide">
            <li>
              <span>{t("install.guide.android_1")}</span>
              <img src={androidImage1Url} alt={t("install.guide.android_1")} />
            </li>
            <li>
              <span>{t("install.guide.android_2")}</span>
              <img src={androidImage2Url} alt={t("install.guide.android_2")} />
            </li>
            <li>
              <span>{t("install.guide.android_3")}</span>
              <img src={androidImage3Url} alt={t("install.guide.android_3")} />
            </li>
          </ol>
        </>
      );
    }

    return <p className="Install__text">{t("install.not_supported")}</p>;
  };

  return (
    <div className="Install">
      <div className="Install__back">
        <Button className="Install__back-button" onClick={() => navigate(-1)}>
          <ArrowLeftIcon />
          <span className="Install__back-text">{t("install.back")}</span>
        </Button>
      </div>
      <div className="Install__logo-wrapper">
        <Logo className="Install__logo" />
      </div>
      <h1 className="header">{t("install.title")}</h1>

      {renderContent()}
    </div>
  );
};

export default Install;
