import "./DefaultError.scss";

import React from "react";

const DefaultError = ({ children, testId, dataCy }) => (
  <div className="DefaultError" data-testid={testId} data-cy={dataCy}>
    {children}
  </div>
);

export default DefaultError;
