import { get } from "lodash";
import { createSelector } from "reselect";

export const getPropertyInvoices = createSelector(
  (state, propertyId) => state?.billing?.invoices?.byProperty?.[propertyId],
  invoices => (invoices ? invoices : [])
);

export const bankAccountSelector = state => state.billing.bank_account;

export const cardSelector = state => state.billing.card || null;

export const directDebitSelector = state => state.billing.direct_debit || null;

export const getPropertyTransactions = createSelector(
  (state, propertyId) => state?.billing?.transactions?.byProperty?.[propertyId],
  transactions => (transactions ? transactions : [])
);

export const getPropertyPayouts = (state, propertyId) =>
  get(state, `billing.payouts.byProperty.${propertyId}`, []);
