import "./PhotosUpload.scss";

import classNames from "classnames";
import { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import DefaultError from "../../components/common/DefaultError";
import Button from "../buttons/Button";
import Spinner from "../Spinner";

export const PhotosUpload = ({ handleUpload, isUploading, error }) => {
  const { t } = useTranslation("common");
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop: handleUpload,
      accept: "image/jpeg, image/png, image/jpg, image/heic, video/*",
      maxSize: 500000000
    });

  const className = classNames("PhotosUpload", {
    reject: isDragReject,
    active: isDragActive
  });

  const statusMessage = useMemo(() => {
    if (isUploading) {
      return t("files_uploading");
    }
    if (isDragActive) {
      return t("drop_files_here");
    }

    return t("drag_photos");
  }, [isUploading, isDragActive, t]);

  return (
    <div {...getRootProps()} className={className} data-cy="photos-upload">
      {error && <DefaultError>{error}</DefaultError>}
      <input {...getInputProps()} />
      {isUploading && <Spinner />}
      <p>{statusMessage}</p>

      {!isUploading && !isDragActive && (
        <>
          <p className="with-line">
            <span className="or"> {t("or")} </span>
          </p>

          <Button>{t("browse_files")}</Button>
        </>
      )}
    </div>
  );
};
