import "./TopCharts.scss";

import Fetcher from "components/Fetcher";
import * as Fetchers from "data/actions/fetchers";
import { format } from "date-fns";
import React, { Component } from "react";
import CountUp from "react-countup";

import {
  getEndOfPreviousMonth,
  getTimeRangeStartDate
} from "../../../../utils/dates";
import { CHART_RANGE_OPTIONS, getStatsSelectOptions } from "../utils";
import TimeRangeSelect from "./TimeRangeSelect";

const DEFAULT_TIME_RANGE = 1;

class BookingsCount extends Component {
  state = {
    fromDate: getTimeRangeStartDate(DEFAULT_TIME_RANGE)
  };

  handleDateRangeChange = value => {
    const dateRange = CHART_RANGE_OPTIONS[value];

    this.setState({
      fromDate: getTimeRangeStartDate(dateRange.months)
    });
  };

  render() {
    const { selectedPropertyId, t, bookingsCount } = this.props;
    const { fromDate } = this.state;
    const endOfLastMonth = getEndOfPreviousMonth();

    const viewsFetcher = Fetchers.bookingsCount(selectedPropertyId, {
      from_date: format(fromDate, "yyyy-MM-dd"),
      to_date: format(endOfLastMonth, "yyyy-MM-dd")
    });

    return (
      <div className="OverviewItem TopCharts__box">
        <TimeRangeSelect
          options={getStatsSelectOptions(t)}
          onChange={this.handleDateRangeChange}
          t={t}
        />

        {!bookingsCount && bookingsCount !== 0 && (
          <p className="TopCharts__error">{t("top_chart.error")}</p>
        )}

        <Fetcher fetcher={viewsFetcher} spin>
          <h2 className="TopCharts__data header">
            <CountUp end={bookingsCount} duration={1.5} />
          </h2>
        </Fetcher>
        <p>{t("booking_count_tile.title")}</p>
      </div>
    );
  }
}

export default BookingsCount;
