import "./ActionModal.scss";

import Button from "components/buttons/Button";
import Modal from "components/common/modal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Input from "../../../../../components/common/Input";
import { IGNORE_ACTION } from "../../../../../data/maintenance/types";

export const ActionModal = ({
  choosenResolution,
  modalData,
  closeModal,
  updateMaintenanceItem
}) => {
  const { t } = useTranslation("host.maintenance");

  const [hostComment, setHostComment] = useState("");
  const [error, setError] = useState(null);

  const handleActionSubmit = action => {
    if (choosenResolution === IGNORE_ACTION) {
      if (hostComment && hostComment.trim() !== "") {
        updateMaintenanceItem({
          host_response: action,
          host_comment: hostComment
        });

        closeModal();
      } else {
        setError(t("host_comment_error"));
      }
    } else {
      updateMaintenanceItem({
        host_response: action,
        host_comment: hostComment
      });
      closeModal();
    }
  };

  return (
    <Modal
      className="ActionModal"
      closeModal={closeModal}
      footer={
        <div>
          <Button outline type="button" onClick={() => closeModal(false)}>
            {t("common:close")}
          </Button>

          <Button
            onClick={() => handleActionSubmit(choosenResolution)}
            data-cy="maintenance-modal-submit"
          >
            {t("common:confirm")}
          </Button>
        </div>
      }
    >
      <dl>
        <dt>Issue description:</dt>
        <dd>{modalData.maintenanceItem.description}</dd>
      </dl>
      <p>{t(`modal.text.${choosenResolution}`)}</p>

      <Input.Area
        testid="host-comment-textarea"
        data-cy="maintenance-host-comment-textarea"
        name="host_comment"
        placeholder={t(`modal.placeholder.${choosenResolution}`)}
        value={hostComment}
        onChange={value => setHostComment(value)}
        error={error}
      />
    </Modal>
  );
};
