import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  receivePreviousBookings,
  receiveUpcomingBookings
} from "../../../data/actions/bookings";
import { getSelectedPropertyId } from "../../../data/properties/selectors";
import api from "../../../services/api";

export const BOOKINGS_LIMIT = 10;

export const useGetUpcomingBookings = bookingsLength => {
  const dispatch = useDispatch();
  const selectedPropertyId = useSelector(getSelectedPropertyId);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isFinal, setIsFinal] = useState(true);

  const getUpcomingBookings = useCallback(
    offset => {
      setLoading(true);

      api
        .get(`/v1/hosts/properties/${selectedPropertyId}/bookings/upcoming`, {
          limit: BOOKINGS_LIMIT,
          offset
        })
        .then(res => {
          setLoading(false);
          dispatch(receiveUpcomingBookings(res, selectedPropertyId));

          if (res.length < BOOKINGS_LIMIT || res.length === 0) {
            setIsFinal(true);
          }
        })
        .catch(err => {
          setError(err);
        });
    },
    [selectedPropertyId, dispatch]
  );

  useEffect(() => {
    if (bookingsLength === 0) {
      setIsFinal(false);
      getUpcomingBookings();
    }
  }, [selectedPropertyId, getUpcomingBookings, bookingsLength]);

  return [loading, error, getUpcomingBookings, isFinal];
};

export const useGetPreviousBookings = bookingsLength => {
  const dispatch = useDispatch();
  const selectedPropertyId = useSelector(getSelectedPropertyId);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isFinal, setIsFinal] = useState(false);

  const getPreviousBookings = useCallback(
    offset => {
      setLoading(true);

      api
        .get(`/v1/hosts/properties/${selectedPropertyId}/bookings/previous`, {
          limit: BOOKINGS_LIMIT,
          offset
        })
        .then(res => {
          setLoading(false);
          dispatch(receivePreviousBookings(res, selectedPropertyId));

          if (res.length < BOOKINGS_LIMIT || res.length === 0) {
            setIsFinal(true);
          }
        })
        .catch(err => {
          setError(err);
        });
    },
    [selectedPropertyId, dispatch]
  );

  useEffect(() => {
    if (bookingsLength === 0) {
      setIsFinal(false);
      getPreviousBookings();
    }
  }, [selectedPropertyId, getPreviousBookings, bookingsLength]);

  return [loading, error, getPreviousBookings, isFinal];
};
