import { get, orderBy } from "lodash";
import { createSelector } from "reselect";
import { LONDON, PLAN_TYPE_FULL_TIME } from "utils/constants";
import { denormalize } from "utils/fn";

import {
  propertyHasMultiplatOption,
  propertyNeedsOnboarding,
  propertyRequiresSetup
} from "../property_states";

export const isSetupSkipped = state =>
  getProperties(state).length > 1 && get(state, "properties.skipSetup", false);

export const getPropertiesWith90DayLimit = state => {
  return getProperties(state)
    .filter(p => p.locality.name === LONDON)
    .filter(p => p.id);
};

export const getPropertyNeedsSetup = state =>
  propertyRequiresSetup(getSelectedProperty(state));

// Retrieves properties ordered by `offboarded_from`
// NOTE: It will return `undefined` if state.properties are not yet fetched
export const getProperties = createSelector(
  state => state.properties.byId,
  byId => orderBy(denormalize(byId), p => p.offboarded_from)
);

export const getPropertiesWithMultiplatOption = createSelector(
  getProperties,
  properties => properties.filter(propertyHasMultiplatOption)
);

export const getPropertiesNeedingMeeting = createSelector(
  getProperties,
  properties => properties.filter(propertyNeedsOnboarding)
);

// Retrieves the selected property or the first property from the list
export const getSelectedProperty = createSelector(
  state => state.properties.byId,
  state => state.properties.selectedId,
  getProperties,
  (byId, selectedId, properties) => byId[selectedId] || properties[0]
);

export const getSelectedPropertyId = state => {
  const selectedProperty = getSelectedProperty(state);
  return getPropertyId(selectedProperty);
};

export const selectedPropertyCountryCodeSelector = state => {
  const selectedProperty = getSelectedProperty(state);
  return get(selectedProperty, "locality.country_code");
};

export const getSelectedPropertyCurrencySymbol = state => {
  const selectedProperty = getSelectedProperty(state);
  return get(selectedProperty, "locality.currency_symbol");
};

export const getSelectedPropertyCurrency = state => {
  const selectedProperty = getSelectedProperty(state);
  return selectedProperty.locality?.currency || null;
};

export const getPropertyId = property => get(property, "id");

export const getHostCleanInstructions = property =>
  get(property, `home_info.cleaning_host_cleaning_instructions`);

export const getCleaningTasks = property =>
  get(property, `home_info.cleaning_tasks`, []);

export const bankAccountSelector = createSelector(
  state => get(state, "billing.bank_account", null),
  bankAccount => bankAccount || {}
);

export const selectedPropertyLocalitySelector = state => {
  const selectedProperty = getSelectedProperty(state);
  return selectedProperty.locality || {};
};

export const selectedPropertyBillingPolicySelector = state => {
  const selectedProperty = getSelectedProperty(state);
  return selectedProperty.active_billing_policy || {};
};

export const selectedPropertyPlanType = state => {
  const active_billing_policy = selectedPropertyBillingPolicySelector(state);
  return active_billing_policy?.plan_type;
};

export const isSelectedPropertyPlanFullTime = state =>
  selectedPropertyPlanType(state) === PLAN_TYPE_FULL_TIME;

export const selectedPropertyLatestBillingPolicySelector = state => {
  const selectedProperty = getSelectedProperty(state);
  return selectedProperty.latest_billing_policy || {};
};

export const selectedPropertyTaxLabelSelector = state => {
  const selectedProperty = getSelectedProperty(state);
  return selectedProperty.locality.tax_label || null;
};

export const selectedPropertyAMSelector = state => {
  const selectedProperty = getSelectedProperty(state);
  return selectedProperty.account_manager || {};
};

export const selectedPropertyPodPhoto = state => {
  const selectedProperty = getSelectedProperty(state);
  return selectedProperty?.pod?.team_photo_url || null;
};

export const selectedPropertyTeamPhotos = createSelector(
  state => getSelectedProperty(state),
  selectedProperty =>
    selectedProperty?.pod?.operators
      .map(operator => operator.profile_picture)
      .filter(el => el && el.length > 0) || []
);

export const selectedPropertyPod = createSelector(
  state => getSelectedProperty(state),
  selectedProperty => selectedProperty?.pod || {}
);

export const selectedPropertyBillingAccount = state => {
  const selectedProperty = getSelectedProperty(state);
  return selectedProperty.billing_account || null;
};

export const getAvailableLocalitiesForProperty = state => {
  return state.user.localities.filter(el => el.is_live);
};

export const getSelectedPropertyNeedsOnboarding = createSelector(
  state => getSelectedProperty(state),
  selectedProperty => propertyNeedsOnboarding(selectedProperty)
);
