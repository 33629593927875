import { RECEIVE_BOOKINGS_COUNT } from "../action_types";

export const receiveHasAnyEarnings = (propertyId, hasAnyEarnings) => ({
  type: "RECEIVE_HAS_ANY_EARNINGS",
  propertyId,
  hasAnyEarnings
});

export const receiveRatings = (propertyId, ratings) => ({
  type: "RECEIVE_RATINGS",
  propertyId,
  ratings
});

export const receiveBookingsCount = (propertyId, bookings) => {
  const { bookings_count } = bookings;

  return {
    type: RECEIVE_BOOKINGS_COUNT,
    propertyId,
    bookingsCount: bookings_count
  };
};

export const receiveOccupancy = (propertyId, occupancyRate) => {
  const { occupancy } = occupancyRate;

  return {
    type: "RECEIVE_OCCUPANCY",
    propertyId,
    occupancy
  };
};

export const receiveEarnings = (propertyId, earnings) => {
  return {
    type: "RECEIVE_EARNINGS",
    propertyId,
    earnings
  };
};
