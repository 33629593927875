import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import api from "../../services/api";
import { useSignupSteps } from "./useSignupSteps";

export const OnboardingContext = React.createContext({});
export const useOnboardingContext = () => useContext(OnboardingContext);

export const INITIAL_SIGNUP_DATA = {
  billing: {
    direct_debit: {}
  },
  host: {},
  property: {
    locality: {}
  }
};

export const OnboardingContextProvider = ({ children }) => {
  const { propertyId } = useParams();

  // Cannot call handleNext right after calling setData
  // as it'll try to redirect before setData finishes
  const [shouldNavigateNext, setShouldNavigateNext] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState(INITIAL_SIGNUP_DATA);

  const sufRequest = async (fn, ...args) => {
    setError(null);
    setLoading(true);

    try {
      const newData = await fn(...args);
      setData(newData);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const url = `/v1/hosts/onboarding/${propertyId}`;
  const refreshData = useCallback(() => sufRequest(api.get, url), [url]);
  const handleSubmit = useCallback(
    body => sufRequest(api.patch, url, null, body),
    [url]
  );

  const handleSubmitAndNavigateNext = useCallback(
    async data => {
      await handleSubmit(data);
      setShouldNavigateNext(true);
    },
    [handleSubmit]
  );

  const { handleNext, handlePrev, ...signupStepsComputedProps } =
    useSignupSteps(data);

  useEffect(() => {
    if (shouldNavigateNext) {
      setShouldNavigateNext(false);
      handleNext(propertyId);
    }
  }, [shouldNavigateNext, handleNext, propertyId]);

  useEffect(() => {
    refreshData();
  }, [propertyId, refreshData]);

  return (
    <OnboardingContext.Provider
      value={{
        data,
        error,
        handleNext: () => handleNext(propertyId),
        handlePrev: () => handlePrev(propertyId),
        handleSubmit,
        handleSubmitAndNavigateNext,
        loading,
        propertyId,
        refreshData,
        setLoading,
        shouldNavigateNext,
        setShouldNavigateNext,
        ...signupStepsComputedProps
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
