import { isEqual } from "date-fns";
import { createSelector } from "reselect";

import { parseDate } from "../../utils/dateParser";
import { SET_SELECTED_BOOKING } from "../action_types";
import { getSelectedPropertyId } from "../properties/selectors";
import { parseBooking } from "./bookings";

export const getCalendar = createSelector(
  [getSelectedPropertyId, state => state.calendar],
  (id, calendar) => calendar[id] || []
);

export const getSelectedBooking = state => state.calendar.selectedBooking;

// State is keyed by propertyId
const calendar = (state = { selectedBooking: null }, action) => {
  switch (action.type) {
    case "RECEIVE_CALENDAR":
      const propertyId = `${action.propertyId}`;

      const newDays = action.calendarDays.map(c => {
        return {
          ...c,
          date: parseDate(c.date, "yyyy-MM-dd"),
          bookings: c.bookings.map(parseBooking)
        };
      });

      const days = state[propertyId] || [];

      days.forEach(day => {
        if (!newDays.find(d => isEqual(d.date, day.date))) {
          newDays.push(day);
        }
      });

      return Object.assign({}, state, {
        [propertyId]: newDays
      });

    case SET_SELECTED_BOOKING:
      const { booking: selectedBooking } = action;

      return { ...state, selectedBooking: selectedBooking };

    default:
      return state;
  }
};

export default calendar;
