import React from "react";

export const renderTooltip = content => {
  return (
    <>
      <h5 className="small-header">{content.title}</h5>

      <p>{content.paragraph1}</p>

      <p>{content.paragraph2}</p>
    </>
  );
};

export const optionsArray = (
  optionsMap,
  { includeEmptyOption = true } = {}
) => {
  return [
    // Once user picks an option he can't choose empty again
    includeEmptyOption && <option key={"empty"} disabled />,
    ...Object.keys(optionsMap).map(value => (
      <option key={value} value={value}>
        {optionsMap[value]}
      </option>
    ))
  ];
};
