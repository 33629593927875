import { isEmpty, isFunction, isObjectLike, values } from "lodash";

/**
 * Merges two collections with the first taking precedence over the second
 * @param {The dominant collection} col1
 * @param {The secondary collection} col2
 * @param {The key to compare on} key
 */
export const mergeCollections = (col1, col2, key = "id") => {
  return col2.reduce((list, a) => {
    return list.find(b => b[key] === a[key]) ? list : [...list, a];
  }, [].concat(col1));
};

export const normalize = list =>
  list.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});

export const denormalize = object => values(object);

// See test for examples
export const isBlank = value =>
  !value || (isObjectLike(value) && isEmpty(value));

export const typeOnly = type => {
  const typeString = isFunction(type) ? type.toString() : type;
  return typeString.replace("_REQUEST", "").replace("_RESPONSE", "");
};

export const isArrayNotEmpty = array => {
  if (!Array.isArray(array)) {
    throw new Error(`The given argument ${array} is not an array`);
  }

  return array.length > 0;
};
