import "./Spinner.scss";

import PropTypes from "prop-types";
import React, { Component } from "react";
import bem from "utils/bem";

export default class Spinner extends Component {
  static propTypes = {
    cover: PropTypes.bool
  };

  static defaultProps = {
    cover: false
  };

  render() {
    const { cover, children } = this.props;

    return (
      <span
        className={bem("Spinner", {
          cover
        })}
      >
        {children}
      </span>
    );
  }
}
