/* eslint-disable jsx-a11y/alt-text */
import "./CleanImages.scss";

import { ReactComponent as Info } from "assets/icons/info.svg";
import Tip from "components/common/Tip";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import CleanImage from "./CleanImage";

const LATEST_CLEAN_IMAGES_TOP_COUNT = 6;
const LATEST_CLEAN_THUMBNAIL_COUNT = 4;

class CleanImages extends Component {
  renderTooltip = () => {
    const { t } = this.props;

    return (
      <div className="Clean__no-images-tooltip">
        <span>{t("clean.no_images_info")}</span>
      </div>
    );
  };

  renderNoImages = () => {
    const { t } = this.props;

    return (
      <div className="Clean__no-images">
        {t("clean.no_images")}
        <Tip position="bottom-start" html={this.renderTooltip()}>
          <Info />
        </Tip>
      </div>
    );
  };

  renderGalleryIcon = () => {
    const { showImagesCarousel, cleanImages } = this.props;

    return (
      <div
        className="CleanImages__clean-image-icon CleanImages__clean-image"
        onClick={() => showImagesCarousel()}
      >
        {cleanImages.length - LATEST_CLEAN_THUMBNAIL_COUNT}
      </div>
    );
  };

  render() {
    const { cleanImages, showNoImagesInfo, showImagesCarousel } = this.props;
    const imagesCount = cleanImages.length;

    if (imagesCount === 0 && showNoImagesInfo) {
      return this.renderNoImages();
    }

    if (imagesCount === 1) {
      return (
        <div className="CleanImages single-image">
          <CleanImage image={cleanImages[0]} onClick={showImagesCarousel} />
        </div>
      );
    }

    if (imagesCount > 1) {
      return (
        <div className="CleanImages">
          {cleanImages
            .slice(0, LATEST_CLEAN_THUMBNAIL_COUNT)
            .map((image, index) => (
              <CleanImage
                image={image}
                key={image.image_url}
                onClick={showImagesCarousel}
                imageIndex={index}
              />
            ))}

          {imagesCount >= LATEST_CLEAN_IMAGES_TOP_COUNT &&
            this.renderGalleryIcon()}
        </div>
      );
    }
  }
}

export default withTranslation("host.cleans")(CleanImages);
