import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { previousSampleBooking } from "utils/constants";

import Button from "../../../../components/buttons/Button";
import DefaultError from "../../../../components/common/DefaultError";
import Spinner from "../../../../components/Spinner";
import { getSelectedPropertyId } from "../../../../data/properties/selectors";
import { previousBookingsSelector } from "../../../../data/selectors/bookings";
import { BOOKINGS_LIMIT, useGetPreviousBookings } from "../useGetBookings";
import { Booking } from "./Booking";

export const PreviousBookings = () => {
  const { t } = useTranslation("host.bookings");
  const selectedPropertyId = useSelector(getSelectedPropertyId);

  const previousBookings = useSelector(state =>
    previousBookingsSelector(state, selectedPropertyId)
  );
  const previousBookingsCount = useMemo(
    () => previousBookings.length,
    [previousBookings]
  );
  const hasPreviousBookings = useMemo(
    () => previousBookingsCount > 0,
    [previousBookingsCount]
  );

  const [loading, error, getPreviousBookings, isFinal] = useGetPreviousBookings(
    previousBookingsCount
  );

  const handleLoadMore = () => {
    getPreviousBookings(previousBookingsCount);
  };

  return (
    <div className="Bookings">
      {!hasPreviousBookings && (
        <React.Fragment>
          <p className="Bookings__description">
            {t("previous_bookings.empty")}
          </p>
          <p className="text-info">{t("previous_bookings.example")}</p>
        </React.Fragment>
      )}

      {loading && previousBookingsCount === 0 && <Spinner />}

      <div className="Bookings__list">
        {hasPreviousBookings &&
          previousBookings.map(b => (
            <Booking key={b.id} booking={b} dataCy="previous-booking" />
          ))}

        {!hasPreviousBookings && !loading && (
          <Booking
            booking={previousSampleBooking}
            sample={true}
            dataCy="sample-previous-booking"
          />
        )}
      </div>

      {error && <DefaultError>{t("common:something_went_wrong")}</DefaultError>}

      {!isFinal && previousBookingsCount !== 0 && (
        <Button
          outline
          small
          onClick={handleLoadMore}
          loading={loading}
          data-cy="load-more-button"
        >
          {t("common:load_more")}
        </Button>
      )}

      {isFinal && previousBookingsCount >= BOOKINGS_LIMIT && (
        <p>{t("bookings.no_more_previous")}</p>
      )}
    </div>
  );
};
