import { useEffect, useState } from "react";
import { localStorage } from "utils/storage";

export const LOCAL_STORAGE_VALUE_NOT_SET = "value_not_set";

export const useLocalStorage = key => {
  const [value, setValue] = useState(
    localStorage.getItem(key) || LOCAL_STORAGE_VALUE_NOT_SET
  );

  useEffect(() => {
    localStorage.setItem(key, value);
  }, [key, value]);

  return [value, setValue];
};
