import { RECEIVE_AMENITIES_PHOTOS } from "../action_types";

const homeInfo = (
  state = {
    amenitiesPhotos: {
      byProperty: {}
    }
  },
  action
) => {
  switch (action.type) {
    case RECEIVE_AMENITIES_PHOTOS: {
      const { propertyId, photos, amenitiesType } = action;

      return {
        ...state,
        amenitiesPhotos: {
          byProperty: {
            ...state.amenitiesPhotos.byProperty,
            [propertyId]: {
              ...state.amenitiesPhotos.byProperty[propertyId],
              [amenitiesType]: photos
            }
          }
        }
      };
    }

    default:
      return state;
  }
};

export default homeInfo;
