import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./TopCharts.scss";

import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

import BookingsCount from "./BookingsCount";
import PropertyOccupancy from "./PropertyOccupancy";

const TopChartsPerDevice = ({ children }) =>
  isMobile ? (
    <div className="OverviewItem">
      <Slider speed={500} slidesToShow={1} slidesToScroll={1} dots>
        {children}
      </Slider>
    </div>
  ) : (
    <>{children}</>
  );

export const TopCharts = ({
  selectedPropertyId,
  ratings,
  bookingsCount,
  occupancy
}) => {
  const { t } = useTranslation("host.overview");

  if (!selectedPropertyId) {
    return null;
  }

  return (
    <TopChartsPerDevice>
      <BookingsCount
        bookingsCount={bookingsCount}
        selectedPropertyId={selectedPropertyId}
        t={t}
      />
      <PropertyOccupancy
        occupancy={occupancy}
        selectedPropertyId={selectedPropertyId}
        t={t}
      />
    </TopChartsPerDevice>
  );
};

export default TopCharts;
