import "./BookingSummary.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import { ChevronUpDown } from "../../../../components/common/ChevronUpDown";
import GuestAirbnbLink from "../../../../components/common/GuestAirbnbLink";
import GuestImage from "../../../../components/common/GuestImage";
import { PlatformLogo } from "../../../../components/common/PlatformLogo";
import { GuestsNumberSum } from "../../../../components/GuestsBreakdown";
import { bookingChannelCodeSelector } from "../../../../data/selectors/bookings";
import { shortDate } from "../../../../utils/dates";
import { hostSummaryEarningsForDisplay } from "../../../../utils/revenue";
import { BookingPrice } from "./BookingPrice";
import { VerificationTooltip } from "./VerificationTooltip";

export const BookingSummary = ({ booking, expanded }) => {
  const { t } = useTranslation("host.bookings");

  const {
    guest_photo_url,
    guest_name,
    nights,
    airbnb_guest_id,
    checkin,
    checkout,
    host_earnings: hostEarnings,
    is_multiplat: isMultiplat,
    airbnb_reservation_data: airbnbReservationData,
    num_guests: mpGuestNumber,
    approved: bookingApproved,
    revenue_breakdown: revenue
  } = booking;

  const channelCodeName = bookingChannelCodeSelector(booking);

  const channelLogo = booking.booking_channel?.logo_url;

  const channelName = booking?.booking_channel?.name;

  const hostNetEarnings = hostSummaryEarningsForDisplay(revenue, channelName);

  return (
    <div className="BookingSummary">
      <div className="BookingSummary__photo">
        <GuestAirbnbLink guestId={airbnb_guest_id}>
          <GuestImage imageUrl={guest_photo_url} verified={bookingApproved} />
        </GuestAirbnbLink>
        <VerificationTooltip
          verified={bookingApproved}
          channelCodeName={channelCodeName}
        />
      </div>

      <p className="small-header">{guest_name}</p>

      <div className="BookingSummary__price">
        <BookingPrice
          channelCodeName={channelCodeName}
          hostEarnings={hostNetEarnings}
          isMultiplat={isMultiplat}
        />
      </div>

      <div className="BookingSummary__details">
        {channelLogo ? (
          <PlatformLogo logoUrl={channelLogo} />
        ) : (
          <p>{channelName} </p>
        )}

        <p className="BookingSummary__details__content">
          {shortDate(checkin)} - {shortDate(checkout)}
        </p>
        {nights && (
          <p className="BookingSummary__details__content">
            {t("booking.nights", { count: nights })}
          </p>
        )}
        <GuestsNumberSum
          className="BookingSummary__details__content"
          airbnbReservationData={airbnbReservationData}
          mpGuestNumber={mpGuestNumber}
        />
      </div>

      {hostEarnings && <ChevronUpDown expanded={expanded} />}
    </div>
  );
};
