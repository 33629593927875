import * as Sentry from "@sentry/browser";
import { get } from "lodash";

export function clearUserContext() {
  Sentry.configureScope(scope => scope.setUser(null));
}

export function configureScopeForUser({ id, emails, firstName, lastName }) {
  Sentry.configureScope(scope => {
    scope.setUser({
      id: (id || "").toString(),
      email: get(emails, "0.address"),
      username: `${firstName} ${lastName}`
    });
  });
}
