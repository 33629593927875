import "./PropertySelect.scss";

import { ReactComponent as HomeIcon } from "assets/icons/home.svg";
import { resumePropertySetup, selectProperty } from "data/actions/properties";
import { getProperties, getSelectedProperty } from "data/properties/selectors";
import React, { useCallback } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../buttons/Button";
import Dropdown from "../../common/Dropdown";
import PropertySelectItem from "./PropertySelectItem";

const PropertySelect = ({ handleNewPropertyRequest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const properties = useSelector(getProperties);
  const selectedProperty = useSelector(getSelectedProperty);

  const handleSelect = useCallback(
    (id, onboarding) => {
      dispatch(selectProperty(id));
      if (onboarding) {
        dispatch(resumePropertySetup(id));
      }
    },
    [dispatch]
  );

  const title =
    selectedProperty.full_address || t("nav.property.more_info_needed");

  const handleNewPropertySelect = () => {
    handleNewPropertyRequest();
  };

  return (
    <Dropdown
      buttonCy="property-select"
      dropdownCy="property-select-dropdown"
      buttonContent={
        <>
          {" "}
          <div className="PropertySelect__icon">
            <HomeIcon />
          </div>
          <span className="PropertySelect__header-text" title={title}>
            {title}
          </span>
        </>
      }
      hasContent={isMobile || properties.length > 1}
    >
      {properties.map(property => (
        <PropertySelectItem
          key={property.id}
          property={property}
          selected={selectedProperty.id === property.id}
          onSelect={handleSelect}
        />
      ))}
      {isMobile && (
        <div className="PropertySelectItem new-property-button">
          <Button small outline onClick={handleNewPropertySelect}>
            {t("nav.add_new_property")}
          </Button>
        </div>
      )}
    </Dropdown>
  );
};

export default PropertySelect;
