export const LOCATION = "location";
export const ACCESS = "access";
export const ADDITIONAL_DETAILS = "additional-details";
export const LISTING = "listing";

export const PROPERTY_SECTIONS = [
  LOCATION,
  ACCESS,
  ADDITIONAL_DETAILS,
  LISTING
];
