import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Price } from "../../../../components/common/Price";
import { bankAccountSelector } from "../../../../data/selectors/billing";

export const BookingPrice = ({ hostEarnings, isMultiplat }) => {
  const { t } = useTranslation("host.bookings");
  const bankAccount = useSelector(bankAccountSelector);

  return (
    <>
      {hostEarnings && <Price amount={hostEarnings} />}

      {isMultiplat && !bankAccount && (
        <Link to="/billing">
          <small className="BookingSummary__price__missing-account">
            {t("bookings.set_payout_account")}
          </small>
        </Link>
      )}
    </>
  );
};
