import * as Sentry from "@sentry/browser";
import get from "lodash/get";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import { getProfile } from "../../data/reducers/user";
import { Error } from "./Error";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null, hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, hasError: true });
    });
  }

  render() {
    const { children, profile } = this.props;
    const { hasError, eventId } = this.state;

    const fullName = [profile.first_name, profile.last_name]
      .filter(Boolean)
      .join(" ");

    const reporter = {
      email: get(profile, "emails.0.address"),
      name: fullName
    };

    return hasError ? <Error eventId={eventId} user={reporter} /> : children;
  }
}

export default compose(
  withTranslation("common"),
  connect(state => ({
    profile: getProfile(state)
  }))
)(ErrorBoundary);
