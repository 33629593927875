import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import analytics, { track } from "services/segment";

import { isAdmin } from "../data/reducers/user";

export const Tracker = ({ message }) => {
  const location = useLocation();
  const admin = useSelector(isAdmin);

  useEffect(() => {
    if (!admin) {
      if (message) {
        track(message);
      }
      analytics().page();
    }
  }, [location, admin, message]);

  return null;
};
