import { RECEIVE_AMENITIES_PHOTOS } from "../action_types";

export const receiveAmenitiesPhotos = ({
  photos,
  propertyId,
  amenitiesType
}) => ({
  type: RECEIVE_AMENITIES_PHOTOS,
  photos,
  propertyId,
  amenitiesType
});
