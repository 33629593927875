export const REASON_SOMETHING_ELSE = "Something else";
export const REASON_LISTING_PRICING_OCCUPANCY =
  "Listing, Pricing and Occupancy";
export const REASON_UPDATE_AVAILABILITY = "Update availability in calendar";
export const REASON_UPGRADE_PLAN = "Upgrade my Plan";
export const REASON_MAINTENANCE = "Maintenance";
export const REASON_ADD_NEW_PROPERTY = "Adding New Property";

export const CONTACT_REASONS_MAIN = [
  { value: "Payments, Refunds and More", label: "payment_refunds_more" },
  { value: "Guest", label: "guest" },
  {
    value: REASON_LISTING_PRICING_OCCUPANCY,
    label: "listing_pricing_occupancy"
  },
  { value: "Housekeeping and Linen", label: "housekeeping_linen" },
  { value: "Houst Account", label: "houst_account" },
  { value: "Property", label: "property" },
  { value: "Long Term Tenancies", label: "long_term_tenancies" },
  { value: REASON_UPGRADE_PLAN, label: "upgrade_my_plan" },
  { value: REASON_SOMETHING_ELSE, label: "something_else" }
];

export const CATEGORIES_WITHOUT_SECONDARY = [
  REASON_UPGRADE_PLAN,
  REASON_SOMETHING_ELSE,
  REASON_ADD_NEW_PROPERTY
];

export const CONTACT_REASONS_SECONDARY = {
  "Payments, Refunds and More": [
    {
      value: "Management invoice",
      label: "management_invoice"
    },
    {
      value: "Housekeeping fees",
      label: "housekeeping_fees"
    },
    {
      value: "Fees and taxes",
      label: "fees_and_taxes"
    },
    {
      value: "Refund or credit",
      label: "refund_or_credit"
    },
    {
      value: "Insurance claim for multi-platform booking",
      label: "insurance_claim"
    },
    {
      value: "Maintenance charge",
      label: "maintenance_charge"
    },
    {
      value: "Payment - other",
      label: "payment_other"
    }
  ],
  Guest: [
    {
      value: "Resolution claim query",
      label: "resolution_claim_query"
    },
    {
      value: "Report guest damage",
      label: "report_guest_damage"
    },
    {
      value: "Booking alterations",
      label: "booking_alterations"
    },
    {
      value: "Guest compensation",
      label: "guest_compensation"
    },
    {
      value: "Guest reviews",
      label: "guest_reviews"
    },
    {
      value: "Cancel booking",
      label: "cancel_booking"
    },
    {
      value: "Potential guest enquiry",
      label: "potential_guest_enquiry"
    },
    {
      value: "Early check-in",
      label: "early_check_in"
    },
    {
      value: "Pass on information to guest (current or future).",
      label: "pass_on_information"
    },
    {
      value: "Guest - Lost property",
      label: "guest_lost_property"
    },
    {
      value: "Guest - other",
      label: "guest_other"
    }
  ],
  [REASON_LISTING_PRICING_OCCUPANCY]: [
    {
      value: "Request changes to listing",
      label: "request_changes_to_listing"
    },
    {
      value: "Request changes to nightly rate",
      label: "request_changes_to_nightly_rate"
    },
    {
      value: REASON_UPDATE_AVAILABILITY,
      label: "update_availability_in_calendar"
    },
    {
      value: "Occupancy query",
      label: "occupancy_query"
    },
    {
      value: "List on additional platforms (Booking.com, Expedia, Homeaway)",
      label: "list_on_additional_platforms"
    },
    {
      value: "List property on Houst Lets",
      label: "list_property_on_houst_lets"
    },
    {
      value: "Superhost / Airbnb plus",
      label: "superhost"
    },
    {
      value: "Airbnb login details",
      label: "airbnb_login_details"
    }
  ],
  "Housekeeping and Linen": [
    {
      value: "Book or cancel a clean",
      label: "book_or_cancel_a_clean"
    },
    {
      value: "Book a linen delivery or collection",
      label: "book_a_linen_delivery_or_collection"
    },
    {
      value: "Housekeeping invoice inquiry",
      label: "housekeeping_invoice_inquiry"
    },
    {
      value: "Additional special instructions for cleaners",
      label: "additional_special_instructions_for_cleaners"
    },
    {
      value: "Clean or cleaner feedback",
      label: "clean_or_cleaner_feedback"
    }
  ],
  Maintenance: [
    {
      value: "Request maintenance job",
      label: "request_maintenance_job"
    },
    {
      value: "Maintenance job follow up",
      label: "maintenance_job_follow_up"
    },
    {
      value: "Maintenance charge enquiry",
      label: "maintenance_charge_enquiry"
    },
    {
      value: "Request maintenance case update",
      label: "request_maintenance_case_update"
    }
  ],
  "Houst Account": [
    {
      value: "Terminate Houst service",
      label: "terminate_houst_service"
    },
    {
      value: "Onboarding",
      label: "onboarding"
    },
    {
      value: "New property",
      label: "new_property"
    },
    {
      value: "New host referral",
      label: "new_host_referral"
    },
    {
      value: "Other",
      label: "other"
    }
  ],
  Property: [
    {
      value: "Book maintenance",
      label: "book_maintenance"
    },
    {
      value: "Lockbox information",
      label: "lockbox_information"
    },
    {
      value: "Key information",
      label: "key_information"
    },
    {
      value: "Update property information",
      label: "update_property_information"
    },
    {
      value: "Book photography",
      label: "book_photography"
    },
    {
      value: "Report damage at property",
      label: "report_damage_at_property"
    }
  ],
  "Long Term Tenancies": [
    {
      value: "Inventory checks",
      label: "inventory_checks"
    },
    {
      value: "Viewing request",
      label: "viewing_request"
    },
    {
      value: "Security deposit",
      label: "security_deposit"
    },
    {
      value: "Long term enquiry",
      label: "long_term_enquiry"
    },
    {
      value: "Sign up to Houst Lets",
      label: "sign_up_to_houst_lets"
    }
  ]
};
