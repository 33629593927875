import "./TextAreaWithInstructions.scss";

import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";

export const TextareaWithInstructions = ({
  name,
  label,
  className,
  instructions,
  handleChange,
  value
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <label htmlFor={name} className={`WithInstructions ${className}`}>
      {label}
      <textarea
        type="text"
        rows={4}
        id={name}
        name={name}
        onChange={handleChange}
        value={value}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
      />

      {instructions && (
        <CSSTransition
          in={isFocused}
          timeout={{
            enter: 300,
            exit: 500
          }}
          classNames="instructions"
          unmountOnExit
        >
          <div className="instructions">{instructions}</div>
        </CSSTransition>
      )}
    </label>
  );
};
