import { get, isNil, pick } from "lodash";
import { createSelector } from "reselect";

import { getSelectedPropertyId } from "../properties/selectors";
import { basePropertyState } from "../reducers/preferences";

export const ACCOUNT_PREFERENCES_KEYS = [
  "send_booking_confirmation_emails",
  "send_booking_confirmation_smses",
  "host_preference_group",
  "independent_insurance",
  "send_host_clean_survey_emails",
  "send_host_clean_survey_sms"
];

export const getPreferences = createSelector(
  state => state.preferences.byProperty[getSelectedPropertyId(state)],
  preferences => preferences || basePropertyState()
);

export const airbnb90DayApprovalSelector = (state, propertyId) =>
  get(
    state,
    `preferences.byProperty.${propertyId}.settings.airbnb_90_day_approval`
  );

export const hasMissingPreferences = state => {
  const settings = getPreferences(state).settings;

  return (
    !settings.maximum_discretionary_spend || !settings.maximum_replacement_spend
  );
};

export const getAccountPreferences = state =>
  pick(getPreferences(state).settings, ACCOUNT_PREFERENCES_KEYS);

export const selectedPropertyLocalRegulationsApproval = state => {
  const approval = getPreferences(state).settings?.local_regulations_approval;

  return isNil(approval) ? null : approval;
};
