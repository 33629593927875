import { Field, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import Input from "../../../../../components/common/Input";
import { useHomeInfoContext } from "../../HomeInfoContext";
import { HomeInfoButtons } from "../HomeInfoButtons";
import { HomeInfoForm } from "../HomeInfoForm.jsx";
import { WIFI } from "./constants";

export const WiFi = () => {
  const { t } = useTranslation("host.home_info");
  const { data, handleSubmitAndNavigateNext } = useHomeInfoContext();

  return (
    <div className="HomeInfo__content">
      <Formik initialValues={data} onSubmit={handleSubmitAndNavigateNext(WIFI)}>
        {({ handleChange, values }) => (
          <HomeInfoForm>
            <label className="HomeInfoForm__element">
              {t("label.wifi_available")}
              <Input.Toggle
                split
                name="wifi_available"
                id="wifi_available"
                onChange={handleChange}
                value={values.wifi_available}
                withFormik
              />
            </label>
            <label className="HomeInfoForm__element">
              {t("label.wifi_name")}
              <Field type="text" name="wifi_network_name" />
            </label>

            <label className="HomeInfoForm__element">
              {t("label.wifi_password")}
              <Field type="text" name="wifi_password" />
            </label>

            <label className="HomeInfoForm__element">
              {t("label.wifi_speed")}
              <Field type="text" name="wifi_speed" />
            </label>

            <label className="HomeInfoForm__element">
              {t("label.wifi_router_location")}
              <Field
                component="textarea"
                rows={4}
                name="maintenance_wifi_router_location"
              />
            </label>

            <label className="HomeInfoForm__element">
              {t("label.wifi_provider")}
              <Field type="text" name="wifi_provider" />
            </label>

            <HomeInfoButtons sectionId={WIFI} />
          </HomeInfoForm>
        )}
      </Formik>
    </div>
  );
};
