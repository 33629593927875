import "./FormGroup.scss";

import { ErrorMessage, Field, connect } from "formik";
import React, { useEffect, useRef } from "react";

import { ErrorText } from "../common/Input";

export const FormGroup = ({
  name,
  label,
  placeholder,
  readOnly,
  focus,
  component,
  formik: { errors },
  children,
  ...props
}) => {
  const ref = useRef(null);

  useEffect(() => {
    focus && ref.current.focus();
  }, [focus]);

  return (
    <div className="FormGroup" id={`anchor-${name}`}>
      {label && <label htmlFor={name}>{label}</label>}

      <Field
        innerRef={ref}
        id={name}
        name={name}
        placeholder={placeholder}
        component={component}
        autoComplete="off"
        readOnly={readOnly}
        {...props}
      >
        {children}
      </Field>

      <ErrorMessage name={name} component={ErrorText} />
    </div>
  );
};

export default connect(FormGroup);
