import "../../signup/Signup.scss";

import Button from "components/buttons/Button";
import Alert from "components/common/Alert";
import Input from "components/common/Input";
import Logo from "components/common/Logo";
import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";
import api from "services/api";

import { LangSelect } from "../../../components/LangSelect";

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    const token = props.params.token;

    this.state = {
      password: null,
      password_conf: null,
      token: token,
      errors: {},
      success: false
    };
  }

  onChange = (key, value) => {
    const { errors } = this.state;

    this.setState({
      [key]: value,
      errors: {
        ...errors,
        [key]: null
      }
    });
  };

  submit = event => {
    const { password, password_conf, token } = this.state;
    event.preventDefault();

    if (password !== password_conf) {
      return this.setState({
        errors: {
          password_conf: "Passwords do not match"
        }
      });
    }

    api
      .put("/v1/hosts/user/reset_password", null, { password, token })
      .then(res => {
        this.setState({ success: true });
      })
      .catch(e => {
        if (e.error.code === 403) {
          // Token must be invalid - redirect to the password reset request
          this.props.history.replace({
            pathname: "/forgotten_password",
            state: {
              error:
                "Your reset password link was invalid or expired. Please request a new one if you still wish to reset your password."
            }
          });
        }

        this.setState({
          errors: e.error.message
        });
      });
  };

  renderPasswordReset() {
    const { password, password_conf, errors } = this.state;

    return (
      <>
        <h4 className="Login__title header">Password reset</h4>

        <form onSubmit={this.submit}>
          <div className="Login__form">
            <Input.Password
              label="Please enter your new password"
              name="password"
              onChange={val => this.onChange("password", val)}
              value={password}
              error={errors["password"]}
              placeholder="New password"
            />

            <Input.Password
              label="Confirm your password"
              name="password_conf"
              onChange={val => this.onChange("password_conf", val)}
              value={password_conf}
              error={errors["password_conf"]}
              placeholder="New password again"
            />
            <Button type="submit">Reset password</Button>
          </div>

          <div className="Login__link">
            <Link to={{ pathname: "/login" }}>
              Actually, I changed my mind...
            </Link>
          </div>

          {this.state.error && (
            <div className="Login__error">
              <Alert danger>
                <p>
                  <small>{this.state.error}</small>
                </p>
              </Alert>
            </div>
          )}
        </form>
      </>
    );
  }

  renderSuccess = () => (
    <>
      <h4 className="Login__title header">Password reset!</h4>

      <p>You can now login with your new password</p>

      <div className="Login__link">
        <Button outline type="button" to="/login">
          Login
        </Button>
      </div>
    </>
  );

  render() {
    const { success } = this.state;

    return (
      <div className="Signup Login">
        <div className="shapes-background"></div>
        <div className="Signup__layout-container">
          <div className="LoginForm">
            <div className="LoginForm__topContainer">
              <div className="dummy"></div>
              <Logo />
              <LangSelect />
            </div>
            {success ? this.renderSuccess() : this.renderPasswordReset()}
          </div>
        </div>
      </div>
    );
  }
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

export default withParams(PasswordReset);
