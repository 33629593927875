import "./DefaultContainer.scss";

import Fetcher from "components/Fetcher";
import React, { Component } from "react";

class Card extends Component {
  render() {
    const {
      title,
      description,
      right,
      fetcher,
      className,
      children,
      dataTestid
    } = this.props;

    const rightContent = fetcher ? (
      <Fetcher
        fetcher={fetcher}
        spin
        renderFailed={() => <span>Something went wrong...</span>}
      >
        {right}
      </Fetcher>
    ) : (
      right
    );

    const content = fetcher ? (
      <Fetcher fetcher={fetcher}>{children}</Fetcher>
    ) : (
      children
    );

    return (
      <div className={`Card ${className || ""}`} data-testid={dataTestid}>
        {title || rightContent ? (
          <div className="Card__header">
            {title ? <h3 className="small-header">{title}</h3> : null}
            {rightContent ? (
              <div className="Card__header__right">{rightContent}</div>
            ) : null}
          </div>
        ) : null}

        {description && <p className="Card__description">{description}</p>}

        {content && <div className="Card__content">{content}</div>}
      </div>
    );
  }
}

Card.Footer = props => (
  <div className={"Card__footer"}>
    <div className={"Card__footer__content"} {...props} />
  </div>
);

export default Card;
