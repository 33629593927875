import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ReactComponent as Info } from "../../../../../assets/icons/info.svg";
import Modal from "../../../../../components/common/modal";
import Tip from "../../../../../components/common/Tip";
import {
  MAINTENANCE_FEE_TYPE_MAP,
  MAINTENANCE_FEE_TYPE_TIME_BASED,
  MAINTENANCE_STATUS_ACCEPTED,
  MAINTENANCE_STATUS_HOST_PLANNED,
  MAINTENANCE_STATUS_IGNORED,
  MAINTENANCE_STATUS_PLANNED,
  MAINTENANCE_STATUS_RESOLVED
} from "../../../../../data/maintenance/types";
import { selectedPropertyLocalitySelector } from "../../../../../data/properties/selectors";
import { formatAmountWithCurrency } from "../../../../../utils/numbers";

export const CostInfo = ({ item, currencySymbol }) => {
  const { t } = useTranslation("host.maintenance");
  const [showInfoModal, setShowInfoModal] = useState(false);
  const { currency, language, country_code } = useSelector(
    selectedPropertyLocalitySelector
  );
  const status = item.status;

  const handleToggleInfoModal = useCallback(() => {
    setShowInfoModal(!showInfoModal);
  }, [showInfoModal]);

  const formattedCostValue = useMemo(() => {
    const { fee_type } = item;
    let costValue = null;

    if (
      [MAINTENANCE_STATUS_RESOLVED, MAINTENANCE_STATUS_IGNORED].includes(
        status
      ) &&
      item.final_cost
    ) {
      costValue = formatAmountWithCurrency(item.final_cost, {
        currency,
        language,
        country_code
      });
    }

    if (item.estimate) {
      costValue = formatAmountWithCurrency(item.estimate, {
        currency,
        language,
        country_code
      });
    }

    if (costValue) {
      return MAINTENANCE_FEE_TYPE_TIME_BASED.includes(fee_type)
        ? t("cost.value", {
            amount: costValue,
            feeType: MAINTENANCE_FEE_TYPE_MAP[fee_type]
          })
        : costValue;
    }

    return t("no_estimate");
  }, [country_code, currency, item, language, status, t]);

  if (
    [
      MAINTENANCE_STATUS_ACCEPTED,
      MAINTENANCE_STATUS_HOST_PLANNED,
      MAINTENANCE_STATUS_PLANNED
    ].includes(status)
  ) {
    return (
      <React.Fragment>
        {showInfoModal && (
          <Modal
            title={t("cost.tooltip_title.notResolved")}
            closeModal={handleToggleInfoModal}
            showDesktopCloseButton
          >
            <div>
              <p>{t("cost.tooltip.notResolved.1")}</p>
              <ul>
                <li>{t("cost.tooltip.notResolved.2")}</li>
                <li>{t("cost.tooltip.notResolved.3")}</li>
                <li>{t("cost.tooltip.notResolved.4")}</li>
                <li>{t("cost.tooltip.notResolved.5")}</li>
                <li>{t("cost.tooltip.notResolved.6")}</li>
                <li>{t("cost.tooltip.notResolved.7")}</li>
              </ul>
            </div>
          </Modal>
        )}
        <dt>{t("label.estimate")}</dt>
        <dd data-testid="item-cost" onClick={handleToggleInfoModal}>
          {formattedCostValue}
          <Info />
        </dd>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <dt>{t("label.actual_cost")}</dt>
      <dd data-testid="resolved-item-cost">
        {formattedCostValue}

        <Tip
          html={
            <>
              <h5 className="small-header">
                {t("cost.tooltip_title.resolved")}
              </h5>
              <p>{t("cost.tooltip.resolved", { currencySymbol })}</p>
            </>
          }
        >
          <Info />
        </Tip>
      </dd>
    </React.Fragment>
  );
};
