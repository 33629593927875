import "./GuestImage.scss";

import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";
import classNames from "classnames";
import React from "react";

const GuestImage = ({ imageUrl, verified }) => {
  return (
    <div
      className={classNames("GuestImage", { verified })}
      style={{ backgroundImage: `url(${imageUrl ? imageUrl : ""})` }}
    >
      {!imageUrl && <ProfileIcon />}
    </div>
  );
};

export default GuestImage;
