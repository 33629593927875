import "./NavMenu.scss";

import { ReactComponent as ConversationIcon } from "assets/icons/chat.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { ReactComponent as OverviewIcon } from "assets/icons/globe.svg";
import { ReactComponent as HomeInfoIcon } from "assets/icons/home.svg";
import { ReactComponent as BillingIcon } from "assets/icons/nav/billing.svg";
import { ReactComponent as BookingIcon } from "assets/icons/nav/bookings.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/nav/calendar.svg";
import { ReactComponent as CleanIcon } from "assets/icons/nav/cleans.svg";
import { ReactComponent as EmailIcon } from "assets/icons/nav/contact.svg";
import { ReactComponent as FaqIcon } from "assets/icons/nav/faq.svg";
import { ReactComponent as MaintenanceIcon } from "assets/icons/nav/maintenance.svg";
import { ReactComponent as OnboardingIcon } from "assets/icons/nav/onboarding.svg";
import { ReactComponent as ChartIcon } from "assets/icons/nav/performance.svg";
import { ReactComponent as PlatformsIcon } from "assets/icons/nav/platforms.svg";
import { ReactComponent as PreferencesIcon } from "assets/icons/nav/preferences.svg";
import classNames from "classnames";
import {
  getPropertiesWithMultiplatOption,
  getSelectedProperty,
  getSelectedPropertyNeedsOnboarding
} from "data/properties/selectors";
import { propertyNotInSpain } from "data/property_states";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { SUPPORT_URL } from "utils/constants";

const AlertIcon = () => <div className="NavMenu__alert-icon">!</div>;

const iconsMap = {
  AlertIcon,
  BookingIcon,
  CalendarIcon,
  ChartIcon,
  CloseIcon,
  DownloadIcon,
  PreferencesIcon,
  ConversationIcon,
  CleanIcon,
  EmailIcon,
  PlatformsIcon,
  OnboardingIcon,
  OverviewIcon,
  MaintenanceIcon,
  FaqIcon,
  BillingIcon,
  HomeInfoIcon
};

const NavIcon = ({ name }) => {
  const Component = iconsMap[name];

  return (
    <div className="NavMenu__icon">
      <Component />
    </div>
  );
};

const CustomNavLink = props => (
  <NavLink
    className={({ isActive }) =>
      isActive ? "NavMenu__link NavMenu__link--active" : "NavMenu__link"
    }
    {...props}
  />
);
const NavMenu = ({
  handleToggleMenu,
  menuOpen,
  preferenceViewed,
  unresolvedMaintenanceCount
}) => {
  const { t } = useTranslation();
  const isPropertyUnsorted = useSelector(getSelectedPropertyNeedsOnboarding);
  const hasMultiplatOption = useSelector(
    state => getPropertiesWithMultiplatOption(state).length > 0
  );
  const selectedProperty = useSelector(getSelectedProperty);

  const menuClassNames = classNames("NavMenu", { "NavMenu--open": menuOpen });

  return (
    <div className={menuClassNames}>
      <div className="NavMenu__background" onClick={handleToggleMenu} />

      <div className="NavMenu__content">
        <button
          type="button"
          className="NavMenu__close-button"
          onClick={handleToggleMenu}
        >
          <CloseIcon />
        </button>

        <ul className="NavMenu__list">
          <li className="NavMenu__item">
            <CustomNavLink to="/">
              {isPropertyUnsorted ? (
                <React.Fragment>
                  <NavIcon name="OnboardingIcon" />
                  {t("nav.menu.onboarding")}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <NavIcon name="OverviewIcon" onClick={handleToggleMenu} />

                  {t("nav.menu.overview")}
                </React.Fragment>
              )}
            </CustomNavLink>
          </li>

          {
            <li className="NavMenu__item">
              <CustomNavLink to="/home-info">
                <NavIcon name="HomeInfoIcon" />

                {t("nav.menu.home_info")}
              </CustomNavLink>
            </li>
          }

          <li className="NavMenu__item">
            <CustomNavLink to="/calendar">
              <NavIcon name="CalendarIcon" />

              {t("nav.menu.calendar")}
            </CustomNavLink>
          </li>

          <li className="NavMenu__item">
            <CustomNavLink to="/bookings">
              <NavIcon name="BookingIcon" />

              {t("nav.menu.bookings")}
            </CustomNavLink>
          </li>

          <li className="NavMenu__item">
            <CustomNavLink to="/performance">
              <NavIcon name="ChartIcon" />

              {t("nav.menu.performance")}
            </CustomNavLink>
          </li>

          <li className="NavMenu__item">
            <CustomNavLink to="/billing">
              <NavIcon name="BillingIcon" />

              {t("nav.menu.billing")}
            </CustomNavLink>
          </li>

          {hasMultiplatOption && (
            <li className="NavMenu__item">
              <CustomNavLink to="/platforms">
                <NavIcon name="PlatformsIcon" />

                {t("nav.menu.platforms")}
              </CustomNavLink>
            </li>
          )}

          <li className="NavMenu__item">
            <CustomNavLink to="/cleans">
              <NavIcon name="CleanIcon" />

              {t("nav.menu.cleans")}
            </CustomNavLink>
          </li>

          <li className="NavMenu__item" data-cy="maintenance-nav-link">
            <CustomNavLink to="/maintenance">
              <NavIcon name="MaintenanceIcon" />

              {t("nav.menu.maintenance")}

              {unresolvedMaintenanceCount > 0 && (
                <AlertIcon className="NavMenu__alert-icon" />
              )}
            </CustomNavLink>
          </li>

          <li className="NavMenu__item">
            <CustomNavLink
              to={
                !preferenceViewed
                  ? "/preferences?tab=tiered_preference"
                  : "/preferences"
              }
            >
              <NavIcon name="PreferencesIcon" />

              {t("nav.menu.preferences")}

              {!preferenceViewed && (
                <AlertIcon className="NavMenu__alert-icon" />
              )}
            </CustomNavLink>
          </li>

          <li className="NavMenu__item">
            <CustomNavLink to="/contact">
              <NavIcon name="EmailIcon" />

              {t("nav.profile_menu.contact")}
            </CustomNavLink>
          </li>

          <li className="NavMenu__item">
            {propertyNotInSpain(selectedProperty) && (
              <a
                className="NavMenu__link"
                href={SUPPORT_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <NavIcon name="FaqIcon" />
                {t("nav.profile_menu.faq")}
              </a>
            )}
          </li>

          <li className="NavMenu__item">
            <CustomNavLink to="/install">
              <NavIcon name="DownloadIcon" />

              {t("install_app")}
            </CustomNavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavMenu;
