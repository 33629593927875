import "./UKReferrals.scss";

import Button from "components/buttons/Button";
import Card from "components/common/Card";
import { fetch } from "data/actions/fetcher";
import * as Fetchers from "data/actions/fetchers";
import { getSelectedProperty } from "data/properties/selectors";
import { getLoginEmail, getPersonalReferralCode } from "data/reducers/user";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { formatAmountWithCurrency } from "utils/numbers";

class UKReferrals extends Component {
  render() {
    const {
      personalReferralCode,
      registerReferral,
      property: {
        locality: { referral_amount, currency, language, country_code }
      }
    } = this.props;

    const dashboardLink = `https://referral.airsorted.uk/?kolid=${personalReferralCode}`;
    const cardTitle = `Invite friends – and earn ${formatAmountWithCurrency(
      referral_amount,
      { currency, language, country_code }
    )} credit for each!`;

    return (
      <div className="UKReferrals">
        <Card title={cardTitle}>
          {personalReferralCode && (
            <div className="UKReferrals__link">
              <a target="_blank" rel="noopener noreferrer" href={dashboardLink}>
                Go to your referral dashboard
              </a>
            </div>
          )}

          {!personalReferralCode && (
            <div>
              <p className="UKReferrals__disclaimer">
                Know people who’ll love Houst? Share your unique referral link
                with them, and we’ll pay you handsomely (in credit) when they
                join, once their first guest has stayed.
              </p>

              <p className="UKReferrals__disclaimer">
                Click the ‘Start Referring’ button now to get your unique
                referral link and find out more
              </p>

              <div className="UKReferrals__button">
                <Button type="button" onClick={registerReferral}>
                  Start Referring
                </Button>
              </div>
            </div>
          )}
        </Card>
      </div>
    );
  }
}

export default compose(
  connect(
    state => ({
      property: getSelectedProperty(state),
      email: getLoginEmail(state),
      personalReferralCode: getPersonalReferralCode(state)
    }),
    dispatch => ({
      registerReferral: () =>
        dispatch(fetch(Fetchers.registerReferral())).then(() =>
          dispatch(fetch(Fetchers.user()))
        )
    })
  )
)(UKReferrals);
