import "./Referrals.scss";

import Card from "components/common/Card";
import { getSelectedProperty } from "data/properties/selectors";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { formatAmountWithCurrency } from "utils/numbers";

import SocialShare from "./components/SocialShare";
import UKReferrals from "./components/UKReferrals";

class Referrals extends Component {
  getCardTitle = () => {
    const {
      t,
      property: {
        locality: { referral_amount, currency, language, country_code }
      }
    } = this.props;

    return t("title", {
      referralAmount: formatAmountWithCurrency(referral_amount, {
        currency,
        language,
        country_code
      })
    });
  };

  render() {
    const { t, property } = this.props;

    if (property.locality.country_code === "GB") {
      return <UKReferrals />;
    }

    return (
      <div className="Referrals">
        <Card title={this.getCardTitle()}>
          <div className="Referrals__content">
            <p>{t("headline")}</p>

            <div className="Referrals__content__code">
              <p>{t("copy_referral_code")}</p>
              <h3 className="big-header">{property.home_code}</h3>
            </div>

            <p>{t("send_referral_code")}</p>

            <SocialShare code={property.home_code} />
          </div>

          <div className="Referrals__footer">
            <p className="text-info">
              <small>{t("footnotes")}</small>
            </p>
          </div>
        </Card>
      </div>
    );
  }
}

export default compose(
  withTranslation("host.referrals"),
  connect(state => ({
    property: getSelectedProperty(state)
  }))
)(Referrals);
