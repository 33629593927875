import "../../ListForm.scss";

import { Field, FieldArray, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as BinIcon } from "../../../../../assets/icons/bin.svg";
import Button from "../../../../../components/buttons/Button";
import Alert from "../../../../../components/common/Alert";
import Card from "../../../../../components/common/Card";
import DefaultError from "../../../../../components/common/DefaultError";
import { HomeInfoForm } from "../../components/HomeInfoForm";
import { BED_SIZES, BED_TYPES, showSaveButton } from "../../formHelpers";
import { useBeds } from "../../useBeds";

const INITIAL_BED_DATA = {
  mattress_size: "",
  location: "",
  bed_type: ""
};

export const Beds = () => {
  const { t } = useTranslation("host.home_info");

  const { beds, deleteBed, editError, getError, handleBedEdit } = useBeds();

  const handleCancelClick = (
    isNew,
    arrayHelpers,
    setFieldTouched,
    setFieldValue,
    index
  ) => {
    if (isNew) {
      arrayHelpers.remove(index);
    } else {
      setFieldValue(`beds[${index}]`, beds[index]);
      setFieldTouched(`beds[${index}]`, false);
    }
  };

  return (
    <>
      {editError && (
        <Alert fixed danger small>
          {editError}
        </Alert>
      )}

      <Card className="ListForm">
        {getError && <DefaultError>{getError}</DefaultError>}

        {beds && (
          <Formik initialValues={{ beds }} enableReinitialize={true}>
            {({
              values,
              touched,
              setFieldTouched,
              handleChange,
              setFieldValue,
              errors,
              handleBlur
            }) => {
              return (
                <HomeInfoForm>
                  <FieldArray
                    name="beds"
                    render={arrayHelpers => (
                      <div className="HomeInfoForm__element ListFormSingle">
                        {values.beds.map((bed, index) => (
                          <div
                            className="ListFormDetails"
                            data-cy="bed-form"
                            key={index}
                          >
                            <label className="HomeInfoForm__element">
                              {t("label.bed_location")}

                              <Field
                                type="text"
                                name={`beds.${index}.location`}
                                onChange={e => {
                                  handleBlur(e);
                                  handleChange(e);
                                }}
                                data-cy={`location-input-${index}`}
                              />
                            </label>

                            <div className="HomeInfoForm__group">
                              <label className="HomeInfoForm__element">
                                {t("label.mattress_size")}
                                <div className="select-wrapper">
                                  <Field
                                    type="number"
                                    component="select"
                                    name={`beds.${index}.mattress_size`}
                                    onChange={e => {
                                      handleBlur(e);
                                      handleChange(e);
                                    }}
                                    data-cy={`mattress-size-input-${index}`}
                                  >
                                    <option disabled={true} value="">
                                      {t("common:please_select")}
                                    </option>
                                    {BED_SIZES.map(size => (
                                      <option
                                        value={size.value}
                                        key={size.label}
                                      >
                                        {t(`bed_size.${size.label}`)}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                              </label>

                              <label className="HomeInfoForm__element">
                                {t("label.bed_type")}
                                <div className="select-wrapper">
                                  <Field
                                    type="number"
                                    component="select"
                                    name={`beds.${index}.bed_type`}
                                    onChange={e => {
                                      handleBlur(e);
                                      handleChange(e);
                                    }}
                                    data-cy={`bed-type-input-${index}`}
                                  >
                                    <option disabled={true} value="">
                                      {t("common:please_select")}
                                    </option>
                                    {BED_TYPES.map(type => (
                                      <option
                                        value={type.value}
                                        key={type.label}
                                      >
                                        {t(`bed_type.${type.label}`)}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                              </label>
                            </div>

                            {showSaveButton(
                              values.beds[index],
                              touched.beds?.[index]
                            ) && (
                              <>
                                <Button
                                  small
                                  type="button"
                                  data-cy={`confirm-button-${index}`}
                                  onClick={() => {
                                    handleBedEdit(values, values.beds[index]);
                                  }}
                                >
                                  {t("common:save")}
                                </Button>

                                <Button
                                  outline
                                  small
                                  type="button"
                                  data-cy={`cancel-button-${index}`}
                                  onClick={() => {
                                    handleCancelClick(
                                      !values.beds[index].id,
                                      arrayHelpers,
                                      setFieldTouched,
                                      setFieldValue,
                                      index
                                    );
                                  }}
                                >
                                  {t("common:cancel")}
                                </Button>
                              </>
                            )}

                            {values.beds[index].id && (
                              <Button
                                outline
                                icon
                                type="button"
                                onClick={() => {
                                  deleteBed(values.beds[index].id);
                                }}
                                data-cy={`delete-button-${index}`}
                              >
                                <BinIcon />
                              </Button>
                            )}
                          </div>
                        ))}

                        <Button
                          outline
                          onClick={() => arrayHelpers.push(INITIAL_BED_DATA)}
                          type="button"
                          data-cy="add-bed-button"
                        >
                          {t("add_bed")}
                        </Button>
                      </div>
                    )}
                  />
                </HomeInfoForm>
              );
            }}
          </Formik>
        )}
      </Card>
    </>
  );
};
