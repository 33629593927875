import "./Billing.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import { useBankAccount } from "utils/useBankAccount";

import Alert from "../../../components/common/Alert";
import { BillingDetails } from "./components/BillingDetails";
import { DocumentsLists } from "./components/DocumentsLists";

export const Billing = () => {
  const { isMissingBankAccount } = useBankAccount();
  const { t } = useTranslation("host.billing");

  return (
    <div className="Billing">
      {isMissingBankAccount && (
        <Alert warning small>
          <p data-cy="bank-details-alert">{t("bank_account.missing_alert")}</p>
        </Alert>
      )}
      <div className="Billing__container">
        <DocumentsLists />
        <BillingDetails />
      </div>
    </div>
  );
};

export default Billing;
