import "./ContactModal.scss";

import Button from "components/buttons/Button";
import Modal from "components/common/modal";
import { getSelectedPropertyId } from "data/properties/selectors";
import { getProfile } from "data/reducers/user";
import { Field, Formik } from "formik";
import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CONTACT_REASONS_SECONDARY } from "scenes/dashboard/contact/components/contact_reasons";
import { findPrimaryEmail } from "utils/findPrimaryEmail";

import config from "../../../../../config";

export const ContactModal = ({
  closeModal,
  maintenanceSalesforceId,
  description
}) => {
  const { t } = useTranslation("host.contact");
  const formRef = useRef(null);
  const formikRef = useRef(null);

  const selectedPropertyId = useSelector(getSelectedPropertyId);
  const profile = useSelector(getProfile);
  const {
    SF_ACTION_URL,
    SF_REDIRECT_URL,
    SF_ORGID,
    SF_PROPID_ID,
    SF_CATEGORY_ID,
    SF_SUB_CATEGORY_ID,
    SF_MAINTENANCE_ID
  } = config.SF_CONTACT_FORM;

  const defaultValues = useMemo(() => {
    const email = findPrimaryEmail(profile.emails);

    return {
      name: `${profile.first_name} ${profile.last_name}`,
      email: email?.address,
      description: description,
      [SF_MAINTENANCE_ID]: maintenanceSalesforceId
    };
  }, [
    profile.emails,
    profile.first_name,
    profile.last_name,
    SF_MAINTENANCE_ID,
    description,
    maintenanceSalesforceId
  ]);

  const handleSubmit = () => {
    // We want to send post and redirect to salesforce page
    // This is to trigger an oldschool synchronous POST request
    formRef.current.submit();
  };

  const triggerFormikSubmit = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }
  };

  return (
    <Modal
      className="ContactModal"
      closeModal={closeModal}
      footer={
        <div>
          <Button outline type="button" onClick={() => closeModal(false)}>
            {t("common:close")}
          </Button>

          <Button
            onClick={() => triggerFormikSubmit()}
            data-cy="maintenance-modal-submit"
          >
            {t("common:confirm")}
          </Button>
        </div>
      }
    >
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={defaultValues}
        innerRef={formikRef}
      >
        <form
          action={SF_ACTION_URL}
          method="POST"
          className="ContactForm"
          ref={formRef}
        >
          <input type="hidden" name="orgid" value={SF_ORGID} />
          <input type="hidden" name="retURL" value={SF_REDIRECT_URL} />
          <input type="hidden" name={SF_CATEGORY_ID} value="Maintenance" />

          <input
            type="hidden"
            id={SF_PROPID_ID}
            name={SF_PROPID_ID}
            size="20"
            value={selectedPropertyId}
          />

          <Field
            type="hidden"
            id={SF_MAINTENANCE_ID}
            name={SF_MAINTENANCE_ID}
            size="20"
            data-testid="maintenance-id-field"
          />

          <div className="ContactForm__group">
            <label htmlFor="name">{t("form.label_name")}</label>

            <Field
              id="name"
              maxLength="80"
              name="name"
              size="20"
              component="input"
              placeholder="John Smith"
              required
              data-testid="name-field"
            />
          </div>

          <div className="ContactForm__group">
            <label htmlFor="email">{t("form.label_email")}</label>

            <Field
              id="email"
              maxLength="80"
              name="email"
              size="20"
              component="input"
              required
              data-testid="email-field"
            />
          </div>

          <div className="ContactForm__group">
            <label htmlFor={SF_SUB_CATEGORY_ID}>
              {t("form.label_reason_specific")}
            </label>

            <div className="select-wrapper">
              <Field
                component="select"
                id={SF_SUB_CATEGORY_ID}
                name={SF_SUB_CATEGORY_ID}
                title="Contact Sub Category"
                data-cy="subcategory-field"
                required
              >
                <option value="" hidden>
                  {t("form.reason.default")}
                </option>
                {CONTACT_REASONS_SECONDARY.Maintenance.map(
                  ({ label, value }) => (
                    <option key={label} value={value}>
                      {t(`form.reason.secondary.${label}`)}
                    </option>
                  )
                )}
              </Field>
            </div>
          </div>

          <div className="ContactForm__group">
            <label htmlFor="message">{t("form.label_message")}</label>

            <Field
              component="textarea"
              name="description"
              id="description"
              required
              data-testid="description-field"
            />
          </div>
        </form>
      </Formik>
    </Modal>
  );
};
