import "./AirbnbAlert.scss";

import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import Button from "../../components/buttons/Button";
import Card from "../../components/common/Card";
import Modal from "../../components/common/modal";
import Checkbox from "../../components/forms/Checkbox";
import { fetch } from "../../data/actions/fetcher";
import { updatePreferences } from "../../data/actions/fetchers";

const FRANCE_COUNTRY_CODE = "FR";
const FRANCE_LIMIT = 120;
const COMMON_LIMIT = 90;

class AirbnbAlert extends Component {
  state = {
    hasOptedOut: false,
    showModal: false
  };

  handleChange = () => {
    this.setState({
      hasOptedOut: !this.state.hasOptedOut
    });
  };

  toggleModal = (show = true) => {
    this.setState({
      showModal: show
    });
  };

  onSubmit = () => {
    this.props.enable90Day(this.props.selectedProperty.id).then(() => {
      this.toggleModal(false);
    });
  };

  render() {
    const { t, limitedProperties, selectedProperty, airbnb90DayApproval } =
      this.props;
    const { showModal, hasOptedOut } = this.state;
    const daysLimit =
      selectedProperty.locality.country_code === FRANCE_COUNTRY_CODE
        ? FRANCE_LIMIT
        : COMMON_LIMIT;

    if (
      airbnb90DayApproval === undefined ||
      airbnb90DayApproval ||
      !limitedProperties.includes(selectedProperty)
    ) {
      return null;
    }

    return (
      <React.Fragment>
        {showModal && (
          <Modal
            title={t("airbnb_alert.modal.title", { daysLimit })}
            closeModal={() => this.toggleModal(false)}
            footer={
              <span>
                <Button
                  type="button"
                  onClick={() => this.toggleModal(false)}
                  outline
                >
                  {t("common:cancel")}
                </Button>
                <Button type="submit" onClick={this.onSubmit}>
                  {t("common:close_and_continue")}
                </Button>
              </span>
            }
          >
            <p>{t("airbnb_alert.modal.paragraph1")}</p>

            <p>{t("airbnb_alert.modal.paragraph2")}</p>
          </Modal>
        )}

        <Card
          title={t("airbnb_alert.title", { daysLimit })}
          className="AirbnbAlert__card"
        >
          <div className="AirbnbAlert">
            <p>{t("airbnb_alert.content", { daysLimit })}</p>

            <div className="AirbnbAlert__form">
              <div className="AirbnbAlert__form__controls">
                <Checkbox id="limit" onChange={this.handleChange}>
                  <label className="Checkbox__label" htmlFor="limit">
                    <Trans i18nKey="airbnb_alert.confirmation">
                      I give consent to Houst to complete{" "}
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLScnhnPsuu6ZJq1pnc-rIFjdDJy9qQcb6IfKbAESE39TrUK5Lw/viewform"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        this form
                      </a>
                      , agree to
                      <button
                        type="button"
                        className="Link-button"
                        onClick={this.toggleModal}
                      >
                        these terms
                      </button>
                      and declare I have current and valid planning permission
                      that allows me to exceed the limit.
                    </Trans>
                  </label>
                </Checkbox>
              </div>

              <div className="AirbnbAlert__form__action">
                <Button
                  onClick={() => this.toggleModal()}
                  disabled={!hasOptedOut}
                  data-cy="airbnb-90-day-submit"
                >
                  {t("airbnb_alert.submit_and_close")}
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default compose(
  withTranslation("host.overview"),
  connect(null, dispatch => ({
    enable90Day: propertyId =>
      dispatch(
        fetch(
          updatePreferences(propertyId, {
            airbnb_90_day_approval: true
          })
        )
      )
  }))
)(AirbnbAlert);
