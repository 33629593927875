import "./Performance.scss";

import Card from "components/common/Card";
import {
  getSelectedProperty,
  getSelectedPropertyId
} from "data/properties/selectors";
import { getPerformance } from "data/selectors/performance";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import CommentsSection from "./components/CommentsSection";
import PerformanceChartContainer from "./components/PerformanceChartContainer";
import Ratings from "./components/Ratings";
import { useGetEarnings } from "./useGetEarnings";

const Performance = () => {
  const selectedProperty = useSelector(getSelectedProperty);
  const performance = useSelector(getPerformance);
  const selectedPropertyId = useSelector(getSelectedPropertyId);
  const { hasAnyEarnings } = performance;

  const { getEarnings } = useGetEarnings(selectedPropertyId);

  useEffect(() => {
    getEarnings();
  }, [getEarnings]);

  return (
    <div className="Performance">
      <Card className="Performance__chart">
        <div className="Performance__content">
          <div className="Performance__summary">
            <PerformanceChartContainer
              property={selectedProperty}
              hasAnyEarnings={hasAnyEarnings}
            />
          </div>
        </div>
      </Card>

      <div className="Card Performance__ratings-container">
        <CommentsSection propertyId={selectedPropertyId} />
        <Ratings />
      </div>
    </div>
  );
};

export default Performance;
