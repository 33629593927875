import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import Input from "../../../../../components/common/Input";
import { useHomeInfoContext } from "../../HomeInfoContext";
import { HomeInfoButtons } from "../HomeInfoButtons";
import { HomeInfoForm } from "../HomeInfoForm.jsx";
import { LISTING } from "./constants";

export const Listing = () => {
  const { t } = useTranslation("host.home_info");

  const { data, handleSubmitAndNavigateNext } = useHomeInfoContext();

  return (
    <div className="HomeInfo__content">
      <Formik
        initialValues={data}
        onSubmit={handleSubmitAndNavigateNext(LISTING)}
      >
        {({ handleChange, values }) => (
          <HomeInfoForm>
            <label className="HomeInfoForm__element">
              {t("label.unsuitable_for_children")}
              <Input.Toggle
                split
                name="unsuitable_for_children"
                id="unsuitable_for_children"
                value={values.unsuitable_for_children}
                onChange={handleChange}
                withFormik
              />
            </label>

            <label className="HomeInfoForm__element">
              {t("label.unsuitable_for_infants")}
              <Input.Toggle
                split
                name="unsuitable_for_infants"
                id="unsuitable_for_infants"
                value={values.unsuitable_for_infants}
                onChange={handleChange}
                withFormik
              />
            </label>

            <label className="HomeInfoForm__element">
              {t("label.allows_pets_as_host")}
              <Input.Toggle
                split
                name="allows_pets_as_host"
                id="allows_pets_as_host"
                value={values.allows_pets_as_host}
                onChange={handleChange}
                withFormik
              />
            </label>

            <HomeInfoButtons sectionId={LISTING} />
          </HomeInfoForm>
        )}
      </Formik>
    </div>
  );
};
