import qs from "utils/querystring";

import { receiveBankAccountDetails, receiveCardDetails } from "./billing";
import { receiveBooking } from "./bookings";
import { receiveCalendar } from "./calendar";
import {
  receiveBookingsCount,
  receiveEarnings,
  receiveOccupancy
} from "./performance";
import { receivePreferences } from "./preferences";
import { receiveProperties, receiveProperty } from "./properties";
import { passwordUpdated, receiveLocalities, receiveUser } from "./user";

// TODO: kill this file
export const createFetcher = config => ({
  method: "get",
  key: `${config.method || "get"} ${qs(config.params, config.endpoint)}`,
  retryCount: 0,
  retryDelay: 1000,
  ...config
});

// GET
export const user = () =>
  createFetcher({
    endpoint: "/v1/hosts/user/me",
    onResponse: res => receiveUser(res)
  });

export const properties = () =>
  createFetcher({
    endpoint: "/v1/hosts/properties",
    onResponse: res => receiveProperties(res)
  });

export const property = propertyId =>
  createFetcher({
    endpoint: `/v1/hosts/properties/${propertyId}`,
    onResponse: res => {
      return receiveProperty(res);
    }
  });

export const terms = propertyId =>
  createFetcher({
    endpoint: `/v1/hosts/properties/${propertyId}/terms.html`
  });

export const booking = bookingId =>
  createFetcher({
    endpoint: `/v1/hosts/bookings/${bookingId}`,
    onResponse: res => receiveBooking(res)
  });

export const earnings = (propertyId, params) =>
  createFetcher({
    params,
    endpoint: `/v1/hosts/properties/${propertyId}/performance/earnings`,
    onResponse: res => receiveEarnings(propertyId, res)
  });

export const bookingsCount = (propertyId, params) =>
  createFetcher({
    params,
    endpoint: `/v1/hosts/properties/${propertyId}/performance/bookings_count`,
    onResponse: res => receiveBookingsCount(propertyId, res)
  });

export const occupancy = (propertyId, params) =>
  createFetcher({
    params,
    endpoint: `/v1/hosts/properties/${propertyId}/performance/occupancy`,
    onResponse: res => receiveOccupancy(propertyId, res)
  });

export const preferences = propertyId =>
  createFetcher({
    endpoint: `/v1/hosts/properties/${propertyId}/preferences`,
    onResponse: res => receivePreferences(res, propertyId)
  });

export const calendar = (propertyId, params) =>
  createFetcher({
    params,
    endpoint: `/v1/hosts/properties/${propertyId}/calendar`,
    onResponse: res => receiveCalendar(res, propertyId)
  });

export const localities = () =>
  createFetcher({
    endpoint: "/v1/hosts/localities",
    onResponse: res => receiveLocalities(res)
  });

// POST
export const updateDirectDebit = () =>
  createFetcher({
    method: "put",
    endpoint: "/v1/hosts/billing/direct_debit"
  });

export const updateStripeCard = token =>
  createFetcher({
    method: "put",
    body: {
      stripe_token: token
    },
    endpoint: "/v1/hosts/billing/card",
    onResponse: res => receiveCardDetails(res)
  });

export const updateBankAccount = body =>
  createFetcher({
    method: "put",
    body,
    endpoint: "/v1/hosts/billing/bank_account",
    onResponse: res => receiveBankAccountDetails(res)
  });

export const updatePreferences = (propertyId, prefs) =>
  createFetcher({
    method: "patch",
    endpoint: `/v1/hosts/properties/${propertyId}/preferences`,
    body: prefs,
    onResponse: res => receivePreferences(res, propertyId)
  });

export const updateUser = user =>
  createFetcher({
    method: "patch",
    body: user,
    endpoint: "/v1/hosts/user/me",
    onResponse: res => receiveUser(res)
  });

export const updatePassword = body =>
  createFetcher({
    method: "put",
    body,
    endpoint: "/v1/hosts/user/me/password",
    onResponse: res => passwordUpdated()
  });

export const updateProperty = (propertyId, body) =>
  createFetcher({
    method: "patch",
    body,
    endpoint: `/v1/hosts/properties/${propertyId}`,
    onResponse: res => receiveProperty(res)
  });

export const payDeposit = propertyId =>
  createFetcher({
    method: "post",
    endpoint: `/v1/hosts/properties/${propertyId}/deposit`
  });

export const registerReferral = () =>
  createFetcher({
    method: "post",
    endpoint: `/v1/hosts/user/subscribe_referral`
  });
