import {
  areIntervalsOverlapping,
  format,
  formatDistance,
  isDate,
  isPast,
  isSameDay,
  isToday,
  isValid,
  isWithinInterval,
  subDays
} from "date-fns";
import enGB from "date-fns/locale/en-GB";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import pt from "date-fns/locale/pt";
import { get, isEmpty } from "lodash";

import i18n from "../i18n";
import { parseDate } from "./dateParser";

export const locales = { en: enGB, fr, es, pt };
const locale = locales[i18n.language];

export const ISO_DATE = "yyyy-MM-dd";

export const prettyDate = date => format(date, "iii do MMM, yyyy", { locale });

export const shortPrettyDate = date =>
  format(date, "iii dd MMM, yyyy", { locale });

export const shortDate = date => format(date, "dd/MM/yy", { locale });

export const getSelectionDaysFromRange = (selectionRange, days) => {
  const [start, end] = selectionRange;

  return days.filter(day =>
    isWithinInterval(new Date(day.date), { start, end })
  );
};

const availabilityComparisonFn = valueToCompare => day =>
  Array.isArray(valueToCompare)
    ? valueToCompare.includes(day.available)
    : day.available === valueToCompare;

export const checkAllDatesAvailability = ({ valueToCompare, days }) =>
  days.every(availabilityComparisonFn(valueToCompare));

export const hasDatesAirbnbSuspended = (selectionRange, airbnbBlockDays) => {
  if (isEmpty(selectionRange) || isEmpty(airbnbBlockDays)) {
    return false;
  }

  const [startSelection, endSelection] = selectionRange;
  const [startAirbnbBlock, endAirbnbBlock] = airbnbBlockDays;

  return areIntervalsOverlapping(
    { start: startSelection, end: endSelection },
    // areRangesOverlapping func is not start date inclusive so removing a day
    {
      start: subDays(startAirbnbBlock, 1),
      end: endAirbnbBlock
    }
  );
};

export const hasDatesAirbnbBlocked = (selectionRange, days) => {
  if (isEmpty(selectionRange)) {
    return false;
  }

  return getSelectionDaysFromRange(selectionRange, days).some(
    day => !day.airbnb_available
  );
};

export const containsBookings = (selectionRange, days) =>
  getSelectionDaysFromRange(selectionRange, days).some(
    day => get(day, "bookings.length", 0) > 0
  );

// Users can block dates if there are no bookings in the selection
// or if the selection contains only the last day of a booking
export const canBlockDates = (selectionRange, days, isAdmin = false) => {
  if (isAdmin) {
    return true;
  }

  const selectedDays = getSelectionDaysFromRange(selectionRange, days);

  const getNumberOfBookings = selectedDays =>
    selectedDays.reduce(
      (prev, current) => prev + (current.bookings?.length || 0),
      0
    );

  const selectionStartsWithLastDayOfBooking = selectedDays =>
    isSameDay(
      get(selectedDays, "[0].date", null),
      get(selectedDays, "[0].bookings[0].checkout", null)
    );
  return (
    !containsBookings(selectionRange, days) ||
    (selectionStartsWithLastDayOfBooking(selectedDays) &&
      getNumberOfBookings(selectedDays) === 1)
  );
};

export const getLastMonth = (now = new Date()) =>
  new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());

export const getLastDayOfMonth = date =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0);

export const getEndOfPreviousMonth = (now = new Date()) =>
  getLastDayOfMonth(getLastMonth(now));

export const getTimeRangeStartDate = (months, now = new Date()) =>
  new Date(now.getFullYear(), now.getMonth() - months, 1);

export const timeAgoInWords = (date1, date2) =>
  formatDistance(date1, date2, { addSuffix: true, locale });

export const dayIsInThePast = date => isPast(date) && !isToday(date);

//Function used to deal with dates in calendar selection feature
//Dates for selection come from few different places, that's why they come in two different formats.
//Afterwards this dates are used in date-fns functions which requires Datetime objects.
export const dateTimeOrStringParser = dateOrString => {
  if (isDate(dateOrString) && isValid(dateOrString)) {
    return dateOrString;
  }

  return parseDate(dateOrString);
};
