import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getSelectedPropertyId } from "../../../data/properties/selectors";
import api from "../../../services/api";

export const BOOKINGS_LIMIT = 10;

export const useBeds = () => {
  const [beds, setBeds] = useState(null);
  const [editError, setEditError] = useState(null);
  const [getError, setGetError] = useState(null);

  const selectedPropertyId = useSelector(getSelectedPropertyId);
  const { t } = useTranslation("host.home_info");

  const getBeds = useCallback(() => {
    setGetError(false);

    api
      .get(`/v1/hosts/properties/${selectedPropertyId}/beds`)
      .then(res => {
        setBeds(res);
      })
      .catch(err => {
        setGetError(t("common:problem_fetching_data"));
      });
  }, [selectedPropertyId, setGetError, t]);

  const postBed = useCallback(
    newBedData => {
      setEditError(null);

      api
        .post(`/v1/hosts/properties/${selectedPropertyId}/beds`, null, {
          bed_type: parseInt(newBedData.bed_type),
          mattress_size: parseInt(newBedData.mattress_size),
          location: newBedData.location
        })
        .then(res => {
          getBeds();
        })
        .catch(err => {
          if (err.error.code === 400) {
            setEditError(t("validation.please_fill_in_all_fields"));
          } else {
            setEditError(t("common:error.try_again"));
          }
        });
    },
    [selectedPropertyId, getBeds, setEditError, t]
  );

  const patchBed = useCallback(
    (newBedData, bedId) => {
      setEditError(null);

      api
        .patch(
          `/v1/hosts/properties/${selectedPropertyId}/beds/${bedId}`,
          null,
          {
            bed_type: parseInt(newBedData.bed_type),
            mattress_size: parseInt(newBedData.mattress_size),
            location: newBedData.location
          }
        )
        .then(res => {
          getBeds();
        })
        .catch(err => {
          if (err.error.code === 400) {
            setEditError(t("validation.please_fill_in_all_fields"));
          } else {
            setEditError(t("common:error.try_again"));
          }
        });
    },
    [selectedPropertyId, getBeds, setEditError, t]
  );

  const deleteBed = useCallback(
    bedId => {
      api
        .delete(`/v1/hosts/properties/${selectedPropertyId}/beds/${bedId}`)
        .then(res => {
          getBeds();
        })
        .catch(err => {
          setEditError(t("common:error.try_again"));
        });
    },
    [selectedPropertyId, getBeds, setEditError, t]
  );

  const handleBedEdit = (values, bedValues) => {
    if (bedValues.id) {
      patchBed(bedValues, bedValues.id);
    } else {
      postBed(bedValues);
    }
  };

  useEffect(() => {
    if (beds === null) {
      getBeds();
    }
  }, [selectedPropertyId, beds, getBeds]);

  return {
    getBeds,
    beds,
    postBed,
    patchBed,
    deleteBed,
    editError,
    getError,
    handleBedEdit
  };
};
