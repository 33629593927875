import "./TruncatedTextBox.scss";

import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Button from "../buttons/Button";

const DEFAULT_TRUNC_LENGTH = 150;

export class TruncatedTextBox extends Component {
  constructor(props) {
    super(props);

    const { text } = this.props;

    this.state = {
      expanded: false,
      truncated: text && text.length > this.truncationLength()
    };
  }

  truncationLength = () => this.props.truncationLength || DEFAULT_TRUNC_LENGTH;

  renderText = () => {
    const { expanded, truncated } = this.state;
    const { text } = this.props;

    if (!text) {
      return;
    }

    if (expanded) {
      return text;
    }

    if (truncated) {
      return `${text.substring(0, this.truncationLength())}...`;
    }

    return text;
  };

  toggleExpanded = () => {
    const { expanded } = this.state;

    this.setState({ expanded: !expanded });
  };

  render() {
    const { t } = this.props;
    const { expanded, truncated } = this.state;

    return (
      <div className="TruncatedTextBox">
        <pre className="TruncatedTextBox__text">{this.renderText()}</pre>
        {truncated && (
          <Button
            outline
            small
            onClick={this.toggleExpanded}
            data-testid="expand-button"
          >
            {expanded ? t("less") : t("read_more")}
          </Button>
        )}
      </div>
    );
  }
}

export default withTranslation("common")(TruncatedTextBox);
