import "./OnboardingChecks.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as CameraIcon } from "../../../../assets/icons/camera.svg";
import { ReactComponent as KeyIcon } from "../../../../assets/icons/key.svg";
import { ReactComponent as WifiIcon } from "../../../../assets/icons/wifi.svg";
import Button from "../../../../components/buttons/Button";

export const OnboardingChecks = ({ selectedProperty }) => {
  const { t } = useTranslation("host.onboarding");
  const navigate = useNavigate();

  const numberOfKeys = () => {
    if (!selectedProperty.bedrooms) {
      return t("checks.unknown_bedrooms_keys");
    }

    let numberOfKeys = selectedProperty.bedrooms >= 2 ? 4 : 3;

    return t("checks.keys", { key_number: numberOfKeys });
  };

  const redirectToHomeInfo = () => {
    navigate("/home-info");
  };

  return (
    <div className="OnboardingChecks">
      <div className="OnboardingChecks__item home-info">
        <Button onClick={redirectToHomeInfo}>
          {t("checks.home_info.header")}
        </Button>
        {t("checks.home_info")}
      </div>

      <div className="OnboardingChecks__item keys">
        <KeyIcon />
        <p className="OnboardingChecks__item-text">{numberOfKeys()}</p>
      </div>

      <div className="OnboardingChecks__item">
        <WifiIcon />
        <p className="OnboardingChecks__item-text">{t("checks.wifi")}</p>
      </div>

      <div className="OnboardingChecks__item">
        <CameraIcon />
        <p className="OnboardingChecks__item-text">{t("checks.furniture")}</p>
      </div>
    </div>
  );
};
