import Card from "components/common/Card";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button from "../../../../components/buttons/Button";
import {
  selectedPropertyAMSelector,
  selectedPropertyBillingPolicySelector,
  selectedPropertyPod
} from "../../../../data/properties/selectors";
import { REASON_UPGRADE_PLAN } from "./contact_reasons";
import { ContactMethodIcon } from "./ContactMethodIcon";

const PRICING_TIER_PREMIUM = "premium";

export const AmCard = ({ setFormInitialValues, handleScrollToForm }) => {
  const { t } = useTranslation("host.contact");
  const billingPolicy = useSelector(selectedPropertyBillingPolicySelector);
  const am = useSelector(selectedPropertyAMSelector);
  const { restricted_access: restrictedAccess } =
    useSelector(selectedPropertyPod);

  const {
    host_contact_calendly_url: amCalendlyUrl,
    profile_picture: amPicture,
    first_name: firstName,
    last_name: lastName
  } = am;

  const handleUpgradePlanClick = () => {
    setFormInitialValues({
      description: t("discuss_my_plan"),
      sfCategoryId: REASON_UPGRADE_PLAN
    });
    handleScrollToForm();
  };

  const { plan_tier: planTier, plan_type: planType } = billingPolicy;

  const header = useMemo(
    () =>
      planTier === PRICING_TIER_PREMIUM && firstName && lastName
        ? t("account_manager_with_name", { firstName, lastName })
        : t("account_manager"),
    [planTier, firstName, lastName, t]
  );

  if (restrictedAccess) {
    return null;
  }

  if (planTier !== PRICING_TIER_PREMIUM) {
    return (
      <Card title={<ContactMethodIcon type="am" />}>
        <div className="Contact__methods__info">
          <h3 className="small-header"> {header}</h3>

          <p className="reset-margin">{t("no_am_info")}</p>
        </div>

        <Button type="button" small outline onClick={handleUpgradePlanClick}>
          {t("upgrade_plan")}
        </Button>
      </Card>
    );
  }

  if (planType && planTier === PRICING_TIER_PREMIUM) {
    return (
      <Card title={!amPicture && <ContactMethodIcon type="am" />}>
        {amPicture && (
          <div className="Contact__image">
            <img src={amPicture} alt={t("am_photo")} />
          </div>
        )}

        <div className="Contact__methods__info">
          <h3 className="small-header"> {header}</h3>

          <p className="reset-margin">{t("am_info")}</p>
        </div>

        {amCalendlyUrl && (
          <Button type="button" small outline>
            <a href={amCalendlyUrl}>{t("book_a_call")}</a>
          </Button>
        )}
      </Card>
    );
  }

  return null;
};
