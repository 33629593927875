import "./BookingsTile.scss";

import { ReactComponent as BookingsIcon } from "assets/icons/bookings.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import Spinner from "components/Spinner";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getSelectedPropertyId } from "../../../../data/properties/selectors";
import {
  getCurrentBooking,
  upcomingBookingsSelector
} from "../../../../data/selectors/bookings";
import OverviewBooking from "./OverviewBooking";

export const BookingsTile = ({ loading }) => {
  const { t } = useTranslation("host.overview");
  const selectedPropertyId = useSelector(getSelectedPropertyId);
  const currentBooking = useSelector(state =>
    getCurrentBooking(state, selectedPropertyId)
  );
  const upcomingBookings = useSelector(state =>
    upcomingBookingsSelector(state, selectedPropertyId)
  );

  const renderBooking = () => {
    const hasUpcomingBookings = upcomingBookings.length;

    if (currentBooking) {
      return <OverviewBooking t={t} booking={currentBooking} />;
    }

    if (hasUpcomingBookings) {
      return <OverviewBooking t={t} booking={upcomingBookings[0]} />;
    }

    return (
      <div
        className="BookingsTile__no-bookings"
        data-testid="booking-tile-no-booking"
      >
        <BookingsIcon />
        <p>{t("bookings_tile.no_upcoming")}</p>
      </div>
    );
  };

  return (
    <div className="OverviewItem BookingsTile" data-cy="booking-tile">
      {loading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <div className="OverviewItem__header" data-testid="overview-booking">
            <h3 className="header" data-testid="overview-booking-title">
              {currentBooking
                ? t("bookings_tile.title.current")
                : t("bookings_tile.title.next")}
            </h3>

            <div className="right">
              <CalendarIcon />
              <strong>
                {t("bookings_tile.upcoming", {
                  upcomingBookingsNumber: upcomingBookings.length
                })}
              </strong>
            </div>
          </div>
          <div className="OverviewItem__content">{renderBooking()}</div>
        </React.Fragment>
      )}

      <Link to="/bookings" className="OverviewItem__see-more">
        {t("bookings_tile.see_more")}
      </Link>
    </div>
  );
};

export default BookingsTile;
