import "./GuestsBreakdown.scss";

import { sum } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

export const GuestsNumberSum = ({
  className,
  airbnbReservationData,
  mpGuestNumber
}) => {
  const { t } = useTranslation("host.bookings");

  if (!airbnbReservationData && !mpGuestNumber) {
    return null;
  }

  if (mpGuestNumber) {
    return (
      <p className={className}>
        {t("booking.guest", { count: mpGuestNumber })}
      </p>
    );
  }

  if (airbnbReservationData) {
    const {
      data: { guest_details: guestDetails }
    } = airbnbReservationData;

    if (!guestDetails) {
      return null;
    }

    const { number_of_adults, number_of_children, number_of_infants } =
      guestDetails;

    const numberOfGuests = sum([
      number_of_adults,
      number_of_children,
      number_of_infants
    ]);

    return (
      <p className={className}>
        {t("booking.guest", { count: numberOfGuests })}{" "}
      </p>
    );
  }
};

export const GuestsDetails = ({ airbnbReservationData, channelCode }) => {
  const { t } = useTranslation("host.bookings");

  if (channelCode !== "airbnb" || !airbnbReservationData) {
    return null;
  }

  const {
    data: { guest_details: guestDetails }
  } = airbnbReservationData;

  if (!guestDetails) {
    return null;
  }

  const { number_of_adults, number_of_children, number_of_infants } =
    guestDetails;

  if (!number_of_adults) {
    return null;
  }

  return (
    <div className="BookingDetails__content">
      <p className="section-label">{t("common:guests")}</p>
      <dl className="GuestsBreakdown">
        <dd>{t("booking.adult", { count: number_of_adults })}</dd>
        <dd>{t("booking.child", { count: number_of_children })}</dd>
        <dd>{t("booking.infant", { count: number_of_infants })}</dd>
      </dl>
    </div>
  );
};
