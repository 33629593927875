import "./StepBankAccount.scss";

import { mapValues } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../../../components/buttons/Button";
import api from "../../../../services/api";
import BankAccountDetails from "../../../dashboard/billing/components/bank_account/BankAccountDetails";
import BankAccountForm from "../../../dashboard/billing/components/bank_account/BankAccountForm";
import { ShapedImage } from "../../Shapes";
import { useOnboardingContext } from "../../SignupContext";
import NavButtonsWrapper from "../NavButtonsWrapper";

export const StepBankAccount = () => {
  const { t } = useTranslation("host.signup");
  const { loading, handlePrev, handleNext, propertyId, refreshData, data } =
    useOnboardingContext();
  const bankAccountData = data.billing.bank_account || null;
  const [serverError, setServerError] = useState(null);
  const [showEditForm, setShowEditForm] = useState(!bankAccountData);

  const handleSubmit = async ({
    setSubmitting,
    setErrors,
    resetForm,
    type,
    values
  }) => {
    const formattedValues = await mapValues(values, (value, key) => {
      if (key !== "owner_name") {
        return value.replace(/[-|\s]/g, "");
      }

      return value;
    });

    setServerError(null);

    await api
      .put(`/v1/hosts/properties/${propertyId}/bank_account`, null, {
        type,
        ...formattedValues
      })
      .then(res => {
        refreshData();
        setShowEditForm(false);
      })
      .catch(err => {
        setServerError(t("common:error.try_again"));
      });
  };

  const handleCancel = () => {
    if (bankAccountData) {
      setShowEditForm(false);
    }
  };

  const initialValues = {
    owner_name: "",
    account_number: "",
    sort_code: "",
    iban: "",
    bic: "",
    bsb: "",
    interac_email: ""
  };

  return (
    <React.Fragment>
      <div className="StepBankAccount Signup__step Signup__step__content">
        <h2 className="Signup__header" data-testid="bank-account-header">
          {t("bank_account.header")}
        </h2>

        <h4 className="Signup__subheader">{t("bank_account.subheader")}</h4>

        {showEditForm ? (
          <BankAccountForm
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            initialValues={initialValues}
            serverError={serverError}
            countryCode={data.property.locality.country_code}
          />
        ) : (
          bankAccountData && (
            <>
              <BankAccountDetails bankAccount={bankAccountData} />
              <Button
                type="button"
                theme="plain"
                onClick={() => setShowEditForm(true)}
              >
                {t("bank_account.edit")}
              </Button>
            </>
          )
        )}

        <NavButtonsWrapper
          next={
            <Button
              type="button"
              disabled={!bankAccountData}
              loading={loading}
              onClick={handleNext}
            >
              {t("common:next")}
            </Button>
          }
          prev={
            <Button type="button" theme="plain" onClick={handlePrev}>
              {t("common:previous")}
            </Button>
          }
        />
      </div>
      <ShapedImage imgId="bankAccount" containerClassName="StepBankAccount" />
    </React.Fragment>
  );
};
