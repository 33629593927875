import "./OnboardingStages.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import bem from "utils/bem";

export const OnboardingStages = ({ selectedProperty }) => {
  const { t } = useTranslation("host.onboarding");
  const { onboarding_meeting_booked: meetingBooked } = selectedProperty;

  return (
    <div className="Onboarding-Stages__container">
      <div className="Onboarding-Stages Onboarding-Stages--onboarding">
        <div className="Onboarding-Stages__progress">
          <div
            className="Onboarding-Stages__progress__track"
            style={{
              width: meetingBooked ? "62.5%" : "37.5%"
            }}
          />
        </div>

        <p
          className={bem("StageButton", {
            signup: true,
            done: true,
            active: false
          })}
        >
          {t("sign_up")}
        </p>

        <p
          className={bem("StageButton", {
            terms: true, // sets icon
            done: meetingBooked,
            active: !meetingBooked
          })}
        >
          {t("onboard_meeting")}
        </p>

        <p className="StageButton StageButton--property">{t("listing_live")}</p>

        <p className="StageButton StageButton--details">{t("first_guest")}</p>
      </div>
    </div>
  );
};
