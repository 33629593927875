import "./ActivityItem.scss";

import { ReactComponent as ChevronIcon } from "assets/icons/chevron.svg";
import classNames from "classnames";
import React, { useCallback, useState } from "react";

const ActivityItem = ({ content, date, t }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpanded = useCallback(
    () => setExpanded(expanded => !expanded),
    []
  );

  const { img, title, body, expandData } = content;

  return (
    <div className="ActivityItem">
      <div className="ActivityItem__main">
        {img}

        <div className="ActivityItem__body">
          <strong>
            <span>{title}</span>
          </strong>

          <div>
            <small className="ActivityItem__body__text">{body}</small>
          </div>
        </div>
        <div className="ActivityItem__details">
          <div className="ActivityItem__time">{date}</div>
          {expandData && (
            <button
              type="button"
              className={classNames(
                "Link-button",
                "ActivityItem__toggle-expand",
                {
                  "ActivityItem__toggle-expand--expanded": expanded
                }
              )}
              onClick={handleToggleExpanded}
              data-cy="toggle-expand"
            >
              {expanded ? t("common:less") : t("common:see_more")}
              <ChevronIcon />
            </button>
          )}
        </div>
      </div>

      {expandData && (
        <div
          className={classNames("ActivityItem__extra", {
            "ActivityItem__extra-active": expanded
          })}
          testid="activity-expandable"
        >
          <p>
            {t("guest_review")}
            <span className="ActivityItem__extra__comment">{expandData}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default ActivityItem;
