import { ReactComponent as ExitIcon } from "assets/icons/exit.svg";
import React from "react";

export const LogoutButton = ({ label, onClick }) => (
  <button type="button" className="link" onClick={onClick}>
    <ExitIcon />

    {label}
  </button>
);
