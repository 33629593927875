import "./TermsFullTime.scss";

import Spinner from "components/Spinner";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PLAN_TYPE_FULL_TIME } from "utils/constants";

import {
  getSelectedPropertyId,
  selectedPropertyLatestBillingPolicySelector,
  selectedPropertyLocalitySelector
} from "../../data/properties/selectors";
import api from "../../services/api";
import Button from "../buttons/Button.jsx";
import DefaultError from "./DefaultError.jsx";
import InlineIframe from "./InlineIframe.jsx";

const SALES_MAIL = "sales@houst.com";
const DEFAULT_AVAILABILITY_ALLOWANCE_YEARLY = 14;
const PAYMENT_TERM_YEARLY = "yearly";

export const TermsTable = () => {
  const { t } = useTranslation("host.terms");
  const [terms, setTerms] = useState("");
  const [error, setError] = useState(null);
  const propertyId = useSelector(getSelectedPropertyId);
  const locality = useSelector(selectedPropertyLocalitySelector);
  const {
    fee_percent: feePercent,
    plan_length: planLength,
    plan_name: planName,
    plan_price: planPrice,
    plan_type: planType,
    availability_allowance: availabilityAllowance,
    payment_term: paymentTerm
  } = useSelector(selectedPropertyLatestBillingPolicySelector);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/v1/hosts/properties/${propertyId}/terms.html`)
      .then(response => setTerms(response))
      .catch(() => {
        setError(t("common:terms_error"));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [propertyId, t]);

  const print = () => {
    document.getElementById("terms").contentWindow.print();
  };

  const displayWithCurrency = amount => {
    return `${locality.currency_symbol}${amount}`;
  };

  if (loading) {
    return <Spinner cover />;
  }

  return (
    <>
      {error && (
        <DefaultError>
          {error} <a href={`mailto:${SALES_MAIL}`}>{SALES_MAIL}</a>
        </DefaultError>
      )}

      <>
        <p>{t("full_time.preface")}</p>
        <table className="TermsFullTime__summary">
          <tbody>
            <tr>
              <td>{t("summary.contract_length")}</td>
              <td>
                {t("host.billing:months_count", {
                  count: planLength
                })}
              </td>
            </tr>
            <tr>
              <td>{t("summary.contract_plan")}</td>
              <td>{planName}</td>
            </tr>
            <tr>
              <td>{t("summary.booking_fee")}</td>
              <td>{feePercent}% + VAT</td>
            </tr>
            <tr>
              <td>{t("summary.platform_fee")}</td>
              <td>
                {t(
                  paymentTerm === PAYMENT_TERM_YEARLY
                    ? "summary.platform_fee_explained_yearly"
                    : "summary.platform_fee_explained_monthly",
                  {
                    price: displayWithCurrency(planPrice)
                  }
                )}
              </td>
            </tr>
            {planType === PLAN_TYPE_FULL_TIME && (
              <tr>
                <td>{t("summary.block_day_allowance")}</td>
                <td>
                  {availabilityAllowance ||
                    DEFAULT_AVAILABILITY_ALLOWANCE_YEARLY}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </>

      {terms && (
        <div className="TermsFullTime__terms-container">
          <InlineIframe
            id="terms"
            title="contract"
            htmlContent={terms}
            data-testid="terms-and-conditions"
          />

          <Button
            small
            theme="link"
            type="button"
            onClick={print}
            className="TermsFullTime__print-button"
          >
            {t("host.setup:terms.print")}
          </Button>
        </div>
      )}
    </>
  );
};
