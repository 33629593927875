import "./MaintenanceList.scss";

import Spinner from "components/Spinner";
import React from "react";
import { useTranslation } from "react-i18next";

import { MaintenanceItem } from "./MaintenanceItem";

export const MaintenanceList = ({ maintenanceData, loading, currentTabId }) => {
  const { t } = useTranslation("host.maintenance");

  if (loading) {
    return (
      <p className="MaintenanceList-loading" testid="maintenance-loading">
        <Spinner /> {t("loading")}
      </p>
    );
  }

  if (maintenanceData.length === 0) {
    return (
      <div className="MaintenanceList-empty" data-cy="maintenance-list-empty">
        <p>{t(`no_items_found.${currentTabId}`)}</p>
      </div>
    );
  }

  return maintenanceData.map(item => (
    <MaintenanceItem item={item} key={item.id} />
  ));
};

export default MaintenanceList;
