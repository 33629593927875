import { startOfDay } from "date-fns";
import { mergeCollections } from "utils/fn";

import { parseDateTime } from "../../utils/dateParser";
import {
  RECEIVE_BOOKING,
  RECEIVE_PREVIOUS_BOOKINGS,
  RECEIVE_UPCOMING_BOOKINGS
} from "../action_types";
import { RECEIVE_BOOKING_CANCELLATION_PENALTY } from "../actions/bookings";

const MULTIPLAT_CHANNELS = ["booking", "homeaway", "expedia"];

export const parseBooking = booking => {
  return {
    ...booking,
    checkin: startOfDay(parseDateTime(booking.checkin)),
    checkout: startOfDay(parseDateTime(booking.checkout)),
    is_multiplat: MULTIPLAT_CHANNELS.includes(
      booking.booking_channel?.code || false
    )
  };
};

export const bookingEarnings = ({ host_earnings, estimated_earnings }) =>
  host_earnings || estimated_earnings;

export const hasEarnings = ({ host_earnings, estimated_earnings }) =>
  Boolean(host_earnings) || Boolean(estimated_earnings);

const bookings = (
  state = { previous: {}, upcoming: {}, penalties: {} },
  action
) => {
  switch (action.type) {
    case RECEIVE_BOOKING: {
      if (!action.booking) {
        return state;
      }

      return {
        ...state,
        list: state.list.map(existingBooking => {
          return existingBooking.id === action.booking.id
            ? parseBooking(action.booking)
            : existingBooking;
        })
      };
    }

    case RECEIVE_UPCOMING_BOOKINGS: {
      const { bookings, propertyId } = action;
      const newBookings = Array.isArray(state.upcoming[propertyId])
        ? mergeCollections(
            bookings.map(parseBooking),
            state.upcoming[propertyId]
          )
        : bookings.map(parseBooking);

      return {
        ...state,
        upcoming: {
          ...state.upcoming,
          [propertyId]: newBookings
        }
      };
    }

    case RECEIVE_PREVIOUS_BOOKINGS: {
      const { bookings, propertyId } = action;
      const newBookings = Array.isArray(state.previous[propertyId])
        ? mergeCollections(
            bookings.map(parseBooking),
            state.previous[propertyId]
          )
        : bookings.map(parseBooking);

      return {
        ...state,
        previous: {
          ...state.previous,
          [propertyId]: newBookings
        }
      };
    }

    case RECEIVE_BOOKING_CANCELLATION_PENALTY: {
      return {
        ...state,
        penalties: {
          ...state.penalties,
          [action.penalty.booking_id]: action.penalty
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default bookings;
