import React, { useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  MAINTENANCE_STATUS_ACCEPTED,
  MAINTENANCE_STATUS_HOST_PLANNED,
  MAINTENANCE_STATUS_IGNORED,
  MAINTENANCE_STATUS_PLANNED,
  MAINTENANCE_STATUS_RESOLVED
} from "../../../data/maintenance/types";

export const UNRESOLVED_TAB_ID = "unresolved";
export const PLANNED_TAB_ID = "planned";
export const RESOLVED_TAB_ID = "resolved";

export const useMaintenanceTabs = maintenance => {
  const { t } = useTranslation("host.maintenance");
  const [currentTabId, updateCurrentTabId] = useState(UNRESOLVED_TAB_ID);

  const data = {
    [UNRESOLVED_TAB_ID]: maintenance[MAINTENANCE_STATUS_ACCEPTED],
    [PLANNED_TAB_ID]: [
      ...maintenance[MAINTENANCE_STATUS_PLANNED],
      ...maintenance[MAINTENANCE_STATUS_HOST_PLANNED]
    ],
    [RESOLVED_TAB_ID]: [
      ...maintenance[MAINTENANCE_STATUS_RESOLVED],
      ...maintenance[MAINTENANCE_STATUS_IGNORED]
    ]
  };

  const currentTabData = data[currentTabId];

  const headers = useMemo(
    () => [
      {
        id: UNRESOLVED_TAB_ID,
        text: (
          <>
            {t("tab.unresolved")}

            <span className="Maintenance__tabs__count">
              {maintenance[MAINTENANCE_STATUS_ACCEPTED].length}
            </span>
          </>
        ),
        dataCy: "maintenance-unresolved-tab"
      },
      {
        id: PLANNED_TAB_ID,
        text: (
          <>
            {t("tab.planned")}

            <span className="Maintenance__tabs__count">
              {maintenance[MAINTENANCE_STATUS_PLANNED].length +
                maintenance[MAINTENANCE_STATUS_HOST_PLANNED].length}
            </span>
          </>
        ),
        dataCy: "maintenance-planned-tab"
      },
      {
        id: RESOLVED_TAB_ID,
        text: (
          <>
            {t("tab.resolved")}

            <span className="Maintenance__tabs__count">
              {maintenance[MAINTENANCE_STATUS_RESOLVED].length +
                maintenance[MAINTENANCE_STATUS_IGNORED].length}
            </span>
          </>
        ),
        dataCy: "maintenance-resolved-tab"
      }
    ],
    [maintenance, t]
  );

  return { headers, currentTabId, updateCurrentTabId, currentTabData };
};
