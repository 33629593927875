import { differenceInMonths, startOfYear } from "date-fns";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { receiveHasAnyEarnings } from "../../../data/actions/performance";
import api from "../../../services/api";
import { formatData, getPerformanceDateRange } from "./components/utils";
import { samplePerfomanceData } from "./samplePerformanceData";

const monthsInYTD = differenceInMonths(Date.now(), startOfYear(Date.now()));

export const useGetEarnings = (
  selectedPropertyId,
  hasAnyEarnings,
  locality
) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getEarnings = useCallback(() => {
    api
      .get(`/v1/hosts/properties/${selectedPropertyId}/performance/summary`)
      .then(res => {
        dispatch(
          receiveHasAnyEarnings(selectedPropertyId, res.has_any_earnings)
        );
      });
  }, [selectedPropertyId, dispatch]);

  const getGraphData = useCallback(
    ({
      dateRange = getPerformanceDateRange({
        monthsToShow: monthsInYTD,
        isYTD: true
      })
    } = {}) => {
      if (hasAnyEarnings) {
        setLoading(true);
        api
          .get(
            `/v1/hosts/properties/${selectedPropertyId}/performance/income`,
            { ...dateRange }
          )
          .then(data => {
            setData(formatData(data, locality));
          })
          .catch(error => {
            setError(true);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setData(formatData(samplePerfomanceData(locality), locality));
      }
    },
    [hasAnyEarnings, locality, selectedPropertyId]
  );

  return { getEarnings, getGraphData, data, loading, error };
};
