import "./SignupTopBar.scss";

import Logo from "components/common/Logo";
import { countries } from "countries-list";
import { logout } from "data/actions/user";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useOnboardingContext } from "scenes/signup/SignupContext";

import Button from "../../../components/buttons/Button";
import { LogoIcon } from "../../../components/common/LogoIcon.jsx";
import { LangSelect } from "../../../components/LangSelect";

const SignupTopBar = ({ logout }) => {
  const {
    data: {
      property: { address, postcode, locality }
    }
  } = useOnboardingContext();
  const { t } = useTranslation();

  const countryCode = locality?.country_code;
  const showAddress = address && postcode && countryCode;
  const countryName = countries[countryCode]?.native;

  return (
    <div className="SignupTopBar">
      <div className="SignupTopBar__buttons">
        <LogoIcon countryCode={countryCode} />
        <Logo countryCode={countryCode} />
        <LangSelect />
        <Button outline type="button" small onClick={logout}>
          {t("common:logout")}
        </Button>
      </div>

      {showAddress && (
        <p className="SignupTopBar__address">{`${address} ${postcode}, ${countryName}`}</p>
      )}
    </div>
  );
};

export default connect(null, { logout })(SignupTopBar);
