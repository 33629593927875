import "./Platforms.scss";

import { getSelectedProperty } from "data/properties/selectors";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Alert from "../../../components/common/Alert.jsx";
import { useBankAccount } from "../../../utils/useBankAccount";
import ExtraInfo from "./components/ExtraInfo";
import PlatformsList from "./components/PlatformsList";
import PlatformsToggle from "./components/PlatformsToggle";

const Platforms = () => {
  const { t } = useTranslation("host.platforms");
  const selectedProperty = useSelector(getSelectedProperty);
  const { isMissingBankAccount } = useBankAccount();

  return (
    <div>
      <div className="Platforms">
        <h2 className="header" data-cy="header">
          {t("platforms_list.title")}
        </h2>

        {isMissingBankAccount && (
          <Alert warning small>
            <p data-cy="bank-details-alert">
              <Trans ns="host.platforms" i18nKey="missing_bank_details_alert">
                Please fill in the bank account details in&nbsp;
                <Link to="/billing">billing section</Link>.
              </Trans>
            </p>
          </Alert>
        )}

        <PlatformsToggle property={selectedProperty} />
        <PlatformsList property={selectedProperty} />
        <ExtraInfo property={selectedProperty} />
      </div>
    </div>
  );
};

export default Platforms;
